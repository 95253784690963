import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SelectControl from "./selectcontrol";
import Loading from "./loading";
import Header from "./header";
import Enumerable from "linq";
import AcctSectBenchRptGrid from "./AccountSectBenchGrid";
import "@progress/kendo-theme-material/dist/all.css";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { filterBy } from "@progress/kendo-data-query";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Modal from "react-bootstrap/Modal";
import { Modal as AntdModal, Button as AntdButton } from "antd";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";

import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";
import { FormControlLabel, Switch } from "@mui/material";

const AcctSectBenchRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem("token"));
  localStorage.setItem(
    "acctSectmodelId",
    JSON.stringify({ modelDesc: "ALL", modelId: -1 })
  );
  var date = new Date(localStorage.getItem("processingDate"));
  const [AcctSectBenchRptData1, populateAcctSectBenchRptData1] = useState([]);
  const [AcctSectBenchRptData2, populateAcctSectBenchRptData2] = useState([]);
  const [AcctList, populateAccountListData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))?.slice()
  );
  //const [selAcct, SetselAcct] = useState(localStorage.getItem('IsAcctSelected')? JSON.parse(localStorage.getItem('AcctSelected')):JSON.parse(localStorage.getItem('acctData'))[0]);
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [selectedAcct, setSelectedAcct] = useState(0);
  const [loading, setLoading] = useState(false);
  //const []
  const [selModel, setSelModel] = useState({
    modelDesc: "None",
    modelId: -1,
  });
  console.log("selModel", selModel);
  const [modellist, setModellist] = useState(
    JSON.parse(localStorage.getItem("modelList"))
  );
  const [session, setSession] = useState("");
  const [enableCombo, setEnableCombo] = useState(false);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [flag, setFlag] = useState(false);
  const [selectedOption, setSelectedOption] = useState(1);
  const [showExecutiveReport, setShowExecutiveReport] = useState(true);
  const [aggregateMode, setAggregateMode] = useState(0);
  const [selecteBenchmark, setSelecteBenchmark] = useState();
  const [asOfDate, setAsOfDate] = useState(date);
  const [onThisPage, setOnThisPage] = useState(false);
  const [priceDate, setPriceDate] = useState(date);
  // validation error message
  const [validationError, setValidationError] = useState("");
  const [isPriceExistsFlagInd, setIsPriceExistsFlagInd] = useState("");
  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  const dispatch = useDispatch();

  const apicallval = useSelector(selectapicall);

  const [initialState, setInitialState] = useState({
    asOfDate: date,
    selectedOption: 1,
    priceDate: date,
    selModel: {
      modelDesc: "None",
      modelId: -1,
    },
    showExecutiveReport: true,
    aggregateMode: 0,
  });

  useEffect(() => {
    debugger;
    // const fetchData = async () => {
    //   setLoading(true);
    //   setEnableCombo(true);
    //   try {
    //     let asOfId = JSON.parse(localStorage.getItem("userId")); // data.Email;

    //     GetAcctSectBenchRptData();
    //   } catch (error) {
    //     console.error(error.message);
    //   }
    // };
    // fetchData();
  }, []);

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetAcctSectBenchRptData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const GetAcctSectBenchRptData = async () => {
    debugger;
    setLoading(true);
    setEnableCombo(true);
    debugger;
    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let mAsOfId = JSON.parse(localStorage.getItem("userId"));
    let lsSelectedAcct = JSON.parse(localStorage.getItem("ASectSelectedAcct"));
    let AcctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;
    // if (lsSelectedAcct !== null) {
    //   AcctId = lsSelectedAcct.acctId;
    // }

    let pageId = 0;
    let mBenchMark = selModel.modelId;
    console.log("benchmarkkkkk", selModel);
    let mConsolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;
    let asOfDt = formatDate(asOfDate, "MM/dd/yyyy");
    let priceDt = formatDate(priceDate, "MM/dd/yyyy");
    let priceFlag = selectedOption;
    let isConsolidation = false;
    let showExcldAst = showExecutiveReport;
    // let mBenchMark =
    //   localStorage.getItem("acctSectmodelId") !== "undefined"
    //     ? JSON.parse(localStorage.getItem("acctSectmodelId")).modelId
    //     : -1;

    // const postData = { mAsOfId, AcctId, pageId, mBenchMark };
    const postData = {
      mAsOfId,
      AcctId,
      pageId,
      mBenchMark,
      mConsolidationId,
      asOfDt,
      priceDt,
      priceFlag,
      showExcldAst,
      aggregateMode,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/AccountSectorsBenchmark", postData, config)
      .then((response) => {
        console.log("response", response);
        debugger;
        const rowData = response.data || [];

        setIsPriceExistsFlagInd(rowData.priceValidation);

        rowData.ocAcctSectT2 = rowData.ocAcctSectT2 || [];

        let consolidationName = JSON.parse(
          localStorage.getItem("RelationID")
        )?.cnsldtnNm;

        var i = 0;

        if (AcctId == 0 && aggregateMode == 1) {
          for (i = 0; i < rowData.ocAcctSectT2.length; i++) {
            rowData.ocAcctSectT2[i].account = consolidationName;
          }
        }

        populateAcctSectBenchRptData1(rowData);
        populateAcctSectBenchRptData2(rowData.ocAcctSectT2);
        setFlag(true);
        //Check
        localStorage.setItem(
          "filterData",
          JSON.stringify(rowData.ocAcctSectT2)
        );
        populateAccountListData(rowData.ocAcctSectT3);
        //setSelAcctData(rowData.ocAcctSectT3);
        // if (lsSelectedAcct !== null) {
        //   //SetselAcct(lsSelectedAcct);
        //   //setSelectedAcct(lsSelectedAcct.acctId);
        // }
        // else {
        //   //SetselAcct(rowData.ocAcctSectT3[0]);
        //   //setSelectedAcct(rowData.ocAcctSectT3[0].acctId);
        // }
        let selAC = JSON.parse(localStorage.getItem("AcctSelected"));
        localStorage.setItem(
          "AcctSctrInitialData",
          JSON.stringify(rowData.ocAcctSectT2)
        );
        setLoading(false);
        setEnableCombo(false);
        // setSelModel(
        //   Enumerable.from(modellist)
        //     .where((w) => w.modelId === rowData.benchmark)
        //     .toArray()[0]
        // );

        for (let i = 0; i < rowData.ocAcctSectT3.length; i++) {
          rowData.ocAcctSectT3[i].shrtNm = selAC.extrnlAcctId;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        //return error;
      });
  };

  if (apicallval === 1) {
    GetAcctSectBenchRptData();
    dispatch(callremove());
  }

  let relationshipdata = JSON.parse(localStorage.getItem("RelationList"));

  const t2accountmappedData = AcctSectBenchRptData2?.map((group) => {
    const relatedAccount = relationshipdata?.find(
      (rel) => rel.acctId === group.acctId
    );
    return {
      ...group, // Add the properties from the group object
      accountName: relatedAccount ? relatedAccount.shrtNm : null,
      externalAccountId: relatedAccount ? relatedAccount.extrnlAcctId : null,
    };
  });

  const modelChange = (event) => {
    debugger;

    if (event.target.value === null) {
      debugger;
      setSelModel({ modelDesc: "None", modelId: -1 });
    } else {
      debugger;
      setSelModel(event.target.value);
      setValidationError("");
    }
  };

  const filterData = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };

  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };

  const handleChange = (event) => {
    if (event.target.value === null || loading) {
      //SetselAcct('');
      SetselAcct(selAcct);
      //SetselAcct(JSON.parse(localStorage.getItem('acctData'))[0]);
      //SetselAcct(selAcct);
      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem("AcctSelected", JSON.stringify(selAcct));
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetAcctSectBenchRptData();
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  const openModal = () => {
    setInitialState({
      asOfDate,
      selectedOption,
      priceDate,
      selModel,
      showExecutiveReport,
      aggregateMode,
    });

    setIsVisible(true);
  };

  const closeModal = () => {
    setAsOfDate(initialState.asOfDate);
    setSelectedOption(initialState.selectedOption);
    setPriceDate(initialState.priceDate);
    setSelModel(initialState.selModel);
    setShowExecutiveReport(initialState.showExecutiveReport);
    setAggregateMode(initialState.aggregateMode);

    setIsVisible(false);
    !onThisPage && navigate("/dashboard");
  };

  const onSubmitModal = (e) => {
    debugger;
    e.preventDefault();

    if (selModel.modelId === -1) {
      setValidationError("Please select a model.");
      return;
    }

    setValidationError("");
    setOnThisPage(true);
    setIsVisible(false);

    {
      selModel.modelId !== -1 && GetAcctSectBenchRptData();
    }
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCheckboxChange = () => {
    setShowExecutiveReport(!showExecutiveReport);
  };

  const handleAggregateMode = () => {
    if (aggregateMode == 0) {
      setAggregateMode(1);
    }
    if (aggregateMode == 1) {
      setAggregateMode(0);
    }
  };

  // const handleDropdownChange = (event) => {
  //   setSelecteBenchmark(event.target.value);
  // };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            <div className="my-1">
              {/* <div className="rounded"></div>
              <div className="row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
                <div className="col-md-4 col-lg-5 col-sm-11 text-start">
                  <span className="px-2">Account(s)</span>
                  <ComboBox
                    style={{
                      width: "350px",
                    }}
                    data={selAcctData}
                    textField="extrnlAcctId"
                    dataItemKey="acctId"
                    filterable={true}
                    value={selAcct}
                    onChange={handleChange}
                    onFilterChange={filterChange}
                    disabled={enableCombo}
                  />
                </div>
                <div className="col">
                  <div>Model:</div>

                  <ComboBox
                    style={{
                      width: "300px",
                    }}
                    data={modellist}
                    textField="modelDesc"
                    dataItemKey="modelId"
                    filterable={true}
                    value={selModel}
                    onChange={modelChange}
                    disabled={enableCombo}
                  />
                </div>
              </div> */}
            </div>
            {!isVisible && <Loading />}
            {/* <Loading /> */}
          </div>
        )}
      </>
    );
  }
  return (
    <div>
      {/* <Header /> */}
      {/* {flag ? <AntdButton onClick={openModal}>Open Modal</AntdButton> : <></>} */}
      <AntdModal
        open={isVisible}
        title="Customize Account Sectors Comparison"
        closable={false}
        footer={[]}
      >
        <form onSubmit={onSubmitModal}>
          <div className="pt-3">
            <span>
              <h6>As of Date</h6>
            </span>
            <DatePicker
              id="dpFrm"
              value={asOfDate}
              format="MM/dd/yyyy"
              // calendar={CustomCalendar}
              // min={minFrmDt}
              // max={maxFrmDt}
              // disabled={isDisabled}
              onChange={(e) => {
                setAsOfDate(e.target.value);
              }}
            />
          </div>
          <hr style={{ margin: "20px 0" }} />

          <h6>Select Pricing Option</h6>
          <div>
            <label>
              <input
                type="radio"
                value={1}
                checked={selectedOption == 1}
                onChange={handleRadioChange}
              />
              &nbsp;Use Current Price
            </label>
            <br />
            <label>
              <input
                type="radio"
                value={2}
                checked={selectedOption == 2}
                onChange={handleRadioChange}
              />
              &nbsp; Use End of Month Price
            </label>
            <br />
            <label>
              <input
                type="radio"
                value={3}
                checked={selectedOption == 3}
                onChange={handleRadioChange}
              />
              &nbsp; Use Non EOM Price
            </label>

            {selectedOption == 3 && (
              <DatePicker
                id="dpFrm"
                value={priceDate}
                format="MM/dd/yyyy"
                // calendar={CustomCalendar}
                // min={minFrmDt}
                // max={maxFrmDt}
                // disabled={isDisabled}
                onChange={(e) => {
                  setPriceDate(e.target.value);
                }}
              />
            )}
            <hr style={{ margin: "20px 0" }} />
          </div>

          {/* <div>
            <h6>Benchmark/ Modal </h6>
            <select value={selecteBenchmark} onChange={handleDropdownChange}>
              <option value="">-- Select --</option>{" "}
              {Benchmarks.map((option, index) => (
                <option key={index} value={option.toLowerCase()}>
                  {option}
                </option>
              ))}
            </select>
          </div> */}

          <div>
            <h6>Benchmark/ Model </h6>
            <ComboBox
              style={{
                width: "300px",
              }}
              data={modellist}
              textField="modelDesc"
              dataItemKey="modelId"
              filterable={true}
              value={selModel}
              onChange={modelChange}
            />
            {validationError && (
              <div style={{ color: "red", marginTop: "10px" }}>
                {validationError}
              </div>
            )}
          </div>

          {/* <div>
            <label className="mx-1 my-1">
              <input
                type="checkbox"
                checked={showExecutiveReport}
                onChange={handleCheckboxChange}
              />
              &nbsp;Show Excluded Assets
            </label>
          </div> */}
          <hr></hr>
          <h6>Miscellaneous Section</h6>
          <FormControlLabel
            control={
              <Switch
                checked={showExecutiveReport}
                onClick={handleCheckboxChange}
                name="chkCtr"
              />
            }
            label="Show Excluded Assets."
            style={{ marginBottom: "10px", display: "block" }}
          />

          {AcctId == 0 && (
            <FormControlLabel
              control={
                <Switch
                  checked={aggregateMode}
                  onClick={handleAggregateMode}
                  name="chkCtr"
                />
              }
              label="Run In Aggregate."
              style={{ marginBottom: "10px", display: "block" }}
            />
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={closeModal}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntdModal>

      <div className="row d-flex justify-content-between align-items-center mx-2 py-1 border-bottom">
        {/* Report Heading */}
        <div className="col reportheading fs-6">
          Account Sectors Comparison Report
        </div>

        {/* Placeholder for Additional Information (if needed) */}
        <div className="col text-center">
          {/* Add dynamic text here if required */}
        </div>

        {/* Filter Button */}
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={openModal}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>
      </div>

      {/* <AcctSectBenchRptGrid
        data={AcctSectBenchRptData2}
        data1={AcctSectBenchRptData1}
      />{" "}
      : <></> */}
      {flag && !isVisible ? (
        <AcctSectBenchRptGrid
          data={AcctSectBenchRptData2}
          data1={AcctSectBenchRptData1}
          isPriceExistFlagInd={isPriceExistsFlagInd}
          aggregateMode={aggregateMode}
          selModel={selModel}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AcctSectBenchRpt;
