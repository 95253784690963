import React from "react";
import { useState, useEffect } from "react";
import "../index.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
  Outlet,
  useParams,
  NavLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  signInWithGooglePopup,
  signInWithMicrosoftPopup,
  createUserDocumentFromAuth,
  firebaseApp,
  auth,
} from "../utils/firebase/firebase.utils";

import RTCustomDashboardRpt from "./cstmDashBoardRpt";
import Home from "./home";
import Dashboard from "./dashboard";
import AcctHoldingRpt from "./acctHoldingRpt";
import AcctTransactionRpt from "./acctTransactionRpt";
import PageNotFound from "./pageNotFound";
import FixdIncmFndmntlsRpt from "./fixdIncmFndmntlsRpt";
import AccountProfileReport from "./accountProfileReport";
import PortfolioHoldingsRpt from "./portfoliHoldingsRpt";
import FixdIncmMaturityLadrRpt from "./fixdIncmMtrtyLadrRpt";
import AcctSectBenchRpt from "./AcctSectBenchRpt";
import AcctPerfRpt from "./AcctPerfRpt";
import FixedIncomePortfolioOverviewRpt from "./fixedIncomePortfolioOverviewRpt";
import SctrReturnPerformanceRpt from "./sctrReturnPerfrmnceRpt";
import TimeWtdRtnRpt from "./TimeWtdRtnRpt";
import DailyPerformanceRpt from "./dailyPerformanceRpt";
import CustomReportMain from "./cstmRptMain";
import ProjectedCashFlowRpt from "./projectedCashFlowRpt";
import RTPortfolioBreakDownRpt from "./portfolioBreakDownRpt";
import RTPerformanceWidgetRpt from "./performanceWidgetRpt";
import AssetAllocModelRptMain from "./assetAllocModelRptMain";
import TopHoldingsReport from "./topHoldingsReport";
import AstByMrktCapitalRpt from "./astByMrktCapitalRpt";
import PortPerfSecurityRpt from "./portPerfSecurityRpt";
import PrchsSaleRpt from "./prchsSaleRpt";
import PrtflioSnpshtComprsnRpt from "./prtfolioSnpshtCmprsnRpt";
import RTPortfolioOverviewRpt from "./portfolioOverviewRpt";
import RTFxdIncmPrtfloOvrvw from "./fixedIncomePortfolioOverviewRpt";
import UserToken from "./userToken";
import CustomActiveReport from "./customActiveReport";
import AssetGrowthAndAllocationRpt from "./assetGrowthAndAllocationRpt";
import AnnualPortfolioValueRpt from "./annualPortfolioValueRpt";
import HistoricalMonthlyPerformanceRpt from "./historicalMonthlyPerformanceRpt";
import PerformanceRiskMetricsRpt from "./performanceRiskMetricsRpt";
import BenchmarkSelPeriodPerfRpt from "./benchmarkSelPeriodPerRpt";
import SelectedPeriodRORSMARpt from "./selectedPeriodRORSMARpt";
import Header from "./header";
import Blank from "./blankpage";
import { Modal } from "react-bootstrap";

function Login() {
  debugger;
  let urlpath = window.location.pathname;
  return (
    <>
      <div></div>

      <Router forceRefresh={true}>
        {urlpath !== "cstmRptMain" && urlpath !== "userToken" ? (
          <Header></Header>
        ) : (
          <></>
        )}
        {/* {JSON.parse(localStorage.getItem("AccountID")).acctId===0 && JSON.parse(localStorage.getItem("RelationID")).cnsldtnId===0} */}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blank" element={<Blank></Blank>} />

          <Route path="/dashboard" element={<Dashboard />} />
          {/* <Route path="/header" element={<Header></Header>}/> */}
          <Route path="/acctHoldingRpt" element={<AcctHoldingRpt />} />
          <Route path="/acctTransactionRpt" element={<AcctTransactionRpt />} />
          <Route
            path="/fixdIncmFndmntlsRpt"
            element={<FixdIncmFndmntlsRpt />}
          />
          <Route
            path="/accountProfileReport"
            element={<AccountProfileReport />}
          />
          <Route
            path="/annualPortfolioReport"
            element={<AnnualPortfolioValueRpt />}
          />
          <Route
            path="/portfoliHoldingsRpt"
            element={<PortfolioHoldingsRpt />}
          />
          <Route
            path="/fixdIncmMtrtyLadrRpt"
            element={<FixdIncmMaturityLadrRpt />}
          />
          <Route path="/AcctSectBenchRpt" element={<AcctSectBenchRpt />} />
          <Route path="/AcctPerfRpt" element={<AcctPerfRpt />} />
          <Route
            path="/fixedIncomePortfolioOverviewRpt"
            element={<FixedIncomePortfolioOverviewRpt />}
          />
          <Route
            path="/sctrReturnPerfrmnceRpt"
            element={<SctrReturnPerformanceRpt />}
          />
          <Route path="/TimeWtdRtnRpt" element={<TimeWtdRtnRpt />} />
          <Route
            path="/dailyPerformanceRpt"
            element={<DailyPerformanceRpt />}
          />
          <Route path="/cstmRptMain" element={<CustomReportMain />} />
          <Route
            path="/projectedCashFlowRpt"
            element={<ProjectedCashFlowRpt />}
          />
          <Route
            path="/portfolioBreakDownRpt"
            element={<RTPortfolioBreakDownRpt />}
          />
          <Route
            path="/PerformanceWidgetRpt"
            element={<RTPerformanceWidgetRpt />}
          />
          <Route
            path="/assetAllocModelRptMain"
            element={<AssetAllocModelRptMain />}
          />
          <Route path="/topHoldingsReport" element={<TopHoldingsReport />} />
          <Route
            path="/astByMrktCapitalRpt"
            element={<AstByMrktCapitalRpt />}
          />
          <Route
            path="/portPerfSecurityRpt"
            element={<PortPerfSecurityRpt />}
          />
          <Route path="/prchsSaleRpt" element={<PrchsSaleRpt />} />
          <Route
            path="/prtfolioSnpshtCmprsnRpt"
            element={<PrtflioSnpshtComprsnRpt />}
          />
          <Route
            path="/portfolioOverviewRpt"
            element={<RTPortfolioOverviewRpt />}
          />
          <Route path="/cstmDashBoardRpt" element={<RTCustomDashboardRpt />} />
          <Route
            path="/fixedIncomePortfolioOverviewRpt"
            element={<RTFxdIncmPrtfloOvrvw />}
          />
          <Route path="/customActiveReport" element={<CustomActiveReport />} />
          <Route
            path="/AssetGrowthAndAllocationRpt"
            element={<AssetGrowthAndAllocationRpt />}
          />
          <Route
            path="/historicalMonthlyPerformance"
            element={<HistoricalMonthlyPerformanceRpt />}
          />

          <Route
            path="/PerformanceRiskMetricsRpt"
            element={<PerformanceRiskMetricsRpt />}
          />

          <Route
            path="/BenchmarkSelPeriodPerfRpt"
            element={<BenchmarkSelPeriodPerfRpt />}
          />

          <Route
            path="/openSelectedPeriodRORSMA"
            element={<SelectedPeriodRORSMARpt />}
          />

          <Route path="*" element={<PageNotFound />} />
          <Route path="/userToken" element={<UserToken />} />
        </Routes>
      </Router>
    </>
  );
}

export default Login;
