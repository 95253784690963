import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";
import { CustomColumnMenu } from "./customColumnMenu";
//import data from './data.json';
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import BankLogoPage from "./bankLogoPage";

import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import {
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import Modal from "react-bootstrap/Modal";
import { Card } from "@mui/material";
import { display } from "@mui/system";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";
const aggregates = [
  {
    field: "totMarket",
    aggregate: "sum",
  },
];

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "1px",

          width: "98%",
          borderBottom: "1px solid #bce8f1",
        }}
      >
        <div className="row d-flex mx-3">
          <div className="col text-start">
            <a className="px-2">
              <BankLogoPage />
            </a>
          </div>
          <div className="col text-end px-5 py-2">
            <h2 className="fw-bold text-fitek">Cash Folw Report</h2>
          </div>
        </div>

        {/* <div className='fw-bold  text-center'>
                  Account Profile</div> */}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          //  borderTop: "1px solid #bce8f1"
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
};

const ProjectedCashFlowGrid = ({ data, flag, accntSelected }) => {
  debugger;
  let _pdfExport;
  const gridRef = useRef();
  const _export = React.useRef(null);
  const excelExport = () => {
    setShow(true);
    openReport();
    // if (_export.current !== null) {
    //   _export.current.save(data);
    // }
  };

  // call active report

  const viewerRef = React.useRef();
  async function loadReport() {
    // load report definition from the file
    debugger;
    const reportResponse = await fetch("CashFlow.rdlx-json");
    debugger;
    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    //  debugger;
    const report = await loadReport();
    const parameters = [
      // <p>Administrator : {localStorage.getItem("Administrator")}</p>
      // </div>
      // <div className="text-end fw-bold col-md-3">
      // <p>AccountType : {localStorage.getItem("acctType")}</p>
      {
        Name: "pCnsldtnNm",
        Value: [
          JSON.parse(localStorage.getItem("RelationID")) != null
            ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm === ""
              ? 0
              : JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm
            : 0,
        ],
      },
      {
        Name: "pExtrnlAcctId",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 0
            : JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId,
        ],
      },
      {
        Name: "pShow",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 3
            : 2,
        ],
      },
      //{Name: 'pE', Value: [ChkBoxState]}
    ];
    //  debugger;
    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(data);
    //  debugger;
    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  //
  const [locked, setLocked] = React.useState(false);
  let allPDFExport;
  const columnLocked = () => {
    setLocked(!locked);
  };
  const totalSum = (props) => {
    const field = props.field || "";
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };
  //let loadedColumns = localStorage.getItem('gridColumns');
  //const columns =

  let defaultColumns = [
    // {
    //     title: 'Account Number',
    //     field: 'extrnlAcctId',
    //     minWidth: "auto",
    //     show: false,
    //     filter: 'text',
    //     locked: true,
    //   },
    // {
    //   title: 'Account Name',
    //   field: 'shrtNm',
    //   minWidth: "auto",
    //   show: false,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Portfolio",
      field: "portId",
      minWidth: "150px",
      show: true,
      filter: "text",
      locked: true,
    },
    {
      title: "T0",
      field: "t0",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },

    {
      title: "T1",
      field: "t1",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "T2",
      field: "t2",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "T2+",
      field: "t3",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
  ];
  //lelocalStorage.setItem('gridColumns', JSON.stringify(defaultColumns));
  let loadedColumns = localStorage.getItem("gridColumns");
  const GridColumns = defaultColumns; //flag ? JSON.parse(loadedColumns) : defaultColumns;//? JSON.parse(loadedColumns) : defaultColumns;
  //GridColumns[8].footerCell= totalSum;
  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      resultExport: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    group: [{ field: "extrnlAcctId" }],
  });

  const [result, setResult] = useState(initialState.result);
  const [dataState, setDataState] = useState(initialState.dataState);
  const [stateColumns, setStateColumns] = useState(GridColumns);
  const [resultExport, setResultExport] = useState(initialState.result);
  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    return newColumnsState;
  };
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
    setResultExport({ ...result });
  };
  const showpreview = async () => {
    //setDataState({ ...dataState,  skip: 0,take: 1000 });
    //setResult(data);
    setShow(true);
  };

  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };
  const saveColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 9; //let 1 for Portfoliholdings Grid
    let ColumnSettings = localStorage.getItem("gridColumns");
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTSaveGridColumn/Index", postData, config)
      .then((response) => {
        console.log(response);
        const rowData = response.data;
        //populatePortfolioHoldingRptData(rowData.ocPortFolioHoldingsMainOutPut);
        //populatePortfolioHoldingRptDatatab2(rowData.ocPortFolioHoldingsTradeTypeOutPut);
        //setflagPortfolio(true);
        //setLoading(false);
      })
      .catch((error) => {
        return error;
      });
  };
  const saveColumnsState = (columns) => {
    console.log("save called!!");
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);
    localStorage.setItem("gridColumns", currentColumnsState);

    saveColumnStateDb();
  };

  useEffect(() => {
    saveColumnsState(stateColumns);
  }, [stateColumns]);

  const [mnrRadioStat, setMnrRadioStat] = useState("checked");
  const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
  const labelContent = (e) => `${e.value.toFixed(2)}`;

  const handleClick = () => {
    setLocked(!locked);
  };

  const _grid = React.useRef();

  const [gridChartCheck, setgridChartCheck] = useState("checked");

  const [collapsedState, setCollapsedState] = React.useState([]);

  const chartDefaultV4Colors = [
    "#0275d8",
    "#5bc0de",
    "#5cb85c",
    "#f0ad4e",
    "#e67d4a",
    "#d9534f",
  ];

  const handleSetDataChecked = () => {
    setgridChartCheck("checked");
  };
  const handleSetChartChecked = () => {
    setgridChartCheck("");
  };

  const RightNameHeader = (props) => {
    return (
      <a
        className="k-link"
        style={{
          float: "right",
        }}
        onClick={props.onClick}
      >
        {/* <span className="k-icon k-i-cart" /> */}
        <span
          style={
            {
              // color: "#53d2fa",
            }
          }
        >
          {props.title}
        </span>
        {props.children}
      </a>
    );
  };
  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };
  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      const columns = GridColumns;
      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - columns.length}
          ></td>
          {getCells(columns, cellProps)}
        </>
      );
    }
    if (cellProps.rowType === "groupFooter") {
      if (cellProps.field === "totMarket") {
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {cellProps.dataItem.aggregates.totMarket.sum}
          </td>
        );
      }
    }
    if (cellProps.rowType === "data") {
      if (cellProps.field === "t0") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "t1") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "t2") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "t3") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "gainLoss") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "income") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "yield") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "p1CashBlncAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "p2P3CashBlncAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "unExecCashAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "tradeCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "excldCashAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "equityPercent") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }
    return tdElement;
  };

  const NumberCell = (props) => {
    if (props.field === "invstmntObj") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "accountType") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "account") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "tckerCusip") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "assetShrtNm") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "units") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "txcstAmt") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "price") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "totMarket") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "gainLoss") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "income") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "yield") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "p1CashBlncAmt") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "p2P3CashBlncAmt") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "unExecCashAmt") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "tradeCash") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "excldCashAmt") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "equityPercent") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
  };

  const labelContent1 = (e) => `${e.value.toFixed(2)}`;
  const FormatLongNumber = ({ value }) => {
    if (value == 0) {
      return 0;
    } else {
      // hundreds
      if (value <= 999) {
        return value;
      }
      // thousands
      else if (value >= 1000 && value <= 999999) {
        return (value / 1000).toFixed(2) + "K";
      }
      // millions
      else if (value >= 1000000 && value <= 999999999) {
        return (value / 1000000).toFixed(2) + "M";
      }
      // billions
      else if (value >= 1000000000 && value <= 999999999999) {
        return (value / 1000000000).toFixed(2) + "B";
      } else return value.toFixed(2);
    }
  };
  const exportPDF = () => {
    _pdfExport.save();
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  return (
    <div>
      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {
        <div className="mx-1 px-1 pt-1 row d-flex justify-content-between align-items-center my-2">
          {/* <div className='col'>
            <p className='reportheading h6'>Cash Flow Report</p>
          </div> */}

          {/* <div className="col text-end">
                                <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
                                    <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem> */}
          {/* <DropdownItem onClick={exportPDF}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem> */}

          {/* </DropdownButton>


                            </div> */}
          <div className="col text-end">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={excelExport}
            >
              Preview
            </button>
          </div>

          {/* <div className='col'>
            <button
              className='btn btn-outline-primary btn-sm'
              onClick={excelExport}>Export to Excel</button>
            &nbsp;
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={exportPDF}
            >
              Export to PDF
            </button>
          </div> */}
        </div>
      }
      {
        //data.length===0?<Card>No Data</Card>:<div><Card><b>PortId:</b> {data[0].portId}</Card><Card><div><b>T0:</b> {data[0].t0}</div><div><b>T1:</b> {data[0].t1}</div><div><b>T2:</b> {data[0].t2}</div><div><b>T2+:</b> {data[0].t3}</div></Card></div>}
      }
      <div className="card-body">
        <div className="mx-1 my-1 py-1">
          <ExcelExport
            data={data}
            ref={_export}
            fileName={
              JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId +
              "_CashFlow.xlsx"
            }
          >
            <ExcelExportColumnGroup
              title={
                "Account Number: " +
                JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId +
                "  Processing Date: " +
                localStorage.getItem("processingDate")
              }
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              <ExcelExportColumn
                field="extrnlAcctId"
                title="Account Number"
                width={150}
              />
              <ExcelExportColumn
                field="shrtNm"
                title="Account Name"
                width={210}
              />
              <ExcelExportColumn field="portId" title="PortId" width={100} />
              <ExcelExportColumn
                field="t0"
                width={110}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="T0"
              />
              <ExcelExportColumn
                field="t1"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="T1"
              />
              <ExcelExportColumn
                field="t2"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="T2"
              />
              <ExcelExportColumn
                field="t3"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="T2+"
              />
            </ExcelExportColumnGroup>
          </ExcelExport>
          <Grid
            style={{ width: "100%", height: "650px" }}
            data={result}
            {...dataState}
            onDataStateChange={dataStateChange}
            expandField="expanded"
            onExpandChange={expandChange}
            cellRender={cellRender}
            sortable={true}
            scrollable={true}
            resizable={true}
            //reorderable={true}

            //pageable={true}
            //pageSize={10}
            groupable={{
              footer: "none",
              enabled: false,
            }}
            //
            pageable={{
              buttonCount: 0,
              info: true,
              previousNext: false,
            }}
            pageSize={Number.MAX_VALUE}
            ref={gridRef.current}
            onColumnReorder={onColumnReorder}
            onColumnResize={onColumnResize}
            //
          >
            {stateColumns.map(
              (column, idx) =>
                column.show && (
                  <Column
                    width={setWidth(column.minWidth)}
                    key={idx}
                    field={column.field}
                    title={column.title}
                    headerClassName={column.headerClassName}
                    {...column}
                    // columnMenu={(props) => (
                    //   <CustomColumnMenuNoGrp
                    //     {...props}
                    //     columns={stateColumns}
                    //     onColumnsSubmit={onColumnsSubmit}
                    //   />
                    // )}
                  />
                )
            )}
          </Grid>
        </div>
      </div>
      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
        fileName={accntSelected.extrnlAcctId + "_CashFlowReport.pdf"}
        margin={{ top: 90, left: 30, right: 30, bottom: 40 }}
        pageTemplate={PageTemplate}
        forcePageBreak=".page-break"
        paperSize="A4"
        scale={0.6}
        allPages={true}
        //margin="1cm"
        landscape={true}
        repeatHeaders={true}
      >
        <Grid
          style={{ height: "600px" }}
          data={resultExport}
          {...dataState}
          onDataStateChange={dataStateChange}
          expandField="expanded"
          onExpandChange={expandChange}
          cellRender={cellRender}
          sortable={true}
          resizable={true}
          pageable={true}
          // total={result.length}
          pageSize={numberSymbols.MAX_VALUE}
          onColumnReorder={onColumnReorder}
          onColumnResize={onColumnResize}
          groupable={{
            footer: "visible",
            enabled: false,
          }}
        >
          {stateColumns.map(
            (column, idx) =>
              column.show && (
                <Column
                  width={setWidth(column.minWidth)}
                  locked={column.locked}
                  key={idx}
                  field={column.field}
                  title={column.title}
                  filter={column.filter}
                  footerCell={column.footerCell}
                  headerClassName={column.headerClassName}
                  //cell={NumberCell}
                  columnMenu={(props) => (
                    <CustomColumnMenuNoGrp
                      {...props}
                      columns={stateColumns}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  )}
                />
              )
          )}
        </Grid>
      </GridPDFExport>
    </div>
  );
};

export default ProjectedCashFlowGrid;
