import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
// import validator from 'validator';
import Select, { SelectChangeEvent } from "@mui/material/Select";
//import SelectControl from './selectcontrol';
import Loading from "./loading";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { lastDayOfMonth, isEqual } from "@progress/kendo-date-math";
//import DailyPerformanceGrid from "./dailyPerformanceGrid";
import Header from "./header";
import { FaCog, FaFilter } from "react-icons/fa";
import { Button, Card } from "@mui/material";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { FormGroup, FormControlLabel, Checkbox, Switch } from "@mui/material";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
//import refreshFunction from './refreshFunc';
import Modal from "react-bootstrap/Modal";
import { Modal as AntdModal, Button as AntdBtn } from "antd";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { BsFillInfoCircleFill, BsThreeDots } from "react-icons/bs";
// import { abs } from 'mathjs'
import { CustomCalendar } from "./customCalendar";
import { Dropdown } from "react-bootstrap";
import Enumerable from "linq";

import { FaInfoCircle } from "react-icons/fa";
// import { Popover } from "@progress/kendo-react-tooltip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";
import { addMonths } from "@progress/kendo-date-math";

import PerformanceRiskMetricsGrid from "./performanceRiskMetricsGrid";
function PerformanceRiskMetricsRpt() {
  const [PerformanceRiskMetricsRPTData, populatePerformanceRiskMetricsRPTData] =
    useState([]);
  const [AcctList, populateAccountListData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [gridDataVisible, setGridDataVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [closeOption, setCloseOption] = useState(false);
  const [priceFlagInd, setPriceFlagInd] = useState(1);
  const [flag, setFlag] = useState(false);
  const [isColumnSave, setIsColumnSave] = useState(false);
  const [toDate, setToDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const [frmDate, setFrmDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const minFrmDt = new Date(localStorage.getItem("processingDate"));
  minFrmDt.setFullYear(minFrmDt.getFullYear() - 4);
  const maxFrmDt = new Date(localStorage.getItem("processingDate"));

  const [isDisabled, setisDisabled] = React.useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const navigate = useNavigate();
  const [reportData, setReportData] = useState([]);
  const [benchmarkListtData, setBenchmarkListData] = useState([]);

  var processingDate = new Date(localStorage.getItem("processingDate"));
  console.log("processing date", processingDate);
  var startDate = new Date(processingDate);
  var endDate = new Date(processingDate);
  startDate.setMonth(startDate.getMonth() - 60);
  startDate = lastDayOfMonth(startDate);
  const [startDt, setStartDt] = React.useState(startDate);
  endDate = lastDayOfMonth(endDate);
  if (endDate > processingDate) {
    endDate = addMonths(endDate, -1);
  }
  const [endDt, setEndDt] = React.useState(endDate);
  var maxEndDate = processingDate;

  const [shwTtlPortfolio, setShwTtlPortfolio] = useState(true);
  const [modalVisible, setModalVisible] = useState(true);
  const [selectedOption, setSelectedOption] = useState(1);

  const dispatch = useDispatch();

  const apicallval = useSelector(selectapicall);

  useEffect(() => {
    const fetchData = async () => {
      //setLoading(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;
        let ConsolidationId =
          JSON.parse(localStorage.getItem("pConsId")) == null
            ? 0
            : localStorage.getItem("pConsId");
        const dropdownData = await GetRiskFreeBenchMarkData();
        console.log("dropdown data", dropdownData);
        //setEmail(email);
        //getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  function setDate(val) {
    // if(val.language === '1'){
    //  const fDate = new Date("2021-04-22");

    //     setfrmDate(fDate);
    //     const setisDisabled = false;
    // }
    // if(val.language === '2'){
    if (val == null) {
      // const fDate = new Date("2021-01-22");
      setToDate(toDate);
    } else {
      setToDate(val);
    }
    //     const setisDisabled = true;
    // }
    //setToDate(val);
  }
  function setfrmDate(val) {
    // if(val.language === '1'){
    //  const fDate = new Date("2021-04-22");

    //     setfrmDate(fDate);
    //     const setisDisabled = false;
    // }
    // if(val.language === '2'){
    if (val == null) {
      // const fDate = new Date("2021-01-22");
      setFrmDate(frmDate);
    } else {
      setFrmDate(val);
    }
    //     const setisDisabled = true;
    // }
    //setToDate(val);
  }
  const searchClick = () => {
    GetPerformanceRiskMetricsRPTData();
  };

  const GetRiskFreeBenchMarkData = async () => {
    //
    setLoading(true);

    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let ind = 1; //JSON.parse(localStorage.getItem("ind"));
    const postData = {
      ind,
      UserId,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/GetBenchmarkList", postData, config)
      .then((response) => {
        //  console.log(response);
        //
        const rowData = response.data;
        setBenchmarkListData(rowData.ocBenchmarkList);
        // getColumnStateDb();
        setLoading(false);
        setFlag(1);
        console.log("postData for BenchmarkList", postData);
        console.log("rowData", rowData.ocBenchmarkList);
        return benchmarkListtData;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        // return error;
      });
  };

  const GetPerformanceRiskMetricsRPTData = async () => {
    //
    setLoading(true);

    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    var UserId = JSON.parse(localStorage.getItem("userId"));
    //let UserId =269406
    let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    var AcctId = 0;

    if (lsSelectedAcct !== null) {
      AcctId = lsSelectedAcct.acctId;
    }
    //Runtime
    // UserId = 78034
    //AcctId= 1;
    let AccountId = AcctId;
    let ConsolidationId =
      JSON.parse(localStorage.getItem("pConsId")) == null
        ? 0
        : localStorage.getItem("pConsId");
    let StartDate = new Date(startDt);
    let AsOfDate = new Date(endDt);
    //Hard coded
    let showAccountPortfolio = shwTtlPortfolio;
    let showL1SegmentBenchmark = true;

    let showCheckedMetrices =
      "3767,3768,3769,3770,3773,3774,3778,3779,3780,3781";
    let showSL1Category =
      "1,2,3,13,4,5,6,7,9,10,11,12,22,14,15,23,24,25,26,27,28,29,30,31,32,33,34,35,36";
    let RiskFreeBenchmarkId = 145;

    const postData = {
      AccountId,
      UserId,
      ConsolidationId,
      StartDate,
      AsOfDate,
      showAccountPortfolio,
      showL1SegmentBenchmark,
      showCheckedMetrices,
      showSL1Category,
      RiskFreeBenchmarkId,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetPerformanceRiskMetrics", postData, config)
      .then((response) => {
        //  console.log(response);
        //
        const rowData = response.data;
        populatePerformanceRiskMetricsRPTData(rowData.t1);
        // populateAccountListData(rowData.lstAcctTmWtdRtnList);
        // setSelAcctData(rowData.lstAcctTmWtdRtnList);
        setReportData(rowData);
        if (lsSelectedAcct !== null) {
          SetselAcct(lsSelectedAcct);
        } else {
          SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
        }
        // getColumnStateDb();
        setLoading(false);
        setFlag(1);
        console.log("postData for Performance risk metrics", postData);
        console.log("rowData for performance risk metrics", rowData);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        // return error;
      });
  };

  if (apicallval === 1) {
    GetPerformanceRiskMetricsRPTData();
    dispatch(callremove());
  }

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        //GetPerformanceRiskMetricsRPTData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  //   const getColumnStateDb = async () => {
  //     //Storing column settings in DB
  //
  //     let token = JSON.parse(localStorage.getItem("token"));
  //     let UserId = JSON.parse(localStorage.getItem("userId"));
  //     let GridId = 33;

  //     const postData = { UserId, GridId };
  //     const config = {
  //       headers: {
  //         authorization: `Bearer ${token.token}`,
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     };
  //     await axios
  //       .post("/RTGetGridColumn/Index", postData, config)
  //       .then((response) => {
  //
  //         console.log("save in db");
  //         console.log(response);
  //         const rowData = response.data;
  //         if (rowData !== "") {
  //           setIsColumnSave(true);
  //           localStorage.setItem("gridColumns", rowData);
  //         } else {
  //           setIsColumnSave(false);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("error in save db " + error);
  //         return error;
  //       });
  //   };

  const handleChange = (event) => {
    //
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetPerformanceRiskMetricsRPTData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  const handleSettings = () => {
    setModalVisible(true);
    setCloseOption(true);
  };

  // const handleRadioChange = (e) => {
  //   setSelectedOption(parseInt(e.target.value));
  //   setPriceFlagInd(parseInt(e.target.value));
  //   console.log("e.target.value", e.target.value);
  //   console.log("e", e);
  //   if (e.target.value == 1 || e.target.value == 2) {
  //     setpriceDt(date); // to be checked
  //   }
  //   // console.log("selectedOption", selectedOption);
  //   // console.log("Selected Price Date", priceDt);
  // };
  const formSubmit = (event) => {
    debugger;
    event.preventDefault();
    setModalVisible(false);
    setCloseOption(false);

    GetPerformanceRiskMetricsRPTData();
    setGridDataVisible(true);
  };
  const handleCancel = () => {
    setModalVisible(false);
    setCloseOption(false);

    !closeOption && navigate("/dashboard");
  };

  function isEndOfMonth(date) {
    if (isEqual(lastDayOfMonth(date), date)) {
      return true;
    } else {
      return false;
    }
  }
  const handleShwTtlPortfolio = (event) => {
    setShwTtlPortfolio(event.target.checked); // Capture whether the checkbox is checked or unchecked
  };

  const [benchMarkId, setBenchmarkId] = React.useState("");
  console.log("benchmarkId", benchMarkId);
  const handleRiskFreeBenchmarkIdChange = (event) => {
    setBenchmarkId(event.target.value);
  };

  console.log("show portfolio", shwTtlPortfolio);
  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            {!modalVisible && <Loading />}
          </div>
        )}
      </>
    );
  }
  return (
    /*
    Start date and end date must be end of month
    Start date cannot be greater than end date
    End date cannot be greater than current date / procesing date
    Start date cannot be equal to end date
    */
    <div>
      <div className="row d-flex justify-content-between align-items-center mx-2 py-1 border-bottom">
        {/* Report Heading */}
        <div className="col reportheading fs-6">
          Performance Risk Metrics Report
        </div>

        {/* Filter Button */}
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>
      </div>
      <AntdModal
        open={modalVisible}
        title="Customize Performance Risk Metrics Report"
        closable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpFrm"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              Start Date:
              {/* <BsFillInfoCircleFill
                title="Start date must be end of month and less than end date "
                color="#0384fc"
                size={12}
              /> */}
            </label>
            <DatePicker
              id="dpFrm"
              value={startDt}
              // calendar={CustomCalendar}
              min={startDate}
              max={endDt}
              disabled={isDisabled}
              style={{ width: "100%" }}
              valid={
                // 1. Check if start and end dates are the end of the month
                isEndOfMonth(startDt) &
                // 2. Start date cannot be greater than end date
                (startDt <= endDt) &
                // 3. End date cannot be greater than processing date
                // (endDt <= maxEndDate) &&
                // 4. Start date cannot be equal to end date
                !isEqual(startDt, endDt)
              }
              onChange={(e) => setStartDt(e.value)}
              validationMessage={
                !startDt || !endDt
                  ? "Both Start Date and End Date are required."
                  : !isEndOfMonth(startDt) || !isEndOfMonth(endDt)
                  ? "Start Date and End Date must be the end of the month."
                  : startDt > endDt
                  ? "Start Date cannot be greater than End Date."
                  : endDt > maxEndDate
                  ? "End Date cannot be greater than the end of month of current (Processing) date."
                  : isEqual(startDt, endDt)
                  ? "Start Date cannot be the same as End Date."
                  : "Validation error"
              }
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpTo"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              End Date:
            </label>
            <DatePicker
              id="dpTo"
              value={endDt}
              // format="MM/dd/yyyy"
              // calendar={CustomCalendar}
              //min={minFrmDt}
              max={maxEndDate}
              disabled={false}
              onChange={(e) => {
                setEndDt(e.value);
              }}
              style={{ width: "100%" }}
              valid={
                // 1. Check if start and end dates are the end of the month
                isEndOfMonth(endDt) &
                  // 2. Start date cannot be greater than end date
                  (startDt <= endDt) &
                  // 3. End date cannot be greater than processing date
                  (endDt <= maxEndDate) &&
                // 4. Start date cannot be equal to end date
                !isEqual(startDt, endDt)
              }
              validationMessage={
                !startDt || !endDt
                  ? "Both Start Date and End Date are required."
                  : !isEndOfMonth(startDt) || !isEndOfMonth(endDt)
                  ? "Start Date and End Date must be the end of the month."
                  : startDt > endDt
                  ? "Start Date cannot be greater than End Date."
                  : endDt > maxEndDate
                  ? "End Date cannot be greater than the end of month of current (Processing) date."
                  : isEqual(startDt, endDt)
                  ? "Start Date cannot be the same as End Date."
                  : "Validation error"
              }
            />
          </div>
          <hr></hr>
          <h6>Display Option</h6>

          <FormControlLabel
            control={
              <Switch
                checked={shwTtlPortfolio}
                onChange={handleShwTtlPortfolio}
                // disabled={true}
              />
            }
            label=" Show Total Portfolio"
            style={{ marginBottom: "10px", display: "block" }}
          />

          {/* <hr></hr> */}
          <div style={{ marginBottom: "20px" }}>
            {/* <label
              htmlFor="dpFrm"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              Risk Free Benchmarks:
            </label> */}
            {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={setBenchmarkId}
                label=" Risk Free Benchmarks:"
                onChange={handleRiskFreeBenchmarkIdChange}
              >
                <MenuItem value="Select benchmark">
                  <em>None</em>
                </MenuItem>
                {benchmarkListtData.map((model) => (
                  <MenuItem key={model.modelId} value={model.modelId}>
                    {model.modelDesc} {/* Display the model description }
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div
                className="btn btn-outline-secondary mx-2"
                onClick={handleCancel}
              >
                Cancel
              </div>
              <button className="btn btn-primary mx-2" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </AntdModal>
      {flag && gridDataVisible ? (
        <PerformanceRiskMetricsGrid
          data={PerformanceRiskMetricsRPTData}
          //flag={isColumnSave}
          reportData={reportData}
          //isPriceExistFlagInd={isPriceExistsFlagInd}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default PerformanceRiskMetricsRpt;
