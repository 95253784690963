import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { FaFileExcel } from "react-icons/fa";

import {
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";

import Modal from "react-bootstrap/Modal";
import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";
const aggregates = [
  // {
  //   field: "totMarket",
  //   aggregate: "sum",
  // },
];

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const PortfolioOverview2ndGridCard = ({ data, priceValidation }) => {
  const gridRef = useRef();
  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(data);
    }
  };
  const [locked, setLocked] = React.useState(false);
  let allPDFExport;
  const columnLocked = () => {
    setLocked(!locked);
  };
  const totalSum = (props) => {
    const field = props.field || "";
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };

  // call active report
  //const [show, setShow] = React.useState(false);
  //   const viewerRef = React.useRef();
  //   async function loadReport() {
  //     // load report definition from the file

  //     const reportResponse = await fetch("PortfolioOverview.rdlx-json");

  //     const report = await reportResponse.json();
  //     return report;
  //   }
  //   async function openReport() {
  //     const report = await loadReport();

  //     report.DataSources[0].ConnectionProperties.ConnectString =
  //       "jsondata=" + JSON.stringify(PortfolioOverviewRptData);

  //     viewerRef.current.Viewer.open(report);
  //   }

  //

  //let loadedColumns = localStorage.getItem('gridColumns');
  //const columns =

  let defaultColumns = [
    {
      title: "Account",
      field: "account",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: true,
    },
    {
      title: "Average Yield",
      field: "avgYield",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: true,
      headerClassName: "rightHeader",
    },
    {
      title: "Average Maturity",
      field: "avgMaturity",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Average YTM",
      field: "avgYTM",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Average Duration",
      field: "avgDuration",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
  ];

  const GridColumns = defaultColumns;

  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    // group: [{ field: "account" }],
  });

  const processWithGroups = (data, dataState) => {
    //
    const groups = dataState.group;
    const filesDocArray = [];

    if (groups) {
      groups.map((group) => (group.aggregates = aggregates));
    }

    dataState.group = groups;
    const newDataState = process(data, dataState);
    setGroupIds({
      data: newDataState.data,
      group: dataState.group,
    });
    return newDataState;
  };
  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );
  //const [result, setResult] = useState(initialState.result);
  const [dataState, setDataState] = useState(initialState.dataState);
  const [stateColumns, setStateColumns] = useState(GridColumns);

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    return newColumnsState;
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };
  const showpreview = async () => {
    //setDataState({ ...dataState,  skip: 0,take: 1000 });
    //setResult(data);
    setShow(true);
  };

  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };
  const saveColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 1; //let 1 for Portfoliholdings Grid
    let ColumnSettings = localStorage.getItem("gridColumns");
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTSaveGridColumn", postData, config)
      .then((response) => {
        console.log(response);
        const rowData = response.data;
        //populatePortfolioHoldingRptData(rowData.ocPortFolioHoldingsMainOutPut);
        //populatePortfolioHoldingRptDatatab2(rowData.ocPortFolioHoldingsTradeTypeOutPut);
        //setflagPortfolio(true);
        //setLoading(false);
      })
      .catch((error) => {
        return error;
      });
  };
  const saveColumnsState = (columns) => {
    console.log("save called!!");
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);
    localStorage.setItem("gridColumns", currentColumnsState);

    saveColumnStateDb();
  };

  useEffect(() => {
    saveColumnsState(stateColumns);
  }, [stateColumns]);

  const [mnrRadioStat, setMnrRadioStat] = useState("checked");
  const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
  const labelContent = (e) => `${e.value.toFixed(2)}`;

  const handleClick = () => {
    setLocked(!locked);
  };

  const _grid = React.useRef();

  const [gridChartCheck, setgridChartCheck] = useState("checked");

  const [collapsedState, setCollapsedState] = React.useState([]);

  const chartDefaultV4Colors = [
    "#0275d8",
    "#5bc0de",
    "#5cb85c",
    "#f0ad4e",
    "#e67d4a",
    "#d9534f",
  ];

  const handleSetDataChecked = () => {
    setgridChartCheck("checked");
  };
  const handleSetChartChecked = () => {
    setgridChartCheck("");
  };

  const RightNameHeader = (props) => {
    return (
      <a
        className="k-link"
        style={{
          float: "right",
        }}
        onClick={props.onClick}
      >
        {/* <span className="k-icon k-i-cart" /> */}
        <span
          style={
            {
              // color: "#53d2fa",
            }
          }
        >
          {props.title}
        </span>
        {props.children}
      </a>
    );
  };
  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };
  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      //IMPORTANT - You need to add collection with the columns and their field name
      //you can define the Grid columns outside of the Grid and reuse them here.
      const columns = [
        { field: "account" },
        { field: "avgYield" },
        { field: "avgMaturity" },
        { field: "avgYTM" },
        { field: "avgDuration" },
      ];
      // const columns = GridColumns;
      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - columns.length}
          ></td>
          {getCells(columns, cellProps)}
        </>
      );
    }
    // if (cellProps.rowType === "groupFooter") {
    //   if (cellProps.field === "totMarket") {
    //     return (
    //       <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
    //         {cellProps.dataItem.aggregates.totMarket.sum}
    //       </td>
    //     );
    //   }
    // }
    if (cellProps.rowType === "data") {
      // if (cellProps.field === "account") {
      //   return (
      //     <td
      //       style={{ textAlign: "right" }}
      //       aria-colindex={cellProps.columnIndex}
      //       role={"gridcell"}
      //     >
      //       {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
      //     </td>
      //   );
      // }
      if (cellProps.field === "avgYield") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "avgMaturity") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "avgYTM") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "avgDuration") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }
    return tdElement;
  };

  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  return (
    <div>
      <Grid
        style={{ width: "100%", height: "auto", maxHeight: "390px" }}
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        expandField="expanded"
        onExpandChange={expandChange}
        cellRender={cellRender}
        sortable={true}
        scrollable={true}
        // resizable={true}
        // reorderable={true}
        //pageable={true}
        //pageSize={10}
        groupable={{
          // footer: "visible",
          enabled: false,
        }}
        //
        ref={gridRef.current}
        // onColumnReorder={onColumnReorder}
        // onColumnResize={onColumnResize}
        //
      >
        {priceValidation != "" && (
          <GridNoRecords>
            <div style={{ color: "red", textAlign: "left" }}>
              {priceValidation}
            </div>
          </GridNoRecords>
        )}
        {stateColumns.map(
          (column, idx) =>
            column.show && (
              <Column
                width={setWidth(column.minWidth)}
                key={idx}
                field={column.field}
                title={column.title}
                headerClassName={column.headerClassName}
                {...column}
                // columnMenu={(props) => (
                //   <CustomColumnMenuNoGrp
                //     {...props}
                //     columns={stateColumns}
                //     onColumnsSubmit={onColumnsSubmit}
                //   />
                // )}
              />
            )
        )}
      </Grid>
    </div>
  );
};

export default PortfolioOverview2ndGridCard;
