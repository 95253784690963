import React from "react";

import { useState, useEffect } from "react";

import axios from "axios";

import Select, { SelectChangeEvent } from "@mui/material/Select";

import SelectControl from "./selectcontrol";

import { filterBy } from "@progress/kendo-data-query";

import Loading from "./loading";

import Header from "./header";

import PrchsSaleGrid from "./prchsSaleGrid";

import { CustomCalendar } from "./customCalendar";

import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";

import Enumerable from "linq";

import { DatePicker } from "@progress/kendo-react-dateinputs";

import Modal from "react-bootstrap/Modal";

import { FcExpired } from "react-icons/fc";

import { useLocation, useNavigate } from "react-router-dom";

import { FaCog, FaFilter } from "react-icons/fa";
import { Modal as AntdModal, Button as AntdBtn } from "antd";

import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";

import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";

const PrchsSaleRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem("token"));

  const [PurchaseSaleRptData, populatePurchaseSaleRptData] = useState([]);

  const [AcctList, populateAccountListData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData")).slice()
  );

  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );

  const [isColumnSave, setIsColumnSave] = useState(false);

  var fromDateStart = new Date(localStorage.getItem("processingDate"));
  fromDateStart.setMonth(fromDateStart.getMonth() - 12);
  // const [frmDate, setfrmDate] = React.useState(fromDateStart);

  const [StartDt, setPrchsSaleStrtDt] = React.useState(fromDateStart); // useState(JSON.parse(localStorage.getItem('prchsSlStrtDt')) === null ? null : new Date(JSON.parse(localStorage.getItem('prchsSlStrtDt'))));

  const [EndDt, setPrchsSaleEndDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  ); // useState(JSON.parse(localStorage.getItem('prchsSlEndDt')) === null ? null : new Date(JSON.parse(localStorage.getItem('prchsSlEndDt'))));

  const [BusnsDt, setBusinessDate] = useState(
    JSON.parse(localStorage.getItem("prchsSlBusDt")) === null
      ? null
      : new Date(JSON.parse(localStorage.getItem("prchsSlBusDt")))
  );

  const [SdtErrMsgHide, setSdtErrMsgHide] = useState(true);

  const [EDtErrMsgHide, setEDtErrMsgHide] = useState(true);

  const [session, setSession] = useState("");

  const [enableCombo, setEnableCombo] = useState(false);

  var minDate = new Date(localStorage.getItem("processingDate"));
  minDate.setMonth(minDate.getMonth() - 60);
  var maxDate = new Date(localStorage.getItem("processingDate"));
  //maxDate.setMonth(maxDate.getMonth());
  const [minFrmDt, setminFrmDt] = React.useState(minDate);
  const [maxFrmDt, setmaxFrmDt] = React.useState(maxDate);

  const [modalVisible, setModalVisible] = useState(true);
  const [closeOption, setCloseOption] = useState(false);

  const [chkRptDispOptn, setChkRptDispOptn] = useState(true);
  const [runInAggregate, setRunInAggregate] = useState(false);

  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);

  const navigate = useNavigate();

  const [tradeOptionValue, setTradeOptionValue] = useState(2);

  const [selectedOptions, setSelectedOptions] = useState({
    stagedTrades: false,
    pendingTrades: true,
  });

  const [initialState, setInitialState] = useState({
    chkRptDispOptn: true,
    runInAggregate: false,
  });

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      setEnableCombo(true);

      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        //setEmail(email);

        GetPrchsSaleRptData();

        getColumnStateDb();

        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    // fetchData();
  }, []);

  const handlerunInAggregate = () => {
    setRunInAggregate(!runInAggregate);
  };

  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));

    let UserId = JSON.parse(localStorage.getItem("userId"));

    let GridId = 4; //let 4 for Purchase Sale Grid

    const postData = { UserId, GridId };

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,

        Accept: "application/json",

        "Content-Type": "application/json",
      },
    };

    await axios
      .post(
        "/RTGetGridColumn/Index",

        postData,

        config
      )

      .then((response) => {
        console.log("save in db");

        console.log(response);

        const rowData = response.data;

        if (rowData !== "") {
          setIsColumnSave(true);

          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })

      .catch((error) => {
        console.log("error in save db " + error);

        return error;
      });
  };

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));

    let AccessToken = token.token;

    let RefreshToken = token.refreshToken;

    const postData = { AccessToken, RefreshToken };

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,

        Accept: "application/json",

        "Content-Type": "application/json",
      },
    };

    await axios
      .post(
        "/token/Refresh",

        postData
      )

      .then((response) => {
        tempToken = response.data;

        localStorage.setItem("token", JSON.stringify(response.data));

        GetPrchsSaleRptData();
      })

      .catch((error) => {
        //

        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }

        console.log("my error is " + error);
      });
  };

  const [mutualFundTrades, setMutualFundTrades] = useState(1);
  const [flag, setFlag] = React.useState(false);

  const GetPrchsSaleRptData = async () => {
    setLoading(true);

    setEnableCombo(true);

    let token = tempToken;

    //let token = JSON.parse(localStorage.getItem('token'));

    let AsOfId = JSON.parse(localStorage.getItem("userId"));

    let lsSelectedAcct = JSON.parse(localStorage.getItem("AccountID"));

    let AcctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;

    let ConsolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;

    if (lsSelectedAcct !== null) {
      AcctId = lsSelectedAcct.acctId;
    }

    let pageId = 1;

    let pStrtDate = null;
    let pEndDate = null;

    let IncludeOrders = tradeOptionValue;
    let ArchvInd = false;
    let MutualFundInd = mutualFundTrades;

    if (StartDt !== null) {
      pStrtDate = `${(StartDt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${StartDt.getDate()
        .toString()
        .padStart(2, "0")}/${StartDt.getFullYear()}`;
    }

    if (EndDt !== null) {
      pEndDate = `${(EndDt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${EndDt.getDate()
        .toString()
        .padStart(2, "0")}/${EndDt.getFullYear()}`;
    }

    const postData = {
      AsOfId,
      AcctId,
      pStrtDate,
      pEndDate,
      ConsolidationId,
      IncludeOrders,
      ArchvInd,
      MutualFundInd,
      runInAggregate,
    };

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,

        Accept: "application/json",

        "Content-Type": "application/json",
      },
    };

    await axios
      .post(
        "/RTPurchaseAndSaleReport",

        postData,

        config
      )

      .then((response) => {
        const rowData = response.data;
        let consolidationName = JSON.parse(
          localStorage.getItem("RelationID")
        )?.cnsldtnNm;

        if (rowData.t1 != null) {
          for (let i = 0; i < rowData.t1.length; i++) {
            if (AcctId == 0 && runInAggregate == true) {
              rowData.t1[i].acctNm = consolidationName;
            }
          }
        }
        populatePurchaseSaleRptData(rowData.t1);

        populateAccountListData(rowData.t2);

        setSelAcctData(rowData.t2);

        if (lsSelectedAcct !== null) {
          SetselAcct(lsSelectedAcct);
        } else {
          SetselAcct(rowData.t2[0]);
        }

        let sDt = new Date(rowData.t3[0].strtDt);

        if (StartDt === null) {
          setPrchsSaleStrtDt(new Date(sDt));

          localStorage.setItem(
            "prchsSlStrtDt",
            JSON.stringify(rowData.t3[0].strtDt)
          );
        }

        // alert(sDt);

        let eDt = new Date(rowData.t3[0].asOfDt);

        if (EndDt === null) {
          setPrchsSaleEndDate(new Date(eDt));

          localStorage.setItem(
            "prchsSlEndDt",
            JSON.stringify(rowData.t3[0].asOfDt)
          );

          setBusinessDate(new Date(eDt));

          localStorage.setItem(
            "prchsSlBusDt",
            JSON.stringify(rowData.t3[0].asOfDt)
          );
        }
        setFlag(true);
        setLoading(false);
        setEnableCombo(false);
      })

      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
      });
  };

  if (apicallval === 1) {
    GetPrchsSaleRptData();
    dispatch(callremove());
  }

  const filterData = (filter) => {
    // const dataAcct = selAcctData.slice();

    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };

  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };

  const handleChange = (event) => {
    if (event.target.value === null) {
      SetselAcct(selAcct);
    } else {
      SetselAcct(event.target.value);

      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));

      GetPrchsSaleRptData();
    }
  };

  const searchClick = () => {
    if (SdtErrMsgHide && EDtErrMsgHide) {
      setSdtErrMsgHide(true);

      setEDtErrMsgHide(true);

      GetPrchsSaleRptData();
    }
  };

  function setStartDate(val) {
    if (val <= EndDt) {
      setPrchsSaleStrtDt(val);

      setSdtErrMsgHide(true);

      localStorage.setItem("prchsSlStrtDt", JSON.stringify(val));
    } else {
      setSdtErrMsgHide(false);
    }
  }

  function setEndDate(val) {
    if (StartDt <= val) {
      setPrchsSaleEndDate(val);

      setEDtErrMsgHide(true);

      localStorage.setItem("prchsSlEndDt", JSON.stringify(val));
    } else {
      setEDtErrMsgHide(false);
    }
  }

  const signOut = () => {
    navigate("/");

    let token = JSON.parse(localStorage.getItem("token"));

    const postData = {};

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,

        Accept: "application/json",

        "Content-Type": "application/json",
      },
    };

    axios
      .post(
        "/token/revoke",

        postData,

        config
      )

      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })

      .catch((error) => {
        //

        console.log("my error is " + error);
      });

    // let tokenNew={token:''};

    // localStorage.setItem('token',JSON.stringify(tokenNew));

    // localStorage.setItem("AcctSelected",null);

    localStorage.removeItem("token");

    localStorage.clear();

    //firebaseApp.auth.signOut();
  };

  const formSubmit = (event) => {
    event.preventDefault();
    setModalVisible(false);
    setCloseOption(false);
    GetPrchsSaleRptData();
  };

  const handleSettings = (e) => {
    setInitialState({
      chkRptDispOptn,
      runInAggregate,
    });
    e.preventDefault();
    setModalVisible(true);
    setCloseOption(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setCloseOption(false);
    setChkRptDispOptn(initialState.chkRptDispOptn);
    setRunInAggregate(initialState.runInAggregate);
    !closeOption && navigate("/dashboard");
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    // Update the selectedOptions state
    setSelectedOptions((prev) => ({
      ...prev,
      [name]: checked,
    }));

    // Calculate the new value dynamically based on the checkbox states
    const newSelectedOptions = {
      ...selectedOptions,
      [name]: checked,
    };

    setTradeOptionValue(
      (newSelectedOptions.stagedTrades ? 1 : 0) +
        (newSelectedOptions.pendingTrades ? 2 : 0)
    );
  };

  const handleMutualFundTrades = (e) => {
    setMutualFundTrades(e.target.checked ? 1 : 0);
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            <Loading />
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      {/* <form onSubmit={formSubmit}>
        <div className="row d-flex justify-content-end align-items-center py-2 px-2 mx-2 bg-light shadow-sm rounded">


          <div className="col">
            <span className="py-1">Start Date</span>

            <div className="text-start">
              <span style={{ color: "red" }} hidden={SdtErrMsgHide}>
                &nbsp;should be less than snapshot date
              </span>

              <DatePicker
                id="dpFrm"
                defaultValue={StartDt}
                // Value={StartDt}
                format="MM/dd/yyyy"
                calendar={CustomCalendar}
                formatPlaceholder={{
                  year: "yyyy",
                  month: "mm",
                  day: "dd",
                }}
                max={
                  new Date(
                    `${(EndDt === null ? BusnsDt : EndDt).getFullYear()},${(
                      (EndDt === null ? BusnsDt : EndDt).getMonth() + 1
                    )
                      .toString()
                      .padStart(2, "0")},${(EndDt === null ? BusnsDt : EndDt)
                      .getDate()
                      .toString()
                      .padStart(2, "0")}`
                  )
                }
                onChange={(e) => {
                  setStartDate(e.value);
                }}
              />
            </div>
          </div>

          <div className="col">
            <div className="text-start">
              <span className="py-1">End Date</span>
              <span style={{ color: "red" }} hidden={EDtErrMsgHide}>
                &nbsp;should be greater than current date
              </span>

              <DatePicker
                defaultValue={EndDt}
                // Value={EndDt}

                format="MM/dd/yyyy"
                calendar={CustomCalendar}
                formatPlaceholder={{
                  year: "yyyy",

                  month: "mm",

                  day: "dd",
                }}
                // max={
                //   new Date(
                //     `${BusnsDt.getFullYear()},${(BusnsDt.getMonth() + 1)
                //       .toString()
                //       .padStart(2, "0")},${BusnsDt.getDate()
                //       .toString()
                //       .padStart(2, "0")}`
                //   )
                // }
                min={minFrmDt}
                max={maxFrmDt}
                onChange={(e) => {
                  setEndDate(e.value);
                }}
              />
            </div>
          </div>

          <div className="col py-1">
            <div className="text-start">
              <input
                type="submit"
                className="btn btn-primary btn-sm"
                value="Submit"
              />
            </div>
          </div>
        </div>
      </form> */}

      <div className="row d-flex justify-content-start align-items-center my-1 px-2 mx-2 shadow-sm rounded">
        <div className="col reportheading fs-6">Purchase & Sell Report</div>

        {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}

        {/* Filter Button */}
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>
      </div>

      <AntdModal
        open={modalVisible}
        title="Customize Purchase & Sell Report"
        closable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "4px 20px 20px 20px" }}>
          <h6>Trade Date</h6>
          <div className="col">
            <span className="py-1">Start Date</span>

            <div className="text-start">
              <span style={{ color: "red" }} hidden={SdtErrMsgHide}>
                &nbsp;should be less than snapshot date
              </span>

              <DatePicker
                id="dpFrm"
                defaultValue={StartDt}
                // Value={StartDt}
                format="MM/dd/yyyy"
                calendar={CustomCalendar}
                formatPlaceholder={{
                  year: "yyyy",
                  month: "mm",
                  day: "dd",
                }}
                max={
                  new Date(
                    `${(EndDt === null ? BusnsDt : EndDt).getFullYear()},${(
                      (EndDt === null ? BusnsDt : EndDt).getMonth() + 1
                    )
                      .toString()
                      .padStart(2, "0")},${(EndDt === null ? BusnsDt : EndDt)
                      .getDate()
                      .toString()
                      .padStart(2, "0")}`
                  )
                }
                onChange={(e) => {
                  setStartDate(e.value);
                }}
              />
            </div>
          </div>

          <div className="col">
            <div className="text-start">
              <span className="py-1">End Date</span>
              <span style={{ color: "red" }} hidden={EDtErrMsgHide}>
                &nbsp;should be greater than current date
              </span>

              <DatePicker
                defaultValue={EndDt}
                // Value={EndDt}

                format="MM/dd/yyyy"
                calendar={CustomCalendar}
                formatPlaceholder={{
                  year: "yyyy",

                  month: "mm",

                  day: "dd",
                }}
                // max={
                //   new Date(
                //     `${BusnsDt.getFullYear()},${(BusnsDt.getMonth() + 1)
                //       .toString()
                //       .padStart(2, "0")},${BusnsDt.getDate()
                //       .toString()
                //       .padStart(2, "0")}`
                //   )
                // }
                min={minFrmDt}
                max={maxFrmDt}
                onChange={(e) => {
                  setEndDate(e.value);
                }}
              />
            </div>
          </div>

          <hr style={{ margin: "20px 0" }} />

          <h6>Trade Type Selection</h6>

          <div>
            <label>
              <input
                type="checkbox"
                name="stagedTrades"
                checked={selectedOptions.stagedTrades}
                onChange={handleCheckboxChange}
              />
              &nbsp;&nbsp;Staged Trades
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                name="pendingTrades"
                checked={selectedOptions.pendingTrades}
                onChange={handleCheckboxChange}
              />
              &nbsp;&nbsp;Pending Trades
            </label>
          </div>
          {/* <div>
            <strong>Selected Value:</strong> {tradeOptionValue}
          </div> */}
          {tradeOptionValue === 0 && (
            <p style={{ color: "red" }}>Please select any trade type.</p>
          )}

          <hr style={{ margin: "20px 0" }} />
          <h6>Miscellaneous Section</h6>

          <FormControlLabel
            control={
              <Switch
                checked={mutualFundTrades}
                onClick={handleMutualFundTrades}
                name="chkCtr"
              />
            }
            label="Include Mutual Fund Trades"
            style={{ marginBottom: "10px", display: "block" }}
          />
          {AcctId == 0 && (
            <FormControlLabel
              control={
                <Switch
                  checked={runInAggregate}
                  onClick={handlerunInAggregate}
                  name="chkCtr"
                />
              }
              label="Run In Aggregate."
              style={{ marginBottom: "10px", display: "block" }}
            />
          )}
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button
              className="btn btn-primary mx-2"
              type="submit"
              disabled={tradeOptionValue === 0}
            >
              Submit
            </button>
          </div>
        </form>
      </AntdModal>

      {flag ? (
        <PrchsSaleGrid
          data={PurchaseSaleRptData}
          flag={isColumnSave}
          accntSelected={selAcct}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default PrchsSaleRpt;
