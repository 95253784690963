import React, { useReducer, useState } from "react";
import { useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
//import MenuIcon from '@mui/material/Menu';

import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import LogoPage from "./logoPage";
import Sidebar from "./sidebar";

import { FaCalendarAlt, FaFileExcel } from "react-icons/fa";
import {
  ProSidebar,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  FaSignOutAlt,
  FaCog,
  FaAngleDown,
  FaChalkboard,
  ImCross,
  FaListAlt,
  FaRegChartBar,
  FaDonate,
  FaChartLine,
  FaDice,
  FaBars,
  FaUserAlt,
  FaCogs,
} from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import "./sidebar.css";
import zIndex from "@mui/material/styles/zIndex";
import { positions } from "@mui/system";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import Login from "./login";
import { useDispatch } from "react-redux";
import { selectTheme, toggleTheme } from "../features/theme/themeSlice";
import { useSelector } from "react-redux";
import {
  apicallSlice,
  increment,
  callactive,
} from "../features/apicall/apicallSlice";
const Header = ({ barstate }) => {
  let headershow = JSON.parse(localStorage.getItem("RelationList"))
    ? true
    : false;
  let themes = useSelector(selectTheme);
  const [update, setupdate] = useState(0);

  const navigate = useNavigate();
  const pages = [];
  const settings = [
    "Dashboard",
    "AcctHoldingRpt",
    "AcctTransactionRpt",
    "PortfolioHolding",
    "Logout",
  ];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [sidebarState, setSidebarState] = React.useState(false);
  debugger;
  const [slctdAcct, setslctdAcct] = React.useState(
    JSON.parse(localStorage.getItem("AccountID"))
      ? JSON.parse(localStorage.getItem("AccountID"))
      : JSON.parse(localStorage.getItem("RelationList"))?.length > 0
      ? JSON.parse(localStorage.getItem("RelationList")).filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.acctId === value.acctId)
        )[0]
      : []
  );
  const [slctdRltn, setslctdRltn] = useState(
    localStorage.getItem("RelationID")
      ? JSON.parse(localStorage.getItem("RelationID"))
      : ""
  );

  debugger;

  const cnsldtndatabasic =
    JSON.parse(localStorage.getItem("RelationList"))?.length > 0
      ? JSON.parse(localStorage.getItem("RelationList")).filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.cnsldtnId === value.cnsldtnId)
        )
      : [];
  const [cnsldtnData, setcnsldtnData] = useState(cnsldtndatabasic);
  const initialaccountdata =
    JSON.parse(localStorage.getItem("RelationList"))?.length > 0
      ? JSON.parse(localStorage.getItem("RelationList")).filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.acctId === value.acctId)
        )
      : [];
  const [selAcctData, setSelAcctData] = useState(
    localStorage.getItem("RelationID")
      ? JSON.parse(localStorage.getItem("RelationList"))?.length > 0
        ? filterBy(
            JSON.parse(localStorage.getItem("RelationList")).filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.acctId === value.acctId)
            ),
            {
              logic: "and",
              filters: [
                {
                  field: "cnsldtnId",
                  operator: "eq",
                  value: JSON.parse(localStorage.getItem("RelationID"))
                    .cnsldtnId,
                  ignoreCase: true,
                },
              ],
            }
          )
        : []
      : JSON.parse(localStorage.getItem("RelationList"))?.length > 0
      ? JSON.parse(localStorage.getItem("RelationList")).filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.acctId === value.acctId)
        )
      : []
  );
  var acctdataBasic =
    JSON.parse(localStorage.getItem("RelationList"))?.length > 0
      ? JSON.parse(localStorage.getItem("RelationList")).filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.acctId === value.acctId)
        )
      : [];
  console.log("selAcctData", selAcctData);
  if (
    !(
      JSON.parse(localStorage.getItem("RelationList")) === null ||
      JSON.parse(localStorage.getItem("RelationList"))?.length === 0
    ) &&
    update === 0
  ) {
    setcnsldtnData(cnsldtndatabasic);
    setSelAcctData(initialaccountdata);
    setslctdAcct(initialaccountdata[0]);

    setupdate(1);
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  let jwtToken = JSON.parse(localStorage.getItem("token"));
  const postRptData = { jwtToken };

  // const openDashBoardPage = () => {
  //   navigate("/dashboard");
  // };
  // const openAcctHoldingRpt = () => {
  //   navigate("/acctHoldingRpt");
  // };
  // const openAcctTransactionRpt = () => {
  //   navigate("/acctTransactionRpt");
  // };
  // const openFixdIncmFndmntlsRpt = () => {
  //   navigate("/fixdIncmFndmntlsRpt");
  // };
  // const openPortfolioHoldingsRpt = () => {
  //   navigate("/portfoliHoldingsRpt");
  // };
  // const openAccountProfilePage = () => {
  //   navigate("/accountProfile");
  // };
  // const openFixdIncmMaturityLadderRpt = () => {
  //   navigate("/fixdIncmMtrtyLadrRpt");
  // };
  // const openAccountSectBenchRpt = () => {
  //   navigate("/AcctSectBenchRpt");
  // };

  // const openReportDesignerRpt = () => {
  //   navigate("/ReportDesignerRpt");
  // };
  // const openAcctPerfRpt = () => {
  //   navigate("/AcctPerfRpt");
  // };
  // const openFixedIncomePortfolioOverviewRpt = () => {
  //   navigate("/fixedIncomePortfolioOverviewRpt");
  // };
  // const openSectorReturnPerformanceRpt = () => {
  //   navigate("/sctrReturnPerfrmnceRpt");
  // };
  const onFilterChangeRelation = (event) => {
    debugger;
    let x = filterBy(cnsldtndatabasic, event.filter);
    setcnsldtnData(filterBy(cnsldtndatabasic, event.filter));
  };
  const onFilterChangeAccount = (event) => {
    debugger;
    console.log(filterBy(acctdataBasic, event.filter));
    setSelAcctData(filterBy(acctdataBasic, event.filter));
  };
  const dispatch = useDispatch();
  const signOut = (e) => {
    debugger;
    setupdate(0);
    setslctdAcct([]);
    setcnsldtnData([]);
    setslctdRltn([]);
    setSelAcctData([]);
    acctdataBasic = [];
    let token = JSON.parse(localStorage.getItem("token"));
    localStorage.clear();
    dispatch(toggleTheme());
    e.preventDefault();
    navigate("/");

    localStorage.removeItem("RelationList");

    localStorage.clear();
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");

        // if (response.statusText === '') {

        // }
        debugger;
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    let tokenNew = { token: "" };
    localStorage.setItem("token", JSON.stringify(tokenNew));
    //firebaseApp.auth.signOut();
  };
  const sidebartoggle = () => {
    debugger;
    if (sidebarState) {
      setSidebarState(false);
    } else {
      setSidebarState(true);
    }
  };

  const ref1 = useRef(null);

  let blankacct = {
    acctId: 0,
    cnsldtnId: 0,
    cnsldtnNm: "",
    extrnlAcctId: "",
    lngNm: "",
    shrtNm: "",
  };
  const handleChangeRelation = (event) => {
    debugger;
    dispatch(callactive());
    //navigate("/blank");

    if (event.target.value === null) {
      if (slctdAcct !== null && slctdAcct.acctId > 0) {
        let blankcons = {
          acctId: 0,
          cnsldtnId: 0,
          cnsldtnNm: "",
          extrnlAcctId: "",
          lngNm: "",
          shrtNm: "",
        };
        localStorage.setItem("RelationID", JSON.stringify(blankcons));
        setslctdRltn(null);
        setSelAcctData(
          JSON.parse(localStorage.getItem("RelationList")).filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.acctId === value.acctId)
          )
        );
        acctdataBasic = JSON.parse(localStorage.getItem("RelationList")).filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.acctId === value.acctId)
        );
      } else {
        setslctdRltn(slctdRltn);
      }
    } else {
      localStorage.setItem("RelationID", JSON.stringify(event.target.value));
      setslctdRltn(event.target.value);
      let filtersettings = {
        field: "cnsldtnId",
        operator: "eq",
        value: event.target.value.cnsldtnId,
      };
      setSelAcctData(
        filterBy(
          JSON.parse(localStorage.getItem("RelationList")),
          filtersettings
        )
      );
      acctdataBasic = filterBy(
        JSON.parse(localStorage.getItem("RelationList")),
        filtersettings
      );
      setslctdAcct(null);
      localStorage.setItem("AccountID", JSON.stringify(blankacct));
    }
    navigate(urlpath);
  };
  const handleChangeAccount = (event) => {
    debugger;
    dispatch(callactive());
    if (event.target.value === null) {
      if (slctdRltn !== null && slctdRltn.cnsldtnId > 0) {
        //setslctdAcct(slctdAcct);
        setslctdAcct(null);
        let filtersettings = {
          field: "cnsldtnId",
          operator: "eq",
          value: JSON.parse(localStorage.getItem("RelationID")).cnsldtnId,
        };

        if (JSON.parse(localStorage.getItem("RelationID")).cnsldtnId > 0) {
          setSelAcctData(
            filterBy(
              JSON.parse(localStorage.getItem("RelationList")),
              filtersettings
            )
          );
        } else {
          setSelAcctData(acctdataBasic);
        }
        localStorage.setItem("AccountID", JSON.stringify(blankacct));
      } else {
        setslctdAcct(slctdAcct);
      }
    } else {
      localStorage.setItem("AccountID", JSON.stringify(event.target.value));
      setslctdAcct(event.target.value);
    }
    navigate(urlpath);
  };
  let urlpath = window.location.pathname;

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (ref1.current && !ref1.current.contains(e.target)) {
      setSidebarState(false);
    } else {
      // console.log("insideside click");
    }
  };

  const handleSelect = (item) => {
    debugger;
    localStorage.setItem("AcctSelected", JSON.stringify(item));
    setslctdAcct(item.extrnlAcctId);
    console.log("Selected Item:", item);
  };
  /*const ref = useDetectClickOutside({ onTriggered: onclickoutside });*/

  return urlpath === "/" || urlpath === "/cstmRptMain" ? (
    <></>
  ) : (
    <>
      <div className="fixed-top shadow-sm">
        <div>
          <nav
            className="navbar navbar-expand-md navbar-light bg-white shadow-sm"
            aria-label="Fourth navbar example"
          >
            <div className="container-fluid mx-2 px-1 d-flex align-items-center">
              <a
                onClick={sidebartoggle}
                className="me-2 text-dark"
                style={{ cursor: "pointer" }}
              >
                {sidebarState ? (
                  <AiOutlineClose size={25} />
                ) : (
                  <FaBars size={25} />
                )}
              </a>
              <a className="px-2">
                <LogoPage />
              </a>

              <div className="ms-auto d-flex flex-column  w-100">
                <div className="dropdown-group btn-group-dropdowns d-flex justify-content-center">
                  <div className="dropdown-item-container text-center">
                    <label
                      className="dropdown-label"
                      htmlFor="dropdownMenuLinkConsolidation2"
                    >
                      <strong>Relationship</strong>
                    </label>
                    <div className="dropdown unique-dropdown">
                      <ComboBox
                        style={{
                          width: "100%",

                          //display: open ? "" : "none"
                        }}
                        data={cnsldtnData}
                        textField="cnsldtnNm"
                        dataItemKey="cnsldtnId"
                        placeholder="Please select  ..."
                        filterable={true}
                        clearButton={
                          slctdRltn != null && slctdRltn.cnsldtnId > 0
                            ? slctdAcct != null && slctdAcct.acctId > 0
                              ? true
                              : false
                            : false

                          // slctdRltn === null &&
                          // slctdAcct === null &&
                          // slctdRltn?.cnsldtnId <= 0 &&
                          // slctdAcct?.acctId <= 0
                        }
                        onChange={handleChangeRelation}
                        value={slctdRltn}
                        id="relationship"
                        onFilterChange={onFilterChangeRelation}
                        // onOpen={reltnOpen}
                        // onClose={reltnClose}
                        //  allowCustom={allowCustom}
                      />
                    </div>
                  </div>

                  <div className="dropdown-item-container text-center">
                    <label
                      className="dropdown-label"
                      htmlFor="dropdownMenuLinkAccount"
                    >
                      <strong>Account(s)</strong>
                    </label>
                    <div className="dropdown unique-dropdown">
                      <ComboBox
                        style={{
                          width: "100%",

                          //display: open ? "" : "none"
                        }}
                        data={selAcctData}
                        clearButton={
                          slctdRltn != null && slctdRltn.cnsldtnId > 0
                            ? slctdAcct != null && slctdAcct.acctId > 0
                              ? true
                              : false
                            : false
                        }
                        textField="extrnlAcctId"
                        dataItemKey="acctId"
                        placeholder="Please select  ..."
                        filterable={true}
                        onChange={handleChangeAccount}
                        value={slctdAcct}
                        id="accounts"
                        onFilterChange={onFilterChangeAccount}
                        // onOpen={reltnOpen}
                        // onClose={reltnClose}
                        //  allowCustom={allowCustom}
                      />
                      {/* <a
                        className="dropdown-toggle btn btn-sm btn-outline-secondary d-flex align-items-center"
                        href="#"
                        id="dropdownMenuLinkAccount"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {slctdAcct}
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLinkAccount"
                        style={{
                          maxHeight: "200px", // Set the maximum height
                          overflowY: "auto", // Enable vertical scrolling
                        }}
                      >
                        {selAcctData.map((item, index) => (
                          <li
                            key={index}
                            className="dropdown-item"
                            style={{ cursor: "pointer" }} // Make the items clickable
                            onClick={() => handleSelect(item)} // Add click handler for selection
                          >
                            {item.extrnlAcctId}
                          </li>
                        ))}
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <small className="mb-0 me-3">
                  <strong>Processing Date:</strong>{" "}
                  {localStorage.getItem("processingDate")}
                </small>
                <small className="mb-0">
                  <strong>Business Date:</strong>{" "}
                  {localStorage.getItem("processingDateOperation")}
                </small>
              </div>

              <div className="d-flex align-items-center ms-3">
                <a
                  // href="#"
                  onClick={signOut}
                  className="btn btn-outline-danger d-flex align-items-center logout-btn position-relative"
                >
                  <FaSignOutAlt className="me-1" />
                  <span className="tooltip-text">Logout</span>
                </a>
              </div>
            </div>
          </nav>
        </div>
        <div ref={ref1} style={{ zIndex: 3, position: "absolute" }}>
          {sidebarState ? <Sidebar /> : null}
        </div>
      </div>

      <div style={{ marginTop: "5rem" }}></div>
    </>
  );
};

export default Header;
