import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SelectControl from "./selectcontrol";
import { filterBy } from "@progress/kendo-data-query";
import Loading from "./loading";
import Header from "./header";
import ProjectedCashFlowGrid from "./projectedCashFlowGrid";
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import Modal from "react-bootstrap/Modal";
import BankLogoPage from "./bankLogoPage";
import PortfolioOverview1stGrid from "./portfolioOverview1stGrid";
import PortfolioOverview2ndGrid from "./portfolioOverview2ndGrid";
import PortfolioOverview3rdGrid from "./portfolioOverview3rdGrid";
import PortfolioOverview4thGrid from "./portfolioOverview4thGrid";
import PortfolioOverview5thGrid from "./portfolioOverview5thGrid";
import { FaFilePdf } from "react-icons/fa";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Enumerable from "linq";
import { Modal as AntModal } from "antd";
import { FaFilter } from "react-icons/fa";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";

import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";

const RTPortfolioOverviewRpt = () => {
  var date = new Date(localStorage.getItem("processingDate"));
  var tempToken = JSON.parse(localStorage.getItem("token"));
  localStorage.setItem(
    "modelId",
    JSON.stringify({ modelDesc: "", modelId: -1 })
  );
  var maxFrmDt = date;

  const [asOfDt, setasOfDt] = React.useState(date);

  const [PortfolioOverviewRptData, populatePortfolioOverviewRptData] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData")).slice()
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [isColumnSave, setIsColumnSave] = useState(false);
  const [selectedAcct, setSelectedAcct] = useState(0);
  const [modelId, setModelId] = useState(-1);
  const [modelData, setModelData] = useState([]);
  const [astcls, setAstClass] = useState("0");
  const [session, setSession] = useState("");
  const [enableCombo, setEnableCombo] = useState(false);
  const navigate = useNavigate();

  const [priceDt, setpriceDt] = React.useState(date);
  const [closeOption, setCloseOption] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [selectedOption, setSelectedOption] = useState(1);
  const [priceFlagInd, setPriceFlagInd] = useState(1);
  const [showExcludedAssets, setExcludedAssets] = useState(true);
  const [chkMaturity, setChkMaturity] = useState(true);
  const [chkCall, setChkCall] = useState(true);
  const [validationError, setValidationError] = useState("");
  const [validationErrorDuration, setvalidationErrorDuration] = useState("");
  const [priceValidation, setPriceValidation] = useState("");

  const GrpByMnrAstList = [
    {
      GrpByMnrAstName: "Display Results by Major Asset Type",
      GrpByMnrAstKey: "J",
      GrpByMnrAstId: 0,
    },
    {
      GrpByMnrAstName: "Display Results by Minor Asset Type",
      GrpByMnrAstKey: "N",
      GrpByMnrAstId: 1,
    },
    {
      GrpByMnrAstName: "Display Results by PMR",
      GrpByMnrAstKey: "P",
      GrpByMnrAstId: 2,
    },
    {
      GrpByMnrAstName: "Display Results by Risk Class Type",
      GrpByMnrAstKey: "R",
      GrpByMnrAstId: 3,
    },

    {
      GrpByMnrAstName: "Display Results by Investment Class Type",
      GrpByMnrAstKey: "I",
      GrpByMnrAstId: 4,
    },
  ];

  const [selGrpByMnrAst, setSelGrpByMnrAst] = useState(GrpByMnrAstList[0]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        //setEmail(email);
        GetPortfolioOverviewData(-1);
        //getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  const handleSettings = () => {
    // setModelId(JSON.parse(localStorage.getItem("modelId")));
    // setSelBnchmrk(selBnchmrk);
    // localStorage.setItem("bnchmrkId", selBnchmrk.modelDesc);
    setInitialState({
      showExcludedAssets,
      runInAggregate,
    });

    setModalVisible(true);
    setCloseOption(true);
  };
  const formSubmit = (event) => {
    debugger;
    event.preventDefault();
    if (selBnchmrk.modelId === -1) {
      setValidationError("Please select a Model/Benchmark. ");
      return;
    }
    if (chkCall == false && chkMaturity == false) {
      setvalidationErrorDuration("Please select atleast one duration option. ");
      return;
    }
    setValidationError("");
    setvalidationErrorDuration("");
    setModalVisible(false);
    setCloseOption(false);
    setLoading(true);
    GetPortfolioOverviewData(selBnchmrk.modelId);
    // GetPortfolioBreakDownData();
  };

  const handleCancel = () => {
    setExcludedAssets(initialState.showExcludedAssets);
    setRunInAggregate(initialState.runInAggregate);

    setModalVisible(false);
    setCloseOption(false);

    !closeOption && navigate("/dashboard");
  };

  const handleRadioChange = (e) => {
    setSelectedOption(parseInt(e.target.value));
    setPriceFlagInd(parseInt(e.target.value));
    console.log("e.target.value", e.target.value);
    console.log("e", e);
    if (e.target.value == 1 || e.target.value == 2) {
      setpriceDt(date); // to be checked
    }
  };
  const handleChkCall = () => {
    debugger;
    setChkCall(!chkCall);
    if(chkCall == false)
      setvalidationErrorDuration("");
  };
  const handleExculdedAssetChange = () => {
    setExcludedAssets(!showExcludedAssets);
  };

  const handleChkMaturityChange = () => {
    debugger;
    setChkMaturity(!chkMaturity);
    if (chkMaturity == false)
      setvalidationErrorDuration("");
  };
  function setPrcDt(val) {
    let newdate = new Date(val);
    if (newdate <= new Date(localStorage.getItem("processingDate")))
      setpriceDt(val);
    else {
      setpriceDt(new Date(localStorage.getItem("processingDate")));
    }
    setpriceDt(val);
  }

  //   const getColumnStateDb = async () =>{
  //     //Storing column settings in DB
  //
  //       let token = JSON.parse(localStorage.getItem('token'));
  //       let UserId = JSON.parse(localStorage.getItem('userId'));
  //       let GridId = 1;//let 1 for Portfoliholdings Grid

  //       const postData = {UserId, GridId};
  //       const config = {
  //         headers: {
  //           'authorization': `Bearer ${token.token}`,
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json'
  //         }

  //       };
  //       await axios.post('/RTGetGridColumn',
  //         postData,
  //         config
  //       )
  //         .then(response => {
  //
  //           console.log('save in db');
  //           console.log(response);
  //             const rowData = response.data;
  //             if(rowData!==""){
  //               setIsColumnSave(true);
  //               localStorage.setItem('gridColumns', rowData);
  //             }
  //             else{
  //               setIsColumnSave(false);
  //             }
  //         })
  //         .catch((error) => {
  //           console.log('error in save db '+error);
  //             return error;
  //         });
  //     }
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetPortfolioOverviewData(-1);
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };
  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  // const GetModelInfo = async () => {

  //   setLoading(true);

  //   let token = JSON.parse(localStorage.getItem('token'));
  //   let UserId = JSON.parse(localStorage.getItem('userId'));
  //

  //   let AcctId = JSON.parse(localStorage.getItem('AcctSelected')).acctId;
  //   let Accounts = "<root> <Account AcctId='" + AcctId + "'/> </root>";
  //   setSelectedAcct(JSON.parse(localStorage.getItem('AcctSelected')).acctId);
  //   let invMix = 0;
  //   let NumOfRows = 10;

  //
  //   const postData1 = { AcctId };
  //   const postData = { UserId, Accounts, modelId, invMix, NumOfRows, AcctId };
  //   const config = {
  //     headers: {
  //       'authorization': `Bearer ${token.token}`,
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     }

  //   };
  //   await axios.post('/GetModelInfo',
  //     postData1,
  //     config
  //   ).then((response => {
  //
  //     const rowData = response.data;
  //     localStorage.setItem("modelId", JSON.stringify(response.data.acctMdl[0].modelId));
  //     setModelData(response.data.model);
  //     setModelId(JSON.parse(localStorage.getItem("modelId")));
  //     postData.modelId = response.data.acctMdl[0].modelId;
  //     console.log(postData.modelId)
  //   })
  //   )

  //     //.then(axios.spread((getmodel,accountprofile) => {

  //     //  console.log(response);

  //     // const rowData = response.data.ocAcctProfile;
  //     //  setAcctProfileRptData(rowData);
  //     //  populateAcctHoldingRptData(rowData.ocAcctHolding)

  //     // setLoading(false);

  //     //getInfo(modelId);

  //     //}))
  //     .catch((error) => {

  //       if (error.response.status === 401) {
  //         refreshToken();

  //       }

  //       // return error;
  //     });
  //     GetPortfolioBreakDownData();
  // }
  const [selModel, setSelModel] = useState({ modelDesc: "", modelId: -1 });
  const [selBnchmrk, setSelBnchmrk] = useState({
    modelDesc: "None",
    modelId: -1,
  });

  const [runInAggregate, setRunInAggregate] = useState(false);

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);

  const [initialState, setInitialState] = useState({
    showExcludedAssets: 1,
    runInAggregate: 0,
  });

  const handleAggregateMode = () => {
    if (runInAggregate == 0) {
      setRunInAggregate(true);
    }
    if (runInAggregate == 1) {
      setRunInAggregate(false);
    }
    console.log("Aggregate", runInAggregate);
  };

  let allPDFExport;
  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const PageTemplate = (props) => {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            top: "1px",

            width: "98%",
            borderBottom: "1px solid #bce8f1",
          }}
        >
          <div className="row d-flex mx-3">
            <div className="col text-start">
              <a className="px-2">
                <BankLogoPage />
              </a>
            </div>
            <div className="col text-end px-5 py-2">
              <h2 className="reportheading fs-6">Portfolio Overview Report </h2>
            </div>
          </div>

          {/* <div className='fw-bold  text-center'>
                    Account Profile</div> */}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            //  borderTop: "1px solid #bce8f1"
          }}
        >
          Page {props.pageNum} of {props.totalPages}
        </div>
      </div>
    );
  };
  const GetPortfolioOverviewData = async (bnchmrkid) => {
    debugger;
    setLoading(true);
    setEnableCombo(true);

    let token = tempToken;
    //let token = JSON.parse(localStorage.getItem('token'));
    let AsOfId = JSON.parse(localStorage.getItem("userId"));
    let AcctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;
    let consolidationId = JSON.parse(localStorage.getItem("RelationID"))
      ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
      : 0;
    let pageId = 1;
    //let ModelId=JSON.parse(localStorage.getItem("modelId")).modelId;
    let BenchMarkId = bnchmrkid;
    let priceFlag = priceFlagInd;
    let grpByMnrAst = selGrpByMnrAst.GrpByMnrAstId;
    let showExcldAst = showExcludedAssets;
    const postData = {
      asOfDt,
      AsOfId,
      AcctId,
      BenchMarkId,
      // pageId,
      consolidationId,
      priceFlag,
      priceDt,
      grpByMnrAst,
      showExcldAst,
      runInAggregate,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTPortfolioOverview", postData, config)
      .then((response) => {
        debugger;
        //  console.log(response);

        //const rowData = response.data;

        //const rowData = Enumerable.from(response.data.ocPortFolioHoldingsMainOutPut).where(w => w.totMarket !== 0).toArray();
        const rowData = response.data;

        rowData.t1 = rowData.t1 || [];
        rowData.t2 = rowData.t2 || [];
        rowData.t3 = rowData.t3 || [];
        rowData.t4 = rowData.t4 || [];
        rowData.t5 = rowData.t5 || [];
        rowData.t6 = rowData.t6 || [];
        rowData.t7 = rowData.t7 || [];
        rowData.t8 = rowData.t8 || [];
        rowData.t9 = rowData.t9 || [];
        rowData.t10 = rowData.t10 || [];
        rowData.t11 = rowData.t11 || [];

        let consolidationName =
          JSON.parse(localStorage.getItem("RelationID"))?.cnsldtnNm || 0;

        if (rowData.t4 != null) {
            if (AcctId == 0 && runInAggregate == true){

              for (let i = 0; i < rowData.t4?.length; i++)
                rowData.t4[i].account = consolidationName;
            }
        }

        if (rowData.t8 != null) {
          if (AcctId == 0 && runInAggregate == true) {
            for (let i = 0; i < rowData.t8?.length; i++)
              rowData.t8[i].account = consolidationName;
          }
        }

        if (rowData.t5 != null) {
          if (AcctId == 0 && runInAggregate == true) {
            for (let i = 0; i < rowData.t5?.length; i++)
              rowData.t5[i].account = consolidationName;
          }
        }

        if (rowData.t6 != null) {
          if (AcctId == 0 && runInAggregate == true) {
            for (let i = 0; i < rowData.t6?.length; i++)
              rowData.t6[i].account = consolidationName;
          }
        }

        if (rowData.t9 != null) {
          if (AcctId == 0 && runInAggregate == true) {
            for (let i = 0; i < rowData.t9?.length; i++)
              rowData.t9[i].account = consolidationName;
          }
        }

        setPriceValidation(rowData.priceValidation);

        populatePortfolioOverviewRptData(rowData);
        // if (bnchmrkid == -1) {
        //   setSelBnchmrk(rowData.t11[0]);
        //   localStorage.setItem("bnchmrkId", rowData.t11[0].modelDesc);
        // }
        //getColumnStateDb();
        setLoading(false);
        setEnableCombo(false);

        //setSelModel(Enumerable.from(rowData.t1).where(w => w.modelId === rowData.t3[0].modelId).toArray()[0])
        // setSelBnchmrk(
        //   Enumerable.from(rowData.t11)
        //     .where((w) => w.modelId === rowData.t2[0].benchmarkId)
        //     .toArray()[0]
        // );

        console.log(selModel);
        // localStorage.setItem(
        //   "bnchmrkId",
        //   Enumerable.from(rowData.t11)
        //     .where((w) => w.modelId === rowData.t2[0].benchmarkId)
        //     .toArray()[0].modelDesc
        // );
        console.log(selBnchmrk);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
      });
  };
  if (apicallval === 1) {
    GetPortfolioOverviewData(selBnchmrk?.modelId || -1);
    dispatch(callremove());
  }
  const showpreview = async () => {
    setShow(true);
  };
  const filterData = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };

  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };
  const filtermodelChange = (event) => {
    setSelModel(filterData(event.filter));
  };
  //   const filterbnchmrkChange = (event) => {
  //
  //     setSelBnchmrk(filterData(event.filter));
  //  };
  const handleChange = (event) => {
    if (event.target.value === null || loading) {
      SetselAcct(selAcct);
      //GetUpdatedAccountProfile(0);
      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem(
        "AcctSelected",
        JSON.stringify(JSON.parse(localStorage.getItem("acctData"))[0])
      );
    } else {
      SetselAcct(event.target.value);

      localStorage.setItem(
        "modelId",
        JSON.stringify({ modelDesc: "", modelId: -1 })
      );
      localStorage.setItem("bnchmrkId", selBnchmrk.modelDesc);
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      console.log(event.target.value);
      GetPortfolioOverviewData(-1);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const bnchmrkChange = (event) => {
    if (event.target.value === null) {
      setSelBnchmrk(selBnchmrk);
      localStorage.setItem("bnchmrkId", selBnchmrk.modelDesc);
    } else {
      setSelBnchmrk(event.target.value);
      localStorage.setItem("bnchmrkId", event.target.value.modelDesc);
      setValidationError("");
      // GetPortfolioOverviewData(event.target.value.modelId);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const mrktCapTypChange = (event) => {
    debugger;

    if (event.target.value === null) {
      debugger;
      setSelGrpByMnrAst(GrpByMnrAstList[0]);
    } else {
      debugger;
      setSelGrpByMnrAst(event.target.value);
      setValidationError("");
    }
  };

  const assetClassChange = (e) => {
    if (astcls !== e.target.value) {
      setAstClass(e.target.value);
      //GetPrtflioSnpshtComprsnRptDataRdo(e.target.value);
    }
  };

  console.log(selModel);

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            {/* <div className="row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
              <div className="subheader text-end col-md-1">
                {" "}
                &nbsp; <b>Account(s):</b>
              </div>

              <div className="col-md-3 text-start">
                <ComboBox
                  style={{
                    width: "350px",
                  }}
                  data={selAcctData}
                  textField="extrnlAcctId"
                  dataItemKey="acctId"
                  filterable={true}
                  value={selAcct}
                  onChange={handleChange}
                  onFilterChange={filterChange}
                  disabled={enableCombo}
                />
              </div>
              <div className="subheader text-end col-md-2">
                {" "}
                &nbsp; <b>Model/Benchmark:</b>
              </div>

              <div className="col-md-4 text-start">
                <ComboBox
                  style={{
                    width: "350px",
                  }}
                  data={PortfolioOverviewRptData.t11}
                  textField="modelDesc"
                  dataItemKey="modelId"
                  filterable={true}
                  value={selBnchmrk}
                  onChange={bnchmrkChange}
                  //  onFilterChange={filterbnchmrkChange}
                />
              </div>

            
            </div> */}
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    <div>
      <AntModal
        open={modalVisible}
        title="Customize the Portfolio Overview Report"
        // onCancel={handleCancel}
        closable={false}
        // maskClosable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpAsOfDt"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              As Of Date
            </label>
            <DatePicker
              id="dpAsOfDt"
              value={asOfDt}
              calendar={CustomCalendar}
              min={new Date("1/1/1000")}
              // max={maxFrmDt}
              onChange={(e) => setasOfDt(e.value)}
              style={{ width: "100%" }}
              // validationMessage={
              //   asOfDt == null
              //     ? "Enter a valid date"
              //     : asOfDt < new Date("1/1/1000")
              //     ? "Enter a date in the correct format (mm/dd/yyyy)"
              //     : asOfDt > maxFrmDt
              //     ? "As Of Date cannot exceed the Processing Date"
              //     : ""
              // }
            />
          </div>
          <hr></hr>

          {/* Pricing Options */}
          <h6>Select Pricing Option</h6>
          <div style={{ marginBottom: "20px" }}>
            {[1, 2, 3].map((option) => (
              <label
                key={option}
                style={{ display: "block", marginBottom: "10px" }}
              >
                <input
                  type="radio"
                  value={option}
                  checked={selectedOption === option}
                  onChange={handleRadioChange}
                />
                {option === 1
                  ? " Use Current Price"
                  : option === 2
                  ? " Use End of Month Price"
                  : " Use Non EOM Price"}
              </label>
            ))}
          </div>

          {/* Conditional Price Date Field */}
          {selectedOption === 3 && (
            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="dpPriceDt"
                className="form-label"
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                Price Date
              </label>
              <DatePicker
                id="dpPriceDt"
                value={priceDt}
                calendar={CustomCalendar}
                min={new Date("1/1/1000")}
                max={maxFrmDt}
                onChange={(e) => setPrcDt(e.value)}
                style={{ width: "100%" }}
                validationMessage={
                  priceDt == null
                    ? "Enter a valid date"
                    : priceDt < new Date("1/1/1000")
                    ? "Enter a date in the correct format (mm/dd/yyyy)"
                    : priceDt > maxFrmDt
                    ? "Price Date cannot exceed the Processing Date"
                    : ""
                }
              />
            </div>
          )}

          <hr></hr>
          <div className="col">
            <div style={{ fontWeight: 600 }}>Account Profile Data Display:</div>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={GrpByMnrAstList}
              textField="GrpByMnrAstName"
              dataItemKey="GrpByMnrAstKey"
              filterable={true}
              value={selGrpByMnrAst}
              onChange={mrktCapTypChange}
              clearButton={false}
              //  onFilterChange={filterbnchmrkChange}
            />
          </div>

          <div className="col">
            <div style={{ fontWeight: 600 }}> Model/Benchmark:</div>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={PortfolioOverviewRptData.t11}
              textField="modelDesc"
              dataItemKey="modelId"
              filterable={true}
              value={selBnchmrk}
              onChange={bnchmrkChange}
              clearButton={false}
              //  onFilterChange={filterbnchmrkChange}
            />
          </div>

          {validationError && (
            <div style={{ color: "red", marginTop: "10px" }}>
              {validationError}
            </div>
          )}

          <hr></hr>
          <h6>Miscellaneous Selection</h6>
          <div>
            {/* <label>
              <input
                type="checkbox"
                checked={showExcludedAssets}
                onChange={handleExculdedAssetChange}
              />
              &nbsp;Show Excluded Assets
            </label> */}
            <FormControlLabel
              control={
                <Switch
                  checked={showExcludedAssets}
                  onChange={handleExculdedAssetChange}
                  name="effectiveDtSwitch"
                />
              }
              label="Show Excluded Assets"
              style={{ marginBottom: "10px", display: "block" }}
            />

            {AcctId == 0 && (
              <FormControlLabel
                control={
                  <Switch
                    checked={runInAggregate}
                    onClick={handleAggregateMode}
                    name="chkCtr"
                  />
                }
                label="Run In Aggregate."
                style={{ marginBottom: "10px", display: "block" }}
              />
            )}
          </div>
          <hr></hr>
          <h6>Fixed Income Duration Selection</h6>
          <div>
            <label>
              <input
                type="checkbox"
                checked={chkMaturity}
                onChange={handleChkMaturityChange}
              />
              &nbsp;Duration to Maturity
            </label>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <label>
              <input
                type="checkbox"
                checked={chkCall}
                onChange={handleChkCall}
              />
              &nbsp;Duration to Call
            </label>
          </div>
          {validationErrorDuration && (
            <div style={{ color: "red", marginTop: "10px" }}>
              {validationErrorDuration}
            </div>
          )}

          {/* <hr style={{ margin: "20px 0" }} /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntModal>
      {/* <Header></Header> */}
      <div className="row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>
        {/* <div className='col-md-4 text-start'>
          <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" defaultChecked={astcls === "0"} value="0" className="btn-check form-check-input" name="astcls" id="model" onChange={assetClassChange} />
                <label className="btn btn-outline-primary btn-sm" htmlFor="model">Model</label>

                <input type="radio" value="1" defaultChecked={astcls === "1"} className="btn-check form-check-input" name="astcls" id="bnchmrk" onChange={assetClassChange} />
                <label className="btn btn-outline-primary btn-sm" htmlFor="bnchmrk">Minor Asset</label>
              </div></div> */}

        {/* <div className="col">
          {" "}
          As Of: {localStorage.getItem("processingDate")}
        </div> */}
        {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}
      </div>
      <div className="mx-1 px-1 row d-flex justify-content-between align-items-center my-2 py-2">
        <div className="col">
          <p className="tableheader h6 text-start col-md-4">
            Portfolio Overview Report
          </p>
        </div>
        <div className="col-md-4 text-start">
          <div className="subheader col-md text-end">
            Due to rounding, percentage may not equal 100.
          </div>
          {/* <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={showpreview}
                                >
                                    <span className='px-1'><FaFilePdf /></span>PDF Preview
                                </button> */}
        </div>

        {/* <div>Benchmark:</div>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={PortfolioOverviewRptData.t1}
              textField="modelDesc"
              dataItemKey="modelId"
              filterable={true}
              value={selBnchmrk}
              onChange={bnchmrkChange}
            //  onFilterChange={filterbnchmrkChange}
            />
            <div>As Of: {localStorage.getItem("processingDate")}</div> */}
        {!modalVisible && (
          <>
            <PortfolioOverview1stGrid
              data={PortfolioOverviewRptData.t4}
              flag={isColumnSave}
              showExport={true}
              PortfolioOverviewRptData={PortfolioOverviewRptData}
              priceValidation={priceValidation}
            />
            <PortfolioOverview2ndGrid
              data={PortfolioOverviewRptData.t8}
              carddata={
                PortfolioOverviewRptData.t7.length === 0
                  ? false
                  : PortfolioOverviewRptData.t7
              }
              flag={isColumnSave}
              showExport={true}
              priceValidation={priceValidation}
            />
            <PortfolioOverview3rdGrid
              data={PortfolioOverviewRptData.t5}
              flag={isColumnSave}
              showExport={true}
              priceValidation={priceValidation}
            />
            <PortfolioOverview4thGrid
              data={PortfolioOverviewRptData.t6}
              bnchmrk={localStorage.getItem("bnchmrkId")}
              flag={isColumnSave}
              showExport={true}
              priceValidation={priceValidation}
            />
            <PortfolioOverview5thGrid
              data={PortfolioOverviewRptData.t9}
              flag={isColumnSave}
              showExport={true}
              priceValidation={priceValidation}
            />
          </>
        )}
        <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
          <Modal.Header closeButton>
            <div className="row w-100 tableheader">
              <div className="col">
                <Modal.Title>
                  Portfolio Overview Report - Pdf Preview
                </Modal.Title>
              </div>
              <div className="col text-end">
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={printPDF}
                >
                  Print
                </button>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <PDFExport
              margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
              pageTemplate={PageTemplate}
              forcePageBreak=".page-break"
              paperSize="A4"
              scale={0.5}
              allPages={true}
              landscape={true}
              repeatHeaders={true}
              ref={(pdfExport) => (allPDFExport = pdfExport)}
            >
              Account Name : {selAcct.extrnlAcctId}
              <hr></hr>
              {!modalVisible && (
                <>
                  <PortfolioOverview1stGrid
                    data={PortfolioOverviewRptData.t4}
                    flag={isColumnSave}
                    showExport={false}
                    priceValidation={priceValidation}
                  />
                  <PortfolioOverview2ndGrid
                    data={PortfolioOverviewRptData.t8}
                    carddata={
                      PortfolioOverviewRptData.t7.length === 0
                        ? false
                        : PortfolioOverviewRptData.t7
                    }
                    flag={isColumnSave}
                    showExport={false}
                    priceValidation={priceValidation}
                  />
                  <PortfolioOverview3rdGrid
                    data={PortfolioOverviewRptData.t5}
                    flag={isColumnSave}
                    showExport={false}
                    priceValidation={priceValidation}
                  />
                  <PortfolioOverview4thGrid
                    data={PortfolioOverviewRptData.t6}
                    bnchmrk={localStorage.getItem("bnchmrkId")}
                    flag={isColumnSave}
                    showExport={false}
                    priceValidation={priceValidation}
                  />
                  <PortfolioOverview5thGrid
                    data={PortfolioOverviewRptData.t9}
                    flag={isColumnSave}
                    showExport={false}
                    priceValidation={priceValidation}
                  />
                </>
              )}
            </PDFExport>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary  btn-sm" onClick={handleClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default RTPortfolioOverviewRpt;
