import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SelectControl from "./selectcontrol";
import { filterBy } from "@progress/kendo-data-query";
import Loading from "./loading";
import Header from "./header";
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import Modal from "react-bootstrap/Modal";
import BankLogoPage from "./bankLogoPage";
import ProjectedCashFlowGrid from "./projectedCashFlowGrid";
import PortfolioBreakDown1stGrid from "./portfolioBreakDown1stGrid";
import PortfolioBreakDown2ndGrid from "./portfolioBreakDown2ndGrid";
import PortfolioBreakDown3rdGrid from "./portfolioBreakDown3rdGrid";
import { FaFilePdf } from "react-icons/fa";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Enumerable from "linq";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal as AntModal } from "antd";
import { FaFilter } from "react-icons/fa";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";
import { FormControlLabel, Switch } from "@mui/material";

const RTPortfolioBreakDownRpt = () => {
  var date = new Date(localStorage.getItem("processingDate"));
  var tempToken = JSON.parse(localStorage.getItem("token"));
  var maxFrmDt = date;
  const [asOfDt, setasOfDt] = React.useState(date);

  localStorage.setItem(
    "modelId",
    JSON.stringify({ modelDesc: "", modelId: -1 })
  );
  localStorage.setItem(
    "bnchmrkId",
    JSON.stringify({ modelDesc: "", modelId: -1 })
  );
  const [PortfolioBreakDownRptData, populatePortfolioBreakDownRptData] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData")).slice()
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );

  const [isColumnSave, setIsColumnSave] = useState(false);
  const [selectedAcct, setSelectedAcct] = useState(0);
  const [modelId, setModelId] = useState(-1);
  const [modelData, setModelData] = useState([]);
  const [dispAssetClass, setdispAssetClass] = useState("");
  const [dispAssetClass2, setdispAssetClass2] = useState("");
  const [session, setSession] = useState("");
  const [enableCombo, setEnableCombo] = useState(false);
  const navigate = useNavigate();

  const [priceDt, setpriceDt] = React.useState(date);
  const [closeOption, setCloseOption] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [selectedOption, setSelectedOption] = useState(1);
  const [priceFlagInd, setPriceFlagInd] = useState(1);
  const [showExcludedAssets, setExcludedAssets] = useState(true);
  const [runInAggregate, setRunInAggregate] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [priceValidation, setPriceValidation] = useState("");
  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);
  const [benchLst, setBenchLst] = useState([]);
  const [modelLst, setModelLst] = useState([]);

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  const [initialState, setInitialState] = useState({
    showExcludedAssets: true,
    runInAggregate: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        //setEmail(email);
        GetPortfolioBreakDownData();
        getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    // GetPortfolioBreakDownData();
    fetchData();
  }, []);

  const handleSettings = () => {
    setInitialState({
      showExcludedAssets,
      runInAggregate,
    });
    setModelId(JSON.parse(localStorage.getItem("modelId")));
    setModalVisible(true);
    setCloseOption(true);
  };
  const formSubmit = (event) => {
    debugger;
    event.preventDefault();
    if (selModel.modelId === -1 && selBnchmrk.modelId == -1) {
      setValidationError("Please select a model and a benchmark.");
      return;
    } else if (selModel.modelId === -1) {
      setValidationError("Please select a model. ");
      return;
    } else if (selBnchmrk.modelId == -1) {
      setValidationError("Please select a benchmark.");
      return;
    }
    setValidationError("");
    setModalVisible(false);
    setCloseOption(false);
    setLoading(true);
    GetPortfolioBreakDownData();
  };

  const handleCancel = () => {
    setExcludedAssets(initialState.setExcludedAssets);
    setRunInAggregate(initialState.runInAggregate);
    setModalVisible(false);
    setCloseOption(false);

    !closeOption && navigate("/dashboard");
  };

  const handleRadioChange = (e) => {
    setSelectedOption(parseInt(e.target.value));
    setPriceFlagInd(parseInt(e.target.value));
    console.log("e.target.value", e.target.value);
    console.log("e", e);
    if (e.target.value == 1 || e.target.value == 2) {
      setpriceDt(date); // to be checked
    }
  };

  const handleCheckboxChange = () => {
    setExcludedAssets(!showExcludedAssets);
  };

  const handlerunInAggregate = () => {
    setRunInAggregate(!runInAggregate);
  };

  function setPrcDt(val) {
    let newdate = new Date(val);
    if (newdate <= new Date(localStorage.getItem("processingDate")))
      setpriceDt(val);
    else {
      setpriceDt(new Date(localStorage.getItem("processingDate")));
    }
    setpriceDt(val);
  }

  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 1; //let 1 for Portfoliholdings Grid

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn", postData, config)
      .then((response) => {
        console.log("save in db");
        console.log(response);
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);
        return error;
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetPortfolioBreakDownData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };
  // const GetModelInfo = async () => {

  //   setLoading(true);

  //   let token = JSON.parse(localStorage.getItem('token'));
  //   let UserId = JSON.parse(localStorage.getItem('userId'));
  //

  //   let AcctId = JSON.parse(localStorage.getItem('AcctSelected')).acctId;
  //   let Accounts = "<root> <Account AcctId='" + AcctId + "'/> </root>";
  //   setSelectedAcct(JSON.parse(localStorage.getItem('AcctSelected')).acctId);
  //   let invMix = 0;
  //   let NumOfRows = 10;

  //
  //   const postData1 = { AcctId };
  //   const postData = { UserId, Accounts, modelId, invMix, NumOfRows, AcctId };
  //   const config = {
  //     headers: {
  //       'authorization': `Bearer ${token.token}`,
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     }

  //   };
  //   await axios.post('/GetModelInfo',
  //     postData1,
  //     config
  //   ).then((response => {
  //
  //     const rowData = response.data;
  //     localStorage.setItem("modelId", JSON.stringify(response.data.acctMdl[0].modelId));
  //     setModelData(response.data.model);
  //     setModelId(JSON.parse(localStorage.getItem("modelId")));
  //     postData.modelId = response.data.acctMdl[0].modelId;
  //     console.log(postData.modelId)
  //   })
  //   )

  //     //.then(axios.spread((getmodel,accountprofile) => {

  //     //  console.log(response);

  //     // const rowData = response.data.ocAcctProfile;
  //     //  setAcctProfileRptData(rowData);
  //     //  populateAcctHoldingRptData(rowData.ocAcctHolding)

  //     // setLoading(false);

  //     //getInfo(modelId);

  //     //}))
  //     .catch((error) => {

  //       if (error.response.status === 401) {
  //         refreshToken();

  //       }

  //       // return error;
  //     });
  //     GetPortfolioBreakDownData();
  // }
  const [selModel, setSelModel] = useState(
    PortfolioBreakDownRptData?.t1
      ? PortfolioBreakDownRptData?.t1[0]
      : { modelDesc: "None", modelId: -1 }
  );
  const [selBnchmrk, setSelBnchmrk] = useState({
    modelDesc: "None",
    modelId: -1,
  });
  const [show, setShow] = React.useState(false);
  const GetPortfolioBreakDownData = async () => {
    setLoading(true);
    setEnableCombo(true);

    let token = tempToken;
    //let token = JSON.parse(localStorage.getItem('token'));
    let AsOfId = JSON.parse(localStorage.getItem("userId"));
    let AcctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;
    let pageId = 1;
    let ModelId = selModel.modelId;
    let BenchMarkId = selBnchmrk.modelId;

    let showExcldAst = showExcludedAssets;
    let consolidationId = JSON.parse(localStorage.getItem("RelationID"))
      ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
      : 0;

    let AsOfDt = asOfDt;

    let priceFlag = priceFlagInd;

    const postData = {
      AsOfId,
      AcctId,
      AsOfDt,
      ModelId,
      BenchMarkId,
      consolidationId,
      priceFlag,
      priceDt,
      showExcldAst,
      runInAggregate,
    };

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTPortfolioBreakDown", postData, config)
      .then((response) => {
        debugger;
        //  console.log(response);

        //const rowData = response.data;

        //const rowData = Enumerable.from(response.data.ocPortFolioHoldingsMainOutPut).where(w => w.totMarket !== 0).toArray();
        const rowData = response.data;

        rowData.t1 = rowData.t1 || [];
        rowData.t2 = rowData.t2 || [];
        rowData.t3 = rowData.t3 || [];
        rowData.t4 = rowData.t4 || [];
        rowData.t5 = rowData.t5 || [];
        rowData.t6 = rowData.t6 || [];
        rowData.t7 = rowData.t7 || [];

        setPriceValidation(rowData.priceValidation);
        // var i=0;
        let consolidationName = JSON.parse(
          localStorage.getItem("RelationID")
        )?.cnsldtnNm;

        for (let i = 0; i < rowData.t4.length; i++) {
          if (AcctId == 0 && runInAggregate == true) {
            rowData.t4[i].accountName = consolidationName;
          }
          if (
            rowData.t4[i].excldInd === 1 &&
            localStorage.getItem("ShwHdeExcldAstVerbag") === "1"
          ) {
            rowData.t4[i].descption = "^" + rowData.t4[i].descption;
          }
          if (
            rowData.t4[i].excldInd === 1 &&
            localStorage.getItem("ShwHdeExcldAstVerbag") === "1"
          ) {
            setdispAssetClass("^ Asset Class Contains IM Excluded Asset(s)");
          }
        }
        for (let i = 0; i < rowData.t5.length; i++) {
          if (AcctId == 0 && runInAggregate == true) {
            rowData.t5[i].accountName = consolidationName;
          }
          if (
            rowData.t5[i].excldInd === 1 &&
            localStorage.getItem("ShwHdeExcldAstVerbag") === "1"
          ) {
            rowData.t5[i].descption = "^" + rowData.t5[i].descption;
          }
          if (
            rowData.t5[i].excldInd === 1 &&
            localStorage.getItem("ShwHdeExcldAstVerbag") === "1"
          ) {
            setdispAssetClass("^ Asset Class Contains IM Excluded Asset(s)");
          }
        }
        for (let i = 0; i < rowData.t6.length; i++) {
          if (AcctId == 0 && runInAggregate == true) {
            rowData.t6[i].accountName = consolidationName;
          }
        }

        let accNamenum = JSON.parse(localStorage.getItem("AcctSelected"));
        for (let x = 0; x < rowData.t7.length; x++) {
          rowData.t7[x].shrtNm = accNamenum.extrnlAcctId;
        }
        if (rowData.t2.length != 0) {
          setBenchLst(rowData.t2);
        }
        if (rowData.t1.length != 0) {
          setModelLst(rowData.t1);
        }

        populatePortfolioBreakDownRptData(rowData);
        getColumnStateDb();
        setLoading(false);
        setEnableCombo(false);

        // setSelModel(
        //   Enumerable.from(rowData.t1)
        //     .where((w) => w.modelId === rowData.t3[0].modelId)
        //     .toArray()[0]
        // );
        // setSelBnchmrk(
        //   Enumerable.from(rowData.t2)
        //     .where((w) => w.modelId === rowData.t3[0].benchmarkId)
        //     .toArray()[0]
        // );
        console.log(selModel);
        console.log(selBnchmrk);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
      });
  };

  if (apicallval === 1) {
    GetPortfolioBreakDownData();
    dispatch(callremove());
  }

  const filterData = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };
  const showpreview = async () => {
    setShow(true);
  };
  let allPDFExport;
  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };
  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };
  const filtermodelChange = (event) => {
    setSelModel(filterData(event.filter));
  };
  //   const filterbnchmrkChange = (event) => {
  //
  //     setSelBnchmrk(filterData(event.filter));
  //  };
  const handleChange = (event) => {
    if (event.target.value === null) {
      SetselAcct(selAcct);
      //GetUpdatedAccountProfile(0);
      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem(
        "AcctSelected",
        JSON.stringify(JSON.parse(localStorage.getItem("acctData"))[0])
      );
    } else {
      SetselAcct(event.target.value);

      localStorage.setItem(
        "modelId",
        JSON.stringify({ modelDesc: "", modelId: -1 })
      );
      localStorage.setItem(
        "bnchmrkId",
        JSON.stringify({ modelDesc: "", modelId: -1 })
      );
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      console.log(event.target.value);
      GetPortfolioBreakDownData();
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  const PageTemplate = (props) => {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            top: "1px",

            width: "98%",
            borderBottom: "1px solid #bce8f1",
          }}
        >
          <div className="row d-flex mx-3">
            <div className="col text-start">
              <a className="px-2">
                <BankLogoPage />
              </a>
            </div>
            <div className="col text-end px-5 py-2 mt-1">
              <h2 className="fw-bold text-fitek">
                Portfolio Breakdown Report{" "}
              </h2>
            </div>
          </div>

          {/* <div className='fw-bold  text-center'>
                    Account Profile</div> */}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            //  borderTop: "1px solid #bce8f1"
          }}
        >
          Page {props.pageNum} of {props.totalPages}
        </div>
      </div>
    );
  };
  const handleClose = () => setShow(false);
  const modelChange = (event) => {
    if (event.target.value === null) {
      setSelModel({ modelDesc: "None", modelId: -1 });
    } else {
      setSelModel(event.target.value);
      localStorage.setItem("modelId", JSON.stringify(event.target.value));
      setValidationError("");
      // GetPortfolioBreakDownData();
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };
  const bnchmrkChange = (event) => {
    if (event.target.value === null) {
      setSelBnchmrk({ modelDesc: "None", modelId: -1 });
    } else {
      setSelBnchmrk(event.target.value);
      localStorage.setItem("bnchmrkId", JSON.stringify(event.target.value));
      setValidationError("");
      // GetPortfolioBreakDownData();
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  console.log(selModel);

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            <div className="row d-flex justify-content-start align-items-center mx-1 shadow-sm rounded">
              <div className="col text-end">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={handleSettings}
                  title="Click to change filters"
                >
                  <FaFilter />
                  <span className="px-2">Change Filters</span>
                </button>
              </div>
              {/* <div className="row d-flex justify-content-between align-items-center bg-light">
                <div className="row">
                  <div className="col">
                    <div> Account(s):</div>
                    <ComboBox
                      style={{
                        width: "330px",
                      }}
                      data={selAcctData}
                      textField="extrnlAcctId"
                      dataItemKey="acctId"
                      filterable={true}
                      value={selAcct}
                      onChange={handleChange}
                      onFilterChange={filterChange}
                      disabled={enableCombo}
                    />
                  </div>
                  <div className="col">
                    <div>Model:</div>

                    <ComboBox
                      style={{
                        width: "300px",
                      }}
                      data={PortfolioBreakDownRptData.t1}
                      textField="modelDesc"
                      dataItemKey="modelId"
                      filterable={true}
                      value={selModel}
                      onChange={modelChange}
                      onFilterChange={filtermodelChange}
                    />
                  </div>
                  <div className="col">
                    <div>Benchmark:</div>
                    <ComboBox
                      style={{
                        width: "300px",
                      }}
                      data={PortfolioBreakDownRptData.t2}
                      textField="modelDesc"
                      dataItemKey="modelId"
                      filterable={true}
                      value={selBnchmrk}
                      onChange={bnchmrkChange}
                      //onFilterChange={filterbnchmrkChange}
                    />
                  </div>
                </div>
              </div> */}
              <div className="reportheading fs-6 mx-1 px-1 mt-1">
                Portfolio Breakdown Report{" "}
              </div>
            </div>
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    <div>
      {/* <Header></Header> */}
      <AntModal
        open={modalVisible}
        title="Customize the Portfolio Breakdown Report"
        // onCancel={handleCancel}
        closable={false}
        // maskClosable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpAsOfDt"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              As Of Date
            </label>
            <DatePicker
              id="dpAsOfDt"
              value={asOfDt}
              calendar={CustomCalendar}
              min={new Date("1/1/1000")}
              max={maxFrmDt}
              onChange={(e) => setasOfDt(e.value)}
              style={{ width: "100%" }}
              validationMessage={
                asOfDt == null
                  ? "Enter a valid date"
                  : asOfDt < new Date("1/1/1000")
                  ? "Enter a date in the correct format (mm/dd/yyyy)"
                  : asOfDt > maxFrmDt
                  ? "As Of Date cannot exceed the Processing Date"
                  : ""
              }
            />
          </div>

          <hr></hr>
          <div className="col">
            <div style={{ fontWeight: "600" }}>Model:</div>

            <ComboBox
              style={{
                width: "300px",
              }}
              data={modelLst}
              textField="modelDesc"
              dataItemKey="modelId"
              filterable={true}
              value={selModel}
              onChange={modelChange}
              //onFilterChange={filtermodelChange}
            />
          </div>
          <div className="col">
            <div style={{ fontWeight: "600" }}>Benchmark:</div>
            <ComboBox
              style={{
                width: "300px",
              }}
              data={benchLst}
              textField="modelDesc"
              dataItemKey="modelId"
              filterable={true}
              value={selBnchmrk}
              onChange={bnchmrkChange}

              //onFilterChange={filterbnchmrkChange}
            />
          </div>
          {validationError && (
            <div style={{ color: "red", marginTop: "10px" }}>
              {validationError}
            </div>
          )}
          <hr></hr>

          {/* Pricing Options */}
          <h6>Select Pricing Option</h6>
          <div style={{ marginBottom: "20px" }}>
            {[1, 2, 3].map((option) => (
              <label
                key={option}
                style={{ display: "block", marginBottom: "10px" }}
              >
                <input
                  type="radio"
                  value={option}
                  checked={selectedOption === option}
                  onChange={handleRadioChange}
                />
                {option === 1
                  ? " Use Current Price"
                  : option === 2
                  ? " Use End of Month Price"
                  : " Use Non EOM Price"}
              </label>
            ))}
          </div>

          {/* Conditional Price Date Field */}
          {selectedOption === 3 && (
            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="dpPriceDt"
                className="form-label"
                style={{
                  display: "block",
                  fontWeight: "600",
                  marginBottom: "5px",
                }}
              >
                Price Date
              </label>
              <DatePicker
                id="dpPriceDt"
                value={priceDt}
                calendar={CustomCalendar}
                min={new Date("1/1/1000")}
                max={maxFrmDt}
                onChange={(e) => setPrcDt(e.value)}
                style={{ width: "100%" }}
                validationMessage={
                  priceDt == null
                    ? "Enter a valid date"
                    : priceDt < new Date("1/1/1000")
                    ? "Enter a date in the correct format (mm/dd/yyyy)"
                    : priceDt > maxFrmDt
                    ? "Price Date cannot exceed the Processing Date"
                    : ""
                }
              />
            </div>
          )}

          <hr></hr>
          <h6>Miscellaneous Section</h6>
          {/* <div>
            <label>
              <input
                type="checkbox"
                checked={showExcludedAssets}
                onChange={handleCheckboxChange}
              />
              &nbsp;Show Excluded Assets
            </label>
          </div> */}
          <FormControlLabel
            control={
              <Switch
                checked={showExcludedAssets}
                onClick={handleCheckboxChange}
                name="chkCtr"
              />
            }
            label="Show Excluded Assets"
            style={{ marginBottom: "10px", display: "block" }}
          />

          {AcctId == 0 && (
            <FormControlLabel
              control={
                <Switch
                  checked={runInAggregate}
                  onClick={handlerunInAggregate}
                  name="chkCtr"
                />
              }
              label="Run In Aggregate."
              style={{ marginBottom: "10px", display: "block" }}
            />
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntModal>
      <div className="row d-flex justify-content-between align-items-center mx-1 shadow-sm rounded">
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>

        <div className="row d-flex justify-content-between align-items-center my-1 mx-1 px-1 bg-light">
          <div className="row d-flex justify-content-center align-items-center">
            {/* <div className="col">
              <div> Account(s):</div>
              <ComboBox
                style={{
                  width: "350px",
                }}
                data={selAcctData}
                textField="extrnlAcctId"
                dataItemKey="acctId"
                filterable={true}
                value={selAcct}
                onChange={handleChange}
                onFilterChange={filterChange}
                disabled={enableCombo}
              />
            </div> */}

            <div className="col">
              {/* <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={showpreview}
                                >
                                   <span className='px-1'><FaFilePdf /></span>PDF Preview
                                </button> */}
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between align-items-center mx-1 px-1 mt-1">
          <div className="col-md-4">
            <div className="reportheading fs-6 h6">
              Portfolio Breakdown Report
            </div>
          </div>
          <div className="subheader col-lg text-end">
            Due to rounding, percentage may not equal 100.
          </div>
        </div>
        {!modalVisible && (
          <>
            <PortfolioBreakDown1stGrid
              data={PortfolioBreakDownRptData.t4}
              flag={isColumnSave}
              showExport={true}
              dispAssetClass={dispAssetClass}
              reportData={PortfolioBreakDownRptData}
              priceValidation={priceValidation}
            />
            <PortfolioBreakDown2ndGrid
              data={PortfolioBreakDownRptData.t5}
              flag={isColumnSave}
              showExport={true}
              dispAssetClass={dispAssetClass2}
              priceValidation={priceValidation}
            />
            <PortfolioBreakDown3rdGrid
              data={PortfolioBreakDownRptData.t6}
              flag={isColumnSave}
              showExport={true}
              priceValidation={priceValidation}
            />
          </>
        )}
        <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
          <Modal.Header closeButton>
            <div className="row w-100 tableheader">
              <div className="col">
                <Modal.Title>
                  Portfolio Breakdown Report - Pdf Preview
                </Modal.Title>
              </div>

              <div className="col text-end">
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={printPDF}
                >
                  Print
                </button>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <PDFExport
              margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
              pageTemplate={PageTemplate}
              forcePageBreak=".page-break"
              paperSize="A4"
              scale={0.5}
              allPages={true}
              landscape={true}
              repeatHeaders={true}
              ref={(pdfExport) => (allPDFExport = pdfExport)}
            >
              Account Name : {selAcct.extrnlAcctId}
              <hr></hr>
              <PortfolioBreakDown1stGrid
                data={PortfolioBreakDownRptData.t4}
                flag={isColumnSave}
                showExport={true}
                dispAssetClass={dispAssetClass}
                reportData={PortfolioBreakDownRptData}
              />
              <PortfolioBreakDown2ndGrid
                data={PortfolioBreakDownRptData.t5}
                flag={isColumnSave}
                showExport={true}
                dispAssetClass={dispAssetClass2}
              />
              <PortfolioBreakDown3rdGrid
                data={PortfolioBreakDownRptData.t6}
                flag={isColumnSave}
                showExport={false}
              />
            </PDFExport>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary  btn-sm" onClick={handleClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default RTPortfolioBreakDownRpt;
