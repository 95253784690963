import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SelectControl from "./selectcontrol";
import Loading from "./loading";
import Header from "./header";
import SctrReturnPerformanceGrid from "./sctrReturnPerfrmnceGrid";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import { Modal as AntdModal, Button as AntdButton } from "antd";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { formatDate, parseDate } from "@telerik/kendo-intl";

import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";

const SctrReturnPerformanceRpt = () => {
  var date = new Date(localStorage.getItem("processingDate"));
  var minDate = new Date(localStorage.getItem("processingDate"));
  minDate.setMonth(minDate.getMonth() - 60);
  var maxDate = new Date(localStorage.getItem("processingDate"));
  const [isDisabled, setisDisabled] = React.useState(false);

  const [SctrReturnPerformanceRptData, populateSctrReturnPerformanceRptData] =
    useState([]);
  const [reportData, setReportData] = useState([]);
  const [AstGrwthRptData, populateAstGrwthRptData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [flag, setFlag] = useState(0);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const [enableCombo, setEnableCombo] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [validationError, setValidationError] = useState("");
  const [onThisPage, setOnThisPage] = useState(false);
  const [frmDate, setfrmDate] = React.useState(date);
  const [toDate, setToDate] = useState(date);
  const [minFrmDt, setminFrmDt] = React.useState(minDate);
  const [maxFrmDt, setmaxFrmDt] = React.useState(maxDate);

  const navigate = useNavigate();

  const apicallval = useSelector(selectapicall);
  const dispatch = useDispatch();
  // useEffect(() => {

  // const fetchData = async () => {
  //      setLoading(true);
  //      setEnableCombo(true);
  //     try {
  //         //let data = location.state;

  //         let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;

  //         //setEmail(email);
  //         GetSctrReturnPerformanceData();

  //         //  console.log(data);
  //     } catch (error) {
  //         console.error(error.message);
  // }

  // }
  // fetchData();
  // }, []);
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetSctrReturnPerformanceData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const openModal = () => {
    setIsVisible(true);
  };

  const onSubmitModal = (e) => {
    debugger;
    e.preventDefault();

    // if (selModel.modelId === -1) {
    //   setValidationError("Please select a model.");
    //   return;
    // }

    setValidationError("");
    setOnThisPage(true);
    setIsVisible(false);

    GetSctrReturnPerformanceData();

    // {
    //   selModel.modelId !== -1 && GetSctrReturnPerformanceData();
    // }
  };
  const closeModal = () => {
    setIsVisible(false);
    !onThisPage && navigate("/dashboard");
  };
  const GetSctrReturnPerformanceData = async () => {
    //
    setLoading(true);
    setEnableCombo(true);
    debugger;
    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let asOfId = JSON.parse(localStorage.getItem("userId"));

    //    let lsSelectedAcct=
    // JSON.parse(localStorage.getItem("AccountID")) != null
    //   ? JSON.parse(localStorage.getItem("AccountID"))
    //   : 0;
    // let lsSelectedAcct=JSON.parse(localStorage.getItem("AcctSelected"));
    let acctIds =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;
    //  if(lsSelectedAcct!== null)
    //  {
    //     acctIds=lsSelectedAcct.acctId;
    //  }
    //let PageId = 1;

    //  {
    //   "asOfId": 0,
    //   "acctIds": 0,
    //   "consolidationId": 0,
    //   "startDate": "string",
    //   "endDate": "string",
    //   "perfIds": "string"
    // }
    let perfIds = null;
    let consolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;

    let startDate = formatDate(frmDate, "MM/dd/yyyy");
    let endDate = formatDate(toDate, "MM/dd/yyyy");
    const postData = {
      asOfId,
      acctIds,
      consolidationId,
      startDate,
      endDate,
      perfIds,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTSectorRetPerfrmnce", postData, config)
      .then((response) => {
        console.log(response);
        //
        const rowData = response.data;
        debugger;
        populateSctrReturnPerformanceRptData(rowData?.sRP1 || []);
        setReportData(rowData);
        setSelAcctData(rowData?.sRP2 || []);
        populateAstGrwthRptData(rowData?.sRP3 || []);
        if (JSON.parse(localStorage.getItem("AccountID")) != null) {
          SetselAcct(JSON.parse(localStorage.getItem("AccountID")));
          // setSelectedAcct(lsSelectedAcct.acctId);
        } else {
          SetselAcct(rowData?.sRP2[0] || []);
          // setSelectedAcct((rowData.sRP2[0]).acctId);
          //setSelectedAcct(rowData.fIM1[0].acctId);
        }
        setLoading(false);
        setEnableCombo(false);
        setFlag(1);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        // return error;
      });
  };

  if (apicallval === 1) {
    GetSctrReturnPerformanceData();
    dispatch(callremove());
  }
  const handleChange = (event) => {
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetSctrReturnPerformanceData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            <Loading />
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      {/* <Header></Header> */}
      <div className="row d-flex justify-content-between align-items-center mx-2 py-1 border-bottom">
        <div className="col reportheading fs-6">Sector Return Report</div>

        {/* <span className='px-2'>Account(s)</span>
          <ComboBox
            style={{
              width: "350px",
            }}
            data={selAcctData}
            textField="extrnlAcctId"
            dataItemKey="acctId"
            filterable={true}
            value={selAcct}
            onChange={handleChange}
            disabled={enableCombo}
            // onFilterChange={filterChange}
          /> */}
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={openModal}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>

        {/* <div className="subheader text-end col-md-2">
            Processing Date : {localStorage.getItem("processingDate")}
          </div> */}
      </div>

      <AntdModal
        open={isVisible}
        title="Customize Account Sectors Comparision"
        closable={false}
        footer={[]}
      >
        <form onSubmit={onSubmitModal}>
          <div className="pt-3">
            <span>
              <h6>From Date</h6>
            </span>
            <DatePicker
              id="dpFrm"
              value={frmDate}
              // calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={isDisabled}
              style={{ width: "100%" }}
              valid={
                frmDate >= minFrmDt && frmDate <= maxFrmDt && frmDate != null
              }
              onChange={(e) => setfrmDate(e.value)}
              validationMessage={
                frmDate == null
                  ? "Enter Valid Date"
                  : frmDate < new Date("1/1/1000")
                  ? "Enter date in correct format (mm/dd/yyyy)"
                  : frmDate > maxFrmDt
                  ? "From date cannot exceed one year from Processing date."
                  : frmDate > toDate
                  ? "From date cannot exceed to date."
                  : frmDate < minFrmDt
                  ? "From date cannot be less than Processing date."
                  : "Validation error"
              }
            />
          </div>
          <div className="pt-3">
            <span>
              <h6>To Date</h6>
            </span>
            <DatePicker
              id="dpTo"
              value={toDate}
              // format="MM/dd/yyyy"
              // calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={false}
              onChange={(e) => {
                setToDate(e.value);
              }}
              style={{ width: "100%" }}
              valid={
                frmDate <= toDate && toDate <= maxFrmDt && toDate >= minFrmDt
              }
              validationMessage={
                toDate == null
                  ? "Enter Valid Date"
                  : toDate < new Date("1/1/1000")
                  ? "Enter date in correct format (mm/dd/yyyy)"
                  : toDate > maxFrmDt
                  ? "To date cannot exceed one year from Processing date."
                  : toDate < frmDate
                  ? "To date cannot be less than from date"
                  : toDate < minFrmDt
                  ? "To date cannot be less than Processing date."
                  : "Validation error"
              }
            />
          </div>

          <hr style={{ margin: "20px 0" }} />

          {/* <div>
            <h6>Benchmark/ Modal </h6>
            <select value={selecteBenchmark} onChange={handleDropdownChange}>
              <option value="">-- Select --</option>{" "}
              {Benchmarks.map((option, index) => (
                <option key={index} value={option.toLowerCase()}>
                  {option}
                </option>
              ))}
            </select>
          </div> */}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={closeModal}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntdModal>

      {/* <div className="rounded"></div> */}

      {flag === 1 ? (
        <SctrReturnPerformanceGrid
          data={SctrReturnPerformanceRptData}
          AstGrwth={AstGrwthRptData}
          reportData={reportData}
          acct={selAcct}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SctrReturnPerformanceRpt;
