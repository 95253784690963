import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import React, { useState, useEffect } from "react";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { orderBy } from "@progress/kendo-data-query";
import Moment from "moment";
import Modal from "react-bootstrap/Modal";
import { Viewer } from "@grapecity/activereports-react";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import { process } from "@progress/kendo-data-query";

const AnnualPortfolioValueDetails = ({ data }) => {
  const [reportData, setReportData] = useState([]);
  let newCombinedDatat2ANDt3 = "";
  const initialSort = [
    {
      field: "colSeq",
      dir: "asc",
    },
  ];

  const [sort, setSort] = useState(initialSort);
  const [show, setShow] = useState(false);

  const viewerRef = React.useRef();

  console.log("Data ", data);
  const t2DataLength = data.t2.length;
  // console.log(t2DataLength);

  // console.log("Merged Data", mergedData);

  // Convert the combined array to a JSON string
  // const combinedJson = JSON.stringify(mergedData);

  // console.log("String JSON",combinedJson);

  const t1Data = data.t1 || [];
  const t2Data = data.t2 || [];
  const t3Data = data.t3 || [];

  // console.log("t1 Data", t1Data)
  console.log("t2 Data", t2Data);
  console.log("t3 Data", t3Data);

  const perfTmpltCtgryNms = t3Data.map((item) => item.perfTmpltCtgryNm);

  console.log("perfTmp", perfTmpltCtgryNms);

  const newT2Data = t2Data;
  // const newT2Data = t2Data.map((item) => {
  //   let colSeq = null;

  //   if (item.columnName === "StartMarket") {
  //     colSeq = 1;
  //   } else if (item.columnName === "EndMarket") {
  //     colSeq = 2;
  //   } else if (item.columnName === "StartAcrlIncm") {
  //     colSeq = 3;
  //   } else if (item.columnName === "EndAcrlIncm") {
  //     colSeq = 4;
  //   } else if (item.columnName === "StartPortVal") {
  //     colSeq = 5;
  //   } else if (item.columnName === "EndPortVal") {
  //     colSeq = 6;
  //   } else if (item.columnName === "IncomeEarned") {
  //     colSeq = 7;
  //   } else if (item.columnName === "IncomeReturn") {
  //     colSeq = 8;
  //   } else if (item.columnName === "Contribution") {
  //     colSeq = 9;
  //   } else if (item.columnName === "Withdrwals") {
  //     colSeq = 10;
  //   } else if (item.columnName === "MarketApprctn") {
  //     colSeq = 11;
  //   } else if (item.columnName === "TotalReturnG") {
  //     colSeq = 12;
  //   } else if (item.columnName === "CapitalReturn") {
  //     colSeq = 13;
  //   }

  //   return {
  //     ...item,
  //     colSeq,
  //   };
  // });

  console.log("New T2Data", newT2Data);

  const headers = t1Data.length > 0 ? t1Data[0] : {};
  const headerTitles = {
    year1: headers.year1 || "Year 1",
    year2: headers.year2 || "Year 2",
    year3: headers.year3 || "Year 3",
    year4: headers.year4 || "Year 4",
    year5: headers.year5 || "Year 5",
    year6: headers.year6 || "Year 6",
  };

  console.log("HeaderTitles", headerTitles);

  // const columnNameMapping = {
  //   StartMarket: "Beginning Market Value",
  //   StartAcrlIncm: "Beginning Accrued Income",
  //   StartPortVal: "Beginning Portfolio Value",
  //   Contribution: "Contributions",
  //   Withdrwals: "Withdrawals",
  //   IncomeEarned: "Income Earned",
  //   EndMarket: "Ending Market Value",
  //   EndAcrlIncm: "Ending Accrued Income",
  //   EndPortVal: "Ending Portfolio Value",
  //   MarketApprctn: "Market Appreciation",
  //   TotalReturnG: "Total Return (Gross Fees)",
  //   CapitalReturn: "Capital Only",
  //   IncomeReturn: "Income Only",
  // };

  // const transformedData = newT2Data.map((item) =>
  //   // console.log("Item", item),
  //   ({
  //     ...item,
  //     columnName: columnNameMapping[item.columnName] || item.columnName,
  //   })
  // );

  //console.log("TransformedData", transformedData);

  async function loadReport() {
    // load report definition from the file
    //
    const reportResponse = await fetch("AnnualPortFolio.rdlx-json");
    //
    const report = await reportResponse.json();
    return report;
  }

  async function openReport() {
    //
    const report = await loadReport();

    //
    const parameters = [
      { Name: "pPriceDate", Value: [localStorage.getItem("processingDate")] },
      { Name: "pAsofDate", Value: [localStorage.getItem("processingDate")] },
      {
        Name: "pCnsldtnNm",
        Value: [
          JSON.parse(localStorage.getItem("RelationID")) != null
            ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm === ""
              ? 0
              : JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm
            : 0,
        ],
      },
      {
        Name: "pExtrnlAcctId",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 0
            : JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId,
        ],
      },
      {
        Name: "pShow",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 3
            : 2,
        ],
      },

      // {Name: 'pEndBalance', Value: [ChkBoxState]}
    ];
    for (let i = 0; i < data.length; i++) {
      data[i].incptnDt = Moment(data[i].incptnDt).format("MM/DD/YYYY");
    }
    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(newCombinedDatat2ANDt3);
    //

    //if(loadingChild==false)
    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  const handleClose = () => setShow(false);

  const excelExport = () => {
    setShow(true);
    openReport();
  };

  const NumberCell = (props) => {
    return (
      <td style={{ textAlign: "right" }}>
        {formatNumber(props.dataItem[props.field], "##,#.00")}
      </td>
    );
  };

  // let transformedDataLength = transformedData.length;
  // console.log("transformedData Length", transformedDataLength);

  // const combinedData = [
  //   ...transformedData.map((item) => ({
  //     ...item,
  //     columnName: item.columnName,
  //     extrnlAcctId: item.extrnlAcctId,
  //     shrtNm: item.shrtNm,
  //     year1: item.year1,
  //     year2: item.year2,
  //     year3: item.year3,
  //     year4: item.year4,
  //     year5: item.year5,
  //     year6: item.year6,
  //   })),
  //   ...t3Data.map((item) => ({
  //     ...item,
  //     columnName: item.perfTmpltCtgryNm,
  //     year1: item.year1,
  //     year2: item.year2,
  //     year3: item.year3,
  //     year4: item.year4,
  //     year5: item.year5,
  //     year6: item.year6,
  //   })),
  // ];

  // console.log("Combined Data", combinedData);

  // // let nextColSeq = 14;

  // const newCombinedData = combinedData.map((item) => {
  //   let colSeq = null;

  //   if (item.columnName === "Beginning Market Value") {
  //     colSeq = 1;
  //   } else if (item.columnName === "Ending Market Value") {
  //     colSeq = 2;
  //   } else if (item.columnName === "Beginning Accrued Income") {
  //     colSeq = 3;
  //   } else if (item.columnName === "Ending Accrued Income") {
  //     colSeq = 4;
  //   } else if (item.columnName === "Beginning Portfolio Value") {
  //     colSeq = 5;
  //   } else if (item.columnName === "Ending Portfolio Value") {
  //     colSeq = 6;
  //   } else if (item.columnName === "Income Earned") {
  //     colSeq = 7;
  //   } else if (item.columnName === "Income Only") {
  //     colSeq = 8;
  //   } else if (item.columnName === "Contributions") {
  //     colSeq = 9;
  //   } else if (item.columnName === "Withdrawals") {
  //     colSeq = 10;
  //   } else if (item.columnName === "Market Appreciation") {
  //     colSeq = 11;
  //   } else if (item.columnName === "Total Return (Gross Fees)") {
  //     colSeq = 12;
  //   } else if (item.columnName === "Capital Only") {
  //     colSeq = 13;
  //   }

  //   if (colSeq === null) {
  //     transformedDataLength++;
  //     colSeq = transformedDataLength;
  //   }

  //   return {
  //     ...item,
  //     colSeq,
  //   };
  // });

  // console.log("New Combined Data", newCombinedData);
  // console.log("Finally Transformed Data Length", transformedDataLength);

  // let newt2t3Data = [...t2Data, ...t3Data]
  // console.log("t2 Data", t2Data)
  // console.log("t3 Data", t3Data)

  // console.log("new combined t2 and t3 data ", newt2t3Data);

  // newCombinedDatat2ANDt3 = {
  //   t1: t1Data,
  //   t2: newCombinedData,
  // };

  newCombinedDatat2ANDt3 = {
    t1: t1Data,
    t2: t2Data,
  };
  // console.log(
  //   " Final newCombinedDatat2ANDt3 ",
  //   JSON.stringify(newCombinedDatat2ANDt3, null, 2)
  // );

  console.log("Type of ", typeof newCombinedDatat2ANDt3);
  console.log("newCombinedDatat2ANDt3 ", newCombinedDatat2ANDt3);
  let gridDtata = "";

  const aggregates = [];

  const [collapsedState, setCollapsedState] = React.useState([]);

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };

  const processWithGroups = (data, dataState) => {
    const groups = dataState.group;

    if (groups) {
      groups.map((group) => (group.aggregates = aggregates));
    }

    dataState.group = groups;
    const newDataState = process(data, dataState);
    setGroupIds({
      data: newDataState.data,
      group: dataState.group,
    });
    return newDataState;
  };

  gridDtata = orderBy(t2Data, sort);

  const createDataState = (dataState) => {
    return {
      result: process(t2Data.slice(0), dataState),
      dataState: dataState,
    };
  };

  let initialState = createDataState({
    take: 1000,
    skip: 0,
    // group: [
    //   {
    //     field: 'groupHeader',
    //   },
    // ],
    group: [{ field: "account" }],
  });
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [result, setResult] = React.useState(
    processWithGroups(t2Data, initialState.dataState)
  );

  const onDataStateChange = React.useCallback((e) => {
    setDataState(e.dataState);

    debugger;

    let updatedState = createDataState(e.dataState);

    // setResultState(processWithGroups(data, updatedState.dataState));
  }, []);

  //setReportData(newCombinedDatat2ANDt3);

  // Convert the combined array to a JSON string
  // const newCombinedDataJson = JSON.stringify(newCombinedData);

  // console.log("newCombinedData String JSON", newCombinedDataJson);

  return (
    <div>
      <div className="col mx-2 mb-1 bg-light border-0 text-end">
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={excelExport}
        >
          Preview
        </button>
      </div>
      <div className="row bg-white g-0">
        <div className="col-12 h-100">
          <div className="card card-rounded w-100">
            <div className="card-body">
              {/* <h6 className="tableheader h6">Annual Portfolio Values Report</h6> */}
              <Grid
                // data={orderBy(t2Data, sort)}
                data={result}
                {...dataState}
                style={{ height: "550px" }}
                sort={sort}
                sortable={true}
                onDataStateChange={onDataStateChange}
                onExpandChange={expandChange}
                expandField="expanded"
              >
                {/* Display the combined columnName/perfTmpltCtgryNm column */}
                <Column field="columnName" title=" " width={"400px"} />
                {/* <Column field="account" title=" " /> */}

                <Column
                  field="year1"
                  title={headerTitles.year1}
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"210px"}
                />
                <Column
                  field="year2"
                  title={headerTitles.year2}
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"210px"}
                />
                <Column
                  field="year3"
                  title={headerTitles.year3}
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"210px"}
                />
                <Column
                  field="year4"
                  title={headerTitles.year4}
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"210px"}
                />
                <Column
                  field="year5"
                  title={headerTitles.year5}
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"210px"}
                />
                <Column
                  field="year6"
                  title={headerTitles.year6}
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"210px"}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AnnualPortfolioValueDetails;
