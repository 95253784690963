import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";
//import { CustomColumnMenu } from './customColumnMenu';
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";
//import data from './data.json';
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import BankLogoPage from "./bankLogoPage";
import { FaFileExcel, FaFilePdf, FaEye } from "react-icons/fa";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import {
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";

import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import Modal from "react-bootstrap/Modal";
import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";

const initialGroup = [
  {
    field: "majorAssetType",
  },
];
const aggregates = [
  {
    field: "totMarket",
    aggregate: "sum",
  },

  {
    field: "income",
    aggregate: "sum",
  },
  {
    field: "txcstAmt",
    aggregate: "sum",
  },

  {
    field: "gainLoss",
    aggregate: "sum",
  },
  {
    field: "marketPercent",
    aggregate: "sum",
  },
  {
    field: "equityPercent",
    aggregate: "sum",
  },
];

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "1px",

          width: "98%",
          borderBottom: "1px solid #bce8f1",
        }}
      >
        <div className="row d-flex mx-3">
          <div className="col text-start">
            <a className="px-2">
              <BankLogoPage />
            </a>
          </div>
          <div className="col text-end px-5 py-2">
            <h2 className="fw-bold text-fitek">Portfolio Holdings</h2>
          </div>
        </div>

        {/* <div className='fw-bold  text-center'>
                  Account Profile</div> */}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          //  borderTop: "1px solid #bce8f1"
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
};
const PortfolioHoldingsGrid = ({
  data,
  flag,
  reportData,
  priceExistFlag,
  runInAggregate,
  displayPercentOption,
}) => {
  let acctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;
  // call active report
  // const [show, setShow] = React.useState(false);

  debugger;
  let filterData = data.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.acctId === value.acctId)
  );

  let principalCashSum = formatNumber(
    filterData.reduce((acc, key) => acc + key.p1CashBlncAmt, 0),
    "##,#.00"
  );
  let incomeCashSum = formatNumber(
    filterData.reduce((acc, key) => acc + key.p2P3CashBlncAmt, 0),
    "##,#.00"
  );
  let tradeCashSum = formatNumber(
    filterData.reduce((acc, key) => acc + key.tradeCash, 0),
    "##,#.00"
  );

  let excludeCashSum = formatNumber(
    filterData.reduce((acc, key) => acc + key.excldCashAmt, 0),
    "##,#.00"
  );

  // console.log("PrincipalCash", principalCashSum)
  // let priceExistFlag = data.priceValidation;
  // console.log("priceExistFlag", priceExistFlag)

  const viewerRef = React.useRef();
  async function loadReport() {
    // load report definition from the file

    const reportResponse = await fetch("PortfolioHolding.rdlx-json");

    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    const report = await loadReport();

    for (let i = 0; i < reportData.ocPortFolioHoldingsMainOutPut.length; i++) {
      reportData.ocPortFolioHoldingsMainOutPut[i].units = Number(
        formatNumber(
          reportData.ocPortFolioHoldingsMainOutPut[i].units,
          "#,##.00"
        ).replace(/,/g, "")
      );
    }

    const parameters = [
      // <p>Administrator : {localStorage.getItem("Administrator")}</p>
      // </div>
      // <div className="text-end fw-bold col-md-3">
      // <p>AccountType : {localStorage.getItem("acctType")}</p>
      {
        Name: "pCnsldtnNm",
        Value: [
          JSON.parse(localStorage.getItem("RelationID")) != null
            ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm === ""
              ? 0
              : JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm
            : 0,
        ],
      },
      {
        Name: "pExtrnlAcctId",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 0
            : JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId,
        ],
      },
      {
        Name: "pShow",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 3
            : 2,
        ],
      },
      {
        Name: "principalCashSum",
        Value: [principalCashSum],
      },
      {
        Name: "incomeCashSum",
        Value: [incomeCashSum],
      },
      {
        Name: "tradeCashSum",
        Value: [tradeCashSum],
      },
      {
        Name: "excludeCashSum",
        Value: [excludeCashSum],
      },
      {
        Name: "prunInAggregate",
        Value: [runInAggregate == true? 1:0],
      },
      //{Name: 'pE', Value: [ChkBoxState]}
    ];

    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(reportData);

    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  //
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const gridRef = useRef();
  const _export = React.useRef(null);
  const excelExport = () => {
    setShow(true);
    openReport();
    // if (_export.current !== null) {
    //   _export.current.save(data);
    // }
  };
  const [locked, setLocked] = React.useState(false);
  let allPDFExport;
  const columnLocked = () => {
    setLocked(!locked);
  };
  const totalSum = (props) => {
    debugger;
    const field = props.field || "";
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };

  // const totalSum = (props) => {
  //   debugger;
  //   const field = props.field || "";
  //   const total = data
  //     .reduce((acc, current) => {
  //       const value = parseFloat(current[field]) || 0; // Ensure numeric values
  //       return acc + value;
  //     }, 0) // Initial accumulator is 0
  //     .toFixed(2); // Round the result to 2 decimal places

  //   return (
  //     <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
  //       {formatNumber(total, "##,#.00")}
  //     </td>
  //   );
  // };

  var marketSum = 0;

  // const totalMarket = data.map((x) => {
  //   x.totMarket;
  // });

  const totalSumYield = (props) => {
    var totalIncome = 0,
      totalMarket = 0,
      totYield = 0;
    for (var i = 0; i < data.length; i++) {
      totalIncome = totalIncome + data[i].income;
      totalMarket = totalMarket + data[i].totMarket;
    }
    if (totalMarket == 0) {
      totYield = 0;
    } else {
      totYield = (totalIncome * 100) / totalMarket;
    }

    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(totYield, "##,#.00")}
      </td>
    );
  };

  //let loadedColumns = localStorage.getItem('gridColumns');
  //const columns =
  const menuWithExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const menuWithoutExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  console.log("acctId", acctId);
  let defaultColumns = [
    // {
    //   title: 'Inv. Objective',
    //   field: 'invstmntObj',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Acct Type',
    //   field: 'accountType',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Account#',
    //   field: 'account',
    //   minWidth: 150,
    //   show: acctId == 0 ? true : false,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Major Asset',
    //   field: 'majorAssetType',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Cusip/Ticker",
      field: "tckerCusip",
      minWidth: "150",
      show: true,
      filter: "text",
      locked: true,
    },
    {
      title: "Description",
      field: "assetShrtNm",
      // minWidth: 180,
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: true,
    },
    {
      title: "Unit",
      field: "units",
      // minWidth: 130,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Cost($)",
      field: "txcstAmt",
      // minWidth: 130,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Price($)",
      field: "price",
      // minWidth: 130,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Market($)",
      field: "totMarket",
      // minWidth: 130,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      //footerCell: totalSum,
      headerClassName: "rightHeader",
    },
    {
      title: "Gain/Loss($)",
      field: "gainLoss",
      // minWidth: 130,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Income($)",
      field: "income",
      // minWidth: 130,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Yield(%)",
      field: "yield",
      // minWidth: 130,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "TMV(%)",
      field: "marketPercent",
      // minWidth: 130,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },

    // {
    //   title: 'Principal($)',
    //   field: 'p1CashBlncAmt',
    //   minWidth: 130,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader',
    // },
    // {
    //   title: 'Inv. Income($)',
    //   field: 'p2P3CashBlncAmt',
    //   minWidth: 130,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader',
    // },
    // {
    //   title: 'UnEx. Cash($)',
    //   field: 'unExecCashAmt',
    //   minWidth: 130,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader',
    // },
    // {
    //   title: 'Trade Cash($)',
    //   field: 'tradeCash',
    //   minWidth: 130,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader',
    // },
    // {
    //   title: 'Excld Cash($)',
    //   field: 'excldCashAmt',
    //   minWidth: 130,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader',
    // },
    {
      title: "Asset Class(%)",
      field: "equityPercent",
      // minWidth: 130,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
  ];

  //lelocalStorage.setItem('gridColumns', JSON.stringify(defaultColumns));
  let loadedColumns = localStorage.getItem("gridColumns");
  const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumns; //? JSON.parse(loadedColumns) : defaultColumns;
  //GridColumns[8].footerCell= totalSum;
  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    group: [
      { field: "account" },
      { field: "invstmntObj" },
      { field: "majorAssetType" },
    ],
  });
  let initialStateExport = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    group: [
      { field: "account" },
      { field: "invstmntObj" },
      { field: "majorAssetType" },
    ],
  });
  console.log("defaultColumns", defaultColumns);

  // defaultColumns[3].footerCell = totalSum;
  // defaultColumns[5].footerCell = totalSum;
  // defaultColumns[6].footerCell = totalSum;
  // defaultColumns[7].footerCell = totalSum;

  // defaultColumns[8].footerCell = totalSumYield;
  if (priceExistFlag == "" || null) {
    for (let i = 0; i < defaultColumns.length; i++) {
      if (defaultColumns[i].field == "txcstAmt") {
        defaultColumns[i].footerCell = totalSum;
      } else if (defaultColumns[i].field == "totMarket") {
        defaultColumns[i].footerCell = totalSum;
      } else if (defaultColumns[i].field == "gainLoss") {
        defaultColumns[i].footerCell = totalSum;
      } else if (defaultColumns[i].field == "income") {
        defaultColumns[i].footerCell = totalSum;
      } else if (defaultColumns[i].field == "yield") {
        defaultColumns[i].footerCell = totalSumYield;
      } else if (
        defaultColumns[i].field == "marketPercent" &&
        (acctId != 0 || (acctId == 0 && runInAggregate == true)) &&
        displayPercentOption != 3
      ) {
        defaultColumns[i].footerCell = totalSum;
      }
    }
  }

  // defaultColumns[1].columnMenu= menuWithExcelCheck;
  // defaultColumns[0].columnMenu= menuWithExcelCheck;
  // defaultColumns[2].columnMenu= menuWithoutExcelCheck;
  // defaultColumns[3].columnMenu= menuWithoutExcelCheck;
  // defaultColumns[4].columnMenu= menuWithoutExcelCheck;
  // defaultColumns[5].columnMenu= menuWithoutExcelCheck;
  // defaultColumns[6].columnMenu= menuWithoutExcelCheck;
  // defaultColumns[7].columnMenu= menuWithoutExcelCheck;
  // defaultColumns[8].columnMenu= menuWithoutExcelCheck;
  // //defaultColumns[9].columnMenu= menuWithoutExcelCheck;
  // defaultColumns[9].columnMenu= menuWithoutExcelCheck;
  //const [result, setResult] = useState(initialState.result);
  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );
  const [resultExport, setResultExport] = React.useState(
    processWithGroups(data, initialStateExport.dataState)
  );
  const [dataState, setDataState] = useState(initialState.dataState);
  const [stateColumns, setStateColumns] = useState(GridColumns);
  //const [resultExport, setResultExport] = useState(initialStateExport.result);
  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
    });

    setResult(updatedState.result);
    setResultExport(updatedStateExcel.result);
    setDataState(updatedState.dataState);
  };

  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    return newColumnsState;
  };
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };
  const showpreview = async () => {
    //setDataState({ ...dataState,  skip: 0,take: 1000 });
    //setResult(data);
    setShow(true);
  };

  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };
  const saveColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 21; //let 1 for Portfoliholdings Grid
    let ColumnSettings = localStorage.getItem("gridColumns");
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTSaveGridColumn/Index", postData, config)
      .then((response) => {
        console.log(response);
        const rowData = response.data;
        //populatePortfolioHoldingRptData(rowData.ocPortFolioHoldingsMainOutPut);
        //populatePortfolioHoldingRptDatatab2(rowData.ocPortFolioHoldingsTradeTypeOutPut);
        //setflagPortfolio(true);
        //setLoading(false);
      })
      .catch((error) => {
        return error;
      });
  };

  const saveColumnsState = (columns) => {
    console.log("save called!!");
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);
    localStorage.setItem("gridColumns", currentColumnsState);

    saveColumnStateDb();
  };

  useEffect(() => {
    saveColumnsState(stateColumns);
  }, [stateColumns]);

  const [mnrRadioStat, setMnrRadioStat] = useState("checked");
  const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
  const labelContent = (e) => `${e.value.toFixed(2)}`;

  const handleClick = () => {
    setLocked(!locked);
  };

  const _grid = React.useRef();

  const [gridChartCheck, setgridChartCheck] = useState("checked");

  const [collapsedState, setCollapsedState] = React.useState([]);

  const chartDefaultV4Colors = [
    "#0275d8",
    "#5bc0de",
    "#5cb85c",
    "#f0ad4e",
    "#e67d4a",
    "#d9534f",
  ];

  const handleSetDataChecked = () => {
    setgridChartCheck("checked");
  };
  const handleSetChartChecked = () => {
    setgridChartCheck("");
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        saveColumnStateDb();
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });
  };
  const RightNameHeader = (props) => {
    return (
      <a
        className="k-link"
        style={{
          float: "right",
        }}
        onClick={props.onClick}
      >
        {/* <span className="k-icon k-i-cart" /> */}
        <span
          style={
            {
              // color: "#53d2fa",
            }
          }
        >
          {props.title}
        </span>
        {props.children}
      </a>
    );
  };
  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate && cellProps.dataItem.field != "invstmntObj") {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else if (
        column.field === "marketPercent" &&
        cellProps.dataItem.field != "account" &&
        (acctId != 0 || (acctId == 0 && runInAggregate == true)) &&
        displayPercentOption != 3 &&
        cellProps.dataItem.field != "invstmntObj"
      ) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field]["sum"],
              "##,#.00"
            )}
          </td>
        );
      } else if (
        (displayPercentOption != 3 &&
          column.field === "marketPercent" &&
          cellProps.dataItem.field == "account" &&
          cellProps.dataItem.field != "invstmntObj") ||
        (displayPercentOption == 3 &&
          column.field === "marketPercent" &&
          cellProps.dataItem.field != "account" &&
          cellProps.dataItem.field != "invstmntObj" &&
          cellProps.dataItem.field == "majorAssetType")
      ) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field]["sum"],
              "##,#.00"
            )}
          </td>
        );
      } else if (
        column.field === "equityPercent" &&
        cellProps.dataItem.field != "account" &&
        cellProps.dataItem.field != "invstmntObj"
      ) {
        debugger;
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field]["sum"],
              "##,#.00"
            )}
          </td>
        );
      } else {
        if (
          column.field === "yield" &&
          cellProps.dataItem.field != "invstmntObj"
        ) {
          const totalIncome = cellProps.dataItem.aggregates.income.sum || 0;
          const totalMarket = cellProps.dataItem.aggregates.totMarket.sum || 0;
          const totalYield =
            totalMarket === 0 ? 0 : (totalIncome * 100) / totalMarket;

          cells.push(
            <td style={{ textAlign: "right" }}>
              {formatNumber(totalYield, "##,#.00")}
            </td>
          );
        } else cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };
  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      //IMPORTANT - You need to add collection with the columns and their field name
      //you can define the Grid columns outside of the Grid and reuse them here.
      const columns = [
        // { field: 'invstmntObj' },
        // { field: 'accountType' },
        // { field: 'account'},
        // { field: "majorAssetType" },
        { field: "tckerCusip" },
        { field: "assetShrtNm" },
        { field: "units" },
        { field: "txcstAmt", aggregate: "sum" },
        { field: "price" },
        { field: "totMarket", aggregate: "sum" },
        { field: "gainLoss", aggregate: "sum" },
        { field: "income", aggregate: "sum" },
        { field: "yield" },
        { field: "marketPercent" },
        { field: "equityPercent" },

        //{ field: 'p1CashBlncAmt' },
        //{ field: 'p2P3CashBlncAmt' },
        // { field: "unExecCashAmt" },
        //{ field: 'tradeCash'},
        //{ field: 'excldCashAmt'},
      ];

      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - columns.length}
          ></td>
          {getCells(columns, cellProps)}
        </>
      );
    }

    // debugger;

    // if (
    //   cellProps.rowType === "groupFooter" &&
    //   cellProps.dataItem.field === "majorAssetType"
    // ) {
    //   var grpYield = 0;
    //   debugger;

    //   if (cellProps.field === "equityPercent") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.equityPercent.sum,
    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "income") {
    //     localStorage.setItem(
    //       "grpTotInc",
    //       cellProps.dataItem.aggregates.income.sum
    //     );
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(cellProps.dataItem.aggregates.income.sum, "##,#.00")}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "totMarket") {
    //     localStorage.setItem(
    //       "grpTotMrkt",
    //       cellProps.dataItem.aggregates.totMarket.sum
    //     );
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.totMarket.sum,
    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "gainLoss") {
    //     localStorage.setItem(
    //       "grpTotMrkt",
    //       cellProps.dataItem.aggregates.totMarket.sum
    //     );
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.gainLoss.sum,
    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "txcstAmt") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.txcstAmt.sum,
    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "yield") {
    //     debugger;
    //     if (JSON.parse(localStorage.getItem("grpTotMrkt")) != 0) {
    //       //grpYield=(grpTotInc*100)/grpTotMrkt;
    //       grpYield =
    //         (JSON.parse(localStorage.getItem("grpTotInc")) * 100) /
    //         JSON.parse(localStorage.getItem("grpTotMrkt"));

    //       // console.log("income", JSON.parse(localStorage.getItem("grpTotInc")));
    //       // console.log("Market", JSON.parse(localStorage.getItem("grpTotMrkt")));
    //     }
    //     localStorage.setItem("grpTotInc", 0);
    //     localStorage.setItem("grpTotMrkt", 0);

    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(grpYield, "##,#.00")}
    //       </td>
    //     );
    //     debugger;
    //   }
    // }

    if (cellProps.rowType === "data") {
      if (cellProps.field === "totMarket") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "units") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "txcstAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "price") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "gainLoss") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "income") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "yield") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "marketPercent") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "p1CashBlncAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "p2P3CashBlncAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "unExecCashAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "tradeCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "excldCashAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "equityPercent") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }
    return tdElement;
  };

  const NumberCell = (props) => {
    if (props.field === "invstmntObj") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "accountType") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "account") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "tckerCusip") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "assetShrtNm") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "units") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "txcstAmt") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "price") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "totMarket") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "gainLoss") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "income") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "yield") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "p1CashBlncAmt") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "p2P3CashBlncAmt") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "unExecCashAmt") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "tradeCash") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "excldCashAmt") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "equityPercent") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
  };

  const labelContent1 = (e) => `${e.value.toFixed(2)}`;
  const FormatLongNumber = ({ value }) => {
    if (value == 0) {
      return 0;
    } else {
      // hundreds
      if (value <= 999) {
        return value;
      }
      // thousands
      else if (value >= 1000 && value <= 999999) {
        return (value / 1000).toFixed(2) + "K";
      }
      // millions
      else if (value >= 1000000 && value <= 999999999) {
        return (value / 1000000).toFixed(2) + "M";
      }
      // billions
      else if (value >= 1000000000 && value <= 999999999999) {
        return (value / 1000000000).toFixed(2) + "B";
      } else return value.toFixed(2);
    }
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  return (
    <div>
      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {
        <div className="my-1 py-1 mx-1 px-1 row d-flex justify-content-between align-items-center my-2">
          {/* <div className="col">
            <p className="reportheading fs-6 h6">Portfolio Holdings Report</p>
          </div> */}
          <div className="col"></div>
          <div className="col text-end">
            {/* <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
                                    <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem> */}
            {/* <DropdownItem onClick={showpreview}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem> */}

            {/* </DropdownButton>


                            </div> */}

            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={excelExport}
            >
              <FaEye /> Preview
            </button>
          </div>

          {/* <div className='col'>
            <button
              className='btn btn-outline-primary btn-sm'
              onClick={excelExport}>Export to Excel</button>
               &nbsp;
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={showpreview}
          >
          Preview
          </button>
          </div> */}
        </div>
      }

      <div className="card-body">
        <div className="row d-flex justify-content-between align-items-center bg-light shadow-sm rounded">
          {/* <div className='subheader text-end col-md-2'><p>Processing Date : {localStorage.getItem("processingDate")}</p></div> */}
          {/* <div className="subheader text-start ">
            <>
              Investment Objective :{" "}
              {data[0] === undefined ? "" : data[0].invstmntObj}
            </>
          </div> */}
          <div className="subheader text-end">
            Due to rounding, percentage may not equal 100.
          </div>
        </div>

        <div className="mx-1 my-1 py-1">
          <ExcelExport
            data={data}
            ref={_export}
            fileName={
              JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
              "_PortfolioHoldingsReport.xlsx"
            }
          >
            <ExcelExportColumnGroup
              title={
                "Account Number: " +
                JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
                "  Processing Date: " +
                localStorage.getItem("processingDate")
              }
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              <ExcelExportColumn
                field="majorAssetType"
                title="Major Asset"
                width={130}
              />
              <ExcelExportColumn
                field="tckerCusip"
                title="Cusip/Ticker"
                width={130}
              />
              <ExcelExportColumn
                field="assetShrtNm"
                title="Description"
                width={270}
              />
              <ExcelExportColumn
                field="units"
                title="Unit"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                width={100}
              />
              <ExcelExportColumn
                field="txcstAmt"
                title="Cost($)"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                width={130}
              />
              <ExcelExportColumn
                field="price"
                width={130}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Price($)"
              />
              <ExcelExportColumn
                field="totMarket"
                width={130}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Market($)"
              />
              <ExcelExportColumn
                field="gainLoss"
                width={130}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="GainLoss($)"
              />
              <ExcelExportColumn
                field="income"
                width={130}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="income($)"
              />
              <ExcelExportColumn
                field="yield"
                width={120}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Yield(%)"
              />
              {/* <ExcelExportColumn field="unExecCashAmt" width={130} headerCellOptions={{ textAlign: "right", }}    cellOptions={{   format: "#,##0.00", }}  title="UnEx. Cash($)"   /> */}
              <ExcelExportColumn
                field="equityPercent"
                width={130}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Asset(%)"
              />
            </ExcelExportColumnGroup>
          </ExcelExport>
          <Grid
            style={{ height: "600px" }}
            data={result}
            {...dataState}
            onDataStateChange={dataStateChange}
            //onColumnReorder={onColumnReorder}
            // onColumnResize={onColumnResize}
            expandField="expanded"
            onExpandChange={expandChange}
            cellRender={cellRender}
            sortable={true}
            //  resizable={true}
            //  pageable={true}
            //  reorderable={true}
            pageable={{
              buttonCount: 0,
              info: true,
              previousNext: false,
            }}
            pageSize={Number.MAX_VALUE}
            groupable={{
              footer: "none",
              enabled: false,
            }}
          >
            {console.log("Price Flag ", priceExistFlag)}

            {priceExistFlag != "" ? (
              <GridNoRecords>
                <div style={{ color: "red", textAlign: "left" }}>
                  {priceExistFlag}
                  {console.log("No Price Exist for the date")}
                </div>
              </GridNoRecords>
            ) : (
              <>{console.log("Price Exist For the date")}</>
            )}
            {stateColumns.map(
              (column, idx) =>
                column.show && (
                  <Column
                    width={setWidth(column.minWidth)}
                    //  locked={column.locked}
                    key={idx}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    //footerCell={column.footerCell}
                    headerClassName={column.headerClassName}
                    {...column}
                    //columnMenu={column.columnMenu}
                    //cell={NumberCell}
                    // columnMenu={(props) => (
                    //   <CustomColumnMenuNoGrp
                    //     {...props}
                    //     columns={stateColumns}
                    //     onColumnsSubmit={onColumnsSubmit}
                    //   />
                    // )}
                  />
                )
            )}
          </Grid>
        </div>
        <div className="row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
          <div className="subheader text-end col-md-2">
            <p>
              Principal Cash :{" "}
              {/* {formatNumber(
                data[0] === undefined ? 0 : data[0].p1CashBlncAmt,
                "##,#.00"
              )} */}
              {formatNumber(principalCashSum, "##,#.00")}
            </p>
          </div>
          <div className="subheader text-end col-md-3">
            <p>
              Income Cash :{" "}
              {/* {formatNumber(
                data[0] === undefined ? 0 : data[0].p2P3CashBlncAmt,
                "##,#.00"
              )} */}
              {formatNumber(incomeCashSum, "##,#.00")}
            </p>
          </div>
          <div className="subheader text-end col-md-3">
            <p>
              Trade Cash :{" "}
              {/* {formatNumber(
                data[0] === undefined ? 0 : data[0].tradeCash,
                "##,#.00"
              )} */}
              {formatNumber(tradeCashSum, "##,#.00")}
            </p>
          </div>
          <div className="subheader text-end col-md-3">
            <p>
              Exclude Cash :{" "}
              {/* {formatNumber(
                data[0] === undefined ? 0 : data[0].excldCashAmt,
                "##,#.00"
              )} */}
              {formatNumber(excludeCashSum, "##,#.00")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioHoldingsGrid;
