import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";
import { CustomColumnMenu } from "./customColumnMenu";
import { groupBy } from "@progress/kendo-data-query";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";
//import data from './data.json';
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import {
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";

import BankLogoPage from "./bankLogoPage";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import Modal from "react-bootstrap/Modal";
import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";
const initialGroup = [
  {
    field: "mjrAstNm",
  },
  {
    field: "mnrAstNm",
  },
];
const aggregates = [
  {
    field: "endMkt",
    aggregate: "sum",
  },
  {
    field: "yield",
    aggregate: "sum",
  },
  {
    field: "endMktPct",
    aggregate: "sum",
  },
  {
    field: "changedMktVal",
    aggregate: "sum",
  },
  {
    field: "percentageReturn",
    aggregate: "sum",
  },
  {
    field: "annualRtrn",
    aggregate: "sum",
  },
  {
    field: "totalreturn",
    aggregate: "sum",
  },
  {
    field: "percentageContribution",
    aggregate: "sum",
  },
  {
    field: "totAnnualizedReturn",
    aggregate: "sum",
  },
];
const processWithGroups = (data, dataState) => {
  //
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};
const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "1px",

          width: "98%",
          borderBottom: "1px solid #bce8f1",
        }}
      >
        <div className="row d-flex mx-3">
          <div className="col text-start">
            <a className="px-2">
              <BankLogoPage />
            </a>
          </div>
          <div className="col text-end px-5 py-2">
            <h2 className="fw-bold text-fitek">
              Portfolio Performance By Security Report
            </h2>
          </div>
        </div>

        {/* <div className='fw-bold  text-center'>
                    Account Profile</div> */}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          //  borderTop: "1px solid #bce8f1"
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
};

const PortPerfSecurityGrid = ({ data, flag, accntSelected, priceExistFlag }) => {
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const _export = React.useRef(null);
  const excelExport = () => {
    setShow(true);
    openReport();
    // if (_export.current !== null) {
    //   _export.current.save(data);
    // }
  };
  // call active report
  //const [show, setShow] = React.useState(false);

  const viewerRef = React.useRef();
  async function loadReport() {
    // load report definition from the file

    const reportResponse = await fetch(
      "PortfolioPerformanceBySecurity.rdlx-json"
    );

    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    const report = await loadReport();

    const parameters = [
      {
        Name: "pCnsldtnNm",
        Value: [
          JSON.parse(localStorage.getItem("RelationID")) != null
            ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm === ""
              ? 0
              : JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm
            : 0,
        ],
      },
      {
        Name: "pExtrnlAcctId",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 0
            : JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId,
        ],
      },
      {
        Name: "pShow",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 3
            : 2,
        ],
      },
      //{Name: 'pE', Value: [ChkBoxState]}
    ];

    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(data);

    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  //
  //const [locked, setLocked] = React.useState(false);
  let allPDFExport;
  // const columnLocked = () => {
  //   setLocked(!locked);
  // };
  const totalSum = (props) => {
    const field = props.field || "";
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };
  const totalSumPercentageRet = (props) => {
    const field = props.field || "";
    var totChngMrktVal = 0;
    var totEndingMrktVal = 0;
    for (var i = 0; i < data.length; i++) {
      totChngMrktVal = totChngMrktVal + data[i].changedMktVal;

      totEndingMrktVal = totEndingMrktVal + data[i].endMkt;
    }
    const total = (totChngMrktVal / totEndingMrktVal) * 100;
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };
  const totalSumAnnPercentageRet = (props) => {
    const field = props.field || "";

    var totdvdndyield = 0;
    var totEndingMrktVal = 0;
    for (var i = 0; i < data.length; i++) {
      totdvdndyield = totdvdndyield + data[i].yield;

      totEndingMrktVal = totEndingMrktVal + data[i].endMkt;
    }
    const total = (totdvdndyield / totEndingMrktVal) * 100;
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };
  const totalSumTotalRet = (props) => {
    const field = props.field || "";
    var totoftotalreturn = 0;
    var totEndingMrktVal = 0;
    for (var i = 0; i < data.length; i++) {
      totoftotalreturn = totoftotalreturn + data[i].totalreturn;

      totEndingMrktVal = totEndingMrktVal + data[i].endMkt;
    }
    const total = (totoftotalreturn / totEndingMrktVal) * 100;
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };
  const menuWithExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const menuWithoutExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });

    newColumnsState[3].footerCell = totalSum;
    newColumnsState[4].footerCell = totalSumPercentageRet;
    //GridColumns[5].footerCell= totalSum;
    newColumnsState[6].footerCell = totalSumAnnPercentageRet;
    newColumnsState[8].footerCell = totalSumTotalRet;
    newColumnsState[9].footerCell = totalSum;
    newColumnsState[2].footerCell = totalSum;
    newColumnsState[7].footerCell = totalSum;
    newColumnsState[1].footerCell = totalSum;

    // newColumnsState[5].footerCell=totalSumPercentageRet ;
    // newColumnsState[3].footerCell= totalSum;
    // newColumnsState[4].footerCell= totalSum;

    // newColumnsState[6].footerCell= totalSumAnnPercentageRet;
    // newColumnsState[8].footerCell= totalSumTotalRet;
    // newColumnsState[9].footerCell= totalSum;
    // newColumnsState[2].footerCell= totalSum;
    // newColumnsState[7].footerCell= totalSum;
    // newColumnsState[0].columnMenu= menuWithExcelCheck;
    // newColumnsState[1].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[2].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[3].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[4].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[5].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[6].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[7].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[8].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[9].columnMenu= menuWithoutExcelCheck;

    return newColumnsState;
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  let defaultColumns = [
    // {
    //   title: 'Securities',
    //   field: 'mjrAstNm',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Minor Asset',
    //   field: 'mnrAstNm',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Asset",
      field: "astShrtNm",
      minWidth: 250,
      show: true,
      filter: "text",
      locked: true,
    },

    {
      title: "Ending Market Value",
      field: "endMkt",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      //footerCell: totalSum,
      headerClassName: "rightHeader",
    },
    {
      title: "Percentage Portfolio",
      field: "endMktPct",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      //footerCell: totalSum,
      headerClassName: "rightHeader",
    },
    {
      title: "Change In Market Value",
      field: "changedMktVal",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      //footerCell: totalSum,
      headerClassName: "rightHeader",
    },
    {
      title: "Percentage Return",
      field: "percentageReturn",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      //footerCell: totalSum,
      headerClassName: "rightHeader",
    },
    {
      title: "Dividend Or Yield",
      field: "yield",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Annualized % Return",
      field: "annualRtrn",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      //footerCell: totalSum,
      headerClassName: "rightHeader",
    },
    {
      title: "Total Return",
      field: "totalreturn",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      //footerCell: totalSum,
      headerClassName: "rightHeader",
    },

    {
      title: "Total Annualized % Return",
      field: "totAnnualizedReturn",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      //footerCell: totalSum,
      headerClassName: "rightHeader",
    },
    {
      title: "Percentage Contribution",
      field: "percentageContribution",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      //footerCell: totalSum,
      headerClassName: "rightHeader",
    },
  ];

  let loadedColumns = localStorage.getItem("gridColumns");
  // const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumns;
  const GridColumns = defaultColumns;
  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };

  let initialStateExport = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    group: [
      { field: "extrnlAcctId" },
      { field: "mjrAstNm" },
      { field: "mnrAstNm" },
    ],
    //group: [{field: 'mjrAstNm'}]
  });
  let initialState = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    group: [
      { field: "extrnlAcctId" },
      { field: "mjrAstNm" },
      { field: "mnrAstNm" },
    ],
    //group: [{field: 'mjrAstNm'}]
  });
  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );
  const [resultExport, setResultExport] = React.useState(
    processWithGroups(data, initialStateExport.dataState)
  );

  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [stateColumns, setStateColumns] = React.useState(GridColumns);
  const [currentColumns, setCurrentColumns] = React.useState(defaultColumns);
  // GridColumns[3].footerCell = totalSum;
  // GridColumns[4].footerCell = totalSumPercentageRet;
  // //GridColumns[5].footerCell= totalSum;
  // GridColumns[6].footerCell = totalSumAnnPercentageRet;
  // GridColumns[8].footerCell = totalSumTotalRet;
  // GridColumns[9].footerCell = totalSum;
  // GridColumns[2].footerCell = totalSum;
  // GridColumns[7].footerCell = totalSum;
  // GridColumns[1].footerCell = totalSum;

  // GridColumns[0].columnMenu= menuWithExcelCheck;

  // GridColumns[1].columnMenu= menuWithoutExcelCheck;//d\y
  // GridColumns[2].columnMenu= menuWithoutExcelCheck;
  // GridColumns[3].columnMenu= menuWithoutExcelCheck;
  // GridColumns[4].columnMenu= menuWithoutExcelCheck;
  // GridColumns[5].columnMenu= menuWithoutExcelCheck;
  // GridColumns[6].columnMenu= menuWithoutExcelCheck;
  // GridColumns[7].columnMenu= menuWithoutExcelCheck;
  // GridColumns[8].columnMenu= menuWithoutExcelCheck;
  // GridColumns[9].columnMenu= menuWithoutExcelCheck;

  //GridColumns[10].footerCell= totalSum;

  const saveColumnStateDb = async () => {
    //Storing column settings in DB

    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 28; //let 1 for Portfoliholdings Grid
    let ColumnSettings = localStorage.getItem("gridColumns");
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTSaveGridColumn/Index", postData, config)
      .then((response) => {
        console.log(response);
        const rowData = response.data;
        //populatePortfolioHoldingRptData(rowData.ocPortFolioHoldingsMainOutPut);
        //populatePortfolioHoldingRptDatatab2(rowData.ocPortFolioHoldingsTradeTypeOutPut);
        //setflagPortfolio(true);
        //setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    tempToken = token;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        saveColumnStateDb();
      })
      .catch((error) => {
        //

        console.log("my error is " + error);
      });
  };
  const saveColumnsState = (columns) => {
    console.log("save called!!");
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);

    localStorage.setItem("gridColumns", currentColumnsState);

    saveColumnStateDb();
  };

  useEffect(() => {
    saveColumnsState(stateColumns);
  }, [stateColumns]);

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
    });
    setResult(processWithGroups(data, updatedState.dataState));
    setResultExport(processWithGroups(data, updatedStateExcel.dataState));
    setDataState(updatedState.dataState);
  };

  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };
  const showpreview = async () => {
    //setDataState({ ...dataState,  skip: 0,take: 1000 });
    //setResult(data);
    setShow(true);
  };

  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };
  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };

  const cellRender = (tdElement, cellProps) => {
    // if (
    //   cellProps.rowType === 'groupHeader' &&
    //   tdElement &&
    //   tdElement.props.role != 'presentation'
    // ) {

    //   //const columns = columns;
    //   const columns = [
    //     { field: 'mjrAstNm'},
    //     { field: 'mnrAstNm'},
    //     { field: 'astShrtNm'},
    //     { field: 'yield'},
    //     { field: 'endMktVal', aggregate: 'sum' },
    //     { field: 'endMktPct', aggregate: 'sum' },
    //     { field: 'changedMktVal', aggregate: 'sum' },

    //     { field: 'percentageReturn', aggregate: 'sum' },

    //     { field: 'annualRtrn', aggregate: 'sum'  },
    //     { field: 'totalreturn', aggregate: 'sum'  },
    //     { field: 'percentageContribution', aggregate: 'sum'  },

    //   ];
    //   return (
    //     <>
    //       <td
    //         {...tdElement.props}
    //         colSpan={tdElement.props.colSpan - columns.length}
    //       ></td>
    //       {getCells(columns, cellProps)}
    //     </>
    //   );
    // }
    // if (cellProps.rowType === "groupFooter") {
    //   if (cellProps.field === "endMkt") {
    //     localStorage.setItem(
    //       "totEndingMrktVal",
    //       cellProps.dataItem.aggregates.endMkt.sum
    //     );
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(cellProps.dataItem.aggregates.endMkt.sum, "##,#.00")}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "endMktPct") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.endMktPct.sum,
    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "changedMktVal") {
    //     localStorage.setItem(
    //       "totchngMrktVal",
    //       cellProps.dataItem.aggregates.changedMktVal.sum
    //     );
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.changedMktVal.sum,
    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "yield") {
    //     localStorage.setItem(
    //       "totyield",
    //       cellProps.dataItem.aggregates.yield.sum
    //     );
    //   }
    //   if (cellProps.field === "percentageReturn") {
    //     // if(JSON.parse(localStorage.getItem("totchngMrktVal"))<0){
    //     //   return (
    //     //     <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={'gridcell'}>
    //     //       {formatNumber((JSON.parse(localStorage.getItem("totchngMrktVal"))/JSON.parse(localStorage.getItem("totEndingMrktVal")))*100,"##,#.00")}
    //     //     </td>
    //     //   );
    //     // }
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.percentageReturn.sum,
    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "annualRtrn") {
    //     var total =isNaN((JSON.parse(localStorage.getItem("totyield")) / JSON.parse(localStorage.getItem("totEndingMrktVal"))) *100) ? 0 :((JSON.parse(localStorage.getItem("totyield")) / JSON.parse(localStorage.getItem("totEndingMrktVal"))) *100) ;
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(total, "##,#.00")}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "totalreturn") {
    //     localStorage.setItem(
    //       "totTotalreturn",
    //       cellProps.dataItem.aggregates.totalreturn.sum
    //     );
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.totalreturn.sum,
    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "percentageContribution") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.percentageContribution.sum,
    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }

    //   if (cellProps.field === "totAnnualizedReturn") {
    //     debugger;
    //     var total = isNaN(
    //       (JSON.parse(localStorage.getItem("totTotalreturn")) /
    //         JSON.parse(localStorage.getItem("totEndingMrktVal"))) *
    //         100
    //     )
    //       ? 0
    //       : (JSON.parse(localStorage.getItem("totTotalreturn")) /
    //           JSON.parse(localStorage.getItem("totEndingMrktVal"))) *
    //         100;

    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(total, "##,#.00")}
    //       </td>
    //     );
    //   }
    // }
    if (cellProps.rowType === "data") {
      if (cellProps.field === "endMkt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "endMktPct") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "changedMktVal") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "percentageReturn") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "yield") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "annualRtrn") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "totalreturn") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "percentageContribution") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "totAnnualizedReturn") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }
    return tdElement;
  };
  let _pdfExport;
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const exportPDF = () => {
    _pdfExport.save();
    setShow(false);
    const handleClose = () => setShow(false);
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {
        <div className="mx-1 px-1 row d-flex justify-content-between align-items-center my-2 pt-1">
          {/* <div className='col'>
                <p className='reportheading fs-6 h6'>Portfolio Performance By Security Report</p>
              </div> */}
          {/* <div className='reportheading fs-6 col-md-3 col-lg-3 col-sm-10'>Portfolio Performance By Security Report</div> */}
          <div className="subheader col-md text-end">
            Due to rounding, percentage may not equal 100.
          </div>
          {/* <div className="col text-end">
                                <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
                                    <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem> */}
          {/* <DropdownItem onClick={exportPDF}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem> */}

          {/* </DropdownButton>


                            </div> */}
          <div className="col text-end">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={excelExport}
            >
              Preview
            </button>
          </div>

          {/* <div className='col'>
                <button
                  className='btn btn-outline-primary btn-sm'
                  onClick={excelExport}>Export to Excel</button>
                   &nbsp;
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={exportPDF}
                >
                  Export to PDF
                </button>
              </div> */}
        </div>
      }
      <div className="card-body">
        <div className="mx-1 my-1 py-1">
          <ExcelExport
            data={data}
            ref={_export}
            fileName={
              accntSelected.extrnlAcctId + "_PortPerfBySecurityReport.xlsx"
            }
          >
            <ExcelExportColumnGroup
              title={
                "Account Number: " +
                JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
                "  Processing Date: " +
                localStorage.getItem("processingDate")
              }
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              <ExcelExportColumn field="astShrtNm" title="Asset" width={250} />
              <ExcelExportColumn
                field="yield"
                title="Dividend Or Yield"
                width={140}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
              />
              <ExcelExportColumn
                field="endMkt"
                title="Ending Market Value"
                width={100}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
              />
              <ExcelExportColumn
                field="endMktPct"
                width={110}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Percentage Portfolio"
              />
              <ExcelExportColumn
                field="changedMktVal"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Change In Market Value"
              />
              <ExcelExportColumn
                field="percentageReturn"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Percentage Return"
              />
              <ExcelExportColumn
                field="annualRtrn"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Annualized % Return"
              />
              <ExcelExportColumn
                field="totalreturn"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Total Return"
              />
              <ExcelExportColumn
                field="totAnnualizedReturn"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Total Annualized % Return"
              />
              <ExcelExportColumn
                field="percentageContribution"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="TPercentage Contribution"
              />
            </ExcelExportColumnGroup>
          </ExcelExport>
          <Grid
            style={{ height: "600px" }}
            data={result}
            {...dataState}
            onDataStateChange={dataStateChange}
            //onColumnReorder={onColumnReorder}
            //onColumnResize={onColumnResize}
            expandField="expanded"
            onExpandChange={expandChange}
            cellRender={cellRender}
            sortable={true}
            //resizable={true}
            // pageable={true}
            //reorderable={true}
            pageable={{
              buttonCount: 0,
              info: true,
              previousNext: false,
            }}

            pageSize={Number.MAX_VALUE}
            groupable={{
              footer: "none",
              enabled: false,
            }}
          >

{console.log("Price Flag ", priceExistFlag)}

{priceExistFlag != "" ? (
  <GridNoRecords>
    <div style={{ color: "red", textAlign: "left" }}>
      {priceExistFlag}
      {console.log("No Price Exist for the date")}
    </div>
  </GridNoRecords>
) : (
  <>{console.log("Price Exist For the date")}</>
)}
            {stateColumns.map(
              (column, idx) =>
                column.show && (
                  <Column
                    width={setWidth(column.minWidth)}
                    locked={column.locked}
                    key={idx}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    footerCell={column.footerCell}
                    headerClassName={column.headerClassName}
                    {...column}
                    //cell={NumberCell}
                    //columnMenu={column.columnMenu}
                    // columnMenu={(props) => (
                    //   <CustomColumnMenuNoGrp
                    //     {...props}
                    //     columns={stateColumns}
                    //     onColumnsSubmit={onColumnsSubmit}
                    //   />
                    // )}
                  />
                )
            )}
          </Grid>
        </div>
      </div>
      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
        fileName={accntSelected.extrnlAcctId + "_PortPerfBySecurityReport.pdf"}
        margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
        pageTemplate={PageTemplate}
        forcePageBreak=".page-break"
        paperSize="A4"
        scale={0.3}
        allPages={true}
        //margin="1cm"
        landscape={true}
        repeatHeaders={true}
      >
        <Grid
          style={{ height: "600px" }}
          data={resultExport}
          {...dataState}
          onDataStateChange={dataStateChange}
          expandField="expanded"
          onExpandChange={expandChange}
          cellRender={cellRender}
          sortable={true}
          //resizable={true}
          pageable={true}
          // total={result.length}
          pageSize={numberSymbols.MAX_VALUE}
          //onColumnReorder={onColumnReorder}
          //onColumnResize={onColumnResize}

          groupable={{
            footer: "visible",
            enabled: false,
          }}
        >
          {stateColumns.map(
            (column, idx) =>
              column.show && (
                <Column
                  width={setWidth(column.minWidth)}
                  //locked={column.locked}
                  key={idx}
                  field={column.field}
                  title={column.title}
                  filter={column.filter}
                  footerCell={column.footerCell}
                  headerClassName={column.headerClassName}
                  //cell={NumberCell}
                  columnMenu={(props) => (
                    <CustomColumnMenuNoGrp
                      {...props}
                      columns={stateColumns}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  )}
                />
              )
          )}
        </Grid>
      </GridPDFExport>
    </div>
  );
};
export default PortPerfSecurityGrid;
