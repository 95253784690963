import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SelectControl from "./selectcontrol";
import { filterBy } from "@progress/kendo-data-query";
import Loading from "./loading";
import Header from "./header";
import { Slide } from "@progress/kendo-react-animation";
import { MdError } from "react-icons/md";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import ProjectedCashFlowGrid from "./projectedCashFlowGrid";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Enumerable from "linq";
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";

import { Modal as AntdModal, Button as AntdBtn } from "antd";
import { FaCog, FaFilter } from "react-icons/fa";

import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";

const ProjectedCashFlowRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [ProjectedCashFlowRptData, populateProjectedCashFlowRptData] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData")).slice()
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [isColumnSave, setIsColumnSave] = useState(false);
  const [showOverdraft, setshowOverdraft] = useState(false);
  const [overdraftVal, setOverdraftVal] = React.useState("");
  const [session, setSession] = useState("");
  const [enableCombo, setEnableCombo] = useState(false);

  const [modalVisible, setModalVisible] = useState(true);
  const [closeOption, setCloseOption] = useState(false);
  const [flag, setFlag] = React.useState(false);

  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        //setEmail(email);
        GetProjectedCashFlowData();
        getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    // fetchData();
  }, []);

  const getColumnStateDb = async () => {
    //Storing column settings in DB
    //
    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 9; //let 1 for Portfoliholdings Grid

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        //
        console.log("save in db");
        console.log(response);
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);
        return error;
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        //
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetProjectedCashFlowData();
      })
      .catch((error) => {
        // //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };
  const [errorvisible, seterrorvisible] = useState(false);
  const handleSubmit = () => {
    if (showOverdraft === true && overdraftVal === "0") {
      //setLabelvisible(true);
    } else {
      GetProjectedCashFlowData();
    }
  };
  const GetProjectedCashFlowData = async () => {
    //

    setLoading(true);
    setEnableCombo(true);
    //
    let token = tempToken;
    //let token = JSON.parse(localStorage.getItem('token'));
    let AsOfId = JSON.parse(localStorage.getItem("userId"));
    let AcctId = JSON.parse(localStorage.getItem("AccountID")).acctId;
    //let AcctId=11;
    // let pageId = 1;

    let OverdraftRange =
      overdraftVal === "" || parseFloat(overdraftVal) === 0
        ? 0
        : parseFloat(overdraftVal);
    //  let rdoOption=0;
    //  let valueOrPercent=10;

    let ConsolidationId = JSON.parse(localStorage.getItem("RelationID")) != null
    ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
    : 0; ;

    const postData = { AsOfId, AcctId, OverdraftRange, ConsolidationId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTProjectedCashFlow", postData, config)
      .then((response) => {
        //
        //  console.log(response);

        //const rowData = response.data;
        //
        //const rowData = Enumerable.from(response.data.ocPortFolioHoldingsMainOutPut).where(w => w.totMarket !== 0).toArray();
        const rowData = response.data.t1;
        //

        populateProjectedCashFlowRptData(rowData);
        getColumnStateDb();
        setFlag(true);
        setLoading(false);
        setEnableCombo(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
      });
  };

  if (apicallval === 1) {
    GetProjectedCashFlowData();
    dispatch(callremove());
  }


  const filterData = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };
  const handleOverdraft = (e) => {
    //
    setshowOverdraft(e.target.checked);
    setOverdraftVal(e.target.checked ? overdraftVal : "");
    if (e.target.checked === false) {
      localStorage.setItem("overdraftvalue", "");
    }

    //localStorage.setItem('StatusInvMixProf', e.target.checked);
    //getChangeInfo(selChangeModel.modelId);
  };
  const handleOverdraftVal = (e) => {
    //
    seterrorvisible(true);
    setOverdraftVal(e.target.value);
    console.log(parseFloat(e.target.value));
    //setshowOverdraft(e.target.checked)
    // localStorage.setItem("overdraftvalue", e.target.value);
    console.log("overdraftvalue", e.target.value)
    //getChangeInfo(selChangeModel.modelId);
  };
  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };
  const handleChange = (event) => {
    //
    if (event.target.value === null || loading) {
      //SetselAcct(JSON.parse(localStorage.getItem('acctData'))[0]);
      //GetUpdatedAccountProfile(0);
      SetselAcct(selAcct);
      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem("AcctSelected", JSON.stringify(selAcct));
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      console.log(event.target.value);
      GetProjectedCashFlowData();
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        // //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        // //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  const formSubmit = (event) => {
    event.preventDefault();
    setModalVisible(false);
    setCloseOption(false);
    GetProjectedCashFlowData();
  };

  const handleCancel = () => {
    setModalVisible(false);
    setCloseOption(false);
    !closeOption && navigate("/dashboard");
  };

  const handleSettings = () => {
    setModalVisible(true);
    setCloseOption(true);
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            <div className="row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
              {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}
            </div>
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    <div>
      {/* <Header></Header> */}
      <div className="row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
        <div className="col reportheading fs-6">Cash Flow Report</div>
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>

        <AntdModal
          open={modalVisible}
          title="Customize Cash Flow Report"
          closable={false}
          footer={[]}
        >
          <hr></hr>
          <form onSubmit={formSubmit} style={{ padding: "20px" }}>
            <div className="subheader">
              <input
                className="form-check-input"
                type="checkbox"
                name="chkInvTrgMix"
                checked={showOverdraft}
                onChange={handleOverdraft}
              ></input>
              <span className="pl-2">
              &nbsp; Show Overdraft Only
              </span>
            </div>
            <br></br>
            {/* <div className='subheader text-end col-md-2'></div> */}
            <div className="subheader  col-md-2">
              <input
                className="textbox"
                placeholder="Enter Value"
                type="number"
                name="chkInvTrgMix"
                // value={
                //   showOverdraft ? localStorage.getItem("overdraftvalue") : ""
                // }
                value={overdraftVal}
                onChange={handleOverdraftVal}
                disabled={!showOverdraft}
              ></input>
            </div>
            {/* <div className="subheader  col-md-1">
          <button
            className="btn btn-sm btn-primary px-2"
            onClick={handleSubmit}

          >
            Submit
          </button>
        </div> */}

            <hr style={{ margin: "20px 0" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div
                className="btn btn-outline-secondary mx-2"
                onClick={handleCancel}
              >
                Cancel
              </div>
              <button
                className="btn btn-primary mx-2"
                type="submit"
                disabled={
                  showOverdraft === false
                    ? false
                    : overdraftVal === "" || parseFloat(overdraftVal) <= 0
                    ? true
                    : false
                }
              >
                Submit
              </button>
              {console.log("showOverdraft value", overdraftVal)}
              {console.log("typeof overdraftval", typeof overdraftVal)}
            </div>

            <NotificationGroup
              style={{
                right: 600,
                top: 0,
                zIndex: 20000,
                alignItems: "flex-start",
                flexWrap: "wrap-reverse",
                width: "auto",
              }}
            >
              <Slide direction={"down"}>
                {(showOverdraft === false
                  ? false
                  : showOverdraft === true &&
                    parseFloat(overdraftVal) === 0 &&
                    errorvisible
                  ? true
                  : false) && (
                  <Notification
                    type={{
                      style: "error",
                      icon: false,
                    }}
                    closable={true}
                    onClose={() => seterrorvisible(false)}
                  >
                    <span>Enter a non-zero value for Overdraft range.</span>
                  </Notification>
                )}
              </Slide>
            </NotificationGroup>
          </form>
        </AntdModal>

        {/* <div className='subheader text-end col-md-1'><div style={{display:(showOverdraft===false?"none":(showOverdraft===true&&overdraftVal==="0"?"block":"none"))}}><label className='form-check-label'  >Enter a non-zero value for Overdraft range.</label></div></div> */}
        {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}
      </div>

      {flag ? (<ProjectedCashFlowGrid
        data={ProjectedCashFlowRptData}
        flag={isColumnSave}
        accntSelected={selAcct}
      />): (<></>)}
    </div>
  );
};

export default ProjectedCashFlowRpt;
