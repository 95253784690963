import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SelectControl from "./selectcontrol";
import Loading from "./loading";
import Header from "./header";
import FixdIncmMaturityLadrGrid from "./fixdIncmMtrtyLadrGrd";
import "@progress/kendo-theme-material/dist/all.css";
import FixdIncmMaturityLadrGrd from "./fixdIncmMtrtyLadrGrd";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "@telerik/kendo-intl";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";

import { Modal as AntdModal, Button as AntdBtn } from "antd";
import { FaCog, FaFilter } from "react-icons/fa";
import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";

const FixdIncmMaturityLadrRpt = () => {
  const [fimlRptMainDataSet, populateFimlRptMainDataSet] = useState([]);
  const [fimlRptCalDtls, populateFimlRptSecondDataSet] = useState([]);
  const [fimlRptmatVsCallPut, populateFimlRptThirdDataSet] = useState([]);
  const [fimAcctList, populateFimAcctList] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(0);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const navigate = useNavigate();

  const [reportData, setReportData] = useState([]);

  var date = new Date(localStorage.getItem("processingDate"));
  var minDate = new Date(localStorage.getItem("processingDate"));
  minDate.setMonth(minDate.getMonth() - 60);
  const [toDate, setToDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  // console.log("date", date);
  var maxFrmDt = date;
  const minFrmDt = minDate;

  const [asOfDt, setasOfDt] = React.useState(date);
  const [priceDt, setpriceDt] = React.useState(date);
  const [frmDate, setfrmDate] = React.useState(date);
  const [selectedOption, setSelectedOption] = useState(1);
  const [modalVisible, setModalVisible] = useState(true);
  const [closeOption, setCloseOption] = useState(false);
  const [showExcludeAsset, setShowExcludeAsset] = useState(true);
  const [isDisabled, setisDisabled] = React.useState(false);

  const [priceFlagInd, setPriceFlagInd] = useState(1);
  const [isPriceExistsFlagInd, setIsPriceExistsFlagInd] = useState(0);
  const [chkRptDispOptn, setChkRptDispOptn] = useState(true);
  const [runInAggregate, setRunInAggregate] = useState(false);

  const dispatch = useDispatch();

  // let isPriceExistFlag = 0;

  const [priceExistFlag, setPriceExistFlag] = useState("");

  const apicallval = useSelector(selectapicall);

  const [initialState, setInitialState] = useState({
    chkRptDispOptn: true,
    runInAggregate: false,
  });

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        //setEmail(email);
        GetFixdIncmMaturityLadrRptData();

        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    // fetchData();
  }, []);

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetFixdIncmMaturityLadrRptData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const GetFixdIncmMaturityLadrRptData = async () => {
    setLoading(true);

    //  let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId"));

    let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    // let acctIds = 0;
    // if (lsSelectedAcct !== null) {
    //   acctIds = lsSelectedAcct.acctId;
    // }
    let AcctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;
    let StrtDt = formatDate(frmDate, "MM/dd/yyyy");
    let EndDt = formatDate(toDate, "MM/dd/yyyy");
    // let PrstInd = 0;
    // let StrtDt = "";
    // let EndDt = "";
    let AsOfDt = formatDate(asOfDt, "MM/dd/yyyy");
    let PriceDt = formatDate(priceDt, "MM/dd/yyyy");
    let PriceFlag = priceFlagInd;
    // let IsConsolidation = false;
    let ShowExcldAst = showExcludeAsset;
    // let PageId = 1;
    let consolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;

    const postData = {
      UserId,
      AcctId,
      StrtDt,
      EndDt,
      AsOfDt,
      PriceDt,
      PriceFlag,
      ShowExcldAst,
      consolidationId,
      runInAggregate,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTFixdIncmMaturity", postData, config)
      .then((response) => {
        //  console.log(response);
        let consolidationName = JSON.parse(
          localStorage.getItem("RelationID")
        )?.cnsldtnNm;
        const rowData = response.data;

        if (rowData.fIM1 != null) {
          for (let i = 0; i < rowData.fIM1.length; i++) {
            if (AcctId == 0 && runInAggregate == true) {
              rowData.fIM1[i].extrnlAcctId = consolidationName;
            }
          }
        }

        if (rowData.fIM2 != null) {
          for (let i = 0; i < rowData.fIM2.length; i++) {
            if (AcctId == 0 && runInAggregate == true) {
              rowData.fIM2[i].extrnlAcctId = consolidationName;
            }
          }
        }

        const fIM1Data =
          rowData?.fIM1?.filter((item) => item.mtrtyYr > 0) || [];
        const fIM2Data =
          rowData?.fIM2?.filter((item) => item.callOrPutYr > 0) || [];

        // populateFimlRptMainDataSet(rowData?.fIM1 || []);
        populateFimlRptMainDataSet(fIM1Data);
        // populateFimlRptSecondDataSet(rowData?.fIM2 || []);
        populateFimlRptSecondDataSet(fIM2Data);
        populateFimlRptThirdDataSet(rowData?.fIM3 || []);
        populateFimAcctList(rowData?.fIM4 || []);
        setSelAcctData(rowData?.fIM4 || []);
        if (lsSelectedAcct !== null) {
          SetselAcct(lsSelectedAcct);
        } else {
          SetselAcct(rowData.fIM4[0]);
        }
        setLoading(false);
        setFlag(1);
        setReportData(rowData);
        setPriceExistFlag(response?.data?.priceValidation);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        //return error;
      });
  };

  if (apicallval === 1) {
    GetFixdIncmMaturityLadrRptData();
    dispatch(callremove());
  }

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  const handleChange = (event) => {
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetFixdIncmMaturityLadrRptData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const handlerunInAggregate = () => {
    setRunInAggregate(!runInAggregate);
  };

  const handleRadioChange = (e) => {
    setSelectedOption(parseInt(e.target.value));
    setPriceFlagInd(parseInt(e.target.value));
    console.log("e.target.value", e.target.value);
    console.log("e", e);
    if (e.target.value == 1 || e.target.value == 2) {
      setpriceDt(date); // to be checked
    }
    // console.log("selectedOption", selectedOption);
    // console.log("Selected Price Date", priceDt);
  };

  const formSubmit = (event) => {
    event.preventDefault();
    setModalVisible(false);
    setCloseOption(false);
    GetFixdIncmMaturityLadrRptData();
  };

  const handleSettings = (e) => {
    setInitialState({
      chkRptDispOptn,
      runInAggregate,
    });
    setModalVisible(true);
    setCloseOption(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setCloseOption(false);
    setChkRptDispOptn(initialState.chkRptDispOptn);
    setRunInAggregate(initialState.runInAggregate);
    !closeOption && navigate("/dashboard");
  };

  const handleExcludeAsset = (e) => {
    setShowExcludeAsset(e.target.checked ? true : false);
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    <div>
      {/* <Header></Header> */}
      {/* <div  className='my-1'>
       <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
       <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
       <span className='px-2'>Account(s)</span>
          <ComboBox
            style={{
              width: "350px",
            }}
            data={selAcctData}
            textField="extrnlAcctId"
            dataItemKey="acctId"
            filterable={true}
            value={selAcct}
            onChange={handleChange}

          />
          </div><div className='subheader text-end col-md-2'>Processing Date : {localStorage.getItem("processingDate")}</div>
          </div>
          </div> */}

      <div className="row d-flex justify-content-between align-items-center mx-2 py-1 border-bottom">
        {/* Report Heading */}
        <div className="col reportheading fs-6">Maturity Ladder Report</div>

        {/* Date Range or As Of Date */}
        <div className="col text-center">
          {`As Of ${new Date(asOfDt).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}`}
        </div>

        {/* Filter Button */}
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>
      </div>

      <AntdModal
        open={modalVisible}
        title="Customize Fixed Income Maturity Ladder Report"
        closable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          {/* As Of Date */}
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpAsOfDt"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              As Of Date
            </label>
            <DatePicker
              id="dpAsOfDt"
              value={asOfDt}
              calendar={CustomCalendar}
              min={new Date("1/1/1000")}
              max={maxFrmDt}
              onChange={(e) => setasOfDt(e.value)}
              style={{ width: "100%" }}
              validationMessage={
                asOfDt == null
                  ? "Enter a valid date"
                  : asOfDt < new Date("1/1/1000")
                  ? "Enter a date in the correct format (mm/dd/yyyy)"
                  : asOfDt > maxFrmDt
                  ? "As Of Date cannot exceed the Processing Date"
                  : ""
              }
            />
          </div>

          <hr style={{ margin: "20px 0" }} />

          <label
            htmlFor="dpAsOfDt"
            className="form-label"
            style={{
              display: "block",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            Maturity Date Selection
          </label>

          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpFrm"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              From
            </label>
            <DatePicker
              id="dpFrm"
              value={frmDate}
              calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={isDisabled}
              style={{ width: "100%" }}
              // valid={
              //   frmDate >= minFrmDt && frmDate <= maxFrmDt && frmDate != null
              // }
              onChange={(e) => setfrmDate(e.value)}
              validationMessage={
                frmDate == null
                  ? "Enter Valid Date"
                  : frmDate < new Date("1/1/1000")
                  ? "Enter date in correct format (mm/dd/yyyy)"
                  : frmDate > maxFrmDt
                  ? "From date cannot exceed one year from Processing date."
                  : frmDate > toDate
                  ? "From date cannot exceed to date."
                  : frmDate < minFrmDt
                  ? "From date cannot be less than Processing date."
                  : "Validation error"
              }
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpTo"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              To
            </label>
            <DatePicker
              id="dpTo"
              value={toDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={false}
              onChange={(e) => {
                setToDate(e.value);
              }}
              style={{ width: "100%" }}
              // valid={
              //   frmDate <= toDate && toDate <= maxFrmDt && toDate >= minFrmDt
              // }
              validationMessage={
                toDate == null
                  ? "Enter Valid Date"
                  : toDate < new Date("1/1/1000")
                  ? "Enter date in correct format (mm/dd/yyyy)"
                  : toDate > maxFrmDt
                  ? "To date cannot exceed one year from Processing date."
                  : toDate < frmDate
                  ? "To date cannot be less than from date"
                  : toDate < minFrmDt
                  ? "To date cannot be less than Processing date."
                  : "Validation error"
              }
            />
          </div>
          <hr></hr>

          {/* Pricing Options */}
          <h6>Select Pricing Option</h6>
          <div style={{ marginBottom: "20px" }}>
            {[1, 2, 3].map((option) => (
              <label
                key={option}
                style={{ display: "block", marginBottom: "10px" }}
              >
                <input
                  type="radio"
                  value={option}
                  checked={selectedOption === option}
                  onChange={handleRadioChange}
                />
                {option === 1
                  ? " Use Current Price"
                  : option === 2
                  ? " Use End of Month Price"
                  : " Use Non EOM Price"}
              </label>
            ))}
          </div>

          {/* Conditional Price Date Field */}
          {selectedOption === 3 && (
            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="dpPriceDt"
                className="form-label"
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                Price Date
              </label>
              <DatePicker
                id="dpPriceDt"
                value={priceDt}
                calendar={CustomCalendar}
                min={new Date("1/1/1000")}
                max={maxFrmDt}
                onChange={(e) => setpriceDt(e.value)}
                style={{ width: "100%" }}
                validationMessage={
                  priceDt == null
                    ? "Enter a valid date"
                    : priceDt < new Date("1/1/1000")
                    ? "Enter a date in the correct format (mm/dd/yyyy)"
                    : priceDt > maxFrmDt
                    ? "Price Date cannot exceed the Processing Date"
                    : ""
                }
              />
            </div>
          )}

          <hr style={{ margin: "20px 0" }} />

          {/* Miscellaneous Section */}
          <h6>Miscellaneous Section</h6>

          <div style={{ marginBottom: "20px" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showExcludeAsset}
                  onChange={handleExcludeAsset}
                  name="excludeAsset"
                />
              }
              label=" Show Excluded Assets"
              style={{ marginBottom: "10px", display: "block" }}
            />
          </div>
          <div>
            {AcctId == 0 && (
              <FormControlLabel
                control={
                  <Switch
                    checked={runInAggregate}
                    onClick={handlerunInAggregate}
                    name="chkCtr"
                  />
                }
                label="Run In Aggregate."
                style={{ marginBottom: "10px", display: "block" }}
              />
            )}
          </div>

          {/* Actions */}

          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntdModal>
      {flag === 1 ? (
        <FixdIncmMaturityLadrGrd
          data={fimlRptMainDataSet}
          callDetails={fimlRptCalDtls}
          matVsCallPut={fimlRptmatVsCallPut}
          reportData={reportData}
          priceExistFlag={priceExistFlag}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default FixdIncmMaturityLadrRpt;
