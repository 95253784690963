import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SelectControl from "./selectcontrol";
import { filterBy } from "@progress/kendo-data-query";
import Loading from "./loading";
import Header from "./header";
import PortfolioHoldingsGrid from "./portfolioHoldingsGrid";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Enumerable from "linq";
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { FaBullseye } from "react-icons/fa";

import { Modal as AntdModal, Button as AntdBtn } from "antd";
import { formatDate } from "@telerik/kendo-intl";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { FaCog, FaFilter } from "react-icons/fa";

import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";
import { degrees } from "pdf-lib";

const PortfolioHoldingsRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [PortfolioHoldingsRptData, populatePortfolioHoldingsRptData] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData")).slice()
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [isColumnSave, setIsColumnSave] = useState(false);
  const [session, setSession] = useState("");
  const [reportData, SetreportData] = useState([]);
  const [enableCombo, setEnableCombo] = useState(false);
  const navigate = useNavigate();

  var date = new Date(localStorage.getItem("processingDate"));
  var maxFrmDt = date;

  const [modalVisible, setModalVisible] = useState(true);
  const [selectedOption, setSelectedOption] = useState(1);
  const [priceDt, setpriceDt] = React.useState(date);

  const [priceFlagInd, setPriceFlagInd] = useState(1);
  const [displayPercentInd, setDisplayPercentInd] = useState(1);
  const [showExcludeAsset, setShowExcludeAsset] = useState(true);
  const [showAllPositionsIndic, setShowAllPositionsIndic] = useState(false);
  const [showProcessTrades, setShowProcessTreds] = useState(false);

  const [closeOption, setCloseOption] = useState(false);
  const [flag, setFlag] = React.useState(false);
  const [displayPercentOption, setDisplayPercentOption] = useState(1);
  const [asOfDt, setasOfDt] = React.useState(date);

  const [priceExistFlag, setPriceExistFlag] = useState("");
  const [runInAggregate, setRunInAggregate] = useState(false);
  let proposedTradeIndicator = JSON.parse(localStorage.getItem("prpsdTrdsInd"));

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);

  const [initialState, setInitialState] = useState({
    selectedOption: 1,
    displayPercentOption: 1,
    showExcludeAsset: true,
    showProcessTrades: false,
    runInAggregate: false,
  });

  const handleAggregateMode = () => {
    if (runInAggregate == 0) {
      setRunInAggregate(true);
    }
    if (runInAggregate == 1) {
      setRunInAggregate(false);
    }
    console.log("Aggregate", runInAggregate);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        //setEmail(email);
        GetPortFolioHoldingsData();
        getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    // fetchData();
  }, []);
  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 1; //let 1 for Portfoliholdings Grid

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        console.log("save in db");
        console.log(response);
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);
        return error;
      });
  };

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetPortFolioHoldingsData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };
  const GetPortFolioHoldingsData = async () => {
    setLoading(true);
    setEnableCombo(true);

    let token = tempToken;
    //let token = JSON.parse(localStorage.getItem('token'));
    let AsOfId = JSON.parse(localStorage.getItem("userId"));
    let mAccountId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;
    let pageId = 1;

    let consolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;
    let PriceDt = formatDate(priceDt, "MM/dd/yyyy");
    let priceFlag = priceFlagInd;
    let dsplyPrcnt = displayPercentInd;
    let showExcldAst = showExcludeAsset;
    // let showAllPositionsInd = showAllPositionsIndic;
    let showAllPositionsInd = true;

    let shwPrpsdTrds = showProcessTrades;
    let AsOfDt = formatDate(asOfDt, "MM/dd/yyyy");

    //  const postData = {AsOfId,mAccountId, pageId};
    const postData = {
      AsOfId,
      mAccountId,
      consolidationId,
      AsOfDt,
      PriceDt,
      priceFlag,
      dsplyPrcnt,
      showExcldAst,
      showAllPositionsInd,
      shwPrpsdTrds,
      runInAggregate,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/PortfolioHoldings", postData, config)
      .then((response) => {
        //  console.log(response);
        debugger;
        const reportData = response.data;
        let consolidationName =
          JSON.parse(localStorage.getItem("RelationID"))?.cnsldtnNm || 0;

        SetreportData(reportData);
        const rowData = Enumerable.from(
          response.data.ocPortFolioHoldingsMainOutPut
        ).toArray();
        // SetreportData(rowData);
        if (rowData != null) {
          if (AcctId == 0 && runInAggregate == true) {
            console.log("inside the aggregate");
            for (var i = 0; i < rowData.length; i++)
              rowData[i].account = consolidationName;
          }
        }

        console.log("Relationship", consolidationName);

        debugger;

        console.log("RowData", rowData);
        populatePortfolioHoldingsRptData(rowData);
        console.log("rowData", response);
        getColumnStateDb();
        localStorage.setItem("filterData", JSON.stringify(rowData));
        setLoading(false);
        setEnableCombo(false);
        setFlag(true);
        setPriceExistFlag(response?.data?.priceValidation);
        //    .filter((value, index, self) =>
        // index === self.findIndex((t) => (
        //   t.acctId === value.acctId
        // )))
        // debugger;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
      });
  };

  if (apicallval === 1) {
    GetPortFolioHoldingsData();
    dispatch(callremove());
  }
  const filterData = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };

  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };
  const handleChange = (event) => {
    if (event.target.value === null) {
      SetselAcct(JSON.parse(localStorage.getItem("acctData"))[0]);
      //GetUpdatedAccountProfile(0);
      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem(
        "AcctSelected",
        JSON.stringify(JSON.parse(localStorage.getItem("acctData"))[0])
      );
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      console.log(event.target.value);
      GetPortFolioHoldingsData();
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  const handleSettings = () => {
    setInitialState({
      displayPercentOption,
      selectedOption,
      showExcludeAsset,
      showProcessTrades,
      runInAggregate,
    });
    setModalVisible(true);
    setCloseOption(true);
  };

  const formSubmit = (event) => {
    debugger;
    event.preventDefault();
    // getModelInfo();
    setModalVisible(false);
    setCloseOption(false);
    GetPortFolioHoldingsData();
  };

  const handleRadioChange = (e) => {
    setSelectedOption(parseInt(e.target.value));
    setPriceFlagInd(parseInt(e.target.value));
    console.log("e.target.value", e.target.value);
    console.log("e", e);
    if (e.target.value == 1 || e.target.value == 2) {
      setpriceDt(date); // to be checked
    }
    // console.log("selectedOption", selectedOption);
    // console.log("Selected Price Date", priceDt);
  };

  const handleDisplayPercent = (e) => {
    // setSelectedOption(e.target.value);
    // setPriceFlagInd(parseInt(e.target.value));
    console.log("e.target.value", e.target.value);
    // console.log("e", e)

    setDisplayPercentOption(parseInt(e.target.value));
    setDisplayPercentInd(parseInt(e.target.value));
  };

  const handleCancel = () => {
    setDisplayPercentOption(initialState.displayPercentOption);
    setSelectedOption(initialState.selectedOption);
    setShowExcludeAsset(initialState.showExcludeAsset);
    setShowProcessTreds(initialState.showExcludeAsset);
    setRunInAggregate(initialState.runInAggregate);

    setModalVisible(false);
    setCloseOption(false);
    !closeOption && navigate("/dashboard");
  };

  function setPrcDt(val) {
    let newdate = new Date(val);
    if (newdate <= new Date(localStorage.getItem("processingDate")))
      setpriceDt(val);
    else {
      setpriceDt(new Date(localStorage.getItem("processingDate")));
    }
    setpriceDt(val);
  }

  const handleExcludeAsset = (e) => {
    setShowExcludeAsset(e.target.checked ? true : false);
  };

  const handleAllPositionsIndicator = (e) => {
    setShowAllPositionsIndic(e.target.checked ? true : false);
  };

  const handleShowProcessTrades = (e) => {
    setShowProcessTreds(e.target.checked ? true : false);
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    <div>
      {/* <Header></Header> */}
      <div className="row d-flex justify-content-start align-items-center my-1 px-2 mx-2 shadow-sm rounded">
        <div className="col reportheading fs-6">Portfolio Holdings Report</div>

        {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}

        {/* Filter Button */}
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>
      </div>

      <AntdModal
        open={modalVisible}
        title="Customize Portfolio Holdings Report"
        closable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "4px 20px 20px 20px" }}>
          {/* As Of Date */}
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpAsOfDt"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              As Of Date
            </label>
            <DatePicker
              id="dpAsOfDt"
              value={asOfDt}
              // calendar={CustomCalendar}
              min={new Date("1/1/1000")}
              max={maxFrmDt}
              onChange={(e) => setasOfDt(e.value)}
              style={{ width: "100%" }}
              valid={priceDt <= maxFrmDt && priceDt >= new Date("1/1/1000")}
              validationMessage={
                asOfDt == null
                  ? "Enter a valid date"
                  : asOfDt < new Date("1/1/1000")
                  ? "Enter a date in the correct format (mm/dd/yyyy)"
                  : asOfDt > maxFrmDt
                  ? "As Of Date cannot exceed the Processing Date"
                  : ""
              }
            />
          </div>

          <hr style={{ margin: "20px 0" }} />

          {/* Pricing Options */}
          <h6 className="pt-2">Select Pricing Option</h6>
          <div style={{ marginBottom: "20px" }}>
            {[1, 2, 3].map((option) => (
              <label
                key={option}
                style={{ display: "block", marginBottom: "10px" }}
              >
                <input
                  type="radio"
                  value={option}
                  checked={selectedOption === option}
                  onChange={handleRadioChange}
                />
                {option === 1
                  ? " Use Current Price"
                  : option === 2
                  ? " Use End of Month Price"
                  : " Use Non EOM Price"}
              </label>
            ))}
          </div>

          <hr></hr>

          {/* Conditional Price Date Field */}
          {selectedOption === 3 && (
            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="dpPriceDt"
                className="form-label"
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                Price Date
              </label>
              <DatePicker
                id="dpPriceDt"
                value={priceDt}
                calendar={CustomCalendar}
                min={new Date("1/1/1000")}
                max={maxFrmDt}
                onChange={(e) => setPrcDt(e.value)}
                style={{ width: "100%" }}
                validationMessage={
                  priceDt == null
                    ? "Enter a valid date"
                    : priceDt < new Date("1/1/1000")
                    ? "Enter a date in the correct format (mm/dd/yyyy)"
                    : priceDt > maxFrmDt
                    ? "Price Date cannot exceed the Processing Date"
                    : ""
                }
              />
            </div>
          )}

          <h6>Display Percent</h6>
          <div style={{ marginBottom: "20px" }}>
            {[1, 2, 3].map((option) => (
              <label
                key={option}
                style={{ display: "block", marginBottom: "10px" }}
              >
                <input
                  type="radio"
                  value={option}
                  checked={displayPercentOption === option}
                  onChange={handleDisplayPercent}
                />
                {option === 1
                  ? " Total Portfolio"
                  : option === 2
                  ? " Selected Portfolio"
                  : " Major Asset Class"}
              </label>
            ))}
          </div>

          <hr></hr>

          {/* Miscellaneous Section */}
          <h6>Miscellaneous Section</h6>
          <div style={{ marginBottom: "20px" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showExcludeAsset}
                  onChange={handleExcludeAsset}
                  name="excludeAsset"
                />
              }
              label=" Show Excluded Assets"
              style={{ marginBottom: "10px", display: "block" }}
            />
            {/* <FormControlLabel
              control={
                <Switch
                  checked={showAllPositionsIndic}
                  onChange={handleAllPositionsIndicator}
                  name="allPositions"
                />
              }
              label=" Show All Positions"
              style={{ marginBottom: "10px", display: "block" }}
            /> */}

            <FormControlLabel
              control={
                <Switch
                  checked={showProcessTrades}
                  onChange={handleShowProcessTrades}
                  name="tipsSwitch"
                  disabled={!proposedTradeIndicator}
                />
              }
              label=" Show Proposed Trades"
              style={{ marginBottom: "10px", display: "block" }}
            />

            {AcctId == 0 && (
              <FormControlLabel
                control={
                  <Switch
                    checked={runInAggregate}
                    onClick={handleAggregateMode}
                    name="chkCtr"
                  />
                }
                label="Run In Aggregate."
                style={{ marginBottom: "10px", display: "block" }}
              />
            )}
          </div>

          {/* Actions */}

          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntdModal>

      {flag ? (
        <PortfolioHoldingsGrid
          data={PortfolioHoldingsRptData}
          flag={isColumnSave}
          reportData={reportData}
          priceExistFlag={priceExistFlag}
          runInAggregate={runInAggregate}
          displayPercentOption={displayPercentOption}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default PortfolioHoldingsRpt;
