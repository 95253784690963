import React from "react";

import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { formatNumber, formatDate, parseNumber } from "@telerik/kendo-intl";

import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";
import Modal from "react-bootstrap/Modal";
import { orderBy } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { groupBy } from "@progress/kendo-data-query";

const AssetGrowthAndAllocationChart = ({ assetGrowthAndAllacData }) => {
  const initialSort = [
    {
      field: "yearEnd",
      dir: "desc",
    },
  ];
  const _ = require("lodash");
  const initialT1 = _.uniqWith(assetGrowthAndAllacData?.t1, _.isEqual);
  const t1Data = orderBy(initialT1, initialSort);
  const initialT2 = _.uniqWith(assetGrowthAndAllacData?.t2, _.isEqual);
  const t2Data = orderBy(initialT2, initialSort);

  const [dataStateT1, setDataStateT1] = React.useState();
  const [dataStateT2, setDataStateT2] = React.useState();

  const initialGroupT1 = [
    {
      field: "account",
    },
  ];

  const initialGroupT2 = [
    {
      field: "account",
    },
  ];


  const createDataStateT1 = (dataStateT1) => {
    return {
      result: process(initialT1, dataStateT1),
      dataState: dataStateT1,
    };
  };

  const createDataStateT2 = (dataStateT2) => {
    return {
      result: process(initialT2, dataStateT2),
      dataState: dataStateT2,
    };
  };


  const processWithGroupsT1 = (initialT1, group) => {
    const groups = group;

    // if (groups) {
    //   groups.map((group) => (group.aggregates = aggregates));
    // }

    // dataStateT1.group = groups;
    const newDataState = groupBy(initialT1, groups);
    setGroupIds({
      data: newDataState,
      group: groups,
    });
    return newDataState;
  };

  const processWithGroupsT2 = (initialT2, group) => {
    const groups = group;

    // if (groups) {
    //   groups.map((group) => (group.aggregates = aggregates));
    // }

    // dataStateT1.group = groups;
    const newDataState = groupBy(initialT2, groups);
    setGroupIds({
      data: newDataState,
      group: groups,
    });
    return newDataState;
  };

  const [collapsedStateT1, setCollapsedStateT1] = React.useState([]);
  const [collapsedStateT2, setCollapsedStateT2] = React.useState([]);

  const [groupT1, setGroupT1] = React.useState(initialGroupT1);
  const [groupT2, setGroupT2] = React.useState(initialGroupT2);


  const [resultStateT1, setResultStateT1] = React.useState(
    processWithGroupsT1(t1Data, initialGroupT1)
  );

  const [resultStateT2, setResultStateT2] = React.useState(
    processWithGroupsT2(t2Data, initialGroupT2)
  );

  const onExpandChangeT1 = React.useCallback(
    (event) => {
      const item = event.dataItem;
      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedStateT1, item.groupId]
          : collapsedStateT1.filter((groupId) => groupId !== item.groupId);
        setCollapsedStateT1(newCollapsedIds);
      }
    },
    [collapsedStateT1]
  );

  const onExpandChangeT2 = React.useCallback(
    (event) => {
      const item = event.dataItem;
      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedStateT2, item.groupId]
          : collapsedStateT2.filter((groupId) => groupId !== item.groupId);
        setCollapsedStateT2(newCollapsedIds);
      }
    },
    [collapsedStateT2]
  );

  const newDataT1 = setExpandedState({
    data: resultStateT1,
    collapsedIds: collapsedStateT1,
  });

  const newDataT2 = setExpandedState({
    data: resultStateT2,
    collapsedIds: collapsedStateT2,
  });
  
  // For Preview Active Report Start
  const [show, setShow] = React.useState(false);
  //const [loadingChild, setLoadingChild] = useState(true);
  const viewerRef = React.useRef();
  async function loadReport() {
    // load report definition from the file
    
    const reportResponse = await fetch("AssetGrowthAndAllocation.rdlx-json");
    
    const report = await reportResponse.json();
    return report;
  }

  async function openReport() {
    
    const report = await loadReport();

    

    for (let i = 0; i < t1Data.length; i++) {
      t1Data[i].marketInMillion = parseNumber(
        formatNumber(t1Data[i].marketInMillion, "n2"))
        t1Data[i].retVal = parseNumber(
          formatNumber(t1Data[i].retVal, "n2")
      );
    }

    // {retVal}
    const parameters = [
      {
        Name: "pCnsldtnNm",
        Value: [
          JSON.parse(localStorage.getItem("RelationID")) != null
            ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm === ""
              ? 0
              : JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm
            : 0,
        ],
      },
      {
        Name: "pExtrnlAcctId",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 0
            : JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId,
        ],
      },
      {
        Name: "pShow",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 3
            : 2,
        ],
      },
    ];

    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify({ t1: t1Data, t2: t2Data });
    
    
    //if(loadingChild==false)
    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }
  //For Preview Active Report End
  const handleClose = () => setShow(false);
  const _export = React.useRef(null);
  const showpreview = async () => {
    setShow(true);
  };
  const excelExport = () => {
    
    setShow(true);
    openReport();
    // if (_export.current !== null) {
    //     _export.current.save(data);
    // }
  };

  const yearEnds = t1Data?.map((item) => item.yearEnd);
  const marketInMillion = t1Data?.map((item) => item.marketInMillion);

  const returnValue = t1Data.map((item) => item.retVal);

  const groupedByYear = t2Data.reduce((acc, item) => {
    const year = item.yearEnd;

    if (!acc[year]) {
      acc[year] = [];
    }

    acc[year].push({
      perfTmpltCtgryNm: item.perfTmpltCtgryNm,
      endMktVlAmt: item.endMktVlAmt,
    });

    return acc;
  }, {});

  const getUniqueCategoryNames = (data) => {
    const categories = new Set();

    for (const year in data) {
      const yearData = data[year];
      if (Array.isArray(yearData)) {
        yearData.forEach((item) => categories.add(item.perfTmpltCtgryNm));
      }
    }

    return Array.from(categories);
  };

  const categoryNames = getUniqueCategoryNames(groupedByYear);
  const t2Year = Object.keys(groupedByYear).reverse();

  const getCategoryValues = (data, categoryName) => {
    const values = [];
    for (const year in data) {
      const yearData = data[year];
      if (Array.isArray(yearData)) {
        const item = yearData.find(
          (entry) => entry.perfTmpltCtgryNm === categoryName
        );
        if (item) {
          values.push(item.endMktVlAmt);
        } else {
          values.push(0);
        }
      }
    }
    return values.reverse();
  };

  const NumberCell = (props) => {
    return (
      <td style={{ textAlign: "right" }}>
        {formatNumber(props.dataItem[props.field], "##,#.00")}
      </td>
    );
  };

  const onDataStateChangeT1 = React.useCallback((e) => {
    setDataStateT1(e.dataState);
    // let gridData = JSON.parse(localStorage.getItem('gridData'));
    // const groups = e.dataState.group;

    // if (groups) {
    //   groups.map((group) => (group.aggregates = aggregates));
    // }
    // e.dataState.group = groups;
    debugger;

    let updatedState = createDataStateT1(e.dataState);
    //

    setResultStateT1(processWithGroupsT1(t1Data, updatedState.dataState));

    // setResultStateT1(processWithGroupsT1(data, initialGroupT1));
    //setResultStateT1(process(data, e.dataState));
  }, []);

  const onDataStateChangeT2 = React.useCallback((e) => {
    setDataStateT2(e.dataState);
    // let gridData = JSON.parse(localStorage.getItem('gridData'));
    // const groups = e.dataState.group;

    // if (groups) {
    //   groups.map((group) => (group.aggregates = aggregates));
    // }
    // e.dataState.group = groups;
    debugger;

    let updatedState = createDataStateT2(e.dataState);
    //

    setResultStateT2(processWithGroupsT2(t2Data, updatedState.dataState));

    // setResultStateT1(processWithGroupsT1(data, initialGroupT1));
    //setResultStateT1(process(data, e.dataState));
  }, []);

  return (
    <div>
      <div className="text-end">
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={excelExport}
        >
          Preview
        </button>
      </div>

      <>
        <div className="row d-flex justify-content-center align-items-center g-0">
          {/* <div className="col-12">
            <p class="reportheading fs-6 h6">
              Asset Growth And Allocation Report
            </p>
          </div> */}
          <div className="col-6 wheight d-flex">
            <div className="card card-rounded wheight w-100">
              <div className="card-body">
                <h6 className="card-title">
                  Units Calendar Year Ending Market Values Over Time
                </h6>
                <Chart style={{ width: "100%" }} legendItemHover>
                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                      categories={yearEnds}
                      title={{
                        text: "Year",
                      }}
                      labels={{
                        visible: true,
                        rotation: 95,
                        format: "d",
                      }}
                    />
                  </ChartCategoryAxis>

                  <ChartTooltip format="n2" />
                  <ChartSeries>
                    <ChartSeriesItem type="area" data={marketInMillion} />
                  </ChartSeries>
                  <ChartValueAxis>
                    <ChartValueAxisItem title={{ text: "Millions" }} />
                  </ChartValueAxis>
                </Chart>
              </div>
            </div>
          </div>
          <div className="col-6 wheight d-flex">
            <div className="card card-rounded h-100 w-100">
              <div className="card-body">
                <h6 className="card-title">
                  Total Return % Calendar Year - Gross of Fees
                </h6>

                <Chart
                  // seriesColors={chartDefaultV4Colors}
                  zoomable={false}
                  style={{ width: "100%" }}
                >
                  <ChartLegend position="bottom" />

                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                      categories={yearEnds}
                      title={{
                        text: "Year",
                      }}
                      labels={{
                        visible: true,
                        rotation: 95,
                        format: "d",
                      }}
                    />
                  </ChartCategoryAxis>
                  <ChartTooltip format="N2" />
                  <ChartSeries>
                    <ChartSeriesItem type="column" data={returnValue} />
                  </ChartSeries>
                </Chart>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex">
            <div className="card card-rounded h-100 w-100">
              <div className="card-body">
                <Grid 
                style={{ height: "350px" }}
                data={newDataT1} 
                // reorderable={true}
                groupable={{
                  footer: "none",
                  enabled: false,
                }}
                pageable={{
                  buttonCount: 0,
                  info: true,
                  previousNext: false,
                }}
                pageSize={Number.MAX_VALUE}
                group={groupT1}
                onDataStateChange={onDataStateChangeT1}
                onExpandChange={onExpandChangeT1}
                expandField="expanded"
                {...dataStateT1}
                >
                  <Column field="yearEnd" title="Year" width="450px"/>
                  <Column
                    field="marketInMillion"
                    title="Market In Million"
                    cell={NumberCell}
                    headerClassName="rightHeader"
                    width="280px"
                  />
                  <Column
                    field="retVal"
                    title="Return Value"
                    cell={NumberCell}
                    headerClassName="rightHeader"
                    width="280px"
                  />
                </Grid>
              </div>
            </div>
          </div>

          <div className="col-6 wheight d-flex">
            <div className="card card-rounded w-100 h-100">
              <div className="card-body">
                <h6 className="card-title">
                  Calendar Year - Allocation Over Time
                </h6>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <Chart
                    style={{
                      width: "100%",
                    }}
                  >
                    <ChartLegend position="bottom" orientation="horizontal" />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={t2Year}
                        title={{
                          text: "Year",
                        }}
                      />
                    </ChartCategoryAxis>
                    <ChartTooltip format="N2" />
                    <ChartSeries>
                      {categoryNames.map((categoryName) => (
                        <ChartSeriesItem
                          type="column"
                          stack={{
                            type: "100%",
                          }}
                          data={getCategoryValues(groupedByYear, categoryName)}
                          name={categoryName}
                        />
                      ))}
                    </ChartSeries>
                  </Chart>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 wheight d-flex">
            <div className="card card-rounded h-100 w-100">
              <div className="card-body">
                <h6 className="card-title">Allocation Over Time</h6>
                <Grid
                  data={newDataT2}
                  style={{ height: "400px" }}
                  // reorderable={true}
                  groupable={{
                    footer: "none",
                    enabled: false,
                  }}
                  pageable={{
                    buttonCount: 0,
                    info: true,
                    previousNext: false,
                  }}
                  pageSize={Number.MAX_VALUE}
                  group={groupT2}
                  onDataStateChangeT={onDataStateChangeT2}
                  onExpandChange={onExpandChangeT2}
                  expandField="expanded"
                  {...dataStateT2}
                >
                  <Column field="yearEnd" title="Year" 
                  // minWidth="450" 
                  width="450px"
                  />
                  <Column
                    field="perfTmpltCtgryNm"
                    title="Category Name"
                    cell={NumberCell}
                    headerClassName="rightHeader"
                    // minWidth="300"
                    // locked="false"
                    width="280px"
                  />
                  <Column
                    field="endMktVlAmt"
                    title="End Market Value"
                    cell={NumberCell}
                    headerClassName="rightHeader"
                    // minWidth="300"
                    // locked="false"
                    width="280px"
                  />
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </>

      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AssetGrowthAndAllocationChart;
