import { createSlice } from '@reduxjs/toolkit'

export const apicallSlice = createSlice({
  name: 'apicall',
  initialState: {
    value: 0,
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.value += 1
    },
    callactive: (state) => {
        
      state.value = 1;
    },
    callremove:(state)=>{
        
      state.value =0;
    },
    
    
  },
})

// Action creators are generated for each case reducer function
export const { increment, callactive, callremove } = apicallSlice.actions
export const selectapicall = (state) => state.apicall.value
export default apicallSlice.reducer