import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
// import validator from 'validator';
// import Select, { SelectChangeEvent } from "@mui/material/Select";
//import SelectControl from './selectcontrol';
import Loading from "./loading";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { lastDayOfMonth, isEqual } from "@progress/kendo-date-math";
import DailyPerformanceGrid from "./dailyPerformanceGrid";
import Header from "./header";
import { Button, Card } from "@mui/material";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
//import refreshFunction from './refreshFunc';
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
// import { abs } from 'mathjs'
import { CustomCalendar } from "./customCalendar";
import { Dropdown } from "react-bootstrap";
import Enumerable from "linq";

import { FaInfoCircle } from "react-icons/fa";
// import { Popover } from "@progress/kendo-react-tooltip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import BenchmarkSelPeriodPerGrid from "./benchmarkSelPeriodPerGrid";
import { Modal as AntModal, Button as aButton } from "antd";
import { Button as tButton } from "@progress/kendo-react-buttons";
import {
  InputPrefix,
  InputSeparator,
  InputSuffix,
} from "@progress/kendo-react-inputs";
import { FaFilter } from "react-icons/fa";
import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";
import { FormControlLabel, Switch } from "@mui/material";

const BenchmarkSelPeriodPerRpt = () => {
  const [benchmarkSelPerRptData, populateBenchmarkSelPerRptData] = useState([]);
  const [TimeWtdRtnRptRptDataChart, populateTimeWtdRtnRptRptDataChart] =
    useState([]);
  const [reportData, setReportData] = useState([]);
  const [IncepDt, setIncepDt] = useState();
  //const [AcctList, populateAccountListData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [selBenchmark, setSelBenchmark] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(0);
  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);
  const [isColumnSave, setIsColumnSave] = useState(false);
  var date = new Date(localStorage.getItem("processingDate"));
  debugger;
  date.setMonth(date.getMonth() - 1);
  const [frmDate, setfrmDate] = React.useState(date);
  //const [toDate, setToDate] = React.useState(new Date(localStorage.getItem("processingDate")));
  const [asOfDate, setAsOfDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(true);
  const [benchmarkList, setBenchmarkList] = React.useState([]);
  const [flagBenchmark, setFlagBenchmark] = React.useState(false);
  const [isChckedBM, setisChckedBM] = useState(false);
  const [openDuration, setOpenDuration] = React.useState(100);
  const [closeDuration, setCloseDuration] = React.useState(300);
  // const minFrmDt = new Date(2021, 8, 14);
  const [closeOption, setCloseOption] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);

  const maxFrmDt = new Date(localStorage.getItem("processingDate"));
  const minFrmDt = new Date(localStorage.getItem("processingDate"));
  minFrmDt.setMonth(minFrmDt.getMonth() - 60);
  const [isDisabled, setisDisabled] = React.useState(false);
  const [runInAggregate, setRunInAggregate] = useState(false);

  const [initialState, setInitialState] = useState({
    runInAggregate: false,
  });

  let acctId = 0;
  let AccountId = JSON.parse(localStorage.getItem("AcctSelected"));
  if (AccountId != null) {
    acctId = AccountId.acctId;
  }

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  const [monthlyOrDaily, setMonthlyOrDaily] = React.useState(
    acctId.acctId == 0 && JSON.parse(localStorage.getItem("pConsId")) > 0
      ? "Monthly"
      : "Daily"
  );
  const [isDisabledDaily, setisDisabledDaily] = React.useState(
    acctId.acctId == 0 && JSON.parse(localStorage.getItem("pConsId")) > 0
      ? true
      : false
  );
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  //const [CardData, populateTimeWtdRtnRptRptCardData] = useState([]);
  const navigate = useNavigate();

  const [dateType, setDateType] = React.useState(1);
  const [countries, seCountries] = React.useState([]);
  //let countries =

  const [state, setState] = React.useState({
    value: [],
    allSelected: true,
  });

  useEffect(() => {
    // debugger;
    const fetchData = async () => {
      // setLoading(true);
      try {
        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;
        GetBenchmarkList();
        //getColumnStateDb();
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  const GetBenchmarkList = async () => {
    let token = tempToken;

    const postData = {};
    debugger;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post("/RTGetBenchmarkList ", postData, config)
      .then((response) => {
        debugger;

        setBenchmarkList(response.data.results);
        localStorage.setItem(
          "benchmarkList",
          JSON.stringify(response.data.results)
        );
        //  setBenchmarkList(response.data.results);
        let bAll = [
          { blndBnchmrkId: -1, bnchmrkName: "Select All" },
          ...response.data.results,
        ];

        let country = Enumerable.from(bAll)

          .select("$.bnchmrkName")
          .toArray();
        seCountries(country);
        // let bAll = [
        //   { mnrAstTypId: -1, mnrAstTypName: "Select All" },
        //   ...response.data.results,
        // ];

        // let country = Enumerable.from(bAll)

        //   .select("$.mnrAstTypName")
        //   .toArray();

        setState({ value: country, allSelected: true });
        setFlagBenchmark(true);
        console.log(response);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        // return error;
      });
  };

  const handelChkBM = (e) => {
    setisChckedBM(e.target.checked);
  };

  const handleChangemonthlyOrDaily = (e) => {
    setMonthlyOrDaily(e.target.value);
  };
  const handelSubmit = (event) => {
    // debugger;
    //  var date = new Date(asOfDate);
    //  var month =date.getMonth();
    //  var year =date.getFullYear();
    //  var day=date.getDate();
    //var lDay= lastDayOfMonth(new Date(year,month,day));

    //let date = dtfrm.selAcctData;
    setfrmDate(asOfDate);
    //  if(validator.isDate(asOfDate)&&(asOfDate>=minFrmDt)&&asOfDate<=maxFrmDt){
    //
    GetTimeWtdRtnRpteData();
    //  }
  };

  // const formSubmit = (event) => {
  //   GetTimeWtdRtnRpteData();
  // };

  //for multiselect dropdown start
  debugger;
  // let benchmarkAll = benchmarkList; //JSON.parse(localStorage.getItem("benchmarkList"));
  // benchmarkAll = [
  //   { mnrAstTypId: -1, mnrAstTypName: "Select All" },
  //   ...benchmarkAll,
  // ];

  const handlerunInAggregate = () => {
    setRunInAggregate(!runInAggregate);
  };

  const handleChangeBenchmark = (event) => {
    debugger;
    const currentSelectAll = state.value.some((i) => i === "Select All");
    const nextSelectAll = event.value.some((i) => i === "Select All");
    let value = event.value;
    const currentCount = state.value.length;
    const nextCount = value.length;

    if (
      nextCount > currentCount &&
      !currentSelectAll &&
      !nextSelectAll &&
      countries.length - 1 === nextCount
    ) {
      value = countries;
    } else if (
      nextCount < currentCount &&
      currentCount === countries.length &&
      currentSelectAll &&
      nextSelectAll
    ) {
      value = value.filter((v) => v !== "Select All");
    } else if (!currentSelectAll && nextSelectAll) {
      value = countries;
    } else if (currentSelectAll && !nextSelectAll) {
      value = [];
    }
    debugger;
    setState({ value });
  };

  console.log("state", state);
  console.log("state.value.length", state.value.length);

  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span>
        <input
          type="checkbox"
          name={itemProps.dataItem}
          checked={itemProps.selected}
          onChange={(e) => itemProps.onClick(itemProps.index, e)}
          style={{ zIndex: 9999999999 }}
        />
        &nbsp;{li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };
  const value = state.value;
  const selected = value.length;

  //for multiselect dropdown end

  const handleSettings = () => {
    setInitialState({
      runInAggregate,
    });
    setModalVisible(true);
    setCloseOption(true);
  };
  const formSubmit = (event) => {
    debugger;
    event.preventDefault();
    setModalVisible(false);
    setCloseOption(false);
    GetTimeWtdRtnRpteData();
  };

  const GetTimeWtdRtnRpteData = async () => {
    setLoading(true);

    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let asOfId = JSON.parse(localStorage.getItem("userId")); //3689

    let AcctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;
    let PageId = 1;
    let netFeeId = 1;

    var date = new Date(asOfDate);
    var month = date.getMonth();
    var year = date.getFullYear();
    var day = date.getDate();
    debugger;
    var lDay = lastDayOfMonth(new Date(year, month, day));
    var IsDaily = 0;

    // asOfId = 6;
    var pageId = 1;
    debugger;
    var newBenchmarkList = "";

    for (var i = 0; i < state.value.length; i++) {
      if (state.value[i] != "Select All") {
        var arrSelBenchmark = Enumerable.from(
          JSON.parse(localStorage.getItem("benchmarkList"))
        )
          .where("$.bnchmrkName =='" + state.value[i] + "'")
          .select("$.blndBnchmrkId")
          .toArray();
        if (arrSelBenchmark.length > 0)
          newBenchmarkList = newBenchmarkList + "," + arrSelBenchmark[0];
      }
    }
    var benchmarkList = newBenchmarkList;
    // "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,97,105,118,119,120,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157,158,159,160,161,162,163,164,165,166,167,168,169,170,171,172,173,174,175,176,177,178,179,180,181,182,183,184,185,186,187,188,189,190,191,192,193,194,195,196,197,198,199,200,201,202,203,204,205";
    // ("1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,");
    var consolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;
    // if (monthlyOrDaily == "Daily") IsDaily = 0;
    // debugger;
    // //var lDay=lastDayOfMonth(asOfDate);
    if (!isEqual(asOfDate, lDay) && IsDaily == 0) {
      lDay = lastDayOfMonth(new Date(year, month - 1, day));

      setAsOfDate(lDay);
    } else {
      lDay = asOfDate;
      setAsOfDate(lDay);
    }

    // if (lsSelectedAcct !== null) {
    //   AcctId = lsSelectedAcct.acctId;
    // }
    let AsofDate = lDay;

    // let ConsolidationId =
    //   JSON.parse(localStorage.getItem("pConsId")) == null
    //     ? 0
    //     : localStorage.getItem("pConsId");
    // AcctId = JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    let ShowBenchmark = 0;
    let RltnshpCd =
      localStorage.getItem("pRltncd") == null ||
      localStorage.getItem("pRltncd") == undefined
        ? ""
        : localStorage.getItem("pRltncd");

    let AsOfDt = formatDate(lDay, "MM/dd/yyyy");

    if (isChckedBM) ShowBenchmark = 1;
    const postData = {
      asOfId,
      AcctId,
      pageId,
      benchmarkList,
      consolidationId,
      AsOfDt,
      runInAggregate,
    };
    debugger;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post("/RTGetBenchmarkSelPeriodPerf", postData, config)
      .then((response) => {
        debugger;
        const rowData = response.data?.t1 || [];
        let consolidationName = JSON.parse(
          localStorage.getItem("RelationID")
        )?.cnsldtnNm;
        for (let i = 0; i < rowData.length; i++) {
          if (AcctId == 0 && runInAggregate == true) {
            rowData[i].extrnlAcctId = consolidationName;
          }
        }

        populateBenchmarkSelPerRptData(rowData);

        setReportData(rowData);

        getColumnStateDb();
        setLoading(false);
        setFlag(1);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        // return error;
      });
  };

  if (apicallval === 1) {
    GetTimeWtdRtnRpteData();
    dispatch(callremove());
  }

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        debugger;
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetTimeWtdRtnRpteData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 33;

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        console.log("save in db");
        debugger;
        //console.log(response);
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);
        return error;
      });
  };

  const handleChange = (event) => {
    //
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetTimeWtdRtnRpteData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const handleCancel = () => {
    setRunInAggregate(initialState.runInAggregate);
    setModalVisible(false);
    setCloseOption(false);

    !closeOption && navigate("/dashboard");
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            <Loading />
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      {/* <Header></Header> */}
      {/* <div className="my-2">
        <div className="rounded"></div>

        <div className="d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
          <div className="col-md-4 col-lg-4 col-sm-11 text-start">
            <span className="px-2">Account(s)</span>
            <ComboBox
              style={{
                width: "330px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mx-4">
          <p className="reportheading fs-6">
            Benchmark Selected Period Performance Report
          </p>
        </div>
      </div> */}

      <div className="container-fluid bg-white">
        {/* <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="row mt-2 d-flex justify-content-end align-items-center">
            <div className="col">
              <label className="form-check-label px-2">Date</label>
              <DatePicker
                id="dpFrm"
                value={asOfDate}
                className="form-control form-control-sm"
                size={"small"}
                width={150}
                format="MM/dd/yyyy"
                max={maxFrmDt}
                disabled={isDisabled}
                onChange={(e) => {
                  debugger;
                  setAsOfDate(e.value);
                }}
              />

              <input
                type="button"
                className="btn btn-primary btn-sm mx-3"
                onClick={handelSubmit}
                value="submit"
              />
            </div>
          </div>
        </form> */}

        {flagBenchmark && (
          <AntModal
            open={modalVisible}
            title="Customize Benchmark Selected Period Performance Report"
            // onCancel={handleCancel}
            closable={false}
            // maskClosable={false}
            footer={[]}
          >
            <form onSubmit={formSubmit} style={{ padding: "20px" }}>
              <div style={{ marginBottom: "20px" }}>
                <label
                  htmlFor="dpFrm"
                  className="form-label"
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    marginBottom: "5px",
                  }}
                >
                  As Of Date
                </label>
                {/* <label className="form-check-label px-2">Date</label> */}
                <DatePicker
                  id="dpFrm"
                  value={asOfDate}
                  className="form-control form-control-sm"
                  size={"small"}
                  width={150}
                  format="MM/dd/yyyy"
                  max={maxFrmDt}
                  disabled={isDisabled}
                  onChange={(e) => {
                    debugger;
                    setAsOfDate(e.value);
                  }}
                  style={{ width: "100%" }}
                  // valid={
                  //   frmDate <= toDate && toDate <= maxFrmDt && toDate >= minFrmDt
                  // }
                  validationMessage={
                    asOfDate == null
                      ? "Enter Valid Date"
                      : asOfDate < new Date("1/1/1000")
                      ? "Enter date in correct format (mm/dd/yyyy)"
                      : asOfDate > maxFrmDt
                      ? "To date cannot exceed one year from Processing date."
                      : asOfDate < frmDate
                      ? "To date cannot be less than from date"
                      : asOfDate < minFrmDt
                      ? "To date cannot be less than Processing date."
                      : "Validation error"
                  }
                />
              </div>

              <hr></hr>

              <div style={{ marginBottom: "20px" }}>
                <label
                  htmlFor="dpFrm"
                  className="form-label"
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    marginBottom: "5px",
                  }}
                >
                  Benchmark List
                </label>

                <MultiSelect
                  style={{
                    //  zIndex: 99999,
                    width: "400px",
                  }}
                  data={countries}
                  // textField="mnrAstTypName"
                  //dataItemKey="mnrAstTypId"
                  itemRender={itemRender}
                  autoClose={false}
                  value={value}
                  onChange={handleChangeBenchmark}
                  tags={
                    selected > 0
                      ? [
                          {
                            text: `${selected} items selected`,
                            data: [...value],
                          },
                        ]
                      : []
                  }
                  suffix={() => (
                    <>
                      <InputSeparator orientation="vertical" />

                      <InputSuffix orientation="horizontal">
                        <span className="k-input-suffix-text">.com</span>
                      </InputSuffix>
                    </>
                  )}
                />

                {AcctId == 0 && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={runInAggregate}
                        onClick={handlerunInAggregate}
                        name="chkCtr"
                      />
                    }
                    label="Run In Aggregate."
                    style={{ marginBottom: "10px", display: "block" }}
                  />
                )}

                {/* <ComboBox
                style={{
                  width: "330px",
                }}
                data={JSON.parse(localStorage.getItem("benchmarkList"))}
                textField="mnrAstTypName"
                dataItemKey="mnrAstTypId"
                // filterable={true}
                value={selBenchmark}
                // onChange={handleChange}
              /> */}
              </div>
              {/* <hr></hr> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <div
                  className="btn btn-outline-secondary mx-2"
                  onClick={handleCancel}
                >
                  Cancel
                </div>
                <button
                  className="btn btn-primary mx-2"
                  type="submit"
                  disabled={state.value.length === 0}
                >
                  Submit
                </button>
              </div>
            </form>
          </AntModal>
        )}

        <form onSubmit={formSubmit}>
          <div className="row d-flex justify-content-between align-items-center mx-2 py-1 border-bottom">
            {/* Report Heading */}
            <div className="col reportheading fs-6">
              Benchmark Selected Period Performance Report
            </div>
            {/* <div className="col text-center">
              {`From ${new Date(frmDate).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })} To ${new Date(asOfDate).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}`}
            </div> */}

            <div className="col text-end">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={handleSettings}
                title="Click to change filters"
              >
                <FaFilter></FaFilter>
                <span className="px-2">Change Filters</span>
              </button>
            </div>
          </div>
        </form>

        {flag === 1 ? (
          <BenchmarkSelPeriodPerGrid data={benchmarkSelPerRptData} />
        ) : (
          <></>
        )}
      </div>
      <style>
        {`.k-list-content {
          z-index:9999999 !important;
    }`}
      </style>
    </div>
  );
};

export default BenchmarkSelPeriodPerRpt;
