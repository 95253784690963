import React, { Component, useEffect } from 'react';
import './App.css';
import Login from './components/login';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logo.svg';
import {ResponsiveContainer} from 'recharts';

import ReportViewer from './components/ReportViewer';
import Header from './components/header';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
  Outlet,
  useParams,
  NavLink,
  useNavigate,
  useLocation,
  MemoryRouter,
} from "react-router-dom";
import Home from './components/home';
import { useSelector } from 'react-redux';
import { selectTheme } from './features/theme/themeSlice';
import { ConfigProvider ,theme} from 'antd';


const App = () => {
  let urlpath=window.location.pathname ;
  const themes=useSelector(selectTheme);
  debugger;
  console.log(themes);
  useEffect(() => {
   debugger;
  }, []);
debugger;
  return (
    <>
    {themes?
 
    <ConfigProvider theme={themes==="light"?{
      // 1. Use dark algorithm
      algorithm: theme.defaultAlgorithm,
      
      // 2. Combine dark algorithm and compact algorithm
      // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
    }:{algorithm: theme.defaultAlgorithm}}>
 <Login />
    </ConfigProvider>
      :<Login />}
     
     </>
  )
}

export default App

