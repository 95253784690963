import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SelectControl from "./selectcontrol";
import { filterBy } from "@progress/kendo-data-query";
import Loading from "./loading";
import Header from "./header";
import { CustomCalendar } from "./customCalendar";
import PrtflioSnpshtComprsnGrd from "./prtfolioSnpshtCmprsnGrd";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { data } from "jquery";
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal as AntdModal, Button as AntdBtn } from "antd";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { FaCog, FaFilter, FaFirefoxBrowser } from "react-icons/fa";

import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";

import { MenuItem, FormControl, InputLabel } from "@mui/material";
import { formatDate } from "@telerik/kendo-intl";

const PrtflioSnpshtComprsnRpt = () => {
  //
  // alert(localStorage.getItem('psStrtDt'));

  var date = new Date(localStorage.getItem("processingDate"));
  var maxFrmDt = date;

  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [dispAssetClass, setdispAssetClass] = useState("");
  const [PrtflioSnpshtComprsnRptData, populatePrtflioSnpshtComprsnRptData] =
    useState([]);
  const [AstClsColorCodeData, setAstClsColorCodeData] = useState([]);
  const [AcctList, populateAccountListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData")).slice()
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [isColumnSave, setIsColumnSave] = useState(false);
  const [astcls, setAstClass] = useState("0");
  //const [CurrDt, setCurrDt] = useState(JSON.parse(localStorage.getItem('psStrtDt')) === null ? null : new Date(JSON.parse(localStorage.getItem('psStrtDt'))));
  //const [SnpDate, setSnpDate] = useState(JSON.parse(localStorage.getItem('psEndDt')) === null ? null : new Date(JSON.parse(localStorage.getItem('psEndDt'))));
  //const [BusnsDt, setBusinessDate] = useState(JSON.parse(localStorage.getItem('psBusDt')) === null ? null : new Date(JSON.parse(localStorage.getItem('psBusDt'))));
  const [CdtErrMsgHide, setCdtErrMsgHide] = useState(true);
  const [SDtErrMsgHide, setSDtErrMsgHide] = useState(true);
  const [CurrDt, setCurrDt] = useState(
    localStorage.getItem("processingDate") === null
      ? null
      : new Date(localStorage.getItem("processingDate"))
  );
  const [SnpDate, setSnpDate] = useState(
    localStorage.getItem("processingDate") === null
      ? null
      : new Date(localStorage.getItem("processingDate"))
  );
  const [CharCurrDt, setCharCurrDt] = useState(
    localStorage.getItem("processingDate") === null
      ? null
      : new Date(localStorage.getItem("processingDate"))
  );
  const [CharSnpDate, setCharSnpDate] = useState(
    localStorage.getItem("processingDate") === null
      ? null
      : new Date(localStorage.getItem("processingDate"))
  );
  const [BusnsDt, setBusinessDate] = useState(
    localStorage.getItem("processingDate") === null
      ? null
      : new Date(localStorage.getItem("processingDate"))
  );
  const [session, setSession] = useState("");
  const [enableCombo, setEnableCombo] = useState(false);
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(true);
  const [currPortPriceSelOpt, SetCurrPortPriceSelOpt] = useState(1);
  const [currPrice, SetCurrPrice] = React.useState(date);
  const [currPriceFlagInd, setCurrPriceFlagInd] = useState(1);

  const [snapPriceSelOpt, setSnapPriceSelOpt] = useState(1);
  const [snapPrice, setSnapPrice] = useState(date);
  const [snapPriceFlagInd, setSnapPriceFlagInd] = useState(1);

  const [showExcludeAsset, setShowExcludeAsset] = useState(true);
  const [closeOption, setCloseOption] = useState(false);

  const [flag, setFlag] = React.useState(false);

  const [priceExistFlag, setPriceExistFlag] = useState("");

  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  useEffect(() => {
    // alert(localStorage.getItem('psStrtDt'));
    // alert(localStorage.getItem('psEndDt'));
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        //setEmail(email);
        GetPrtflioSnpshtComprsnRptData();
        getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    // fetchData();
  }, []);

  const options = [
    { text: "Major Asset Type", value: 0 },
    { text: "Minor Asset Type", value: 1 },
    { text: "Risk Class", value: 2 },
    { text: "Investment Class", value: 3 },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [runInAggregate, setRunInAggregate] = useState(false);
  const [initialState, setInitialState] = useState({
    selectedOption: options[0],
    showExcludeAsset: true,
    runInAggregate: false,
  });

  const handlerunInAggregate = () => {
    setRunInAggregate(!runInAggregate);
  };

  const handleSelectionChange = (event) => {
    const selectedValue = event.target.value;
    const selectedObj = options.find(
      (option) => option.value === selectedValue
    );
    setSelectedOption(selectedObj);
    console.log("Selected Value:", selectedObj);
  };

  const getColumnStateDb = async () => {
    //Storing column settings in DB
    //
    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 5; //let 5 for Portfolio Snapshot Comparison Report

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        //
        console.log("save in db");
        console.log(response);
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);
        return error;
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        //
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetPrtflioSnpshtComprsnRptData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };
  const GetPrtflioSnpshtComprsnRptData = async () => {
    setLoading(true);
    setEnableCombo(true);

    let token = tempToken;
    //let token = JSON.parse(localStorage.getItem('token'));
    let AsOfId = JSON.parse(localStorage.getItem("userId"));

    let lsSelectedAcct = JSON.parse(localStorage.getItem("AccountID"));
    let AcctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;

    let consolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;
    if (lsSelectedAcct !== null) {
      AcctId = lsSelectedAcct.acctId;
    }

    // let pageId = 1;

    let GrpByMnrAst = selectedOption.value;
    let CurrentDt = formatDate(CurrDt, "MM/dd/yyyy");
    let SnapshotDt = formatDate(SnpDate, "MM/dd/yyyy");

    let portfolioPriceDt = formatDate(currPrice, "MM/dd/yyyy");
    let portfolioPriceFlag = currPriceFlagInd;
    let snapPriceDt = formatDate(snapPrice, "MM/dd/yyyy");
    let snapPriceFlag = snapPriceFlagInd;
    let showExcldAst = showExcludeAsset;

    if (CurrDt !== null) {
      CurrentDt = `${(CurrDt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${CurrDt.getDate()
        .toString()
        .padStart(2, "0")}/${CurrDt.getFullYear()}`;
      localStorage.setItem("psStrtDt", JSON.stringify(CurrDt));
    }
    if (SnpDate !== null) {
      SnapshotDt = `${(SnpDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${SnpDate.getDate()
        .toString()
        .padStart(2, "0")}/${SnpDate.getFullYear()}`;
      localStorage.setItem("psEndDt", JSON.stringify(SnpDate));
    }
    const postData = {
      AsOfId,
      AcctId,
      GrpByMnrAst,
      CurrentDt,
      SnapshotDt,
      consolidationId,
      portfolioPriceDt,
      portfolioPriceFlag,
      snapPriceDt,
      snapPriceFlag,
      showExcldAst,
      runInAggregate,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTPortfolioSnapShotComparison", postData, config)
      .then((response) => {
        debugger;
        const rowData = response.data;
        rowData.t1 = rowData.t1 || [];

        var i = 0;

        let consolidationName = JSON.parse(
          localStorage.getItem("RelationID")
        )?.cnsldtnNm;

        for (i = 0; i < rowData?.t1?.length; i++) {
          if (AcctId == 0 && runInAggregate == true) {
            rowData.t1[i].account = consolidationName;
          }
          if (
            rowData.t1[i].excldInd === 1 &&
            localStorage.getItem("ShwHdeExcldAstVerbag") === "1"
          ) {
            rowData.t1[i].groupName = "^" + rowData.t1[i].groupName;
          }
          if (
            rowData.t1[i].excldInd === 1 &&
            localStorage.getItem("ShwHdeExcldAstVerbag") === "1"
          ) {
            setdispAssetClass("^ Asset Class Contains IM Excluded Asset(s)");
          }
        }
        populatePrtflioSnpshtComprsnRptData(rowData?.t1 || []);
        // populateAccountListData(rowData.t2);
        // setSelAcctData(rowData.t2);
        if (lsSelectedAcct !== null) {
          SetselAcct(lsSelectedAcct);
        } else {
          // SetselAcct(rowData.t2[0]);
          console.log("else is executed");
        }
        // let sDt = new Date(rowData.t3[0].strtDt);
        // if (CurrDt === null) {
        //   setCurrDt(new Date(sDt));
        //   localStorage.setItem(
        //     "psStrtDt",
        //     JSON.stringify(rowData.t3[0].strtDt)
        //   );
        // }
        // alert(sDt);
        // let eDt = new Date(rowData.t3[0].asOfDt);
        // if (SnpDate === null) {
        //   // setSnpDate(new Date(eDt));
        //   localStorage.setItem("psEndDt", JSON.stringify(rowData.t3[0].asOfDt));
        //   // setBusinessDate(new Date(eDt));
        //   localStorage.setItem("psBusDt", JSON.stringify(rowData.t3[0].asOfDt));
        // }

        setAstClsColorCodeData(rowData?.t4 || []);

        setLoading(false);
        setEnableCombo(false);
        setFlag(true);
        setPriceExistFlag(response?.data?.priceValidation);
      })
      .catch((error) => {
        debugger;
        if (error.response.status === 401) {
          console.log("error", error);
          refreshToken();
        }
      });
  };

  if (apicallval === 1) {
    GetPrtflioSnpshtComprsnRptData();
    dispatch(callremove());
  }

  const handleSettings = () => {
    setInitialState({
      selectedOption,
      showExcludeAsset,
      runInAggregate,
    });
    setModalVisible(true);
    setCloseOption(true);
  };

  const formSubmit = (event) => {
    debugger;
    event.preventDefault();
    // setCharCurrDt(CurrDt);
    // setCharSnpDate(SnpDate);
    setModalVisible(false);
    setCloseOption(false);
    localStorage.setItem("psStrtDt", JSON.stringify(CurrDt));
    localStorage.setItem("psEndDt", JSON.stringify(SnpDate));
    GetPrtflioSnpshtComprsnRptData();
  };

  // const GetPrtflioSnpshtComprsnRptDataRdo = async (GrpByMAst) => {
  //   setLoading(true);
  //   setEnableCombo(true);
  //   //
  //   let token = tempToken;
  //   //let token = JSON.parse(localStorage.getItem('token'));
  //   let AsOfId = JSON.parse(localStorage.getItem("userId"));

  //   let lsSelectedAcct = JSON.parse(localStorage.getItem("AccountID"));
  //   let AcctId = JSON.parse(localStorage.getItem("AccountID")) != null
  //   ? JSON.parse(localStorage.getItem("AccountID")).acctId
  //   : 0;

  //   let consolidationId =
  //   JSON.parse(localStorage.getItem("RelationID")) != null
  //     ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
  //     : 0;
  //   if (lsSelectedAcct !== null) {
  //     AcctId = lsSelectedAcct.acctId;
  //   }

  //   // let pageId = 1;
  //   let GrpByMnrAst = GrpByMAst;
  //   let CurrentDt = null,
  //     SnapshotDt = null;
  //   if (CurrDt !== null) {
  //     CurrentDt = `${(CurrDt.getMonth() + 1)
  //       .toString()
  //       .padStart(2, "0")}/${CurrDt.getDate()
  //       .toString()
  //       .padStart(2, "0")}/${CurrDt.getFullYear()}`;
  //   }
  //   if (SnpDate !== null) {
  //     SnapshotDt = `${(SnpDate.getMonth() + 1)
  //       .toString()
  //       .padStart(2, "0")}/${SnpDate.getDate()
  //       .toString()
  //       .padStart(2, "0")}/${SnpDate.getFullYear()}`;
  //   }
  //   const postData = {
  //     AsOfId,
  //     AcctId,
  //     pageId,
  //     GrpByMnrAst,
  //     CurrentDt,
  //     SnapshotDt,
  //   };
  //   const config = {
  //     headers: {
  //       authorization: `Bearer ${token.token}`,
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   await axios
  //     .post("/RTPortfolioSnapShotComparison", postData, config)
  //     .then((response) => {
  //       const rowData = response.data;
  //       var i = 0;

  //       for (i = 0; i < rowData.t1.length; i++) {
  //         if (
  //           rowData.t1[i].excldInd === 1 &&
  //           localStorage.getItem("ShwHdeExcldAstVerbag") === "1"
  //         ) {
  //           rowData.t1[i].groupName = "^" + rowData.t1[i].groupName;
  //         }
  //         if (
  //           rowData.t1[i].excldInd === 1 &&
  //           localStorage.getItem("ShwHdeExcldAstVerbag") === "1"
  //         ) {
  //           setdispAssetClass("^ Asset Class Contains IM Excluded Asset(s)");
  //         }
  //       }
  //       populatePrtflioSnpshtComprsnRptData(rowData.t1);
  //       populateAccountListData(rowData.t2);
  //       setSelAcctData(rowData.t2);
  //       if (lsSelectedAcct !== null) {
  //         SetselAcct(lsSelectedAcct);
  //       } else {
  //         SetselAcct(rowData.t2[0]);
  //       }
  //       let sDt = new Date(rowData.t3[0].strtDt);
  //       if (CurrDt === null) {
  //         setCurrDt(new Date(sDt));
  //         localStorage.setItem(
  //           "psStrtDt",
  //           JSON.stringify(rowData.t3[0].strtDt)
  //         );
  //       }
  //       // alert(sDt);
  //       let eDt = new Date(rowData.t3[0].asOfDt);
  //       if (SnpDate === null) {
  //         setSnpDate(new Date(eDt));
  //         localStorage.setItem("psEndDt", JSON.stringify(rowData.t3[0].asOfDt));
  //         setBusinessDate(new Date(eDt));
  //         localStorage.setItem("psBusDt", JSON.stringify(rowData.t3[0].asOfDt));
  //       }
  //       setAstClsColorCodeData(rowData.t4);
  //       setFlag(true);
  //       setLoading(false);
  //       setEnableCombo(false);
  //     })
  //     .catch((error) => {
  //       if (error.response.status === 401) {
  //         refreshToken();
  //       }
  //     });
  // };

  const handleExcludeAsset = (e) => {
    setShowExcludeAsset(e.target.checked ? true : false);
  };

  const filterData = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };

  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };

  const handleChange = (event) => {
    //
    if (event.target.value === null) {
      SetselAcct(JSON.parse(localStorage.getItem("acctData"))[0]);
      //GetUpdatedAccountProfile(0);
      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem(
        "AcctSelected",
        JSON.stringify(JSON.parse(localStorage.getItem("acctData"))[0])
      );
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      console.log(event.target.value);
      GetPrtflioSnpshtComprsnRptData();
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  // const assetClassChange = (e) => {
  //   if (astcls !== e.target.value) {
  //     setAstClass(e.target.value);
  //     GetPrtflioSnpshtComprsnRptDataRdo(e.target.value);
  //   }
  // };

  const searchClick = () => {
    if (CdtErrMsgHide && SDtErrMsgHide) {
      setCdtErrMsgHide(true);
      setSDtErrMsgHide(true);
      GetPrtflioSnpshtComprsnRptData();
    }
  };

  function setFromDate(val) {
    const cDt = `${val.getDate()}-${val.getMonth()}-${val.getFullYear()}`;
    // const sDt = `${SnpDate.getDate()}-${SnpDate.getMonth()}-${SnpDate.getFullYear()}`;
    if (val <= SnpDate) {
      setCurrDt(val);
      setCdtErrMsgHide(true);
      setSDtErrMsgHide(true);
      // localStorage.setItem('psStrtDt', JSON.stringify(val));
    } else {
      setCurrDt(val);
      setCdtErrMsgHide(false);
    }
  }
  function setAsOfDate(val) {
    // const sDt = `${val.getDate()}-${val.getMonth()}-${val.getFullYear()}`;
    const cDt = `${CurrDt.getDate()}-${CurrDt.getMonth()}-${CurrDt.getFullYear()}`;
    if (CurrDt <= val) {
      setSnpDate(val);
      setSDtErrMsgHide(true);
      setCdtErrMsgHide(true);
      // localStorage.setItem('psEndDt', JSON.stringify(val));
    } else {
      setSnpDate(val);
      setSDtErrMsgHide(false);
    }
  }

  const currPortPriceHandle = (e) => {
    SetCurrPortPriceSelOpt(parseInt(e.target.value));
    setCurrPriceFlagInd(parseInt(e.target.value));
    console.log("e.target.value", e.target.value);
    console.log("e", e);
    if (e.target.value == 1 || e.target.value == 2) {
      SetCurrPrice(date); // to be checked
    }
    // console.log("currPortPriceSelOpt", currPortPriceSelOpt);
    // console.log("Selected Price Date", currPrice);
  };

  const snapPriceHandle = (e) => {
    setSnapPriceSelOpt(parseInt(e.target.value));
    setSnapPriceFlagInd(parseInt(e.target.value));
    console.log("e.target.value", e.target.value);
    console.log("e", e);
    if (e.target.value == 1 || e.target.value == 2) {
      setSnapPrice(date); // to be checked
    }
    // console.log("currPortPriceSelOpt", currPortPriceSelOpt);
    // console.log("Selected Price Date", currPrice);
  };

  function setPrcDt(val) {
    let newdate = new Date(val);
    if (newdate <= new Date(localStorage.getItem("processingDate")))
      SetCurrPrice(val);
    else {
      SetCurrPrice(new Date(localStorage.getItem("processingDate")));
    }
    SetCurrPrice(val);
  }

  const handleCancel = () => {
    setSelectedOption(initialState.selectedOption);
    setShowExcludeAsset(initialState.showExcludeAsset);
    setRunInAggregate(initialState.runInAggregate);

    setModalVisible(false);
    setCloseOption(false);
    !closeOption && navigate("/dashboard");
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            {/* <div className="row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
              <div className="subheader text-end col-md-1">
                {" "}
                &nbsp; Account(s):
              </div>
              <div className="col-md-3 text-start">
                <ComboBox
                  style={{
                    width: "350px",
                  }}
                  data={selAcctData}
                  textField="extrnlAcctId"
                  dataItemKey="acctId"
                  filterable={true}
                  value={selAcct}
                  onChange={handleChange}
                  onFilterChange={filterChange}
                  disabled={enableCombo}
                />
              </div>
            </div> */}
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    <div style={{ overflow: "hidden" }}>
      {/* <Header></Header>
      <div className="row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
        <div className="col-md-4 col-lg-5 col-sm-11 text-start">
          <span className="px-2">Account(s)</span>
          <ComboBox
            style={{
              width: "350px",
            }}
            data={selAcctData}
            textField="extrnlAcctId"
            dataItemKey="acctId"
            filterable={true}
            value={selAcct}
            onChange={handleChange}
            onFilterChange={filterChange}
            disabled={enableCombo}
          />
        </div>
      </div> */}
      <div>
        {/* <form onSubmit={formSubmit}>

          <div className="row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 rounded">
            <div className="col">
              <div className="form-check mt-1 k-text-center py-2 mb-2">
                <div
                  className="btn-group btn-group-sm"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <input
                    type="radio"
                    defaultChecked={astcls === "0"}
                    value="0"
                    className="btn-check form-check-input"
                    name="astcls"
                    id="mjrAstTyp"
                    onChange={assetClassChange}
                  />
                  <label
                    className="btn btn-outline-primary btn-sm"
                    htmlFor="mjrAstTyp"
                  >
                    Major Asset
                  </label>

                  <input
                    type="radio"
                    value="1"
                    defaultChecked={astcls === "1"}
                    className="btn-check form-check-input"
                    name="astcls"
                    id="mnrAstTyp"
                    onChange={assetClassChange}
                  />
                  <label
                    className="btn btn-outline-primary btn-sm"
                    htmlFor="mnrAstTyp"
                  >
                    Minor Asset
                  </label>
                </div>
              </div>
            </div>
           
            
            <div className="col">
              <div className="text-start">
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Submit"
                />
              </div>
            </div>
          </div>
          
        </form> */}

        <div className="row d-flex justify-content-start align-items-center my-1 px-2 mx-2 shadow-sm rounded">
          <div className="col reportheading fs-6">
            Portfolio Snapshot Comparison Report
          </div>

          {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}

          {/* Filter Button */}
          <div className="col text-end">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={handleSettings}
              title="Click to change filters"
            >
              <FaFilter />
              <span className="px-2">Change Filters</span>
            </button>
          </div>
        </div>

        <AntdModal
          open={modalVisible}
          title="Customize Portfolio Snapshot Comparison Report"
          closable={false}
          footer={[]}
        >
          <form onSubmit={formSubmit} style={{ padding: "4px 20px 20px 20px" }}>
            <h6>Date Selection</h6>

            <div className="col">
              <span className="py-1">Current Date</span>
              <div className="text-start">
                <DatePicker
                  id="dpFrm"
                  value={CurrDt}
                  calendar={CustomCalendar}
                  // max={
                  //   new Date(
                  //     `${(SnpDate === null
                  //       ? BusnsDt
                  //       : SnpDate
                  //     ).getFullYear()},${(
                  //       (SnpDate === null ? BusnsDt : SnpDate).getMonth() + 1
                  //     )
                  //       .toString()
                  //       .padStart(2, "0")},${(SnpDate === null
                  //       ? BusnsDt
                  //       : SnpDate
                  //     )
                  //       .getDate()
                  //       .toString()
                  //       .padStart(2, "0")}`
                  //   )
                  // }
                  max={maxFrmDt}
                  min={new Date("1/1/1000")}
                  onChange={(e) => {
                    setCurrDt(e.value);
                  }}
                  valid={CurrDt != null && CurrDt <= maxFrmDt}
                  validationMessage={
                    CurrDt == null
                      ? "Enter Valid Date"
                      : CurrDt < new Date("1/1/1000")
                      ? "Enter date in correct format (mm/dd/yyyy)"
                      : CurrDt > maxFrmDt
                      ? "Current Date cannot exceed the processing date"
                      : ""
                  }

                  // valid={
                  //   CurrDt <=
                  //     new Date(
                  //       `${(SnpDate === null
                  //         ? BusnsDt
                  //         : SnpDate
                  //       ).getFullYear()},${(
                  //         (SnpDate === null ? BusnsDt : SnpDate).getMonth() + 1
                  //       )
                  //         .toString()
                  //         .padStart(2, "0")},${(SnpDate === null
                  //         ? BusnsDt
                  //         : SnpDate
                  //       )
                  //         .getDate()
                  //         .toString()
                  //         .padStart(2, "0")}`
                  //     ) &&
                  //   CurrDt != null &&
                  //   CurrDt <= SnpDate &&
                  //   CurrDt > new Date("1/1/1000")
                  // }
                />
              </div>
            </div>

            <div className="col">
              <div className="text-start">
                <span className="py-1">Snapshot Date</span>
                <DatePicker
                  defaultValue={SnpDate}
                  calendar={CustomCalendar}
                  // max={
                  //   new Date(
                  //     `${BusnsDt.getFullYear()},${(BusnsDt.getMonth() + 1)
                  //       .toString()
                  //       .padStart(2, "0")},${BusnsDt.getDate()
                  //       .toString()
                  //       .padStart(2, "0")}`
                  //   )
                  // }
                  max={maxFrmDt}
                  onChange={(e) => {
                    setSnpDate(e.value);
                  }}
                  valid={SnpDate != null && SnpDate <= maxFrmDt}
                  // valid={
                  //   CurrDt <=
                  //     new Date(
                  //       `${(SnpDate === null
                  //         ? BusnsDt
                  //         : SnpDate
                  //       ).getFullYear()},${(
                  //         (SnpDate === null ? BusnsDt : SnpDate).getMonth() + 1
                  //       )
                  //         .toString()
                  //         .padStart(2, "0")},${(SnpDate === null
                  //         ? BusnsDt
                  //         : SnpDate
                  //       )
                  //         .getDate()
                  //         .toString()
                  //         .padStart(2, "0")}`
                  //     ) &&
                  //   CurrDt != null &&
                  //   CurrDt <= SnpDate &&
                  //   CurrDt > new Date("1/1/1000")
                  // }
                  validationMessage={
                    SnpDate == null
                      ? "Enter Valid Date"
                      : SnpDate < new Date("1/1/1000")
                      ? "Enter date in correct format (mm/dd/yyyy)"
                      : SnpDate > maxFrmDt
                      ? "Snapshot Date cannot exceed the processing date"
                      : ""
                  }
                />
              </div>
            </div>

            {/* Current Portfolio Price Options */}
            <h6 className="pt-2">Current Portfolio Price Selection</h6>
            <div style={{ marginBottom: "20px" }}>
              {[1, 2, 3].map((option) => (
                <label
                  key={option}
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  <input
                    type="radio"
                    value={option}
                    checked={currPortPriceSelOpt === option}
                    onChange={currPortPriceHandle}
                  />
                  {option === 1
                    ? " Use Current Price"
                    : option === 2
                    ? " Use End of Month Price"
                    : " Use Non EOM Price"}
                </label>
              ))}
            </div>

            {/* Conditional Price Date Field */}
            {currPortPriceSelOpt === 3 && (
              <div style={{ marginBottom: "20px" }}>
                <label
                  htmlFor="dpPriceDt"
                  className="form-label"
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    marginBottom: "5px",
                  }}
                >
                  Price Date
                </label>
                <DatePicker
                  id="dpPriceDt"
                  value={currPrice}
                  calendar={CustomCalendar}
                  min={new Date("1/1/1000")}
                  max={maxFrmDt}
                  onChange={(e) => SetCurrPrice(e.value)}
                  style={{ width: "100%" }}
                  validationMessage={
                    currPrice == null
                      ? "Enter a valid date"
                      : currPrice < new Date("1/1/1000")
                      ? "Enter a date in the correct format (mm/dd/yyyy)"
                      : currPrice > maxFrmDt
                      ? "Price Date cannot exceed the Processing Date"
                      : ""
                  }
                />
              </div>
            )}

            <hr></hr>

            {/* Snapshot Price Options */}
            <h6 className="pt-2">Snapshot Price Selection</h6>
            <div style={{ marginBottom: "20px" }}>
              {[1, 2, 3].map((option) => (
                <label
                  key={option}
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  <input
                    type="radio"
                    value={option}
                    checked={snapPriceSelOpt === option}
                    onChange={snapPriceHandle}
                  />
                  {option === 1
                    ? " Use Current Price"
                    : option === 2
                    ? " Use End of Month Price"
                    : " Use Non EOM Price"}
                </label>
              ))}
            </div>

            {/* Conditional Price Date Field */}
            {snapPriceSelOpt === 3 && (
              <div style={{ marginBottom: "20px" }}>
                <label
                  htmlFor="dpPriceDt"
                  className="form-label"
                  style={{
                    display: "block",
                    fontWeight: "bold",
                    marginBottom: "5px",
                  }}
                >
                  Snapshot Price Date
                </label>
                <DatePicker
                  id="dpPriceDt"
                  value={snapPrice}
                  // calendar={CustomCalendar}
                  min={new Date("1/1/1000")}
                  max={maxFrmDt}
                  onChange={(e) => setSnapPrice(e.value)}
                  style={{ width: "100%" }}
                  validationMessage={
                    snapPrice == null
                      ? "Enter a valid date"
                      : snapPrice < new Date("1/1/1000")
                      ? "Enter a date in the correct format (mm/dd/yyyy)"
                      : snapPrice > maxFrmDt
                      ? "Price Date cannot exceed the Processing Date"
                      : ""
                  }
                />
              </div>
            )}

            <hr></hr>

            <h6>Other Selections</h6>
            <label>Display Selections : </label>
            <br />
            <br />
            <div>
              <FormControl fullWidth>
                <InputLabel>Asset Type</InputLabel>
                <Select
                  value={selectedOption.value}
                  label="Asset Type"
                  onChange={handleSelectionChange}
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* {selectedOption && (
        <div>
          <h4>Selected Value: {selectedOption.value}</h4>
          <p>Text: {selectedOption.text}</p>
        </div>
      )} */}
            </div>

            {/* Miscellaneous Section */}
            <h6>Miscellaneous Section</h6>
            <div style={{ marginBottom: "20px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showExcludeAsset}
                    onChange={handleExcludeAsset}
                    name="excludeAsset"
                  />
                }
                label=" Show Excluded Assets"
                style={{ marginBottom: "10px", display: "block" }}
              />

              {AcctId == 0 && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={runInAggregate}
                      onClick={handlerunInAggregate}
                      name="chkCtr"
                    />
                  }
                  label="Run In Aggregate."
                  style={{ marginBottom: "10px", display: "block" }}
                />
              )}
              {/* <FormControlLabel
              control={
                <Switch
                  checked={showAllPositionsIndic}
                  onChange={handleAllPositionsIndicator}
                  name="allPositions"
                />
              }
              label=" Show All Positions"
              style={{ marginBottom: "10px", display: "block" }}
            /> */}
            </div>

            {/* Actions */}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div
                className="btn btn-outline-secondary mx-2"
                onClick={handleCancel}
              >
                Cancel
              </div>
              <button className="btn btn-primary mx-2" type="submit">
                Submit
              </button>
            </div>
          </form>
        </AntdModal>
      </div>
      {flag ? (
        <PrtflioSnpshtComprsnGrd
          data={PrtflioSnpshtComprsnRptData}
          astColorModel={AstClsColorCodeData}
          flag={isColumnSave}
          dispAssetClass={dispAssetClass}
          priceExistFlag={priceExistFlag}
          selectedOption={selectedOption}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default PrtflioSnpshtComprsnRpt;
