import React from "react";
import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SelectControl from "./selectcontrol";
import Dropdown from "react-bootstrap/Dropdown";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { AiFillSetting } from "react-icons/ai";
import { filterBy } from "@progress/kendo-data-query";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import Modal from "react-bootstrap/Modal";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { useLocation, useNavigate } from "react-router-dom";

import { FcExpired } from "react-icons/fc";
import Loading from "./loading";
import Header from "./header";
import DateRange from "./dateRange";
import AcctTransactionGrid from "./acctTransactionGrid";
import { formatDate, parseDate } from "@telerik/kendo-intl";
import { Button } from "react-bootstrap";
import { Modal as AntModal } from "antd";
import { FaFilter } from "react-icons/fa";

import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";

const moment = require("moment");
// import "@progress/kendo-theme-material/dist/all.css";
//import "@progress/kendo-theme-default/dist/all.css";
const AcctTransactionRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [AcctTransactionRptData, populateAcctTransactionRptData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [effectivecDtInd, seteffectiveDtInd] = useState(true);
  const [excludeSuppress, setexcludeSuppress] = useState(false);
  const [excludeSweep, setexcludeSweep] = useState(false);
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData")).slice()
  );
  const [session, setSession] = useState("");
  var date = new Date(localStorage.getItem("processingDate"));
  const navigate = useNavigate();
  date.setMonth(date.getMonth() - 12);
  const [frmDate, setfrmDate] = React.useState(date);
  const [isClicked, setIsClicked] = useState(
    localStorage.getItem("isSearchClicked")
  );
  const [searchData, setSearchData] = useState(
    JSON.parse(localStorage.getItem("searchedData"))
  );
  const [firstFlag, setFirstFlag] = useState(0);
  const [isDisabled, setisDisabled] = React.useState(false);
  const [toDate, setToDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const [flag, setFlag] = React.useState(false);
  var minDate = new Date(localStorage.getItem("processingDate"));
  minDate.setMonth(minDate.getMonth() - 60);
  var maxDate = new Date(localStorage.getItem("processingDate"));
  //maxDate.setMonth(maxDate.getMonth());
  const [minFrmDt, setminFrmDt] = React.useState(minDate);
  const [maxFrmDt, setmaxFrmDt] = React.useState(maxDate);
  const [reportData, setReportData] = useState([]);
  // const minFrmDt = new Date(2021,8,13);
  // const maxFrmDt = new Date(2022,8,14);
  const [enableCombo, setEnableCombo] = useState(false);
  const [closeOption, setCloseOption] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);

  const apicallval = useSelector(selectapicall);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        //setEmail(email);
        GetAcctTransactionData();

        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    // fetchData();
  }, []);

  function setDate(val) {
    if (val == null) {
      var newToDate = new Date(frmDate);
      newToDate.setMonth(newToDate.getMonth() + 12);
      setfrmDate(frmDate);
      if (newToDate >= new Date(localStorage.getItem("processingDate"))) {
        setToDate(new Date(localStorage.getItem("processingDate")));
      } else {
        setToDate(newToDate);
      }
    } else {
      var newToDate = new Date(val);
      newToDate.setMonth(val.getMonth() + 12);
      setfrmDate(val);
      if (newToDate >= new Date(localStorage.getItem("processingDate"))) {
        setToDate(new Date(localStorage.getItem("processingDate")));
      } else {
        setToDate(newToDate);
      }
    }
  }
  function setTDate(val) {
    if (val == null) {
      var newStartDate = new Date(toDate);
      newStartDate.setMonth(newStartDate.getMonth() - 12);
      setToDate(toDate);
      //setfrmDate(newStartDate);
    } else {
      var newStartDate = new Date(val);
      newStartDate.setMonth(val.getMonth() - 12);
      setToDate(val);
      setfrmDate(newStartDate);
    }
  }

  // Handle modal close actions
  const handleCancel = () => {
    setModalVisible(false);
    setCloseOption(false);

    !closeOption && navigate("/dashboard");
  };

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        console.log("Token value in refresh token:", response.data);
        localStorage.setItem("token", JSON.stringify(response.data));

        GetAcctTransactionData();
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }

        console.log("my error is " + error);
      });
  };
  const handleSettings = () => {
    setModalVisible(true);
    setCloseOption(true);
  };
  const formSubmit = (event) => {
    debugger;
    event.preventDefault();
    setModalVisible(false);
    setCloseOption(false);
    GetAcctTransactionData();
  };
  const handleEffectiveDtInd = (e) => {
    seteffectiveDtInd(e.target.checked);
  };
  const handleExcludeSuppress = (e) => {
    setexcludeSuppress(e.target.checked);
  };
  const handleExcludeSweep = (e) => {
    setexcludeSweep(e.target.checked);
  };

  const GetAcctTransactionData = async () => {
    debugger;

    setLoading(true);
    setEnableCombo(true);
    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let userId = JSON.parse(localStorage.getItem("userId"));
    let acctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;
    console.log("acctId", acctId);
    //let startDate = "09/13/2021";
    //let startDate= (localStorage.getItem('processingDate'));
    let startDate = frmDate;
    let endDate = toDate;
    let EffectiveDtInd = effectivecDtInd;
    let ExcludeSupp = excludeSuppress;

    //

    let SwpInd = excludeSweep;

    let RltnshpCd = "";
    let mConsolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;
    let PageNo = 0;

    //

    //s
    // startDate.setDate(startDate.getDate());
    // endDate.setDate(endDate.getDate());

    startDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    endDate = moment(endDate).format("YYYY-MM-DD HH:mm:ss");

    const postData = {
      userId,
      startDate,
      endDate,
      acctId,
      mConsolidationId,
      EffectiveDtInd,
      ExcludeSupp,
      SwpInd,
      RltnshpCd,
      PageNo,
    };

    // const postData = {userId, startDate,endDate,acctId,EffectiveDtInd,ExcludeSupp };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    console.log(postData);

    await axios
      .post("/AcctTransactDateRange", postData, config)
      .then((response) => {
        console.log("Transaction", response.data);

        const rowData = response.data;
        localStorage.setItem("EndingBal", rowData.endingBal);
        localStorage.setItem("acctType", rowData.accountType || "  ");
        localStorage.setItem("Administrator", rowData.administrator || "  ");
        populateAcctTransactionRptData(rowData.ocAcctTransaction);
        // for (let i = 0; i < rowData.ocAcctTransaction.length; i++) {
        //   rowData.ocAcctTransaction[i].accountName =
        //     rowData.ocAcctTransaction[i].accountName.split(":")[1];
        // }
        setReportData(rowData);
        setFirstFlag(1);
        setFlag(true);
        setLoading(false);
        setEnableCombo(false);
        for (var i = 0; i < rowData.ocAcctTransaction.length; i++) {
          // // rowData.ocAcctTransaction[i].prcsDt=formatDate(new Date(rowData.ocAcctTransaction[i].prcsDt),'d');
          //  let cdt = new Date(rowData.ocAcctTransaction[i].prcsDt);
          //   let edt= new Date(rowData.ocAcctTransaction[i].effectiveDate);
          //   let endt= new Date(rowData.ocAcctTransaction[i].entryDate);
          //  // rowData.ocAcctTransaction[i].prcsDt=(cdt.getMonth()+1).toString().padStart(2, '0')/cdt.getDate().toString().padStart(2, '0')/cdt.getFullYear();
          // rowData.ocAcctTransaction[i].prcsDt=new Date(cdt.getFullYear(),cdt.getDate(),cdt.getMonth());
          // rowData.ocAcctTransaction[i].effectiveDate=new Date(edt.getFullYear(),edt.getDate(),edt.getMonth());
          // rowData.ocAcctTransaction[i].entryDate=new Date(endt.getFullYear(),endt.getDate(),endt.getMonth());
          rowData.ocAcctTransaction[i].prcsDt = parseDate(
            formatDate(rowData.ocAcctTransaction[i].prcsDt, "MM/dd/yyyy")
          );

          rowData.ocAcctTransaction[i].effectiveDate = parseDate(
            formatDate(rowData.ocAcctTransaction[i].effectiveDate, "MM/dd/yyyy")
          );

          rowData.ocAcctTransaction[i].entryDate = parseDate(
            formatDate(rowData.ocAcctTransaction[i].entryDate, "MM/dd/yyyy")
          );

          rowData.ocAcctTransaction[i].tradeDate = parseDate(
            formatDate(rowData.ocAcctTransaction[i].tradeDate, "MM/dd/yyyy")
          );
        }

        localStorage.setItem(
          "filterData",
          JSON.stringify(rowData.ocAcctTransaction)
        );

        localStorage.removeItem("TransactDataState");
        console.log(i);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        //return error;
      });
  };

  if (apicallval === 1) {
    GetAcctTransactionData();
    dispatch(callremove());
  }

  const filterChange = (event) => {
    setSelAcctData(filterData1(event.filter));
  };
  const filterData1 = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };
  const handleChange = (event) => {
    if (event.target.value === null) {
      SetselAcct(selAcct);
      //GetUpdatedAccountProfile(0);
      localStorage.setItem("IsAcctSelected", false);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));

      GetAcctTransactionData();
      console.log(selAcct);

      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };
  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            <div></div>

            {!modalVisible && <Loading />}
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      {/* <Header /> */}

      {/* Reusable Modal */}
      <AntModal
        open={modalVisible}
        title="Customize Transactions Report"
        // onCancel={handleCancel}
        closable={false}
        // maskClosable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpFrm"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              From
            </label>
            <DatePicker
              id="dpFrm"
              value={frmDate}
              // calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={isDisabled}
              style={{ width: "100%" }}
              valid={
                frmDate >= minFrmDt && frmDate <= maxFrmDt && frmDate != null
              }
              onChange={(e) => setfrmDate(e.value)}
              validationMessage={
                frmDate == null
                  ? "Enter Valid Date"
                  : frmDate < new Date("1/1/1000")
                  ? "Enter date in correct format (mm/dd/yyyy)"
                  : frmDate > maxFrmDt
                  ? "From date cannot exceed one year from Processing date."
                  : frmDate > toDate
                  ? "From date cannot exceed to date."
                  : frmDate < minFrmDt
                  ? "From date cannot be less than Processing date."
                  : "Validation error"
              }
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpTo"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              To
            </label>
            <DatePicker
              id="dpTo"
              value={toDate}
              // format="MM/dd/yyyy"
              // calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={false}
              onChange={(e) => {
                setToDate(e.value);
              }}
              style={{ width: "100%" }}
              valid={
                frmDate <= toDate && toDate <= maxFrmDt && toDate >= minFrmDt
              }
              validationMessage={
                toDate == null
                  ? "Enter Valid Date"
                  : toDate < new Date("1/1/1000")
                  ? "Enter date in correct format (mm/dd/yyyy)"
                  : toDate > maxFrmDt
                  ? "To date cannot exceed one year from Processing date."
                  : toDate < frmDate
                  ? "To date cannot be less than from date"
                  : toDate < minFrmDt
                  ? "To date cannot be less than Processing date."
                  : "Validation error"
              }
            />
          </div>
          <hr></hr>
          <h6>Miscellaneous Section</h6>

          <div className="form-group" style={{ marginBottom: "20px" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={effectivecDtInd}
                  onClick={handleEffectiveDtInd}
                  name="chkCtr"
                />
              }
              label="Effective Date Indicator"
              style={{ marginBottom: "10px", display: "block" }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={excludeSuppress}
                  onClick={handleExcludeSuppress}
                  name="chkCtr"
                />
              }
              label="Exclude Suppress Transactions"
              style={{ marginBottom: "10px", display: "block" }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={excludeSweep}
                  onClick={handleExcludeSweep}
                  name="chkCtr"
                />
              }
              label="Exclude Sweep Transactions"
              style={{ marginBottom: "10px", display: "block" }}
            />
          </div>

          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntModal>
      <form onSubmit={formSubmit}>
        <div className="row d-flex justify-content-between align-items-center mx-2 py-1 border-bottom">
          {/* Report Heading */}
          <div className="col reportheading fs-6">Transactions Report</div>
          <div className="col text-center">
            {`From ${new Date(frmDate).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })} To ${new Date(toDate).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}`}
          </div>

          <div className="col text-end">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={handleSettings}
              title="Click to change filters"
            >
              <FaFilter></FaFilter>
              <span className="px-2">Change Filters</span>
            </button>
          </div>
        </div>
      </form>
      {flag ? (
        <AcctTransactionGrid
          data={AcctTransactionRptData}
          reportData={reportData}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AcctTransactionRpt;
