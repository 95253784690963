import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
import SelectControl from "./selectcontrol";
import { filterBy } from "@progress/kendo-data-query";
import { Modal as AntdModal, Button as AntdBtn } from "antd";
import { FaFilter } from "react-icons/fa";
import Enumerable from "linq";
import { formatDate, numberSymbols } from "@telerik/kendo-intl";
import Loading from "./loading";
import { CustomCalendar } from "./customCalendar";
import Header from "./header";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import FixedIncomePortfolioOverviewGrid from "./fixedIncomePortfolioOverviewGrid";
// import "@progress/kendo-theme-material/dist/all.css";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
//import "@progress/kendo-theme-default/dist/all.css";
import data from "./selectcontrol";
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { Col, Row } from "react-bootstrap";
const FixedIncomePortfolioOverviewRpt = () => {
  const [table1, setTableChart1] = useState([]);
  const [table2, setTableChart2] = useState([]);
  const [table3, setTableChart3] = useState([]);
  const [table4, setTableChart4] = useState([]);
  const [table5, setTableChart5] = useState([]);
  const [closeOption, setCloseOption] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [fromPriceDt, setFromPriceDt] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  var date = new Date(localStorage.getItem("processingDate"));
  date.setMonth(date.getMonth() - 12);
  const [showExcludeAsset, setShowExcludeAsset] = useState(true);
  const [bandYrFlag, setbandYearFlag] = useState(1);
  const [shwPrpsdTrd, setshwPrpsdTrds] = useState(true);
  const [shwBondByIndSctr, setshwBondByIndSctr] = useState(true);

  const [frmDate, setfrmDate] = React.useState(date);
  const [fromPriceFlagInd, setFromPriceFlagInd] = useState(1);
  var minDate = new Date(localStorage.getItem("processingDate"));
  minDate.setMonth(minDate.getMonth() - 24);
  var maxDate = new Date(localStorage.getItem("processingDate"));
  const [minFrmDt, setminFrmDt] = React.useState(minDate);
  const [maxFrmDt, setmaxFrmDt] = React.useState(maxDate);
  const [fromPriceDtSelOpt, setFromPriceDtSelOpt] = useState(1);
  const [typeval, settypeval] = useState({
    typeId: "I",
    typeNm: "Investment Class Type",
  });
  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData")).slice()
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const displaytype = [
    { typeId: "I", typeNm: "Investment Class Type" },
    { typeId: "N", typeNm: "Minor Asset Type" },
  ];
  const [
    FixedIncomePortfolioOverviewRptData,
    populateFixedIncomePortfolioOverviewRptData,
  ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setloaded] = useState(false);
  const [enableCombo, setEnableCombo] = useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const navigate = useNavigate();
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     setEnableCombo(true);
  //     try {
  //       //let data = location.state;

  //       let AsOfId = JSON.parse(localStorage.getItem("userId")); // data.Email;

  //       //setEmail(email);
  //       FixedIncomePortfolioOverviewData();

  //       //  console.log(data);
  //     } catch (error) {
  //       console.error(error.message);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const filterData = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };

  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };
  const handleChange = (event) => {
    if (event.target.value === null) {
      SetselAcct(JSON.parse(localStorage.getItem("acctData"))[0]);
      //GetUpdatedAccountProfile(0);
      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem(
        "AcctSelected",
        JSON.stringify(JSON.parse(localStorage.getItem("acctData"))[0])
      );
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));

      FixedIncomePortfolioOverviewData(event.target.value.acctId);
    }
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        FixedIncomePortfolioOverviewData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };
  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  function setDate(val) {
    //setfrmDate(val);
    if (val == null) {
      setfrmDate(frmDate);
    } else {
      setfrmDate(val);
    }
  }
  const fromPriceDtHandleRadioChange = (e) => {
    setFromPriceDtSelOpt(parseInt(e.target.value));
    setFromPriceFlagInd(parseInt(e.target.value));

    // console.log("fromPriceDtSelOpt", fromPriceDtSelOpt);
    // console.log("Selected Price Date", fromPriceDt);
  };
  const FixedIncomePortfolioOverviewData = async () => {
    setLoading(true);
    setloaded(false);
    setEnableCombo(true);
    // "asOfId": 0,
    // "acctId": 0,
    // "consolidationId": 0,
    // "asOfDt": "string",
    // "priceDt": "string",
    // "priceFlag": 0,
    // "bandYearFlag": true,
    // "showExcldAst": true,
    // "shwPrpsdTrds": true,
    // "shwBondByIndSct": true,
    // "shwAssetType":.
    //  "string"

    let token = JSON.parse(localStorage.getItem("token"));
    let AsOfId = JSON.parse(localStorage.getItem("userId"));
    let lsSelectedAcct = JSON.parse(localStorage.getItem("AccountID")); // JSON.parse(localStorage.getItem("AcctSelected"));
    let acctId = lsSelectedAcct != null ? lsSelectedAcct.acctId : 0;
    let consolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;
    let asOfDt = formatDate(frmDate, "MM/dd/yyyy");
    let priceDt = formatDate(fromPriceDt, "MM/dd/yyyy");
    let priceFlag = fromPriceFlagInd;
    let showExcldAst = showExcludeAsset;
    let bandYearFlag = bandYrFlag == 1 ? true : false;
    let shwPrpsdTrds = shwPrpsdTrd;
    let shwBondByIndSct = shwBondByIndSctr;
    let shwAssetType = typeval.typeId;
    let runInAggregate = true;

    //  AsOfId=1744;
    const postData = {
      AsOfId,
      acctId,
      consolidationId,
      asOfDt,
      priceDt,
      priceFlag,
      bandYearFlag,
      showExcldAst,
      shwPrpsdTrds,
      shwBondByIndSct,
      shwAssetType,
      runInAggregate,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    // console.log("Accept", application);
    // console.log("Content-Type", pageId);
    await axios
      .post("/FixedIncomePortfolioOverview", postData, config)
      .then((response) => {
        //  console.log(response);
        debugger;
        console.log("FixedIncomePortfolioOverview:", response);

        const rowData = response.data;
        rowData.lstFixedIncomePortfolioOverviewT1 =
          rowData.lstFixedIncomePortfolioOverviewT1 || [];
        rowData.lstFixedIncomePortfolioOverviewT2 =
          rowData.lstFixedIncomePortfolioOverviewT2 || [];
        rowData.lstFixedIncomePortfolioOverviewT3 =
          rowData.lstFixedIncomePortfolioOverviewT3 || [];
        rowData.lstFixedIncomePortfolioOverviewT4 =
          rowData.lstFixedIncomePortfolioOverviewT4 || [];
        rowData.lstFixedIncomePortfolioOverviewT5 =
          rowData.lstFixedIncomePortfolioOverviewT5 || [];

        populateFixedIncomePortfolioOverviewRptData(rowData);
        setloaded(true);
        setLoading(false);
        setEnableCombo(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };
  if (apicallval === 1) {
    FixedIncomePortfolioOverviewData();
    dispatch(callremove());
  }
  const handlechngtyp = (e) => {
    debugger;
    settypeval(e.value);
  };
  const handleCancel = () => {
    setModalVisible(false);
    setCloseOption(false);
    !closeOption && navigate("/dashboard");
  };

  const handleSettings = () => {
    setModalVisible(true);
    setCloseOption(true);
  };
  const formSubmit = (event) => {
    event.preventDefault();
    setModalVisible(false);
    setCloseOption(false);
    FixedIncomePortfolioOverviewData();
  };

  const handleExcludeAsset = (e) => {
    setShowExcludeAsset(e.target.checked ? true : false);
  };
  const handlebandYrFlag = (e) => {
    setbandYearFlag(parseInt(e.target.value));
    // setbandYearFlag(e.target.checked ? true : false);
  };
  const handleshwPrpsdTrd = (e) => {
    setshwPrpsdTrds(e.target.checked ? true : false);
  };
  const handleshwBondByIndSctr = (e) => {
    setshwBondByIndSctr(e.target.checked ? true : false);
  };
  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            <Loading />
          </div>
        )}
      </>
    );
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="col text-end">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={handleSettings}
          title="Click to change filters"
        >
          <FaFilter />
          <span className="px-2">Change Filters</span>
        </button>
      </div>

      <AntdModal
        open={modalVisible}
        title="Customize Fixed Income Portfolio Overview Report"
        closable={false}
        footer={[]}
        width={600}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          {/* AsOf Date */}
          <h6>As Of Date</h6>
          {/* <div className="col-md-3 col-lg-3 col-sm-10"> */}
          {/* <span className="py-1">Enter As of Date:</span>&nbsp;&nbsp; */}
          <DatePicker
            id="dpFrm"
            defaultValue={frmDate}
            format="MM/dd/yyyy"
            calendar={CustomCalendar}
            min={minFrmDt}
            formatPlaceholder={{
              year: "yyyy",
              month: "mm",
              day: "dd",
            }}
            width={300}
            max={maxFrmDt}
            //disabled={isDisabled}
            onChange={(e) => {
              setDate(e.value);
            }}
          />
          {/* </div> */}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {/* <div className="col-md-3 col-lg-3 col-sm-10"> */}
          {/* </div> */}
          <hr></hr>
          <DropDownList
            style={{
              width: "300px",
            }}
            label="Display Results By:"
            data={displaytype}
            textField="typeNm"
            valueField="typeId"
            dataItemKey="typeId"
            //filterable={false}
            //disabled={invMixVal}
            //defaultItem={initialModelDropdown}
            value={typeval}
            onChange={handlechngtyp}
          />
          <hr></hr>
          {/* Pricing Options */}
          <h6>Price Selection</h6>
          <div style={{ marginBottom: "20px" }}>
            {[1, 2, 3].map((option) => (
              <label
                key={option}
                style={{ display: "block", marginBottom: "10px" }}
              >
                <input
                  type="radio"
                  value={option}
                  checked={fromPriceDtSelOpt === option}
                  onChange={fromPriceDtHandleRadioChange}
                />
                {option === 1
                  ? " Use Current Price"
                  : option === 2
                  ? " Use End of Month Price"
                  : " Use Non EOM Price"}
              </label>
            ))}
          </div>
          {/* Conditional Price Date Field */}
          {fromPriceDtSelOpt === 3 && (
            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="dpPriceDt"
                className="form-label"
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                From Price Date
              </label>
              <DatePicker
                id="dpPriceDt"
                value={fromPriceDt}
                calendar={CustomCalendar}
                min={new Date("1/1/1000")}
                max={maxFrmDt}
                width={300}
                onChange={(e) => setFromPriceDt(e.value)}
                style={{ width: "100%" }}
              />
            </div>
          )}
          <hr></hr>
          {/* Miscellaneous Section */}
          <h6>Miscellaneous Selection</h6>
          <Row>
            <div>
              {[1, 2].map((option) => (
                <label
                  key={option}
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  <input
                    type="radio"
                    value={option}
                    checked={bandYrFlag === option}
                    onChange={handlebandYrFlag}
                  />
                  {option === 1 ? " Maturity Bands" : " Maturity Years"}
                </label>
              ))}
            </div>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={showExcludeAsset}
                    onChange={handleExcludeAsset}
                    name="excludeAsset"
                  />
                }
                label="Show Excluded Assets"
                style={{ marginBottom: "10px", display: "block" }}
              />
            </div>
            {/* <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={bandYrFlag}
                    onChange={handlebandYrFlag}
                    name="excludeAsset"
                  />
                }
                label="Maturity Bands"
                style={{ marginBottom: "10px", display: "block" }}
              />
            </div> */}
          </Row>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={shwPrpsdTrd}
                  onChange={handleshwPrpsdTrd}
                  name="excludeAsset"
                />
              }
              label="Display Staged Trades"
              style={{ marginBottom: "10px", display: "block" }}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={shwBondByIndSctr}
                  onChange={handleshwBondByIndSctr}
                  name="excludeAsset"
                />
              }
              label="Display Bonds By Industry Sector"
              style={{ marginBottom: "10px", display: "block" }}
            />
          </div>
          {/* Actions */}
          <hr style={{ margin: "20px 0" }} />
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntdModal>

      {loaded ? (
        <FixedIncomePortfolioOverviewGrid
          data={FixedIncomePortfolioOverviewRptData}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
export default FixedIncomePortfolioOverviewRpt;
