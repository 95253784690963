import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import axios from "axios";
import { process } from "@progress/kendo-data-query";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { FaFileExcel, FaFilePdf, FaEye } from "react-icons/fa";
import Enumerable from "linq";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";
import { Pager } from "@progress/kendo-react-data-tools";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import * as ReactDOM from "react-dom";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
} from "@progress/kendo-react-charts";
import BankLogoPage from "./bankLogoPage";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";
import Modal from "react-bootstrap/Modal";
const aggregates = [
  // {
  //   field: "cost",
  //   aggregate: "sum",
  // },
  {
    field: "market",
    aggregate: "sum",
  },
  {
    field: "unrGainLoss",
    aggregate: "sum",
  },
  {
    field: "estAnnInc",
    aggregate: "sum",
  },
  {
    field: "cost",
    aggregate: "sum",
  },

  // {
  //   field: "principalCash",
  //   aggregate: "sum",
  // },
  // {
  //   field: "incomeCash",
  //   aggregate: "sum",
  // },
  // {
  //   field: "investedIncome",
  //   aggregate: "sum",
  // }
];

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const AcctHoldingGrid = ({ data, flag, reportData, priceExistFlag }) => {
  // let acctId = JSON.parse(localStorage.getItem("AccountID")).acctId;
  let acctId = JSON.parse(localStorage.getItem("AccountID")).acctId;

  {
    var pcash =
      data[0] == undefined
        ? formatNumber(0, "##,#.00")
        : formatNumber(data[0].principalCash, "##,#.00");
  }
  var incmCash =
    data[0] == undefined
      ? formatNumber(0, "##,#.00")
      : formatNumber(data[0].incomeCash, "##,#.00");
  var invIncm =
    data[0] == undefined
      ? formatNumber(0, "##,#.00")
      : formatNumber(data[0].investedIncome, "##,#.00");
  console.log(data);
  var tempToken = JSON.parse(localStorage.getItem("token"));

  const menuWithExcelCheck = (props) => {
    //
    return (
      <div>
        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const menuWithoutExcelCheck = (props) => {
    //
    return (
      <div>
        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };
  const _export = React.useRef(null);
  const excelExport = () => {
    setShow(true);
    openReport();
    // if (_export.current !== null) {
    //   _export.current.save(data);
    // }
  };

  // call active report
  //const [show, setShow] = React.useState(false);
  const viewerRef = React.useRef();
  async function loadReport() {
    // load report definition from the file

    const reportResponse = await fetch("Holding.rdlx-json");

    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    const report = await loadReport();

    let account = localStorage.getItem("AcctSelected");
    let accountN = JSON.parse(account);
    let splitdata = accountN.extrnlAcctId.split(":");
    const parameters = [
      // <p>Administrator : {localStorage.getItem("Administrator")}</p>
      // </div>
      // <div className="text-end fw-bold col-md-3">
      // <p>AccountType : {localStorage.getItem("acctType")}</p>
      {
        Name: "pCnsldtnNm",
        Value: [
          JSON.parse(localStorage.getItem("RelationID")) != null
            ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm === ""
              ? 0
              : JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm
            : 0,
        ],
      },
      {
        Name: "pExtrnlAcctId",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 0
            : JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId,
        ],
      },
      {
        Name: "pShow",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 3
            : 2,
        ],
      },
      //{Name: 'pE', Value: [ChkBoxState]}
    ];
    // for (let i = 0; i < reportData.ocAcctHolding.length; i++) {
    //   //reportData.ocAcctHolding[i].accountName =  reportData.ocAcctHolding[i].accountName.split(":")[1];
    //   reportData.ocAcctHolding[i].accountNumber =
    //     accountN.extrnlAcctId.split(":")[0];
    //   let accountNM = accountN.extrnlAcctId.split(":")[1];
    //   if (splitdata.length > 2) {
    //     for (let j = 2; j < splitdata.length; j++) {
    //       accountNM = accountNM + accountN.extrnlAcctId.split(":")[j];
    //     }
    //   }
    //   reportData.ocAcctHolding[i].accountName = accountNM;
    // }

    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(reportData);

    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  //
  const [locked, setLocked] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);

  const columnLocked = () => {
    setLocked(!locked);
  };
  const totalSumYield = (props) => {
    var totalIncome = 0,
      totalMarket = 0,
      totYield = 0;
    for (var i = 0; i < data.length; i++) {
      totalIncome = totalIncome + data[i].estAnnInc;
      totalMarket = totalMarket + data[i].market;
    }
    if (totalMarket == 0) {
      totYield = 0;
    } else {
      totYield = (totalIncome * 100) / totalMarket;
    }

    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(totYield, "##,#.00")}
      </td>
    );
  };
  const totalSum = (props) => {
    const field = props.field || "";
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };

  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });

    newColumnsState[2].footerCell = totalSum;
    newColumnsState[3].footerCell = totalSum;
    newColumnsState[4].footerCell = totalSum;
    newColumnsState[5].footerCell = totalSum;
    newColumnsState[6].footerCell = totalSumYield;
    newColumnsState[0].columnMenu = menuWithExcelCheck;
    newColumnsState[3].columnMenu = menuWithoutExcelCheck;
    newColumnsState[1].columnMenu = menuWithExcelCheck;
    newColumnsState[2].columnMenu = menuWithExcelCheck;
    newColumnsState[4].columnMenu = menuWithoutExcelCheck;
    newColumnsState[5].columnMenu = menuWithoutExcelCheck;
    newColumnsState[6].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[7].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[8].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[9].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[10].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[11].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[12].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[13].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[14].columnMenu= menuWithExcelCheck;
    // newColumnsState[15].columnMenu= menuWithExcelCheck;
    // newColumnsState[16].columnMenu= menuWithExcelCheck;
    // newColumnsState[17].columnMenu= menuWithExcelCheck;
    // newColumnsState[18].columnMenu= menuWithExcelCheck;
    // newColumnsState[19].columnMenu= menuWithExcelCheck;
    // newColumnsState[20].columnMenu= menuWithExcelCheck;
    // newColumnsState[21].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[22].columnMenu= menuWithExcelCheck;
    // newColumnsState[23].columnMenu= menuWithExcelCheck;
    // newColumnsState[24].columnMenu= menuWithExcelCheck;
    // newColumnsState[25].columnMenu= menuWithExcelCheck;
    return newColumnsState;
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  // let acctId = JSON.parse(localStorage.getItem("AccountID")).acctId;

  const defaultColumns = [
    // {
    //   title: 'Branch',
    //   field: 'branch',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Acct. Type',
    //   field: 'accountType',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: "Account#",
    //   field: "accountName",
    //   minWidth: 150,
    //   show: acctId == 0 ? true : false,
    //   filter: "text",
    //   locked: true,
    // },
    //  {
    //   title: 'Major Asset name',
    //   field: 'MjrAstNm',
    //   minWidth: 150,
    //   show: false,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Ticker',
    //   field: 'tckrSymbl',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Cusip',
    //   field: 'cusip',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Asset",
      field: "asset",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: true,
    },

    {
      title: "Shares",
      field: "shares",
      // minWidth: 150,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Cost($)",
      field: "cost",
      // minWidth: 150,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Market($)",
      field: "market",
      // minWidth: 150,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      //footerCell: totalSum,
      headerClassName: "rightHeader",
    },
    {
      title: "Unr Gain Loss($)",
      field: "unrGainLoss",
      // minWidth: 180,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Est Ann Inc($)",
      field: "estAnnInc",
      // minWidth: 150,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Yield(%)",
      field: "yield",
      // minWidth: 150,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    // {
    //   title: 'Acc Int.(%)',
    //   field: 'accruedInterest',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Principal($)',
    //   field: 'principalCash',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Income($)',
    //   field: 'incomeCash',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Inv. Income($)',
    //   field: 'investedIncome',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Cost Per Share',
    //   field: 'costPerShare',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Country',
    //   field: 'country',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Location',
    //   field: 'location',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Registration',
    //   field: 'registration',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Holding Date',
    //   field: 'holdingDate',
    //   //field: 'holdingDate',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'date',
    //   locked: false,

    // },
    // {
    //   title: 'Industry',
    //   field: 'industry',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'IssueType',
    //   field: 'issueType',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Manual Price Source',
    //   field: 'manualPriceSource',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Price',
    //   field: 'price',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Price Date',
    //   field: 'priceDate',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'date',
    //   locked: false,

    // },
    // {
    //   title: 'Security Type',
    //   field: 'securityType',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Pricing Source',
    //   field: 'pricingSource',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Pricing Source Desc',
    //   field: 'pricingSourceDesc',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },

    // {
    //   title: 'Inv. Objective',
    //   field: 'investmentObjective',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Administrator',
    //   field: 'administrator',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Inv. Officer',
    //   field: 'investmentOfficer',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Rate',
    //   field: 'rate',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,

    // },
    // {
    //   title: 'Tax Cost($)',
    //   field: 'txCstAmt',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Yield To Cost(%)',
    //   field: 'yldToCost',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
  ];
  const defaultColumnsCopy = [
    // {
    //   title: 'Branch',
    //   field: 'branch',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Acct. Type',
    //   field: 'accountType',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: "Account#",
    //   field: "accountName",
    //   minWidth: 150,
    //   show: acctId == 0 ? true : false,
    //   filter: "text",
    //   locked: true,
    // },
    //  {
    //   title: 'Major Asset name',
    //   field: 'MjrAstNm',
    //   minWidth: 150,
    //   show: false,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Ticker',
    //   field: 'tckrSymbl',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Cusip',
    //   field: 'cusip',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Asset",
      field: "asset",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: true,
    },

    {
      title: "Shares",
      field: "shares",
      // minWidth: 150,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Cost($)",
      field: "cost",
      // minWidth: 150,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Market($)",
      field: "market",
      // minWidth: 150,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      //footerCell: totalSum,
      headerClassName: "rightHeader",
    },
    {
      title: "Unr Gain Loss($)",
      field: "unrGainLoss",
      // minWidth: 180,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Est Ann Inc($)",
      field: "estAnnInc",
      // minWidth: 150,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Yield(%)",
      field: "yield",
      // minWidth: 150,
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    // {
    //   title: 'Acc Int.(%)',
    //   field: 'accruedInterest',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Principal($)',
    //   field: 'principalCash',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Income($)',
    //   field: 'incomeCash',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Inv. Income($)',
    //   field: 'investedIncome',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Cost Per Share',
    //   field: 'costPerShare',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Country',
    //   field: 'country',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Location',
    //   field: 'location',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Registration',
    //   field: 'registration',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Holding Date',
    //   field: 'holdingDate',
    //   //field: 'holdingDate',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'date',
    //   locked: false,

    // },
    // {
    //   title: 'Industry',
    //   field: 'industry',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'IssueType',
    //   field: 'issueType',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Manual Price Source',
    //   field: 'manualPriceSource',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Price',
    //   field: 'price',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Price Date',
    //   field: 'priceDate',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'date',
    //   locked: false,

    // },
    // {
    //   title: 'Security Type',
    //   field: 'securityType',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Pricing Source',
    //   field: 'pricingSource',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Pricing Source Desc',
    //   field: 'pricingSourceDesc',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },

    // {
    //   title: 'Inv. Objective',
    //   field: 'investmentObjective',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Administrator',
    //   field: 'administrator',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Inv. Officer',
    //   field: 'investmentOfficer',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: false,

    // },
    // {
    //   title: 'Rate',
    //   field: 'rate',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,

    // },
    // {
    //   title: 'Tax Cost($)',
    //   field: 'txCstAmt',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: 'Yield To Cost(%)',
    //   field: 'yldToCost',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName: 'rightHeader'
    // },
  ];
  //
  const CustomGroupHeader = (props) => {
    //
    return `${props.value}`;
  };
  let loadedColumns = localStorage.getItem("gridColumns");
  const GridColumns = defaultColumns;
  if (
    localStorage.getItem("HoldingDataState") !== undefined &&
    localStorage.getItem("HoldingDataState") !== null
  ) {
    var datastatetemp = JSON.parse(localStorage.getItem("HoldingDataState"));
    if (datastatetemp.filter !== undefined) {
      if (datastatetemp.filter.filters.length > 0) {
        for (var i = 0; i < GridColumns.length; i++) {
          for (var j = 0; j < datastatetemp.filter.filters.length; j++) {
            if (
              GridColumns[i].field ==
              datastatetemp.filter.filters[j].filters[0].field
            ) {
              GridColumns[i].headerClassName = "active";
              break;
            } else {
              GridColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < GridColumns.length; i++) {
          GridColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < GridColumns.length; i++) {
        GridColumns[i].headerClassName = "";
      }
    }
  }
  for (var i = 0; i < GridColumns.length; i++) {
    for (var j = 0; j < defaultColumnsCopy.length; j++) {
      if (
        GridColumns[i].field === defaultColumnsCopy[j].field &&
        defaultColumnsCopy[j].headerClassName !== undefined
      )
        GridColumns[i].headerClassName =
          defaultColumnsCopy[j].headerClassName +
          " " +
          GridColumns[i].headerClassName;
    }
  }

  //const GridColumns = flag ? defaultColumns: defaultColumns;
  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };

  // const createDataState = (dataState) => {
  //   return {
  //     result: process(data.slice(0), dataState),
  //     dataState: dataState,
  //   };
  // };
  // let initialState = createDataState({
  //   take: 10,
  //   skip: 0,
  //   group: [{ field: 'mjrAstNm' }]
  // });
  let initialState = createDataState({
    filter:
      localStorage.getItem("HoldingDataState") === undefined ||
      localStorage.getItem("HoldingDataState") === null
        ? undefined
        : JSON.parse(localStorage.getItem("HoldingDataState")).filter,
    sort:
      localStorage.getItem("HoldingDataState") === undefined ||
      localStorage.getItem("HoldingDataState") === null
        ? undefined
        : JSON.parse(localStorage.getItem("HoldingDataState")).sort,
    take: 4000,
    skip: 0,
    group: [{ field: "accountName" }, { field: "mjrAstNm" }],
  });
  let initialStateExport = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    group: [{ field: "accountName" }, { field: "mjrAstNm" }],
  });

  // const [result, setResult] = React.useState(
  //   processWithGroups(data, initialState.dataState)
  // );

  //localStorage.removeItem("HoldingDataState");
  console.log(JSON.parse(localStorage.getItem("HoldingDataState")));
  const [result, setResult] = React.useState(
    processWithGroups(
      data,
      localStorage.getItem("HoldingDataState") === undefined ||
        localStorage.getItem("HoldingDataState") === null
        ? initialState.dataState
        : JSON.parse(localStorage.getItem("HoldingDataState"))
    )
  );

  const [resultExport, setResultExport] = React.useState(
    processWithGroups(data, initialStateExport.dataState)
  );

  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [stateColumns, setStateColumns] = React.useState(GridColumns);
  //
  debugger;
  console.log("gridcolumns", GridColumns);
  // GridColumns[2].footerCell = totalSum;
  // GridColumns[3].footerCell = totalSum;
  // GridColumns[4].footerCell = totalSum;
  // GridColumns[5].footerCell = totalSum;
  // GridColumns[6].footerCell = totalSumYield;

  console.log("priceExistFlag", priceExistFlag);

  if (priceExistFlag == null || "") {
    for (let i = 0; i < GridColumns.length; i++) {
      if (GridColumns[i].field == "cost") {
        GridColumns[i].footerCell = totalSum;
      } else if (GridColumns[i].field == "market") {
        GridColumns[i].footerCell = totalSum;
      } else if (GridColumns[i].field == "unrGainLoss") {
        GridColumns[i].footerCell = totalSum;
      } else if (GridColumns[i].field == "estAnnInc") {
        GridColumns[i].footerCell = totalSum;
      } else if (GridColumns[i].field == "yield") {
        GridColumns[i].footerCell = totalSumYield;
      }
    }
  }
  
  // GridColumns[0].columnMenu= menuWithExcelCheck;
  // GridColumns[3].columnMenu= menuWithoutExcelCheck;
  // GridColumns[1].columnMenu= menuWithExcelCheck;
  // GridColumns[2].columnMenu= menuWithExcelCheck;
  // GridColumns[4].columnMenu= menuWithoutExcelCheck;
  // GridColumns[5].columnMenu= menuWithoutExcelCheck;
  // GridColumns[6].columnMenu= menuWithoutExcelCheck;
  // GridColumns[7].columnMenu= menuWithoutExcelCheck;
  // GridColumns[8].columnMenu= menuWithoutExcelCheck;
  // GridColumns[9].columnMenu= menuWithoutExcelCheck;
  // GridColumns[10].columnMenu= menuWithoutExcelCheck;
  // GridColumns[11].columnMenu= menuWithoutExcelCheck;
  // GridColumns[12].columnMenu= menuWithoutExcelCheck;
  // GridColumns[13].columnMenu= menuWithoutExcelCheck;
  // GridColumns[14].columnMenu= menuWithExcelCheck;
  // GridColumns[15].columnMenu= menuWithExcelCheck;
  // GridColumns[16].columnMenu= menuWithExcelCheck;
  // GridColumns[17].columnMenu= menuWithoutExcelCheck;
  // GridColumns[18].columnMenu= menuWithExcelCheck;
  // GridColumns[19].columnMenu= menuWithExcelCheck;
  // GridColumns[20].columnMenu= menuWithExcelCheck;
  // GridColumns[21].columnMenu= menuWithoutExcelCheck;
  // GridColumns[22].columnMenu= menuWithoutExcelCheck;
  // GridColumns[23].columnMenu= menuWithExcelCheck;
  // GridColumns[24].columnMenu= menuWithExcelCheck;
  // GridColumns[25].columnMenu= menuWithExcelCheck;

  const [asOfDate, setasOfDate] = useState(0);
  let pageSize = 10;
  const [page, setPage] = React.useState({
    skip: 0,
    take: Number.MAX_VALUE,
  });
  // const dataExport = process(data, {
  //   group: initialGroup,
  // }).data;
  let _pdfExport;

  const saveColumnStateDb = async () => {
    //Storing column settings in DB
    //
    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 22;
    let ColumnSettings = localStorage.getItem("gridColumns");
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTSaveGridColumn/Index", postData, config)
      .then((response) => {
        console.log(response);
        const rowData = response.data;
        //populatePortfolioHoldingRptData(rowData.ocPortFolioHoldingsMainOutPut);
        //populatePortfolioHoldingRptDatatab2(rowData.ocPortFolioHoldingsTradeTypeOutPut);
        //setflagPortfolio(true);
        //setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //
          refreshToken();
        }
        return error;
      });
  };
  const refreshToken = async () => {
    //
    let token = JSON.parse(localStorage.getItem("token"));
    tempToken = token;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        saveColumnStateDb();
      })
      .catch((error) => {
        //

        console.log("my error is " + error);
      });
  };
  // const saveColumnsState = (columns) => {
  //     console.log('save called!!');
  //     //console.log(columns);
  //     let currentColumnsState = JSON.stringify(columns);
  //     //
  //     localStorage.setItem('gridColumns', currentColumnsState);

  //     saveColumnStateDb();

  // };

  useEffect(() => {
    //
    saveColumnsState(stateColumns);
  }, [stateColumns]);

  // const dataStateChange = (event) => {
  //   //
  //   let updatedState = createDataState(event.dataState);
  //   let updatedStateExcel = createDataState({
  //     take: Number.MAX_VALUE,
  //     skip: 0,
  //     sort: event.dataState.sort,
  //     group: event.dataState.group,

  //   });

  //   setResult(processWithGroups(data, updatedState.dataState));
  //   setResultExport(processWithGroups(data, updatedStateExcel.dataState));
  //   setDataState(updatedState.dataState);
  // };
  const saveColumnsState = () => {
    console.log("save called!!");
    //console.log(columns);

    var columns = [];
    if (localStorage.getItem("SelAcctId") > 0) {
      columns[0] = defaultColumns[0];
      for (var i = 0; i < stateColumns.length; i++) {
        columns[i + 1] = stateColumns[i];
      }
    } else {
      columns = stateColumns;
    }

    for (var i = 0; i < columns.length; i++) {
      if (columns[i].headerClassName !== undefined) {
        if (columns[i].headerClassName.includes("rightHeader")) {
          columns[i].headerClassName = "rightHeader";
        } else {
          columns[i].headerClassName = "";
        }
      }
    }
    let currentColumnsState = JSON.stringify(columns);

    localStorage.setItem("gridColumns", currentColumnsState);
    saveColumnStateDb();
  };
  const dataStateChange = (event) => {
    localStorage.setItem("filter", JSON.stringify(event.dataState.filter));
    if (event.dataState.filter !== undefined) {
      if (event.dataState.filter.filters.length > 0) {
        for (var i = 0; i < stateColumns.length; i++) {
          for (var j = 0; j < event.dataState.filter.filters.length; j++) {
            if (
              stateColumns[i].field ==
              event.dataState.filter.filters[j].filters[0].field
            ) {
              stateColumns[i].headerClassName = "active";
              break;
            } else {
              stateColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < stateColumns.length; i++) {
          stateColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < stateColumns.length; i++) {
        stateColumns[i].headerClassName = "";
      }
    }

    for (var i = 0; i < stateColumns.length; i++) {
      for (var j = 0; j < defaultColumnsCopy.length; j++) {
        if (
          stateColumns[i].field === defaultColumnsCopy[j].field &&
          defaultColumnsCopy[j].headerClassName !== undefined
        )
          stateColumns[i].headerClassName =
            defaultColumnsCopy[j].headerClassName +
            " " +
            stateColumns[i].headerClassName;
      }
    }

    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
    });

    setResult(processWithGroups(data, updatedState.dataState));
    localStorage.setItem(
      "HoldingDataState",
      JSON.stringify(updatedState.dataState)
    );
    setResultExport(processWithGroups(data, updatedStateExcel.dataState));
    setDataState(updatedState.dataState);
  };

  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  const exportPDF = () => {
    //let oldDataState = { ...dataState };
    // if (pdfExportComponent.current) {
    //setDataState({ ...dataState, skip: 0, take: Number.MAX_VALUE });

    _pdfExport.save();

    // setTimeout(() => {
    //   setDataState(oldDataState);
    // });
  };

  const PageTemplate = (props) => {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            top: "1px",

            width: "98%",
            borderBottom: "1px solid #bce8f1",
          }}
        >
          <div className="row d-flex mx-3">
            <div className="col text-start">
              <a className="px-2">
                <BankLogoPage />
              </a>
            </div>
            <div className="col text-end px-5 py-2">
              <h2 className="fw-bold text-fitek">Account Holding Report</h2>
            </div>
          </div>

          {/* <div className='fw-bold  text-center'>
                    Account Profile</div> */}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            //  borderTop: "1px solid #bce8f1"
          }}
        >
          Page {props.pageNum} of {props.totalPages}
        </div>
      </div>
    );
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };

  const getCells = (columns, cellProps) => {
    debugger;
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else {
        if (column.field == "yield") {
          cells.push(
            <td style={{ textAlign: "right" }}>
              {formatNumber(
                isNaN(
                  (cellProps.dataItem.aggregates.estAnnInc.sum /
                    cellProps.dataItem.aggregates.market.sum) *
                    100
                )
                  ? 0
                  : (cellProps.dataItem.aggregates.estAnnInc.sum /
                      cellProps.dataItem.aggregates.market.sum) *
                      100,
                "##,#.00"
              )}
            </td>
          );
        } else cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };
  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      //IMPORTANT - You need to add collection with the columns and their field name
      //you can define the Grid columns outside of the Grid and reuse them here.
      const columns = [
        // { field: 'branch' },
        // { field: 'accountType' },
        // { field: 'accountName'},
        { field: "asset" },
        // { field: "tckrSymbl" },
        // { field: "cusip" },
        // { field: "pmrDesc" },
        { field: "shares" },

        { field: "cost", aggregate: "sum" },
        { field: "market", aggregate: "sum" },
        { field: "unrGainLoss", aggregate: "sum" },
        { field: "estAnnInc", aggregate: "sum" },
        { field: "yield" },
        // { field: "accruedInterest" },
        // { field: "principalCash" },
        // { field: "incomeCash" },
        // { field: "investedIncome" },
        // { field: 'investmentObjective'},
        // { field: 'administrator'},
        // { field: 'investmentOfficer'},

        // { field: "txCstAmt" },
        // { field: "yldToCost" },
      ];

      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - columns.length}
          ></td>
          {getCells(columns, cellProps)}
        </>
      );
    }
    // if (cellProps.rowType === "groupFooter") {
    //   var grpYield = 0;

    //   if (cellProps.field === "cost") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(cellProps.dataItem.aggregates.cost.sum, "##,#.00")}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "market") {
    //     localStorage.setItem(
    //       "totmarket",
    //       cellProps.dataItem.aggregates.market.sum
    //     );
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(cellProps.dataItem.aggregates.market.sum, "##,#.00")}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "unrGainLoss") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.unrGainLoss.sum,
    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "estAnnInc") {
    //     localStorage.setItem(
    //       "totestAnnInc",
    //       cellProps.dataItem.aggregates.estAnnInc.sum
    //     );
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.estAnnInc.sum,
    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }
    //   if (cellProps.field === "yield") {
    //     if (JSON.parse(localStorage.getItem("totmarket")) > 0) {
    //       //grpYield=(grpTotInc*100)/grpTotMrkt;
    //       grpYield =
    //         (JSON.parse(localStorage.getItem("totestAnnInc")) * 100) /
    //         JSON.parse(localStorage.getItem("totmarket"));
    //     }
    //     localStorage.setItem("totestAnnInc", 0);
    //     localStorage.setItem("totmarket", 0);
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(grpYield, "##,#.00")}
    //       </td>
    //     );
    //   }
    // }
    if (cellProps.rowType === "data") {
      if (cellProps.field === "market") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "unrGainLoss") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "estAnnInc") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "yield") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "yldToCost") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "accruedInterest") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "principalCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "incomeCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "investedIncome") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "txCstAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "cost") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "shares") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "priceDate") {
        let cdt = new Date(cellProps.dataItem["priceDate"]);
        return (
          <td style={{ textAlign: "left" }}>
            {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
            {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
          </td>
        );
      }
      if (cellProps.field === "holdingDate") {
        let cdt = new Date(cellProps.dataItem["holdingDate"]);
        if (!isNaN(cdt.getTime())) {
          return (
            <td style={{ textAlign: "left" }}>
              {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
              {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
            </td>
          );
        } else {
          return <td style={{ textAlign: "left" }}>&nbsp;</td>;
        }
      }
      if (cellProps.field === "price") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "costPerShare") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "costPerShare") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "priceDate") {
        let cdt = new Date(cellProps.dataItem["priceDate"]);
        if (!isNaN(cdt.getTime())) {
          return (
            <td style={{ textAlign: "left" }}>
              {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
              {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
            </td>
          );
        } else {
          return <td style={{ textAlign: "left" }}>&nbsp;</td>;
        }
      }
    }

    return tdElement;
  };

  const NumberCell = (props) => {
    if (props.field === "branch") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "accountType") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "accountName") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "asset") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "tckrSymbl") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "cusip") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "pmrDesc") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "shares") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "cost") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "market") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "unrGainLoss") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "estAnnInc") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "yield") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "accruedInterest") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "principalCash") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "incomeCash") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "investedIncome") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "investmentObjective") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "administrator") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "investmentOfficer") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "rate") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "txCstAmt") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "yldToCost") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "costPerShare") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "price") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
  };
  const labelContent = (props) => {
    return `${props.category} : ${props.dataItem.market}`;
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {
        <div className="row d-flex justify-content-between align-items-center card-header">
          {acctId != 0 && (
            <>
              <div className="subheader col-sm text-start">
                Principal Cash: {pcash}
              </div>
              <div className="subheader col-sm text-start">
                Income Cash: {incmCash}
              </div>
              <div className="subheader col-sm text-start">
                Invested Income: {invIncm}
              </div>
            </>
          )}
          <div className="tableheader col-lg text-end small">
            Due to rounding, percentage may not equal 100.
          </div>
          {/* <div className="col text-end">
                                <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
                                    <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem> */}
          {/* <DropdownItem onClick={exportPDF}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem> */}

          {/* </DropdownButton>
           </div> */}

          <div className="col-sm text-end">
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={excelExport}
            >
              <FaEye /> Preview
            </button>
          </div>
        </div>
        /* <div className="card mx-2 my-2">
            <div className="card-header tableheader">Account Transaction Report</div>
        </div> */
      }
      <div className="card-body">
        <div className="mx-1">
          <ExcelExport
            data={data}
            ref={_export}
            fileName={
              JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
              "_AcctHolding.xlsx"
            }
          >
            <ExcelExportColumnGroup
              title={
                "Account Number: " +
                JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
                "  Processing Date: " +
                localStorage.getItem("processingDate")
              }
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              <ExcelExportColumn
                field="tranTypNm"
                hidden={true}
                groupHeader={CustomGroupHeader}
              />

              <ExcelExportColumn field="tckrSymbl" title="Ticker" />
              <ExcelExportColumn field="cusip" title="Cusip" width={50} />
              <ExcelExportColumn field="asset" title="Asset" />

              <ExcelExportColumn
                field="shares"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Shares"
              />
              <ExcelExportColumn
                field="cost"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Cost($)"
              />
              <ExcelExportColumn
                field="market"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Market($)"
              />
              <ExcelExportColumn
                field="unrGainLoss"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Unr Gain Loss($)"
              />
              <ExcelExportColumn
                field="estAnnInc"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Est Ann Inc($)"
              />
              <ExcelExportColumn
                field="yield"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Yield(%)"
              />
              {/* <ExcelExportColumn field="accruedInterest"  headerCellOptions={{ textAlign: "right", }}  cellOptions={{   format: "#,##0.00", }}   title="Acc Int.(%)"   />
              <ExcelExportColumn field="principalCash" headerCellOptions={{ textAlign: "right", }}   cellOptions={{   format: "#,##0.00", }}   title="Principal($)"   />
              <ExcelExportColumn field="incomeCash"  headerCellOptions={{ textAlign: "right", }}   cellOptions={{   format: "#,##0.00", }}   title="Income($)"   />             
              <ExcelExportColumn field="investedIncome"  headerCellOptions={{ textAlign: "right", }}  cellOptions={{   format: "#,##0.00", }}   title="Inv. Income($)"   />
              
              <ExcelExportColumn field="costPerShare" headerCellOptions={{ textAlign: "right", }}  cellOptions={{   format: "#,##0.00", }} title="Cost Per Share"   />
              <ExcelExportColumn field="country" title="Country"   />
              <ExcelExportColumn field="location" title="Location"   />
              <ExcelExportColumn field="registration" title="Registration"   />
              <ExcelExportColumn field="holdingDate" title="Holding Date"   />
              <ExcelExportColumn field="industry" title="Industry"   />
              <ExcelExportColumn field="issueType" title="Issue Type"   />
              <ExcelExportColumn field="manualPriceSource" title="Manual Price Source"   />
              <ExcelExportColumn field="price" headerCellOptions={{ textAlign: "right", }}  cellOptions={{   format: "#,##0.00", }} title="Price"   />
              <ExcelExportColumn field="priceDate" title="Price Date"   />
              <ExcelExportColumn field="securityType" title="Security Type"   />
              <ExcelExportColumn field="pricingSource" title="Pricing Source"   />
              <ExcelExportColumn field="pricingSourceDesc" title="Pricing Source Desc"   /> */}
            </ExcelExportColumnGroup>
          </ExcelExport>

          <Grid
            style={{ height: "600px" }}
            data={result}
            {...dataState}
            onDataStateChange={dataStateChange}
            //onColumnReorder={onColumnReorder}
            //onColumnResize={onColumnResize}
            expandField="expanded"
            onExpandChange={expandChange}
            cellRender={cellRender}
            sortable={true}
            // resizable={true}
            // pageable={true}
            // reorderable={true}
            pageable={{
              buttonCount: 0,
              info: true,
              previousNext: false,
            }}
            pageSize={Number.MAX_VALUE}
            groupable={{
              enabled: false,
              footer: "none",
            }}
          >
            {console.log("Price Flag ", priceExistFlag)}

            {priceExistFlag != "" ? (
              <GridNoRecords>
                <div style={{ color: "red", textAlign: "left" }}>
                  {priceExistFlag}
                  {console.log("No Price Exist for the date")}
                </div>
              </GridNoRecords>
            ) : (
              <>{console.log("Price Exist For the date")}</>
            )}
            {stateColumns.map(
              (column, idx) =>
                column.show && (
                  <Column
                    width={setWidth(column.minWidth)}
                    //  locked={column.locked}
                    key={idx}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    footerCell={column.footerCell}
                    headerClassName={column.headerClassName}
                    {...column}
                    columnMenu={column.columnMenu}

                    //cell={NumberCell}
                    // columnMenu={(props) => (
                    //   <CustomColumnMenuNoGrp
                    //     {...props}
                    //     columns={stateColumns}
                    //     onColumnsSubmit={onColumnsSubmit}
                    //   />
                    // )}
                  />
                )
            )}
          </Grid>

          <GridPDFExport
            ref={(element) => {
              _pdfExport = element;
            }}
            margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
            pageTemplate={PageTemplate}
            forcePageBreak=".page-break"
            paperSize="A4"
            scale={0.3}
            allPages={true}
            //margin="1cm"
            landscape={true}
            repeatHeaders={true}
          >
            <Grid
              style={{ height: "600px" }}
              data={resultExport}
              {...dataState}
              onDataStateChange={dataStateChange}
              expandField="expanded"
              onExpandChange={expandChange}
              cellRender={cellRender}
              sortable={true}
              // resizable={true}
              pageable={true}
              // total={result.length}
              pageSize={numberSymbols.MAX_VALUE}
              //onColumnReorder={onColumnReorder}
              //onColumnResize={onColumnResize}

              groupable={{
                footer: "visible",
                enabled: false,
              }}
            >
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      locked={column.locked}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      filter={column.filter}
                      footerCell={column.footerCell}
                      headerClassName={column.headerClassName}
                      //cell={NumberCell}
                      // columnMenu={(props) => (
                      //   <CustomColumnMenuNoGrp
                      //     {...props}
                      //     columns={stateColumns}
                      //     onColumnsSubmit={onColumnsSubmit}
                      //   />
                      // )}
                    />
                  )
              )}
            </Grid>
          </GridPDFExport>
        </div>
      </div>
      {/* <div className="col-4">
        <div className="k-card">
          <Chart
            style={{
              height: 350,
            }}
          >
            <ChartTitle text="Account Holdings By Major Asset" />
            <ChartLegend position="right" orientation="horizontal" />
            <ChartSeries>
              <ChartSeriesItem
                type="pie"
                overlay={{
                  gradient: "sharpBevel",
                }}
                tooltip={{
                  visible: true,
                }}
                data={data}
                categoryField="mjrAstNm"
                field="market"
                labels={{
                  visible: true,
                  content: labelContent
                }}
              />
            </ChartSeries>
          </Chart>
        </div>
      </div> */}
    </div>
  );
};

export default AcctHoldingGrid;
