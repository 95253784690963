import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { formatNumber, formatDate, parseNumber } from "@telerik/kendo-intl";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { CustomCalendar } from "./customCalendar";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Loading from "./loading";
import Header from "./header";
import AccountProfileDetails from "./accountProfileDetails";
import Modal from "react-bootstrap/Modal";
import { Modal as AntModal, Button as aButton } from "antd";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import ParamsModal from "./paramsModal";
import { AiFillSetting } from "react-icons/ai";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { FaFilter } from "react-icons/fa";
import { useSelector } from "react-redux";
import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useDispatch } from "react-redux";

const AccountProfileReport = () => {
  // debugger;
  const [assetAllocModelRptData, setAssetAllocModelRptData] = useState([]);
  const [chartColor, setChartColor] = useState([]);

  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [selectedAcct, setSelectedAcct] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modelData, setModelData] = useState([]);
  const [modelId, setModelId] = useState(-1);
  const [flag, setFlag] = useState(0);
  const [session, setSession] = useState("");
  const [enableCombo, setEnableCombo] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [closeOption, setCloseOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState(1);
  const [chkRptDispOptn, setChkRptDispOptn] = useState(true);
  const [runInAggregate, setRunInAggregate] = useState(false);
  var date = new Date(localStorage.getItem("processingDate"));
  var maxFrmDt = date;

  const [asOfDt, setasOfDt] = React.useState(date);
  const [priceDt, setpriceDt] = React.useState(date);
  const [title, setTitle] = React.useState("Asset Class");
  const navigate = useNavigate();

  const [priceExistFlag, setPriceExistFlag] = useState("");
  const [displayOption, setDisplayOption] = useState(0);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const dispatch = useDispatch();
  const [priceFlagInd, setPriceFlagInd] = useState(1);
  const apicallval = useSelector(selectapicall);

  const [initialState, setInitialState] = useState({
    chkRptDispOptn: true,
    runInAggregate: false,
  });

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;
        getModelInfo();
      } catch (error) {
        console.error(error.message);
      }
    };
    // fetchData();
  }, []);

  const handleChangeDispOptn = (e) => {
    // debugger;
    setChkRptDispOptn(e.currentTarget.checked);
  };

  const handlerunInAggregate = () => {
    setRunInAggregate(!runInAggregate);
  };

  const getModelInfo = async () => {
    setLoading(true);
    setEnableCombo(true);
    dispatch(callremove());
    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));

    let AcctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0; //JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    let consolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;
    let Accounts = "<root> <Account AcctId='" + AcctId + "'/> </root>";
    setSelectedAcct(JSON.parse(localStorage.getItem("AcctSelected")).acctId);
    let invMix = 0;
    let NumOfRows = 10;

    const postData1 = { AcctId, consolidationId };
    // const postData = { UserId, Accounts, modelId, invMix, NumOfRows, AcctId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/GetModelInfo", postData1, config)
      .then((response) => {
        const rowData = response.data;

        localStorage.setItem(
          "modelId",
          JSON.stringify(response.data.acctMdl[0].modelId)
        );
        setModelData(response.data.model);
        setModelId(JSON.parse(localStorage.getItem("modelId")));
        setLoading(false);
        setEnableCombo(false);
        //postData.modelId = response.data.acctMdl[0].modelId;
        // console.log(postData.modelId)
      })

      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }

        // return error;
      });
    //  let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    GetAllocModelData();
  };
  if (apicallval === 1) {
    getModelInfo();
    dispatch(callremove());
  }
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        getModelInfo();
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        //
        console.log("my error is " + error);
      });
  };

  const GetAllocModelData = async () => {
    setLoading(true);
    setEnableCombo(true);

    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId"));

    let lsSelectedAcct = JSON.parse(localStorage.getItem("AccountID")); // JSON.parse(localStorage.getItem("AcctSelected"));
    let AcctId = lsSelectedAcct != null ? lsSelectedAcct.acctId : 0;
    let mAccountId = AcctId;
    let NumOfRows = 10;
    let PageId = 1;
    let modelId = JSON.parse(localStorage.getItem("modelId"));
    let invMix = 0;
    let AsOfDt = formatDate(asOfDt, "MM/dd/yyyy");

    let PriceFlag = priceFlagInd;
    let PriceDt = formatDate(priceDt, "MM/dd/yyyy");

    if (modelId == -1) invMix = 1;
    // if (lsSelectedAcct !== null) {
    //     AcctId = lsSelectedAcct.acctId;
    // }
    //const postData = { AsOfId, AcctId, ModelId, PageId,invMix };
    let consolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;
    let showExcldAst = chkRptDispOptn;
    let GrpByMnrAst = displayOption;
    const postData = {
      UserId,
      mAccountId,
      consolidationId,
      showExcldAst,
      PriceFlag,
      PriceDt,
      AsOfDt,
      GrpByMnrAst,
      runInAggregate,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTAccountProfile", postData, config)
      .then((response) => {
        console.log(response);
        // debugger;
        const rowData = response.data;
        let consolidationName = JSON.parse(
          localStorage.getItem("RelationID")
        )?.cnsldtnNm;

        if (rowData.ocrtAccountProfile != null) {
          for (let i = 0; i < rowData.ocrtAccountProfile.length; i++) {
            if (
              chkRptDispOptn == true &&
              rowData.ocrtAccountProfile[i].groupName == "Miscellaneous Assets"
            ) {
              rowData.ocrtAccountProfile[i].groupName = "^Miscellaneous Assets";
            }
            rowData.ocrtAccountProfile[i].txCstAmt = parseNumber(
              formatNumber(rowData.ocrtAccountProfile[i].txCstAmt, "##,#.00")
            );
            rowData.ocrtAccountProfile[i].market = parseNumber(
              formatNumber(rowData.ocrtAccountProfile[i].market, "##,#.00")
            );
            rowData.ocrtAccountProfile[i].income = parseNumber(
              formatNumber(rowData.ocrtAccountProfile[i].income, "##,#.00")
            );
            rowData.ocrtAccountProfile[i].yield = parseNumber(
              formatNumber(rowData.ocrtAccountProfile[i].yield, "##,#.00")
            );
            rowData.ocrtAccountProfile[i].marketPercent = parseNumber(
              formatNumber(
                rowData.ocrtAccountProfile[i].marketPercent,
                "##,#.00"
              )
            );
            if (AcctId == 0 && runInAggregate == true) {
              rowData.ocrtAccountProfile[i].accountNumber = consolidationName;
            }
          }
          console.log(
            "localStorage.getItem(RelationID.cnsldtnNm;",
            consolidationName
          );

          console.log("rowdata", rowData);

          setAssetAllocModelRptData(rowData.ocrtAccountProfile);
          setChartColor(rowData.ocAssetColorModel);
        } else {
          setAssetAllocModelRptData([]);
          setChartColor([]);
        }

        setPriceExistFlag(response?.data?.priceValidation);

        if (lsSelectedAcct !== null) {
          SetselAcct(lsSelectedAcct);
        } else {
          //SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
        }
        setLoading(false);
        setEnableCombo(false);
        setFlag(1);
        if (GrpByMnrAst == 0) setTitle("Asset Class");
        else if (GrpByMnrAst == 1) setTitle("Minor Asset Class");
        else if (GrpByMnrAst == 2) setTitle("PMR");
        else if (GrpByMnrAst == 3) setTitle("Industry Sector");
        else setTitle(" ");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };

  const handleChange = (event) => {
    if (event.target.value === null || loading) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      getModelInfo();
      // GetAllocModelData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const formSubmit = (event) => {
    // debugger;
    event.preventDefault();
    getModelInfo();
    setModalVisible(false);
    setCloseOption(false);
  };
  const handleSettings = (e) => {
    setInitialState({
      chkRptDispOptn,
      runInAggregate,
    });
    e.preventDefault();
    setModalVisible(true);
    setCloseOption(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setCloseOption(false);
    setChkRptDispOptn(initialState.chkRptDispOptn);
    setRunInAggregate(initialState.runInAggregate);

    !closeOption && navigate("/dashboard");
  };
  const handleRadioChange = (e) => {
    setSelectedOption(parseInt(e.target.value));
    setPriceFlagInd(parseInt(e.target.value));
    console.log("Selected Option:", e.target.value);
    if (e.target.value == 1 || e.target.value == 2) {
      setPrcDt(date);
    }
  };
  function setPrcDt(val) {
    debugger;
    let newdate = new Date(val);
    // newdate = formatDate(newdate, "MM/dd/yyyy");
    if (newdate <= new Date(localStorage.getItem("processingDate")))
      setpriceDt(val);
    else {
      setpriceDt(new Date(localStorage.getItem("processingDate")));
    }
    console.log("Price Date", priceDt);
    
  }

  const handleDisplayChange = (e) => {
    // debugger;
    setDisplayOption(e.target.value);
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            {/* <div className="my-1">
             
              <div className="row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
                <div className="col-md-4 col-lg-5 col-sm-11 text-start">
                  <span className="px-2">Account(s)</span>
                  <ComboBox
                    style={{
                      width: "350px",
                    }}
                    data={selAcctData}
                    textField="extrnlAcctId"
                    dataItemKey="acctId"
                    filterable={true}
                    value={selAcct}
                    onChange={handleChange}
                    disabled={enableCombo}
                   
                  />
                </div>
              </div>
            </div> */}
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    <div style={{ overflow: "hidden" }}>
      {/* <Header></Header> */}
      {/* <div className="my-1">
      
        <div className="row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
          <div className="col-md-4 col-lg-5 col-sm-11 text-start">
            <span className="px-2">Account(s)</span>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              disabled={enableCombo}
             
            />
          </div>
        </div>
      </div> */}
      {/* <Button variant="primary" onClick={handleSettings}>
        <AiFillSetting />
      </Button> */}
      {/* Reusable Modal */}
      {/* <ParamsModal
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      /> */}

      {/* Updated Header Section */}
      <div className="row d-flex justify-content-between align-items-center mx-2 py-1 border-bottom">
        {/* Report Heading */}
        <div className="col reportheading fs-6">Account Profile Report</div>

        {/* Placeholder for Additional Information (if needed) */}
        <div className="col text-center">
          {/* Add dynamic text here if required */}
        </div>

        {/* Filter Button */}
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>
      </div>

      {/* Updated Modal Section */}
      <AntModal
        open={modalVisible}
        title="Customize Account Profile Report"
        closable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          {/* As Of Date */}
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpAsOfDt"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              As Of Date
            </label>
            <DatePicker
              id="dpAsOfDt"
              value={asOfDt}
              calendar={CustomCalendar}
              min={new Date("1/1/1000")}
              max={maxFrmDt}
              onChange={(e) => setasOfDt(e.value)}
              style={{ width: "100%" }}
              validationMessage={
                asOfDt == null
                  ? "Enter a valid date"
                  : asOfDt < new Date("1/1/1000")
                  ? "Enter a date in the correct format (mm/dd/yyyy)"
                  : asOfDt > maxFrmDt
                  ? "As Of Date cannot exceed the Processing Date"
                  : ""
              }
            />
          </div>
          <hr style={{ margin: "20px 0" }} />

          {/* Pricing Options */}
          <h6>Select Pricing Option</h6>
          <div style={{ marginBottom: "20px" }}>
            {[1, 2, 3].map((option) => (
              <label
                key={option}
                style={{ display: "block", marginBottom: "10px" }}
              >
                <input
                  type="radio"
                  value={option}
                  checked={selectedOption === option}
                  onChange={handleRadioChange}
                />
                {option === 1
                  ? " Use Current Price"
                  : option === 2
                  ? " Use End of Month Price"
                  : " Use Non EOM Price"}
              </label>
            ))}
          </div>

          {/* Conditional Price Date Field */}
          {selectedOption === 3 && (
            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="dpPriceDt"
                className="form-label"
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                Price Date
              </label>
              <DatePicker
                id="dpPriceDt"
                value={priceDt}
                calendar={CustomCalendar}
                min={new Date("1/1/1000")}
                max={maxFrmDt}
                onChange={(e) => setPrcDt(e.value)}
                style={{ width: "100%" }}
                validationMessage={
                  priceDt == null
                    ? "Enter a valid date"
                    : priceDt < new Date("1/1/1000")
                    ? "Enter a date in the correct format (mm/dd/yyyy)"
                    : priceDt > maxFrmDt
                    ? "Price Date cannot exceed the Max Date"
                    : ""
                }
              />
            </div>
          )}

          {/* Report Display Option */}
          <hr style={{ margin: "20px 0" }} />
          <h6>Miscellaneous Section</h6>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={chkRptDispOptn}
                  onChange={handleChangeDispOptn}
                  name="reportDisplaySwitch"
                />
              }
              label="Show Excluded Assets"
              style={{ marginBottom: "10px", display: "block" }}
            />
          </div>
          {AcctId == 0 && (
            <FormControlLabel
              control={
                <Switch
                  checked={runInAggregate}
                  onClick={handlerunInAggregate}
                  name="chkCtr"
                />
              }
              label="Run In Aggregate."
              style={{ marginBottom: "10px", display: "block" }}
            />
          )}

          {/* Report Display Option */}
          <hr style={{ margin: "20px 0" }} />
          <h6>Report Display Option</h6>

          <div style={{ marginBottom: "20px" }}>
            <FormControl sx={{ m: 1, width: 350 }} size="small">
              <Select
                value={displayOption}
                onChange={handleDisplayChange}
                //displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {/* <MenuItem value="">
                <em>None</em>
              </MenuItem> */}
                <MenuItem value={0}>
                  Display Results by Major Asset Type
                </MenuItem>
                <MenuItem value={1}>
                  Display Results by Minor Asset Type
                </MenuItem>
                <MenuItem value={2}>Display Results by PMR</MenuItem>
                <MenuItem value={3}>Display Results by Sector</MenuItem>
                <MenuItem value={4}>
                  Display Results by Risk Class Type
                </MenuItem>
                <MenuItem value={5}>
                  Display Results by Investment Class Type
                </MenuItem>
              </Select>
            </FormControl>
            {/* <select>
              <option value="0">Display Results by Major Asset Type</option>
              <option value="1">Display Results by Minor Asset Type</option>
              <option value="2">Display Results by PMR</option>
              <option value="3">Display Results by Sector</option>
              <option value="4">Display Results by Risk Class Type</option>
              <option value="5">
                Display Results by Investment Class Type
              </option>
            </select> */}
          </div>

          {/* Actions */}

          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntModal>

      {flag ? (
        <AccountProfileDetails
          chartColor={chartColor}
          data={assetAllocModelRptData}
          acct={selAcct}
          allmodelData={modelData}
          selModelId={modelId}
          priceExistFlag={priceExistFlag}
          title={title}
          runInAggregate={runInAggregate}
          showExcludedAssets={chkRptDispOptn}
          IsRunAsAggregate={runInAggregate}
          chkRptDispOptn={chkRptDispOptn}
          asOfDt={formatDate(asOfDt, "MM/dd/yyyy")}
          priceDt={formatDate(priceDt, "MM/dd/yyyy")}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AccountProfileReport;
