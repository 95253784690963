import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import Moment from "react-moment";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import BankLogoPage from "./bankLogoPage";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Enumerable from "linq";
import FixdIncmMaturityLadrCallDetls from "./fixdIncmMtrtyLadrCallDetls";
import { Modal } from "react-bootstrap";
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";

import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";

const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "1px",

          width: "98%",
          borderBottom: "1px solid #bce8f1",
        }}
      >
        <div className="row d-flex mx-3">
          <div className="col text-start">
            <a className="px-2">
              <BankLogoPage />
            </a>
          </div>
          <div className="col text-end px-5 py-2">
            <h2 className="fw-bold text-fitek">
              Fixed Income Maturity Ladder Report
            </h2>
          </div>
        </div>

        {/* <div className='fw-bold  text-center'>
                  Account Profile</div> */}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          //  borderTop: "1px solid #bce8f1"
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
};

const initialGroup = [
  {
    // field: "accountNumber",
  },
];

const initialDataState = {
  skip: 0,
  take: 10,
};

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  /*if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }*/

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};
const FixdIncmMaturityLadrGrd = ({
  data,
  callDetails,
  matVsCallPut,
  reportData,
  priceExistFlag,
}) => {
  // call active report
  // const [show, setShow] = React.useState(false);
  const viewerRef = React.useRef();
  async function loadReport() {
    // load report definition from the file

    if (ChkBoxState == true) {
      const reportResponse = await fetch(
        "FixedIncomeMaturityLadder_CallDetails.rdlx-json"
      );
      const report = await reportResponse.json();
      return report;
    } else {
      const reportResponse = await fetch("FixedIncomeMaturityLadder.rdlx-json");
      const report = await reportResponse.json();
      return report;
    }

    // const report = await reportResponse.json();
    // return report;
  }
  async function openReport() {
    const parameters = [{ Name: "ShowCallDetails", Value: [ChkBoxState] },
    {
      Name: "pCnsldtnNm",
      Value: [
        JSON.parse(localStorage.getItem("RelationID")) != null
          ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm === ""
            ? 0
            : JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm
          : 0,
      ],
    },
    {
      Name: "pExtrnlAcctId",
      Value: [
        JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
          ? 0
          : JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId,
      ],
    },
    {
      Name: "pShow",
      Value: [
        JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
          ? 3
          : 2,
      ],
    },];

    //{ ReportParams: parameters }

    const report = await loadReport();

    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(reportData);

    //report.ReportParams={parameters};
    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  //

  const _export = React.useRef(null);
  const _grid = React.useRef();
  const excelExport = () => {
    //
    setShow(true);
    openReport();
    // if (_export.current !== null) {
    //   _export.current.save(data);
    // }
  };

  const [locked, setLocked] = React.useState(false);

  const columnLocked = () => {
    setLocked(!locked);
  };

  const totalSum = (props) => {
    const field = props.field || "";
    const total = data.reduce((acc, current) => acc + current[field], 0);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };
  const totalSumPercent = (props) => {
    const field = props.field || "";
    const total = data.reduce((acc, current) => acc + current[field] * 100, 0);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };
  const avgYield = (props) => {
    const field = props.field;
    const len = data.length;
    const average =
      data.reduce((acc, current) => acc + current[field], 0) / len;
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        Avg: {formatNumber(average, "##,#.00")}
      </td>
    );
  };

  const totalSumYield = (props) => {
    var totalIncome = 0,
      totalMarket = 0,
      totYield = 0;
    for (var i = 0; i < data.length; i++) {
      totalIncome = totalIncome + data[i].income;
      totalMarket = totalMarket + data[i].market;
    }
    if (totalMarket == 0) {
      totYield = 0;
    } else {
      totYield = (totalIncome * 100) / totalMarket;
    }

    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(totYield, "##,#.00")}
      </td>
    );
  };

  const RightNameHeader = (props) => {
    return (
      <a
        className="k-link"
        style={{
          float: "right",
        }}
        onClick={props.onClick}
      >
        {/* <span className="k-icon k-i-cart" /> */}
        <span
          style={
            {
              // color: "#53d2fa",
            }
          }
        >
          {props.title}
        </span>
        {props.children}
      </a>
    );
  };

  debugger;

  const filteredData = data.filter((item) => item.mtrtyYr > 0);

  // console.log(filteredData);

  const columns = [
    // {
    //   title: 'Account Number',
    //   field: 'accountNumber',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Account Name',
    //   field: 'accountName',
    //   minWidth: 170,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Description",
      field: "mtrtyYrDesc",
      minWidth: 450,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Par Value",
      field: "shares",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      footerCell: totalSum,
      headerCell: RightNameHeader,
    },
    {
      title: "Market Value($)",
      field: "market",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      footerCell: totalSum,
      headerCell: RightNameHeader,
    },
    {
      title: "Income($)",
      field: "income",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      footerCell: totalSum,
      headerCell: RightNameHeader,
    },
    {
      title: "Yield(%)",
      field: "yield",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      footerCell: totalSumYield,
      headerCell: RightNameHeader,
    },
    {
      title: "Percent",
      field: "marketPercent",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      // footerCell: totalSumPercent,
      headerCell: RightNameHeader,
    },
  ];

  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data.slice(0), dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 4000,
    skip: 0,
    sort: [{ field: "mtrtyYrDesc", dir: "asc" }],
    group: [{ field: "extrnlAcctId" }],
  });
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [resultState, setResultState] = React.useState(
    processWithGroups(data, initialState.dataState)
  );
  const [stateColumns, setStateColumns] = React.useState(columns);
  const [currentColumns, setCurrentColumns] = React.useState(columns);
  //setResultState(process({data}, initialDataState))
  const [ChkBoxState, setChkBoxState] = useState(false);
  let pageSize = 10;
  const [page, setPage] = React.useState({
    skip: 0,
    take: pageSize,
  });
  const [collapsedState, setCollapsedState] = React.useState([]);
  const [chartData, setSelectedData] = React.useState(
    data.sort((a, b) => a.mtrtyYr - b.mtrtyYr)
  );
  const [callData, setCallDetails] = React.useState(callDetails);
  const [chartmatVsCallPutData, setmatVsCallPutData] = React.useState(
    matVsCallPut.sort((a, b) => a.callOrPutYr - b.callOrPutYr)
  );
  const [callFlag, setCallFlag] = useState(0);

  const onDataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);

    setResultState(processWithGroups(data, updatedState.dataState));

    setDataState(updatedState.dataState);
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };

  const handleColumnLockToggle = (columnField, state) => {
    let newColumns = currentColumns.map((column) => {
      if (column.field === columnField) {
        column.locked = state;
      }

      return column;
    });
    setCurrentColumns(newColumns);
  }; // place all locked columns first

  const cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "data") {
      if (cellProps.field === "shares") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "market") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "income") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "marketPercent") {
        //
        let mb = 100;
        let mc = cellProps.dataItem[cellProps.field];
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {formatNumber(mc * mb, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "yield") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      // if (cellProps.field === "mtrtyYr") {
      //   return (
      //     <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
      //       Bonds Maturing In&nbsp;{formatNumber(cellProps.dataItem[cellProps.field], "###")}
      //     </td>
      //   );
      // }
    }

    return tdElement;
  };

  const ShowCallPutDetails = (e) => {
    debugger;
    setChkBoxState(e.target.checked);
  };

  const pageChange = (event) => {
    setPage(event.page);
  };

  const onExpandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResultState({ ...resultState });
  };

  const defaultTooltipRender = ({ point }) =>
    `${formatNumber(point.value, "##,#.00")}`;
  const labelContent = (e) => `${formatNumber(e.value, "##,#.00")}`;
  const labelContent1 = (props) => {
    let formatedNumber = Number(props.dataItem.mvPercent).toLocaleString(
      undefined,
      {
        style: "percent",
        minimumFractionDigits: 2,
      }
    );
    return `${props.category}  ${props.dataItem.mvPercent.toFixed(2)}%`;
  };

  const onRowClick = (e) => {
    //
    var myr = e.dataItem.acctId;

    var acctData = Enumerable.from(data)
      .where((w) => w.acctId === myr)
      .toArray();

    setSelectedData(acctData);
    var calData = Enumerable.from(callDetails)
      .where((w) => w.acctId === myr)
      .toArray();
    setCallDetails(calData);
    var mVsCData = Enumerable.from(matVsCallPut)
      .where((w) => w.acctId === myr)
      .toArray();
    setmatVsCallPutData(mVsCData);
    setCallFlag(1);

    setResultState(processWithGroups(data, dataState));
    setDataState(dataState);
  };

  const FormatLongNumber = ({ value }) => {
    if (value === 0) {
      return 0;
    } else {
      // for testing
      //value = Math.floor(Math.random()*1001);

      // hundreds
      if (value <= 999) {
        return value;
      }
      // thousands
      else if (value >= 1000 && value <= 999999) {
        return value / 1000 + "K";
      }
      // millions
      else if (value >= 1000000 && value <= 999999999) {
        return value / 1000000 + "M";
      }
      // billions
      else if (value >= 1000000000 && value <= 999999999999) {
        return value / 1000000000 + "B";
      } else return value;
    }
  };
  let allPDFExport;
  let gridPDFExport = [];
  const [show, setShow] = React.useState(false);
  const showpreview = async () => {
    setShow(true);
  };
  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };
  const handleClose = () => setShow(false);
  const CustomGroupHeader = (props) => {
    //
    return `${props.value}`;
  };
  const grid = (
    // <Grid style={{ height: "450px" }}
    //   data={resultState}
    //   onRowClick={onRowClick}
    //   groupable={true}
    //   resizable={true}
    //   pageable={true}
    //   pageSize={20}
    //   onDataStateChange={onDataStateChange}
    //   {...dataState}
    //   onExpandChange={onExpandChange}
    //   expandField="expanded"
    //   cellRender={cellRender}
    // >
    <Grid
      style={{ height: "600px" }}
      data={resultState}
      {...dataState}
      onDataStateChange={onDataStateChange}
      // onRowClick={onRowClick}
      expandField="expanded"
      onExpandChange={onExpandChange}
      cellRender={cellRender}
      sortable={true}
      // resizable={true}
      // pageable={true}
      // reorderable={true}

      //pageSize={10}
      pageable={{
        buttonCount: 0,
        info: true,
        previousNext: false,
      }}
      pageSize={Number.MAX_VALUE}

      groupable={{
        footer: "visible",
        enabled: false,
      }}
    >
      <GridToolbar>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox name="chkShwMtrtyCall" onChange={ShowCallPutDetails} />
            }
            label="Show Call Details"
          />
        </FormGroup>
      </GridToolbar>
      {console.log("Price Flag ", priceExistFlag)}

      {priceExistFlag != "" ? (
        <GridNoRecords>
          <div style={{ color: "red", textAlign: "left" }}>
            {priceExistFlag}
            {console.log("No Price Exist for the date")}
          </div>
        </GridNoRecords>
      ) : (
        <>{console.log("Price Exist For the date")}</>
      )}
      {stateColumns.map(
        (column, idx) =>
          column.show && (
            // <Column
            //   width={setWidth(column.minWidth)}
            //   locked={column.locked}
            //   key={idx}
            //   field={column.field}
            //   title={column.title}
            //   filter={column.filter}
            //   headerCell={column.headerCell}
            //   columnMenu={(props) => (
            //     <CustomColumnMenuNoGrp
            //       {...props}
            //       columns={stateColumns}
            //       onColumnsSubmit={onColumnsSubmit}
            //     />
            <Column
              width={setWidth(column.minWidth)}
              locked={column.locked}
              key={idx}
              field={column.field}
              title={column.title}
              filter={column.filter}
              footerCell={column.footerCell}
              headerClassName={column.headerClassName}
              {...column}
              //cell={NumberCell}
              //         columnMenu={(props) => (
              //           <CustomColumnMenuNoGrp
              //             {...props}npm
              //             columns={stateColumns}
              //             onColumnsSubmit={onColumnsSubmit}
              //           />
              // )}
            />
          )
      )}
    </Grid>
  );

  return (
    <div>
      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div className="mx-1 px-1 row d-flex justify-content-between align-items-center my-2">
        {/* <div className="col">
          <p className="reportheading fs-6">
            Fixed Income Maturity Ladder Report
          </p>
        </div> */}
        <div className="col text-end">
          {/* <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
                                    <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem>
                                    <DropdownItem onClick={showpreview}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem>

                                </DropdownButton> */}
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={excelExport}
          >
            Preview
          </button>
        </div>

        {/* <div className="col">
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={excelExport}
          >
            Export to Excel
          </button>
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={showpreview}
          >
            Preview
          </button>
        </div> */}
      </div>

      <div className="card-body">
        <div className="mx-1 px-1 my-1 py-1">
          <ExcelExport
            data={data}
            ref={_export}
            fileName={
              JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
              "_FixedIncomeMaturityLadder.xlsx"
            }
          >
            <ExcelExportColumnGroup
              title={
                "Account Number: " +
                JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
                "  Processing Date: " +
                localStorage.getItem("processingDate")
              }
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              <ExcelExportColumn
                field="tranTypNm"
                hidden={true}
                groupHeader={CustomGroupHeader}
              />
              <ExcelExportColumn field="mtrtyYrDesc" title="Description" />
              <ExcelExportColumn
                field="shares"
                title="Par Value"
                width={100}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
              />
              <ExcelExportColumn
                field="market"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Market Value($)"
              />

              <ExcelExportColumn
                field="income"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Income($)"
              />
              <ExcelExportColumn
                field="yield"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Yield%"
              />
              <ExcelExportColumn
                field="marketPercent"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Percent"
              />
            </ExcelExportColumnGroup>
            {grid}
          </ExcelExport>
          <div className="card rounded my-2 mx-1">
            {/* Show Call Details check box */}
            {!ChkBoxState ? (
              <Chart style={{ height: "500px" }}>
                {/* <ChartTitle text="Maturity Date Vs Call/Put Date" /> */}
                <ChartLegend position="bottom" />
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    labels={{
                      visible: true,
                      rotation: 85,
                      format: "d",
                    }}
                    //  categories={categoryAxis}
                  />
                </ChartCategoryAxis>
                {/* <ChartTooltip render={defaultTooltipRender} /> */}
                <ChartValueAxis>
                  <ChartValueAxisItem
                    // title={{
                    //     text: "Percentage",
                    // }}
                    min={0}
                    labels={{
                      visible: true,
                      content: FormatLongNumber,
                    }}
                  />
                </ChartValueAxis>
                <ChartSeries>
                  <ChartSeriesItem
                    type="column"
                    data={chartData}
                    field="shares"
                    categoryField="mtrtyYr"
                    labels={{
                      visible: true,
                      content: labelContent,
                    }}
                    name="Shares"
                  />
                </ChartSeries>
              </Chart>
            ) : (
              <div></div>
            )}
          </div>
          <div className="row">
            <div className="my-2 col-md-6 col-lg-6 col-sm-11">
              {console.log("callFlag", callFlag)}
              {callFlag === 1 ? (
                
                <FixdIncmMaturityLadrCallDetls
                  data={callData}
                  chkState={ChkBoxState}
                  shwCstmColMnu={show}
                />
              ) : (
                <FixdIncmMaturityLadrCallDetls
                  data={callDetails}
                  chkState={ChkBoxState}
                  shwCstmColMnu={show}
                />
              )}
            </div>
            {console.log("ChkBoxState", ChkBoxState)}
            {ChkBoxState ? (
              <div className="card my-2 col-md-5 col-lg-5 col-sm-11'">
                <div className="card-header tableheader">
                  Maturity Date Vs Call / Put Date
                </div>
                <Chart style={{ height: "350px" }}>
                  {/* <ChartTitle text="Maturity Date Vs Call/Put Date" /> */}
                  <ChartLegend position="bottom" />
                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                      labels={{
                        visible: true,
                        rotation: 85,
                        format: "d",
                      }}
                      //  categories={categoryAxis}
                    />
                  </ChartCategoryAxis>
                  <ChartTooltip render={defaultTooltipRender} />
                  <ChartValueAxis>
                    <ChartValueAxisItem
                      // title={{
                      //     text: "Percentage",
                      // }}
                      min={0}
                      labels={{
                        visible: true,
                        content: FormatLongNumber,
                      }}
                    />
                  </ChartValueAxis>
                  <ChartSeries>
                    <ChartSeriesItem
                      type="column"
                      data={chartmatVsCallPutData}
                      categoryField="callOrPutYr"
                      field="mtrShares"
                      labels={{
                        visible: false,
                        content: labelContent,
                      }}
                      name="Maturity Shares"
                    />
                    <ChartSeriesItem
                      type="column"
                      data={chartmatVsCallPutData}
                      categoryField="callOrPutYr"
                      field="callPutShares"
                      labels={{
                        visible: false,
                        content: labelContent,
                      }}
                      name="Call/Put Shares"
                    />
                  </ChartSeries>
                </Chart>
              </div>
            ) : (
              <div></div>
            )}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixdIncmMaturityLadrGrd;
