import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
// import validator from 'validator';
import Select, { SelectChangeEvent } from "@mui/material/Select";
//import SelectControl from './selectcontrol';
import Loading from "./loading";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { lastDayOfMonth, isEqual } from "@progress/kendo-date-math";
import { Modal as AntModal, Button as aButton } from "antd";
import DailyPerformanceGrid from "./dailyPerformanceGrid";
import Header from "./header";
import { Button, Card } from "@mui/material";
import { FaFilter } from "react-icons/fa";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
//import refreshFunction from './refreshFunc';
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
// import { abs } from 'mathjs'
import { CustomCalendar } from "./customCalendar";
import { Dropdown } from "react-bootstrap";
import Enumerable from "linq";

import { FaInfoCircle } from "react-icons/fa";
// import { Popover } from "@progress/kendo-react-tooltip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SelectedPeriodRORSMAGrid from "./selectedPeriodRORSMAGrid";
import BenchmarkSelPeriodPerGrid from "./benchmarkSelPeriodPerGrid";
import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";

const SelectedPeriodRORSMARpt = () => {
  const [RORFlag, setRORFlag] = useState(false);
  const [selectedPerRORSMARptData, populateSelectedPerRORSMARptData] = useState(
    []
  );
  const [selectedPerRORSMAData, setSelectedPerRORSMAData] = useState([]);
  const [TimeWtdRtnRptRptDataChart, populateTimeWtdRtnRptRptDataChart] =
    useState([]);
  const [reportData, setReportData] = useState([]);
  const [IncepDt, setIncepDt] = useState();
  //const [AcctList, populateAccountListData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(0);
  const [isColumnSave, setIsColumnSave] = useState(false);
  var date = new Date(localStorage.getItem("processingDate"));
  date.setMonth(date.getMonth() - 1);
  const [frmDate, setfrmDate] = React.useState(date);
  //const [toDate, setToDate] = React.useState(new Date(localStorage.getItem("processingDate")));
  const [asOfDate, setAsOfDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(true);
  const [isChckedBM, setisChckedBM] = useState(false);
  const [openDuration, setOpenDuration] = React.useState(100);
  const [closeDuration, setCloseDuration] = React.useState(300);
  // const minFrmDt = new Date(2021, 8, 14);

  const maxFrmDt = new Date(localStorage.getItem("processingDate"));
  const minFrmDt = new Date(localStorage.getItem("processingDate"));
  minFrmDt.setMonth(minFrmDt.getMonth() - 60);

  const [isDisabled, setisDisabled] = React.useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [onThisPage, setOnThisPage] = useState(false);

  let acctId = 0;
  let AccountId = JSON.parse(localStorage.getItem("AcctSelected"));
  if (AccountId != null) {
    acctId = AccountId.acctId;
  }

  const [monthlyOrDaily, setMonthlyOrDaily] = React.useState(
    acctId.acctId == 0 && JSON.parse(localStorage.getItem("pConsId")) > 0
      ? "Monthly"
      : "Daily"
  );
  const [isDisabledDaily, setisDisabledDaily] = React.useState(
    acctId.acctId == 0 && JSON.parse(localStorage.getItem("pConsId")) > 0
      ? true
      : false
  );
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  //const [CardData, populateTimeWtdRtnRptRptCardData] = useState([]);
  const navigate = useNavigate();

  const [dateType, setDateType] = React.useState(1);

  const dispatch = useDispatch();

  const apicallval = useSelector(selectapicall);

  useEffect(() => {
    // debugger;
    const fetchData = async () => {
      setLoading(true);
      try {
        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;
        GetTimeWtdRtnRpteData();
        //getColumnStateDb();
      } catch (error) {
        console.error(error.message);
      }
    };
    // fetchData();
  }, []);

  const handelChkBM = (e) => {
    setisChckedBM(e.target.checked);
  };

  const handleChangemonthlyOrDaily = (e) => {
    setMonthlyOrDaily(e.target.value);
  };
  const handelSubmit = (event) => {
    // debugger;
    //  var date = new Date(asOfDate);
    //  var month =date.getMonth();
    //  var year =date.getFullYear();
    //  var day=date.getDate();
    //var lDay= lastDayOfMonth(new Date(year,month,day));

    //let date = dtfrm.selAcctData;
    setfrmDate(asOfDate);
    //  if(validator.isDate(asOfDate)&&(asOfDate>=minFrmDt)&&asOfDate<=maxFrmDt){
    //
    GetTimeWtdRtnRpteData();
    //  }
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setOnThisPage(true);
    setModalVisible(false);
    GetTimeWtdRtnRpteData();
  };

  const GetTimeWtdRtnRpteData = async () => {
    setLoading(true);

    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let asOfId = JSON.parse(localStorage.getItem("userId")); //3689
    let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    let AcctId = lsSelectedAcct.acctId;
    let PageId = 1;
    let netFeeId = 1;

    var date = new Date(asOfDate);
    var month = date.getMonth();
    var year = date.getFullYear();
    var day = date.getDate();
    var lDay = lastDayOfMonth(new Date(year, month, day));
    var IsDaily = 0;

    var pageId = 1;
    var benchmarkList = "14,2,3,11,6,5,8,7,4,1,9";

    var consolidationId = 0;
    if (monthlyOrDaily == "Daily") IsDaily = 0;

    // setAsOfDate(lDay);
    var lDay = lastDayOfMonth(asOfDate);
    if (!isEqual(asOfDate, lDay) && IsDaily == 0) {
      lDay = lastDayOfMonth(new Date(year, month - 1, day));

      setAsOfDate(lDay);
    } else {
      lDay = asOfDate;
      setAsOfDate(lDay);
    }
    debugger;
    // if (lsSelectedAcct !== null) {
    //   AcctId = lsSelectedAcct.acctId;
    // }
    // let AsofDate = lDay;
    debugger;
    let ConsolidationId =
      JSON.parse(localStorage.getItem("pConsId")) == null
        ? 0
        : localStorage.getItem("pConsId");
    AcctId = JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    let ShowBenchmark = 0;
    //  setAsOfDate("09/30/2024");
    let AsOfDt = formatDate(lDay, "MM/dd/yyyy");
    // setAsOfDate(AsOfDt);
    let RltnshpCd =
      localStorage.getItem("pRltncd") == null ||
      localStorage.getItem("pRltncd") == undefined
        ? ""
        : localStorage.getItem("pRltncd");
    if (isChckedBM) ShowBenchmark = 1;

    const postData = {
      asOfId,
      AcctId,
      consolidationId,
      AsOfDt,
    };
    debugger;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post("/RTGetSelectedRORSMA", postData, config)
      .then((response) => {
        debugger;
        const rowData = response.data;
        

        //         let gridData = Enumerable.from(rowData.lstSelectedPeriodT1)
        //           .where((w) => w.market > 0)
        //           .toArray();

        //         let chartData = Enumerable.from(rowData.lstSelectedPeriodT2)
        //           .where((w) => w.market > 0)
        //           .toArray();

        populateSelectedPerRORSMARptData(rowData?.ocSelectedRORSMAT1 || []);
        setSelectedPerRORSMAData(rowData);

        //         populateTimeWtdRtnRptRptDataChart(rowData.lstSelectedPeriodT2);
        //         setReportData(rowData1.ocSelectedRORSMAT1);
        //         let cdt = new Date(rowData.perfIncptnDt);
        //         let siDate =
        //           (cdt.getMonth() + 1).toString().padStart(2, "0") +
        //           "/" +
        //           cdt.getDate().toString().padStart(2, "0") +
        //           "/" +
        //           cdt.getFullYear();
        //         setIncepDt(siDate);
        //         console.log("Inception DATE:", siDate);
        //         if (lsSelectedAcct !== null) {
        //           SetselAcct(lsSelectedAcct);
        //         }
        //         // else {
        //         //   SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
        //         // }
        getColumnStateDb();
        setLoading(false);
        setRORFlag(true);
        //         setFlag(1);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        // return error;
      });
  };

  if (apicallval === 1) {
    GetTimeWtdRtnRpteData();
    dispatch(callremove());
  }
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        debugger;
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetTimeWtdRtnRpteData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 33;

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        console.log("save in db");
        debugger;
        //console.log(response);
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);
        return error;
      });
  };

  const handleChange = (event) => {
    //
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetTimeWtdRtnRpteData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  const openFilterModal = () => {
    setModalVisible(true);
  }

  const closeModal = () => {
    setModalVisible(false);
    !onThisPage && navigate("/dashboard");
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            {!modalVisible && <Loading />}
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      {/* <Header></Header> */}

      <AntModal
        open={modalVisible}
        title="Customize Selected Period ROR SMA Report"
        // onCancel={handleCancel}
        closable={false}
        // maskClosable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          <div className="pt-3">
            <span>
              <h6>As of Date</h6>
            </span>
            <DatePicker
              id="dpFrm"
              value={asOfDate}
              className="form-control form-control-sm"
              size={"small"}
              width={150}
              format="MM/dd/yyyy"
              // calendar={CustomCalendar}
              // min={minFrmDt}
              max={maxFrmDt}
              disabled={isDisabled}
              onChange={(e) => {
                setAsOfDate(e.value);
              }}
            />
          </div>

          {/* <hr></hr> */}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={closeModal}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntModal>

      <div className="row d-flex justify-content-between align-items-center mx-2 py-1 border-bottom">
        <div className="col reportheading fs-6">
          Selected Period ROR SMA Report
        </div>
        <div className="col text-center"></div>

        {/* Filter Button */}
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={openFilterModal}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>
      </div>

      {/* <div className="my-2">
        <div className="rounded"></div>

        <div className="d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
          <div className="col-md-4 col-lg-4 col-sm-11 text-start">
            <span className="px-2">Account(s)</span>
            <ComboBox
              style={{
                width: "330px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mx-4">
          <p className="reportheading fs-6">Selected Period ROR SMA Report</p>
        </div>
      </div> */}
      {RORFlag ? (
        <SelectedPeriodRORSMAGrid
          data={selectedPerRORSMARptData}
          reportData={selectedPerRORSMAData}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SelectedPeriodRORSMARpt;
