import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Enumerable from "linq";
import { formatDate, numberSymbols } from "@telerik/kendo-intl";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Loading from "./loading";
import Header from "./header";
import AssetVsModelGrid from "./assetVsModelGrid";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { formatNumber, parseNumber } from "@telerik/kendo-intl";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Modal as AntdModal, Button as AntdBtn } from "antd";
import { CustomCalendar } from "./customCalendar";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { FaFilter } from "react-icons/fa";
import { filterBy } from "@progress/kendo-data-query";

var dataaxios;
const AssetAllocModelRptMain = () => {
  var date = new Date(localStorage.getItem("processingDate"));
  date.setMonth(date.getMonth() - 12);
  const [assetAllocModelRptData, setAssetAllocModelRptData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [selectedAcct, setSelectedAcct] = useState(0);
  const [loading, setLoading] = useState(true);
  const [modelData, setModelData] = useState([]);
  const [dropdownData, setDropdownData] = React.useState(modelData);
  const filterData = (filter) => {
    const data = modelData.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {
    setDropdownData(filterData(event.filter));
  };
  const [modelId, setModelId] = useState(-1);
  const [modalVisible, setModalVisible] = useState(true);
  const [fromPriceDt, setFromPriceDt] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const [closeOption, setCloseOption] = useState(false);
  const [isColumnSave, setIsColumnSave] = useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const [showExcludeAsset, setShowExcludeAsset] = useState(true);
  const [invmixcheck, setinvmixcheck] = useState(false);
  const [runInAggregate, setRunInAggregate] = useState(false);

  const [enableCombo, setEnableCombo] = useState(false);
  const [frmDate, setfrmDate] = React.useState(date);
  const [toDate, setToDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const [loaded, setloaded] = useState(false);
  const [selChangeModel, setSelChangeModel] = React.useState([]);
  const [fromPriceFlagInd, setFromPriceFlagInd] = useState(1);
  var minDate = new Date(localStorage.getItem("processingDate"));
  minDate.setMonth(minDate.getMonth() - 24);
  var maxDate = new Date(localStorage.getItem("processingDate"));
  const [minFrmDt, setminFrmDt] = React.useState(minDate);
  const [maxFrmDt, setmaxFrmDt] = React.useState(maxDate);
  const [fromPriceDtSelOpt, setFromPriceDtSelOpt] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  const [initialState, setInitialState] = useState({
    showExcludeAsset: true,
    invmixcheck: false,
    runInAggregate: false,
  });

  const [priceExistFlag, setPriceExistFlag] = useState("");

  const getModelInfo = async () => {
    setLoading(true);
    setEnableCombo(true);

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));

    let AcctId = JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    let Accounts = "<root> <Account AcctId='" + AcctId + "'/> </root>";
    setSelectedAcct(JSON.parse(localStorage.getItem("AcctSelected")).acctId);
    let invMix = 0;
    let NumOfRows = 10;

    const postData1 = { AcctId };
    // const postData = { UserId, Accounts, modelId, invMix, NumOfRows, AcctId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/GetModelInfo", postData1, config)
      .then((response) => {
        const rowData = response.data;

        // let modellist=rowData.model.map((n) =>{
        //     if(n.modelId===-1){
        //         n.modelNm="None";
        //         n.modelId=n.modelId;
        //         n.disabled=true;
        //         return {};

        //     }
        //     else{
        //         n.modelNm=n.modelNm;
        //         n.modelId=n.modelId;
        //         return n;
        //     }
        //     } );
        localStorage.setItem(
          "modelId",
          JSON.stringify(response.data.acctMdl[0].modelId)
        );
        let modellist = Enumerable.from(rowData.model)

          .where((w) => w.modelId !== -1)

          .toArray();
        let selmdl = rowData.model.filter(
          (x) => x.modelId === response.data.acctMdl[0].modelId
        );
        setinvmixcheck(selmdl[0].modelId === -1 ? true : false);
        setSelChangeModel(
          selmdl[0].modelId === -1
            ? { modelId: -1, modelNm: "None" }
            : selmdl[0]
        );
        setModelData(modellist);
        setDropdownData(modellist);
        setModelId(JSON.parse(localStorage.getItem("modelId")));
        //  getColumnStateDb();

        setLoading(false);
        setEnableCombo(false);
        //postData.modelId = response.data.acctMdl[0].modelId;
        // console.log(postData.modelId)
        GetAllocModelData(1);
      })

      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }

        // return error;
      });
    //  let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
  };
  useMemo(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;
        getModelInfo();
        //getColumnStateDb();
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);
  const getColumnStateDb = async () => {
    //Storing column settings in DB
    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 26; //let 1 for Portfoliholdings Grid

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);
        return error;
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        getModelInfo();
      })
      .catch((error) => {
        // ;
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };
  const fromPriceDtHandleRadioChange = (e) => {
    setFromPriceDtSelOpt(parseInt(e.target.value));
    setFromPriceFlagInd(parseInt(e.target.value));

    // console.log("fromPriceDtSelOpt", fromPriceDtSelOpt);
    // console.log("Selected Price Date", fromPriceDt);
  };

  const GetAllocModelData = async (flag) => {
    setLoading(true);
    setEnableCombo(true);

    let token = tempToken;
    let asOfId = JSON.parse(localStorage.getItem("userId"));
    let asOfDt = formatDate(frmDate, "MM/dd/yyyy");
    let lsSelectedAcct = JSON.parse(localStorage.getItem("AccountID")); // JSON.parse(localStorage.getItem("AcctSelected"));
    let acctId = lsSelectedAcct != null ? lsSelectedAcct.acctId : 0;
    //let PageId = 1;
    let modelId = JSON.parse(localStorage.getItem("modelId"));
    let invMix = invmixcheck ? 1 : 0;
    let consolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;
    let priceDt = formatDate(fromPriceDt, "MM/dd/yyyy");
    let priceFlag = fromPriceFlagInd;
    let showExcldAst = showExcludeAsset;

    // if(ModelId==-1)
    // invMix=1;
    // if (lsSelectedAcct !== null) {
    //     AcctId = lsSelectedAcct.acctId;
    // }
    setAssetAllocModelRptData([]);
    const postData = {
      asOfId,
      asOfDt,
      acctId,
      modelId,
      consolidationId,
      invMix,
      priceDt,
      priceFlag,
      showExcldAst,
      runInAggregate,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    console.log("Asset Alloc post data:", postData);
    await axios
      .post("/RTAssetAllocationVsModel", postData, config)
      .then((response) => {
        debugger;
        console.log("AssetAlloc Data t1:", response);

        const rowData = response.data;
        rowData.t1 = rowData.t1 || [];
        let consolidationName = JSON.parse(
          localStorage.getItem("RelationID")
        )?.cnsldtnNm;

        if (AcctId == 0 && runInAggregate == true) {
          for (let i = 0; i < rowData.t1.length; i++) {
            rowData.t1[i].accountName = consolidationName;
          }
        }
        dataaxios = rowData;

        // for(let i=0;i<rowData.t1.length;i++){
        //     rowData.t1[i].mdlwegh = parseNumber(formatNumber(rowData.t1[i].mdlwegh,"n2"));
        // }

        setAssetAllocModelRptData(rowData);
        //getColumnStateDb();

        // getColumnStateDb();
        setLoading(false);
        setEnableCombo(false);
        if (flag != 1) {
          setloaded(true);
        }

        setPriceExistFlag(response?.data?.priceValidation);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };
  if (apicallval === 1) {
    debugger;
    console.log("Called from apicallval");
    getModelInfo();

    dispatch(callremove());
  }

  const handleCancel = () => {
    setShowExcludeAsset(initialState.showExcludeAsset);
    setinvmixcheck(initialState.invmixchecks);
    setRunInAggregate(initialState.runInAggregate);
    setModalVisible(false);
    setCloseOption(false);
    !closeOption && navigate("/dashboard");
  };

  const handleSettings = () => {
    setInitialState({
      showExcludeAsset,
      invmixcheck,
      runInAggregate,
    });
    setModalVisible(true);
    setCloseOption(true);
  };
  const formSubmit = (event) => {
    event.preventDefault();
    setModalVisible(false);
    setCloseOption(false);
    GetAllocModelData();
    setDropdownData(modelData);
  };

  const handleExcludeAsset = (e) => {
    setShowExcludeAsset(e.target.checked ? true : false);
  };

  const handlerunInAggregate = () => {
    setRunInAggregate(!runInAggregate);
  };

  const handleInvmix = (e) => {
    setinvmixcheck(e.target.checked ? true : false);
    setSelChangeModel(
      e.target.checked ? { modelId: -1, modelNm: "None" } : selChangeModel
    );
    localStorage.setItem("modelId", JSON.stringify(-1));
  };
  const handleChangeAllocModel = (e) => {
    setSelChangeModel(e.target.value);
    // setDropdownData(modelData);
    localStorage.setItem("modelId", JSON.stringify(e.target.value.modelId));
    // e.preventDefault();
    // localStorage.setItem('StatusInvMix', 'false');

    // localStorage.setItem('ModelNm', e.target.value.modelNm);

    // getChangeInfo(e.target.value.modelId)
  };

  const handleChange = (event) => {
    if (event.target.value === null || loading) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      getModelInfo();
      // GetAllocModelData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };
  function setDate(val) {
    //setfrmDate(val);
    if (val == null) {
      setfrmDate(frmDate);
    } else {
      setfrmDate(val);
    }
  }
  function setTDate(val) {
    if (val == null) {
      setToDate(toDate);
    } else {
      setToDate(val);
    }
  }
  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        // ;
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        // ;
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  console.log("Asset Data:", assetAllocModelRptData);
  debugger;
  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    <div>
      <div className="col text-end">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={handleSettings}
          title="Click to change filters"
        >
          <FaFilter />
          <span className="px-2">Change Filters</span>
        </button>
      </div>

      <AntdModal
        open={modalVisible}
        title="Customize Asset Allocation Vs Model Report"
        closable={false}
        footer={[]}
        width={600}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          {/* AsOf Date */}
          <h6>As Of Date</h6>
          {/* <div className="col-md-3 col-lg-3 col-sm-10"> */}
          {/* <span className="py-1">Enter As of Date:</span>&nbsp;&nbsp; */}
          <DatePicker
            id="dpFrm"
            defaultValue={frmDate}
            format="MM/dd/yyyy"
            calendar={CustomCalendar}
            min={minFrmDt}
            formatPlaceholder={{
              year: "yyyy",
              month: "mm",
              day: "dd",
            }}
            width={300}
            max={maxFrmDt}
            //disabled={isDisabled}
            onChange={(e) => {
              setDate(e.value);
            }}
          />
          {/* </div> */}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {/* <div className="col-md-3 col-lg-3 col-sm-10"> */}
          {/* </div> */}
          <hr></hr>
          {/* Pricing Options */}
          <h6>Price Selection</h6>
          <div style={{ marginBottom: "20px" }}>
            {[1, 2, 3].map((option) => (
              <label
                key={option}
                style={{ display: "block", marginBottom: "10px" }}
              >
                <input
                  type="radio"
                  value={option}
                  checked={fromPriceDtSelOpt === option}
                  onChange={fromPriceDtHandleRadioChange}
                />
                {option === 1
                  ? " Use Current Price"
                  : option === 2
                  ? " Use End of Month Price"
                  : " Use Non EOM Price"}
              </label>
            ))}
          </div>
          {/* Conditional Price Date Field */}
          {fromPriceDtSelOpt === 3 && (
            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="dpPriceDt"
                className="form-label"
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                From Price Date
              </label>
              <DatePicker
                id="dpPriceDt"
                value={fromPriceDt}
                calendar={CustomCalendar}
                min={new Date("1/1/1000")}
                max={maxFrmDt}
                width={300}
                onChange={(e) => setFromPriceDt(e.value)}
                style={{ width: "100%" }}
              />
            </div>
          )}
          <hr></hr>
          <h6>Investment Model</h6>
          {console.log("ModelDataaaaaaaaaaaaaaaaaaaaaaa", modelData)}
          <DropDownList
            style={{
              width: "320px",
            }}
            data={dropdownData}
            textField="modelNm"
            //valueField="modelId"
            dataItemKey="modelId"
            filterable={true}
            onFilterChange={filterChange}
            disabled={invmixcheck}
            //defaultItem={initialModelDropdown}
            value={selChangeModel}
            onChange={handleChangeAllocModel}
            // skipDisabledItems={true}
          />
          <hr></hr>
          {/* Miscellaneous Section */}
          <h6>Miscellaneous Section</h6>
          <div style={{ marginBottom: "20px" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showExcludeAsset}
                  onChange={handleExcludeAsset}
                  name="excludeAsset"
                />
              }
              label=" Show Excluded Assets"
              style={{ marginBottom: "10px", display: "block" }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <FormControlLabel
              control={<Switch checked={invmixcheck} onChange={handleInvmix} />}
              label=" Compare against Investment Target Mix"
              style={{ marginBottom: "10px", display: "block" }}
            />
          </div>
          {AcctId == 0 && (
            <FormControlLabel
              control={
                <Switch
                  checked={runInAggregate}
                  onClick={handlerunInAggregate}
                  name="chkCtr"
                />
              }
              label="Run In Aggregate."
              style={{ marginBottom: "10px", display: "block" }}
            />
          )}
          {/* Actions */}
          <hr style={{ margin: "20px 0" }} />
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntdModal>
      {loaded ? (
        <AssetVsModelGrid
          data={dataaxios}
          acct={selAcct}
          allmodelData={modelData}
          selModelId={modelId}
          flag={isColumnSave}
          priceExistFlag={priceExistFlag}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AssetAllocModelRptMain;
