import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Loading from "./loading";
import Header from "./header";
import TopHoldingsGrid from "./topHoldingsGrid";
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";

import { Modal as AntdModal, Button as AntdBtn } from "antd";
import { formatDate } from "@telerik/kendo-intl";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { FaCog, FaFilter } from "react-icons/fa";

import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";

const TopHoldingsReport = () => {
  var date = new Date(localStorage.getItem("processingDate"));
  var maxFrmDt = date;

  const [assetAllocModelRptData, setAssetAllocModelRptData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [selectedAcct, setSelectedAcct] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isColumnSave, setIsColumnSave] = useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const [enableCombo, setEnableCombo] = useState(false);
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(true);
  const [closeOption, setCloseOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState(1);

  const [flag, setFlag] = React.useState(false);
  const [showExcludeAsset, setShowExcludeAsset] = useState(true);

  const [priceDt, setpriceDt] = React.useState(date);
  const [asOfDt, setAsOfDt] = React.useState(date);
  const [priceFlagInd, setPriceFlagInd] = useState(1);
  const [marketValOptn, setMarketValOptn] = useState(false);
  const [topHoldingVal, setTopHoldingVal] = useState(10);
  const [runInAggregate, setRunInAggregate] = useState(false);

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);
  const [priceExistFlag, setPriceExistFlag] = useState("");

  const [initialState, setInitialState] = useState({
    runInAggregate: 0,
  });

  const handleAggregateMode = () => {
    if (runInAggregate == 0) {
      setRunInAggregate(true);
    }
    if (runInAggregate == 1) {
      setRunInAggregate(false);
    }
    console.log("Aggregate", runInAggregate);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;
        getTopHoldingData();
        getColumnStateDb();
      } catch (error) {
        console.error(error.message);
      }
    };
    // fetchData();
  }, []);

  const getTopHoldingData = async () => {
    setLoading(true);
    setEnableCombo(true);

    let token = tempToken;
    let AsOfId = JSON.parse(localStorage.getItem("userId"));
    let AcctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;
    //let AcctId = JSON.parse(localStorage.getItem("AccountID")).acctId;
    let consolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;
    // let consolidationId = JSON.parse(
    //   localStorage.getItem("RelationID")
    // ).cnsldtnId;
    // let PageId=1;
    let rdoOption = 0;
    let valueOrPercent = topHoldingVal;

    let PriceDt = formatDate(priceDt, "MM/dd/yyyy");
    let AsOfDt = formatDate(asOfDt, "MM/dd/yyyy");
    let priceFlag = priceFlagInd;
    let showExcldAst = showExcludeAsset;

    const postData = {
      AsOfId,
      AcctId,
      consolidationId,
      rdoOption,
      valueOrPercent,
      PriceDt,
      AsOfDt,
      priceFlag,
      showExcldAst,
      runInAggregate,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTTopHoldingsReport", postData, config)
      .then((response) => {
        const rowData = response.data;
        debugger;

        let consolidationName =
          JSON.parse(localStorage.getItem("RelationID"))?.cnsldtnNm || 0;
        if (rowData.t1 != null) {
          if (AcctId == 0 && runInAggregate == true) {
            for (let i = 0; i < rowData.t1?.length; i++)
              rowData.t1[i].extrnlAcctId = consolidationName;
          }
        }

        setAssetAllocModelRptData(rowData.t1 == null ? [] : rowData.t1);
        setPriceExistFlag(response?.data?.priceValidation);
        getColumnStateDb();
        setFlag(true);
        setLoading(false);
        setEnableCombo(false);
        //postData.modelId = response.data.acctMdl[0].modelId;
        console.log(rowData);
      })

      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }

        // return error;
      });
  };

  if (apicallval === 1) {
    getTopHoldingData();
    dispatch(callremove());
  }

  const handleChange = (event) => {
    //
    if (event.target.value === null || loading) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      getTopHoldingData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 25; //let 1 for Portfoliholdings Grid

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        console.log("save in db");
        console.log(response);
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);
        return error;
      });
  };

  const refreshToken = async () => {
    let token = tempToken;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        getTopHoldingData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  const handleSettings = () => {
    setInitialState({
      runInAggregate,
    });
    setModalVisible(true);
    setCloseOption(true);
  };

  const formSubmit = (event) => {
    debugger;
    event.preventDefault();
    // getModelInfo();
    setModalVisible(false);
    setCloseOption(false);
    getTopHoldingData();
  };

  const handleRadioChange = (e) => {
    setSelectedOption(parseInt(e.target.value));
    setPriceFlagInd(parseInt(e.target.value));
    console.log("e.target.value", e.target.value);
    console.log("e", e);
    if (e.target.value == 1 || e.target.value == 2) {
      setpriceDt(date); // to be checked
    }
    // console.log("selectedOption", selectedOption);
    // console.log("Selected Price Date", priceDt);
  };

  const handleRadioChangeMkt = (e) => {
    debugger;
    setMarketValOptn(e.currentTarget.checked);
    if (!e.currentTarget.checked) setTopHoldingVal(10);
  };

  const handleTopHoldingVal = (e) => {
    debugger;
    setTopHoldingVal(e.target.value);
  };

  const handleCancel = () => {
    setRunInAggregate(initialState.runInAggregate);

    setModalVisible(false);
    setCloseOption(false);
    !closeOption && navigate("/dashboard");
  };

  const handleExcludeAsset = (e) => {
    setShowExcludeAsset(e.target.checked ? true : false);
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    <div>
      {/* <Header></Header> */}
      <div className="row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
        <div className="col reportheading fs-6">Top Holdings Report</div>

        {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}

        {/* Filter Button */}
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>
      </div>

      <AntdModal
        open={modalVisible}
        title="Customize Top Holdings Report"
        closable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          {/* <hr /> */}

          <h6>As Of Date Selection</h6>
          <div style={{ marginBottom: "20px" }}>
            <label
              key={marketValOptn}
              style={{ display: "block", marginBottom: "10px" }}
            >
              &nbsp; Enter As Of Date:
              <DatePicker
                id="dpAsOfDate"
                value={asOfDt}
                calendar={CustomCalendar}
                min={new Date("1/1/1000")}
                max={maxFrmDt}
                onChange={(e) => setAsOfDt(e.value)}
                style={{ width: "100%" }}
              />
            </label>
          </div>
          <hr />
          {/* Pricing Options */}
          <h6>Select Pricing Option</h6>
          <div style={{ marginBottom: "20px" }}>
            {[1, 2, 3].map((option) => (
              <label
                key={option}
                style={{ display: "block", marginBottom: "10px" }}
              >
                <input
                  type="radio"
                  value={option}
                  checked={selectedOption === option}
                  onChange={handleRadioChange}
                />
                {option === 1
                  ? " Use Current Price"
                  : option === 2
                  ? " Use End of Month Price"
                  : " Use Non EOM Price"}
              </label>
            ))}
          </div>

          {/* Conditional Price Date Field */}
          {selectedOption === 3 && (
            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="dpPriceDt"
                className="form-label"
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                Price Date
              </label>
              <DatePicker
                id="dpPriceDt"
                value={priceDt}
                calendar={CustomCalendar}
                min={new Date("1/1/1000")}
                max={maxFrmDt}
                onChange={(e) => setpriceDt(e.value)}
                style={{ width: "100%" }}
              />
            </div>
          )}

          <hr></hr>

          {/* Miscellaneous Section */}
          <h6>Miscellaneous Section</h6>
          <div style={{ marginBottom: "20px" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showExcludeAsset}
                  onChange={handleExcludeAsset}
                  name="excludeAsset"
                />
              }
              label=" Show Excluded Assets"
              style={{ marginBottom: "10px", display: "block" }}
            />
          </div>
          {AcctId == 0 && (
            <FormControlLabel
              control={
                <Switch
                  checked={runInAggregate}
                  onClick={handleAggregateMode}
                  name="chkCtr"
                />
              }
              label="Run In Aggregate."
              style={{ marginBottom: "10px", display: "block" }}
            />
          )}
          <hr />
          <h6>Advanced Option</h6>
          <div style={{ marginBottom: "20px" }}>
            <label
              key={marketValOptn}
              style={{ display: "block", marginBottom: "10px" }}
            >
              <input
                type="checkbox"
                value={marketValOptn}
                checked={marketValOptn}
                onChange={handleRadioChangeMkt}
              />
              &nbsp; By Market Value.Top{" "}
              <input
                type="number"
                min="1"
                max="999999999"
                disabled={!marketValOptn}
                value={topHoldingVal}
                onChange={handleTopHoldingVal}
              ></input>
              &nbsp; Holdings
            </label>
          </div>

          {/* Actions */}
          <hr style={{ margin: "20px 0" }} />
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntdModal>

      {flag ? (
        <TopHoldingsGrid
          data={assetAllocModelRptData}
          acct={selAcct}
          flag={isColumnSave}
          priceExistFlag={priceExistFlag}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default TopHoldingsReport;
