import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SelectControl from "./selectcontrol";
import Loading from "./loading";
import Header from "./header";
import { CustomCalendar } from "./customCalendar";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import { FaFilePdf } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import BankLogoPage from "./bankLogoPage";
// import AcctSectBenchRptGrid from './AccountSectBenchGrid';
import "@progress/kendo-theme-material/dist/all.css";
import { filterBy } from "@progress/kendo-data-query";
import AcctPerfRptGrid from "./AcctPerfRptGrid";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { FaFilter } from "react-icons/fa";
import { Modal as AntModal } from "antd";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "@telerik/kendo-intl";
import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";
import { FormControlLabel, Switch } from "@mui/material";

const AcctPerfRpt = () => {
  const [AcctPerfRptData1, populateAcctPerfRptData1] = useState([]);
  const [AcctPerfRptData2, populateAcctPerfRptData2] = useState([]);
  const [AcctPerfRptData3, populateAcctPerfRptData3] = useState([]);
  const [ReportData, popReportData] = useState([]);
  const [AcctList, populateAccountListData] = useState([]);
  const [enableCombo, setEnableCombo] = useState(false);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  //const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [loading, setLoading] = useState(true);
  // const [frmDate, setfrmDate] = React.useState("");
  var date = new Date(localStorage.getItem("processingDate"));

  //date.setMonth(date.getMonth() - 12);
  const [frmDate, setfrmDate] = React.useState(date);

  const [toDate, setToDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const [isDisabled, setisDisabled] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const [flagHolding, setflagHolding] = React.useState(false);
  //const value = new Date("2022-04-22");
  const minFrmDt = new Date(2021, 8, 14);
  const maxFrmDt = new Date(2022, 8, 14);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);
  const [closeOption, setCloseOption] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [netFeeInd, setNetFeeInd] = useState(1);
  const [assetTypLst, setAssetTypLst] = useState([]);
  const [assetType, setAssetType] = useState();

  useEffect(() => {
    const fetchData = async () => {
      //
      setLoading(true);
      setEnableCombo(true);

      try {
        let asOfId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        GetAcctPerfRptData();
      } catch (error) {
        console.error(error.message);
      }
    };
    // fetchData();
    GetAssetType();
  }, []);

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetAcctPerfRptData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  let allPDFExport;
  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);

  function setDate(val) {
    // if(val.language === '1'){
    //     const fDate = new Date("2021-04-22");

    //     setfrmDate(fDate);
    //     const setisDisabled = false;
    // }
    // if(val.language === '2'){
    //     const fDate = new Date("2021-01-22");

    //     setfrmDate(fDate);
    //     const setisDisabled = true;
    // }
    if (val == null) {
      setfrmDate(frmDate);
    } else {
      setfrmDate(val);
    }
  }

  const searchClick = () => {
    GetAcctPerfRptData();
  };
  const handleSettings = () => {
    setModalVisible(true);
    setCloseOption(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setCloseOption(false);

    !closeOption && navigate("/dashboard");
  };

  const assetTypeChange = (event) => {
    debugger;

    if (event.target.value === null) {
      debugger;
      setAssetType({
        cdId: "na",
        cdVlDesc: "None",
      });
    } else {
      debugger;
      setAssetType(event.target.value);
    }
  };

  // const handleCheckboxChange = () => {
  //   if (setNetFeeInd == 0) {
  //     setNetFeeInd(1);
  //   } else {
  //     setNetFeeInd(0);
  //   }
  // };


  const handleCheckboxChange = () => {
    setNetFeeInd((prevValue) => (prevValue === 1 ? 0 : 1));
  };

  const formSubmit = (event) => {
    event.preventDefault();
    setModalVisible(false);
    setCloseOption(false);
    setLoading(true);
    GetAcctPerfRptData();
  };

  const GetAssetType = async () => {
    setLoading(true);
    let userId = JSON.parse(localStorage.getItem("userId"));
    const postData = { userId };
    let token = tempToken;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post("/GetAssetType", postData, config)
      .then((response) => {
        setAssetTypLst(response.data?.ocAssetType);
        setAssetType(response.data?.ocAssetType[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response.status === 401) {
          refreshToken();
        }
      });
  };

  const GetAcctPerfRptData = async () => {
    !modalVisible && setLoading(true);
    setEnableCombo(true);

    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let AsOfId = JSON.parse(localStorage.getItem("userId"));
    let lsSelectedAcct = JSON.parse(localStorage.getItem("ASectSelectedAcct"));
    // let AcctId = JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    //let AcctId = 0;
    let PageId = 1;
    // if (lsSelectedAcct !== null) {
    //   AcctId = lsSelectedAcct.acctId;
    // }
    let AcctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;
    let consolidationId = JSON.parse(localStorage.getItem("RelationID"))
      ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
      : 0;
    let StartDate = formatDate(frmDate, "MM/dd/yyyy");
    let EndDate = formatDate(toDate, "MM/dd/yyyy");
    let astType = assetType.cdId;

    const postData = {
      AsOfId,
      AcctId,
      StartDate,
      EndDate,
      consolidationId,
      netFeeInd,
      astType,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    debugger;
    await axios
      .post("/AccountPerformanceSummary", postData, config)
      .then((response) => {
        //  console.log(response);
        console.log("response", response);
        //
        const rowData = response?.data;

        populateAcctPerfRptData1(
          rowData.lstAccountPerformanceSummaryActivity == null
            ? []
            : rowData.lstAccountPerformanceSummaryActivity
        );
        populateAcctPerfRptData2(
          rowData.lstAccountPerformanceSummaryAllocation == null
            ? []
            : rowData.lstAccountPerformanceSummaryAllocation
        );
        populateAcctPerfRptData3(
          rowData.lstAccountPerformanceSummaryROR == null
            ? []
            : rowData.lstAccountPerformanceSummaryROR
        );
        populateAccountListData(
          rowData.lstAcctPerfAcctList == null ? [] : rowData.lstAcctPerfAcctList
        );
        // setSelAcctData(
        //   rowData.lstAcctPerfAcctList == null ? [] : rowData.lstAcctPerfAcctList
        // );
        setSelAcctData(selAcctData);
        popReportData(rowData);
        // if (lsSelectedAcct !== null) {
        //   SetselAcct(lsSelectedAcct);
        // }
        // else {
        //   SetselAcct(rowData.lstAcctPerfAcctList[0]);
        // }

        debugger;
        if (rowData.lstAccountPerformanceSummaryAllocation != null)
          localStorage.setItem(
            "AcctAllocInitialData",
            JSON.stringify(rowData.lstAccountPerformanceSummaryAllocation)
          );
        setLoading(false);
        setEnableCombo(false);
        setFlag(1);
      })
      .catch((error) => {
        console.log("error", error);

        if (error.response.status === 401) {
          refreshToken();
        }
        //return error;
      });
  };

  if (apicallval === 1) {
    GetAcctPerfRptData();
    dispatch(callremove());
  }

  const filterData = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };
  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };

  const handleChange = (event) => {
    //
    if (event.target.value === null || loading) {
      //SetselAcct('');
      SetselAcct(selAcct);
      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem("AcctSelected", JSON.stringify(selAcct));
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetAcctPerfRptData();
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };
  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header>
            <div className="my-1">
              <div className="row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
                <div className="col-md-4 col-lg-5 col-sm-11 text-start">
                  <span className="col-md-2 px-2">Account(s)</span>
                  <ComboBox
                    style={{
                      width: "350px",
                    }}
                    data={selAcctData}
                    textField="extrnlAcctId"
                    dataItemKey="acctId"
                    filterable={true}
                    value={selAcct}
                    onChange={handleChange}
                    onFilterChange={filterChange}
                    disabled={enableCombo}
                  />
                </div>
              </div>
            </div> */}
            {/* <form onSubmit={formSubmit}>
              <div className="row d-flex align-items-end justify-content-center py-2 mx-1 border-0 shadow-none">
                <div className="col-md-3 col-lg-3 col-sm-10">
                  <span className="py-1">From</span>
                  <DatePicker
                    id="dpFrm"
                    defaultValue={frmDate}
                    format="MM/dd/yyyy"
                    formatPlaceholder={{
                      year: "yyyy",
                      month: "mm",
                      day: "dd",
                    }}
                    //min={minFrmDt}
                    max={
                      new Date(
                        `${frmDate.getFullYear()},${(frmDate.getMonth() + 1)
                          .toString()
                          .padStart(2, "0")},${frmDate
                          .getDate()
                          .toString()
                          .padStart(2, "0")}`
                      )
                    }
                    disabled={isDisabled}
                    onChange={(e) => {
                      setDate(e.value);
                    }}
                  />
                </div>

                <div className="col-md-3 col-lg-3 col-sm-10">
                  <span className="py-1">To</span>
                  <DatePicker
                    disabled={false}
                    formatPlaceholder={{
                      year: "yyyy",
                      month: "mm",
                      day: "dd",
                    }}
                    defaultValue={toDate}
                    format="MM/dd/yyyy"
                  />
                </div>

                <div className="col-md-2 col-lg-2 col-sm-10">
                  <input
                    type="submit"
                    className="btn btn-primary btn-sm"
                    value="Submit"
                  />
                </div>
              </div>
            </form> */}
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    <div style={{ overflow: "hidden" }}>
      {/* <Header></Header>
      <div className="my-1">
        <div className="row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
          <div className="col-md-4 col-lg-5 col-sm-11 text-start">
            <span className="col-md-2 px-2">Account(s)</span>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
              disabled={enableCombo}
            />
          </div>
        </div>
      </div> */}
      <AntModal
        open={modalVisible}
        title="Customize the Account Performance Summary Report"
        // onCancel={handleCancel}
        closable={false}
        // maskClosable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpAsOfDt"
              className="form-label"
              style={{
                display: "block",
                fontWeight: 600,
                marginBottom: "5px",
              }}
            >
              From
            </label>
            <DatePicker
              id="dpFrm"
              value={frmDate}
              // format="MM/dd/yyyy"
              // calendar={CustomCalendar}
              // formatPlaceholder={{
              //   year: "yyyy",
              //   month: "mm",
              //   day: "dd",
              // }}
              //min={minFrmDt}
              valid={frmDate <= date}
              max={date}
              disabled={isDisabled}
              onChange={(e) => {
                setfrmDate(e.value);
              }}
              validationMessage={
                frmDate == null
                  ? "Enter Valid Date"
                  : frmDate < new Date("1/1/1000")
                  ? "Enter date in correct format (mm/dd/yyyy)"
                  : frmDate > date
                  ? "From date cannot exceed Processing date."
                  : frmDate > toDate
                  ? "From date cannot exceed to date."
                  : frmDate < new Date("1/2/1000")
                  ? "From date is not valid."
                  : "Validation error"
              }
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpAsOfDt"
              className="form-label"
              style={{
                display: "block",
                fontWeight: 600,
                marginBottom: "5px",
              }}
            >
              To
            </label>
            <DatePicker
              //disabled={false}
              value={toDate}
              max={date}
              // formatPlaceholder={{
              //   year: "yyyy",
              //   month: "mm",
              //   day: "dd",
              // }}
              // format="MM/dd/yyyy"
              valid={frmDate <= toDate && toDate <= date && toDate != null}
              onChange={(e) => {
                setToDate(e.value);
              }}
              // calendar={CustomCalendar}
              validationMessage={
                toDate == null
                  ? "Enter Valid Date"
                  : toDate < new Date("1/1/1000")
                  ? "Enter date in correct format (mm/dd/yyyy)"
                  : toDate > date
                  ? "To date cannot exceed Processing date."
                  : toDate < frmDate
                  ? "To date cannot be less than from date"
                  : toDate < new Date("1/2/1000")
                  ? "To date is not valid."
                  : "Validation error"
              }
            />
          </div>
          <hr></hr>
          <div className="col">
            <div style={{ fontWeight: 600 }}>Display Results By:</div>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={assetTypLst}
              textField="cdVlDesc"
              dataItemKey="cdId"
              value={assetType}
              onChange={assetTypeChange}
            />
          </div>
          <hr></hr>

          <h6>Miscellaneous Section</h6>

          {/* <label>
            <input
              type="checkbox"
              checked={netFeeInd}
              onChange={handleCheckboxChange}
            />
            &nbsp; Net Fees in the ROR Calculation.
          </label> */}

          <FormControlLabel
            control={
              <Switch
                checked={netFeeInd === 1}
                onClick={handleCheckboxChange}
                name="chkCtr"
              />
            }
            label="Net Fees in the ROR Calculation."
            style={{ marginBottom: "10px", display: "block" }}
          />

          {/* <hr style={{ margin: "20px 0" }} /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit"  disabled={assetType.cdId === "na"}>
              Submit
            </button>
          </div>
        </form>
      </AntModal>
      <div className="row d-flex justify-content-between align-items-center mx-2 py-1 border-bottom">
        {/* Report Heading */}
        <div className="col reportheading fs-6">
          Account Performance Summary Report
        </div>

        {/* Placeholder for Additional Information (if needed) */}
        <div className="col text-center">
          {/* Add dynamic text here if required */}
        </div>

        {/* Filter Button */}
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>
      </div>
      {/* <div className="col-md">
        <p className="reportheading fs-6">Account Performance Summary Report</p>
      </div> */}
      {/* <form onSubmit={formSubmit}>
        <div className="row d-flex align-items-end justify-content-center py-2 mx-1 border-0 shadow-none">
          <div className="col-md-2 col-lg-2 col-sm-10">
            <input
              type="submit"
              className="btn btn-primary btn-sm"
              value="Submit"
            />
          </div>
        </div>
      </form> */}
      {flag === 1 && !modalVisible ? (
        <AcctPerfRptGrid
          data={AcctPerfRptData1}
          alloc={AcctPerfRptData2}
          ror={AcctPerfRptData3}
          reportdata={ReportData}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AcctPerfRpt;
