import React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import {
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import Moment from "moment";
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ColumnMenu, ColumnMenuCheckboxFilter } from "./columnMenu";
import { process } from "@progress/kendo-data-query";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import Modal from "react-bootstrap/Modal";
import Loading from "./loading";
import Enumerable from "linq";
import { orderBy } from "@progress/kendo-data-query";
import BankLogoPage from "./bankLogoPage";
import { Card, DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { FaEye, FaFileExcel, FaFilePdf } from "react-icons/fa";
import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";
import { groupBy } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import moment from "moment";
const AccountProfileDetails = ({
  chartColor,
  data,
  acct,
  allmodelData,
  selModelId,
  dispAssetClass,
  priceExistFlag,
  title,
  runInAggregate,
  showExcludedAssets,
  IsRunAsAggregate,
  chkRptDispOptn,
  asOfDt,
  priceDt,
}) => {
  console.log("As Of Date", asOfDt);

  const [PTitle, setPTitle] = useState(title);
  const [runAsAggregate, setRunAsAggregate] = useState(IsRunAsAggregate);
  const [ChkRptDispOptn, setChkRptDispOptn] = useState(chkRptDispOptn);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [sortOrder, setSortOrder] = React.useState([
    {
      field: "groupId",
      dir: "asc",
    },
  ]);
  const initialGroup = [
    {
      field: "accountNumber",
    },
  ];
  debugger;
  const aggregates = [
    {
      field: "txCstAmt",
      aggregate: "sum",
    },
    {
      field: "market",
      aggregate: "sum",
    },
    {
      field: "income",
      aggregate: "sum",
    },
    {
      field: "yield",
      aggregate: "sum",
    },
    {
      field: "marketPercent",
      aggregate: "sum",
    },

    // {
    //   field: "principalCash",
    //   aggregate: "sum",
    // },
    // {
    //   field: "incomeCash",
    //   aggregate: "sum",
    // },
    // {
    //   field: "investedIncome",
    //   aggregate: "sum",
    // }
  ];
  // const processWithGroups = (data, group) => {
  //   const newDataState = groupBy(data, group);
  //   setGroupIds({
  //     data: newDataState,
  //     group: group,
  //   });
  //   return newDataState;
  // };

  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  const processWithGroups = (data, group) => {
    const groups = group;

    if (groups) {
      groups.map((group) => (group.aggregates = aggregates));
    }

    // dataState.group = groups;
    const newDataState = groupBy(data, groups);
    setGroupIds({
      data: newDataState,
      group: groups,
    });
    return newDataState;
  };
  const [collapsedState, setCollapsedState] = React.useState([]);

  const [group, setGroup] = React.useState(initialGroup);
  const [resultState, setResultState] = React.useState(
    processWithGroups(data, initialGroup)
  );

  const onExpandChange = React.useCallback(
    (event) => {
      const item = event.dataItem;
      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState]
  );
  debugger;
  const newData = setExpandedState({
    data: resultState,
    collapsedIds: collapsedState,
  });

  // For Preview Active Report Start
  const [show, setShow] = React.useState(false);
  // const [title, setTitle] = React.useState("Asset Class");
  //const [loadingChild, setLoadingChild] = useState(true);
  const viewerRef = React.useRef();
  async function loadReport() {
    // load report definition from the file

    const reportResponse = await fetch("Accountprofile.rdlx-json");

    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    const report = await loadReport();

    const parameters = [
      // { Name: "pPriceDate", Value: [localStorage.getItem("processingDate")] },
      { Name: "pPriceDate", Value: [priceDt] },
      // { Name: "pAsofDate", Value: [localStorage.getItem("processingDate")] },
      { Name: "pAsofDate", Value: [asOfDt] },
      {
        Name: "pCnsldtnNm",
        Value: [
          JSON.parse(localStorage.getItem("RelationID")) != null
            ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm === ""
              ? 0
              : JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm
            : 0,
        ],
      },
      {
        Name: "pExtrnlAcctId",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 0
            : JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId,
        ],
      },
      {
        Name: "pShow",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId != ""
            ? 2
            : runAsAggregate == true
            ? 2
            : 3,
        ],
      },
      {
        Name: "pTitle",
        Value: [PTitle],
      },
      // pAggregate = 1 -> account select = 2 -> Relationship and Run in Aggregate select = 3 -> Relationship and No run in aggregate
      {
        Name: "pAggregate",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId != ""
            ? 1
            : runAsAggregate == true
            ? 2
            : 3,
        ],
      },
      {
        Name: "pExclude",
        Value: [ChkRptDispOptn == true ? 1 : 0],
      },
    ];
    for (let i = 0; i < data.length; i++) {
      data[i].incptnDt = Moment(data[i].incptnDt).format("MM/DD/YYYY");
    }
    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(data);

    //if(loadingChild==false)
    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }
  //For Preview Active Report End
  const [sort, setSort] = React.useState([]);

  const [selChangeModel, setSelChangeModel] = React.useState([]);

  const accountVsModelFinalData = data;

  // orderBy(
  //   Enumerable.from(data)
  //     .join(
  //       chartColor,
  //       (pk) => pk.groupId,
  //       (fk) => fk.mjrAstTypId,
  //       (left, right) => ({ ...left, ...right })
  //     )
  //     .toArray(),
  //   sortOrder
  // );

  // const a = [2, 4, 6];

  // Use reduce to calculate the sum
  // const sum = a.reduce((acc, x) => acc + x, 0);

  // console.log(sum);

  var totalMarket = accountVsModelFinalData.reduce(
    (a, v) => (a = a + v.market),
    0
  );

  console.log("totalMarket", totalMarket);
  var result = Enumerable.from(accountVsModelFinalData)
    .groupBy("$.groupName", null, function (key, g) {
      var result = {
        groupName: key,
        total: g.sum("$.market"),
        percentage: g.sum("$.market") / totalMarket,
      };
      return result;
    })
    .where((w) => w.total > 0)
    .toArray();

  const initialDataState = {};
  const initialDataStatePortfolio = {};
  const initialDataStateTopHolding = {};

  let _pdfExport;
  const [dataState, setDataState] = React.useState();
  // const [resultState, setResultState] = React.useState(
  //   process(data, initialDataState)
  // );
  const [dataStatePortfolio, setDataStatePortfolio] = React.useState();

  const [dataStateTopHolding, setDataStateTopHolding] = React.useState();

  //const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const _export = React.useRef(null);
  const showpreview = async () => {
    setShow(true);
  };
  const excelExport = () => {
    setShow(true);
    openReport();
    // if (_export.current !== null) {
    //     _export.current.save(data);
    // }
  };

  let allPDFExport;
  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };

  const PageTemplate = (props) => {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            top: "1px",

            width: "98%",
            borderBottom: "1px solid #bce8f1",
          }}
        >
          <div className="row d-flex mx-3">
            <div className="col text-start">
              <a className="px-2">
                <BankLogoPage />
              </a>
            </div>
            <div className="col text-end px-5 py-2">
              <h2 className="reportheading fs-6 col-md-3 col-lg-3 col-sm-10">
                Account Profile Report{" "}
              </h2>
            </div>
          </div>

          {/* <div className='fw-bold  text-center'>
                        Account Profile</div> */}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            //  borderTop: "1px solid #bce8f1"
          }}
        >
          Page {props.pageNum} of {props.totalPages}
        </div>
      </div>
    );
  };
  const NumberCell = (props) => {
    return (
      <td style={{ textAlign: "right" }}>
        {formatNumber(props.dataItem[props.field], "##,#.00")}
      </td>
    );
  };

  const getCells = (columns, cellProps) => {
    let cells = [];
    debugger;
    columns.forEach((column) => {
      var colStatus = Enumerable.from(stateColumns)

        .where((w) => w.field === column.field)

        .toArray();

      //if( colStatus.length!=0 && colStatus[0].show){
      let totalyield =
        (isNaN(
          cellProps.dataItem.aggregates.income.sum /
            cellProps.dataItem.aggregates.market.sum
        )
          ? 0
          : cellProps.dataItem.aggregates.income.sum /
            cellProps.dataItem.aggregates.market.sum) * 100;
      if (column.aggregate) {
        if (column.field === "yield") {
          cells.push(
            <td style={{ textAlign: "right" }}>
              {formatNumber(
                totalyield,

                "##,#.00"
              )}
            </td>
          );
        } else {
          cells.push(
            <td style={{ textAlign: "right" }}>
              {formatNumber(
                cellProps.dataItem.aggregates[column.field][column.aggregate],

                "##,#.00"
              )}
            </td>
          );
        }
      } else {
        cells.push(<td>&nbsp;</td>);
      }

      // }
    });

    return cells;
  };

  const stateColumns = [
    // {
    //   title: "Account#",
    //   field: "accountNumber",
    //   minWidth: 150,
    //   show: true,
    //   filter: "text",
    //   locked: true,
    // },
    {
      title: "Major Asset name",
      field: "accountName",
      minWidth: 150,
      show: false,
      filter: "text",
      locked: true,
    },
    {
      title: "Ticker",
      field: "groupName",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: true,
    },
    {
      title: "Cusip",
      field: "txCstAmt",
      minWidth: 180,
      show: true,
      filter: "text",
      locked: true,
    },
    {
      title: "Asset",
      field: "market",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: true,
    },
    {
      title: "Asset",
      field: "income",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: true,
    },
    {
      title: "Asset",
      field: "yield",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: true,
    },
    {
      title: "Asset",
      field: "marketPercent",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: true,
    },
  ];

  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      //IMPORTANT - You need to add collection with the columns and their field name

      //you can define the Grid columns outside of the Grid and reuse them here.

      const columns = [
        // { field: "accountNumber" },

        // { field: "accountName" },

        { field: "groupName" },

        { field: "txCstAmt", aggregate: "sum" },
        { field: "market", aggregate: "sum" },

        { field: "income", aggregate: "sum" },

        { field: "yield", aggregate: "sum" },

        { field: "marketPercent", aggregate: "sum" },
      ];

      if (!tdElement.props.colSpan) {
        return <td></td>;
      }

      let currentColumns = stateColumns.filter((c) => c.show);

      currentColumns = currentColumns.map((col) => {
        let aggrCol = columns.find((c) => {
          return c.field == col.field;
        });

        if (aggrCol && aggrCol.aggregate) {
          console.log("aggregate");

          return {
            ...col,

            aggregate: aggrCol.aggregate,
          };
        } else {
          return col;
        }
      });

      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - currentColumns.length}
          ></td>

          {getCells(
            currentColumns.sort((a, b) => {
              return a.orderIndex - b.orderIndex;
            }),

            cellProps
          )}
        </>
      );
    }

    // if (cellProps.rowType === "groupFooter") {
    //   if (cellProps.field === "market") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(cellProps.dataItem.aggregates.market.sum, "##,#.00")}
    //       </td>
    //     );
    //   }

    //   if (cellProps.field === "cost") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(cellProps.dataItem.aggregates.cost.sum, "##,#.00")}
    //       </td>
    //     );
    //   }

    //   if (cellProps.field === "estAnnInc") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.estAnnInc.sum,

    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }

    //   if (cellProps.field === "unrGainLoss") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.unrGainLoss.sum,

    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }

    //   if (cellProps.field === "investedIncome") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.investedIncome.sum,

    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }

    //   if (cellProps.field === "yield") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.yield.average,

    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }

    //   if (cellProps.field === "accruedInterest") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(
    //           cellProps.dataItem.aggregates.accruedInterest.average,

    //           "##,#.00"
    //         )}
    //       </td>
    //     );
    //   }

    //   // if (cellProps.field === "acrdIncmAmt") {

    //   //   return (

    //   //     <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>

    //   //       {formatNumber(cellProps.dataItem.aggregates.acrdIncmAmt.sum, "##,#.00")}

    //   //     </td>

    //   //   );

    //   // }
    // }

    if (cellProps.rowType === "data") {
      if (cellProps.field === "market") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "income") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "marketPercent") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "yield") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "yldToCost") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "accruedInterest") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "acrdIncmAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "principalCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "incomeCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "investedIncome") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "txCstAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "cost") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "shares") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "priceDate") {
        let cdt = new Date(cellProps.dataItem["priceDate"]);

        if (moment(cellProps.dataItem["priceDate"]).isValid()) {
          return (
            <td
              style={{ textAlign: "left" }}
              data-grid-col-index={cellProps.columnIndex}
            >
              {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
              {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
            </td>
          );
        } else {
          return (
            <td
              style={{ textAlign: "left" }}
              data-grid-col-index={cellProps.columnIndex}
            >
              {cellProps.dataItem["priceDate"]}
            </td>
          );
        }
      }

      if (cellProps.field === "holdingDate") {
        let cdt = new Date(cellProps.dataItem["holdingDate"]);

        if (!isNaN(cdt.getTime())) {
          return (
            <td
              style={{ textAlign: "left" }}
              data-grid-col-index={cellProps.columnIndex}
            >
              {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
              {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
            </td>
          );
        } else {
          return <td style={{ textAlign: "left" }}>&nbsp;</td>;
        }
      }

      if (cellProps.field === "price") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "costPerShare") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "costPerShare") {
        return (
          <td
            style={{ textAlign: "right" }}
            data-grid-col-index={cellProps.columnIndex}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "priceDate") {
        let cdt = new Date(cellProps.dataItem["priceDate"]);

        if (!isNaN(cdt.getTime())) {
          return (
            <td
              style={{ textAlign: "left" }}
              data-grid-col-index={cellProps.columnIndex}
            >
              {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
              {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
            </td>
          );
        } else {
          return <td style={{ textAlign: "left" }}>&nbsp;</td>;
        }
      }
    }

    return tdElement;
  };

  //const labelContent1 = (e) => `${e.value.toFixed(2)}%`;
  const labelContent = (e) => `${formatNumber(e.percentage, "##,#.00")}%`;
  const labelContent1 = (props) => {
    let formatedNumber = Number(props.dataItem.percentage).toLocaleString(
      undefined,
      {
        style: "percent",
        minimumFractionDigits: 2,
      }
    );
    return `${props.category}  ${formatNumber(
      props.dataItem.percentage,
      "p2"
    )}`;
  };

  // const labelContent1 = (props) => {
  //   debugger;
  //   let formatedNumber = Number(props.percentage).toLocaleString(undefined, {
  //     style: "percent",
  //     minimumFractionDigits: 2,
  //   });
  //   return `${props.category}  ${formatNumber(props.percentage, "##,#.00")}%`;
  // };

  const labelContentAccVMdl = (props) => {
    let formatedNumber = Number(props.dataItem.mdlWegh).toLocaleString(
      undefined,
      {
        style: "percent",
        minimumFractionDigits: 2,
      }
    );
    return `${props.category}  ${formatNumber(
      props.dataItem.mdlWegh,
      "##,#.00"
    )}%`;
  };

  const defaultTooltipRender = ({ point }) =>
    `${formatNumber(point.value, "##,#.00")}%`;

  const totalSum = (props) => {
    const field = props.field || "";
    const total = data.reduce((acc, current) => acc + current[field], 0);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };
  const totalYield = (props) => {
    const field = props.field || "";
    const totalIncome = data.reduce(
      (acc, current) => acc + current["income"],
      0
    );
    const totalMarket = data.reduce(
      (acc, current) => acc + current["market"],
      0
    );
    const totalYeild = (totalIncome / totalMarket) * 100;
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(totalYeild, "##,#.00")}
      </td>
    );
  };

  const onDataStateChange = React.useCallback((e) => {
    setDataState(e.dataState);
    // let gridData = JSON.parse(localStorage.getItem('gridData'));
    // const groups = e.dataState.group;

    // if (groups) {
    //   groups.map((group) => (group.aggregates = aggregates));
    // }
    // e.dataState.group = groups;
    debugger;

    let updatedState = createDataState(e.dataState);
    //

    setResultState(processWithGroups(data, updatedState.dataState));

    // setResultState(processWithGroups(data, initialGroup));
    //setResultState(process(data, e.dataState));
  }, []);

  const menuWithExcelCheck = (props) => {
    return (
      <div>
        <ColumnMenuCheckboxFilter {...props} data={data} />
      </div>
    );
  };

  //   let acctId = JSON.parse(localStorage.getItem("AccountID")).acctId;

  const grid = (
    <Grid
      style={{ height: "450px" }}
      data={newData}
      groupable={{
        footer: "none",
        enabled: false,
      }}
      //onGroupChange={onGroupChange}
      group={group}
      cellRender={cellRender}
      sortable={false}
      onDataStateChange={onDataStateChange}
      onExpandChange={onExpandChange}
      expandField="expanded"
      {...dataState}
    >
      {/* {JSON.parse(localStorage.getItem("AccountID")).acctId == 0 ? (
        <Column
          field="accountNumber"
          menu={true}
          title="Account Number"
          // show = {JSON.parse(localStorage.getItem("AccountID")).acctId == 0 ? true : false}
          width="auto"
        />
      ) : (
        <></>
      )}

      {JSON.parse(localStorage.getItem("AccountID")).acctId == 0 ? (
        <Column
          field="accountName"
          menu={true}
          title="Account Name"
          // show = {JSON.parse(localStorage.getItem("AccountID")).acctId == 0 ? true : false}
          width="auto"
        />
      ) : (
        <></>
      )} */}

      {console.log("Price Flag ", priceExistFlag)}

      {priceExistFlag != "" ? (
        <GridNoRecords>
          <div style={{ color: "red", textAlign: "left" }}>
            {priceExistFlag}
            {console.log("No Price Exist for the date")}
          </div>
        </GridNoRecords>
      ) : (
        <>{console.log("Price Exist For the date")}</>
      )}

      <Column field="groupName" menu={true} title={title} width="auto" />
      <Column
        field="txCstAmt"
        menu={true}
        title="Total Cost($)"
        width="auto"
        //footerCell={totalSum}
        //cell={NumberCell}
        filter={"numeric"}
        headerClassName="rightHeader"
      />
      <Column
        field="market"
        menu={true}
        title="Market Value($)"
        width="auto"
        // footerCell={totalSum}
        // cell={NumberCell}
        filter={"numeric"}
        headerClassName="rightHeader"
      />

      <Column
        field="income"
        menu={true}
        title="Income($)"
        width="auto"
        // footerCell={totalSum}
        // cell={NumberCell}
        filter={"numeric"}
        headerClassName="rightHeader"
      />

      <Column
        field="yield"
        menu={true}
        title="Yield(%)"
        width="auto"
        //  footerCell={totalYield}
        // cell={NumberCell}
        filter={"numeric"}
        headerClassName="rightHeader"
      />
      <Column
        field="marketPercent"
        title="Market Value(%)"
        width="auto"
        // footerCell={totalSum}
        //cell={NumberCell}
        filter={"numeric"}
        headerClassName="rightHeader"
      />
    </Grid>
  );

  const incptnDt =
    data.length > 0 ? Moment(data[0].incptnDt).format("MM/DD/YYYY") : "";
  return (
    <div>
      <div>
        <div className="card-header d-flex justify-content-end align-items-center">
          <div className="tableheader mx-2">
            Due to rounding, percentage may not equal 100.
          </div>
          <div>
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={excelExport}
            >
              <FaEye /> Preview
            </button>
          </div>
        </div>
        {(JSON.parse(localStorage.getItem("AccountID")).acctId != 0 ||
          (JSON.parse(localStorage.getItem("AccountID")).acctId == 0 &&
            runInAggregate)) &&
        data.length > 0 ? (
          <div className="row d-flex justify-content-center">
            <Card
              style={{
                width: 1400,
                borderRadius: "15px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <div className="row d-flex justify-content-between">
                <div className="col text-start">
                  <div className="reportheading fs-6">
                    <p>Administrative Information</p>
                  </div>
                  {!(
                    JSON.parse(localStorage.getItem("AccountID")).acctId == 0 &&
                    runInAggregate
                  ) ? (
                    <>
                      {" "}
                      <p>
                        <b>Account Title:</b>{" "}
                        {data.length > 0 ? data[0].accountName : ""}
                      </p>
                      <p>
                        <b>Account Number:</b>{" "}
                        {data.length > 0 ? data[0].accountNumber : ""}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        <b>Relationship:</b>{" "}
                        {
                          JSON.parse(localStorage.getItem("RelationID"))
                            ?.cnsldtnNm
                        }
                      </p>
                    </>
                  )}
                  <p>
                    <b>Administrator:</b>{" "}
                    {data.length > 0 ? data[0].administrator : ""}
                  </p>
                  <p>
                    <b>Capacity:</b> {data.length > 0 ? data[0].capacity : ""}
                  </p>
                  <p>
                    <b>Inception Date:</b> {incptnDt}
                  </p>
                  <p>
                    <b>YTD Gain/Loss:</b>{" "}
                    {data.length > 0 ? data[0].ytdGainLoss : ""}
                  </p>
                </div>
                <div className="col text-end">
                  <div className="reportheading fs-6">
                    <p>Investment Profile</p>
                  </div>
                  <p>
                    <b>Investment Officer:</b>{" "}
                    {data.length > 0 ? data[0].investmentOfficer : ""}
                  </p>
                  <p>
                    <b>Investment Authority:</b>{" "}
                    {data.length > 0 ? data[0].invAuthority : ""}
                  </p>
                  <p>
                    <b>Investment Objective:</b>{" "}
                    {data.length > 0 ? data[0].invObjective : ""}
                  </p>
                  <p>
                    {/* <b>As of Date:</b> {localStorage.getItem("processingDate")} */}
                    <b>As of Date:</b> {asOfDt}
                  </p>
                  <p>
                    <b>Price Date:</b> {priceDt}
                  </p>
                </div>
              </div>
            </Card>
          </div>
        ) : (
          <></>
        )}
        <p></p>
        <div className="col col-md-12 col-sm-12 py-1">
          <ExcelExport
            data={data}
            ref={_export}
            fileName={
              JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
              "_AcctHolding.xlsx"
            }
          >
            <ExcelExportColumnGroup
              title={
                "Account Number: " +
                JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
                "  Processing Date: " +
                localStorage.getItem("processingDate")
              }
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              {grid}
              {/*
                                <ExcelExportColumn field="groupName" title="Asset Class" width={170} />
                                <ExcelExportColumn field="txCstAmt" title="Total Cost($)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
                                <ExcelExportColumn field="market" title="Market Value($)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
                                <ExcelExportColumn field="income" title="Income($)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
                                <ExcelExportColumn field="yield" title="Yield(%)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
                                <ExcelExportColumn field="marketPercent" title="Market Value(%)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
*/}
            </ExcelExportColumnGroup>
          </ExcelExport>
          {data.length > 0 ? (
            <Chart style={{ height: "440px" }}>
              <ChartLegend position="bottom" />

              <ChartSeries>
                <ChartSeriesItem
                  type="pie"
                  data={result}
                  // colorField="chrtColorId"
                  field="total"
                  categoryField="groupName"
                  autoFit={true}
                  labels={{
                    visible: true,
                    content: labelContent1,
                  }}
                />
              </ChartSeries>
            </Chart>
          ) : (
            <></>
          )}

          <div className="row d-flex justify-content-center">
            Liabilities Excluded From Summary
          </div>

          {grid}
          {showExcludedAssets && (
            <div> ^ Asset Class Contains Excluded Asset(s)</div>
          )}
          {/* <div className="row mx-1 my-2 pb-3 mb-5">

                            <div className="col col-md-6 col-sm-10 py-2">
                                <div className="col-md-12 card-header tableheader">Account Holdings  </div>
                                <div className="card rounded h-100">

                                    <div className="w-100">
                                        <Chart style={{ height: "440px" }}>
                                            
                                            <ChartLegend position="bottom" />

                                            <ChartSeries>
                                                <ChartSeriesItem
                                                    type="pie"
                                                    data={data}
                                                    colorField='chartColorCOde'
                                                    field="marketPercent"
                                                    categoryField="groupName"
                                                    autoFit={true}
                                                    labels={{
                                                        visible: true,
                                                        content: labelContent1,
                                                    }}
                                                />
                                            </ChartSeries>
                                        </Chart>

                                    </div>
                                </div>
                            </div>

                            

                        </div>


                        

                        

                        

                       
                    </div>
                </div>*/}

          {/* <Modal show={show} onHide={handleClose} fullscreen={true} size="xl" >
                    <Modal.Header><div className="row w-100 tableheader">
                        <div className='col'>
                            <Modal.Title>Account Profile Report - Pdf Preview</Modal.Title>
                        </div>
                        <div className='col text-end'>
                            <button
                                className="btn btn-outline-primary btn-sm mx-2"
                                onClick={printPDF}
                            >
                                Print
                            </button>
                            <button className="btn btn-outline-danger  btn-sm" onClick={handleClose}>
                                Close


                            </button>
                        </div>


                    </div>
                    </Modal.Header>
                    <Modal.Body>
                        <PDFExport
                            margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
                            pageTemplate={PageTemplate}
                            forcePageBreak=".page-break"
                            paperSize="A4"
                            scale={0.5}
                            allPages={true}

                            landscape={true}
                            repeatHeaders={true}

                            ref={(pdfExport) => (allPDFExport = pdfExport)}>

                            Account Name : {acct.extrnlAcctId}
                            <hr></hr>
                            <div className='row d-flex justify-content-center'>
                     <Card style={{width:1400, borderRadius:'15px', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                    <div className='row d-flex justify-content-between'>
                    
                <div className='col text-start'>
                   
                <div className='reportheading fs-6' ><p>Administrative Information</p></div>
<p><b>Account Title:</b> {data[0].accountName}</p>
<p><b>Account Number:</b> {data[0].accountNumber}</p>
<p><b>Administrator:</b> {data[0].administrator}</p>
<p><b>Capacity:</b> {data[0].capacity}</p>
<p><b>Inception Date:</b> { incptnDt}</p>
<p><b>YTD Gain/Loss:</b> {data[0].ytdGainLoss}</p>
</div>
<div className='col text-end'>
<div className='reportheading fs-6' ><p>Investment Profile</p></div>
<p><b>Investment Officer:</b> {data[0].investmentOfficer}</p>
<p><b>Investment Authority:</b> {data[0].invAuthority}</p>
<p><b>Investment Objective:</b> {data[0].invObjective}</p>
<p><b>As of Date:</b> {localStorage.getItem("processingDate")}</p>
<p><b>Price Date:</b> {localStorage.getItem("processingDate")}</p>

</div></div></Card></div><p></p>
                            <Chart style={{ height: "440px" }}>
                                            
                                            <ChartLegend position="bottom" />

                                            <ChartSeries>
                                                <ChartSeriesItem
                                                    type="pie"
                                                    data={accountVsModelFinalData}
                                                    colorField='chrtColorId'
                                                    field="marketPercent"
                                                    categoryField="groupName"
                                                    autoFit={true}
                                                    labels={{
                                                        visible: true,
                                                        content: labelContent1,
                                                    }}
                                                />
                                            </ChartSeries>
                                        </Chart>
                                        <div className='row d-flex justify-content-center'>Liabilities Excluded From Summary</div>
                                        {grid}

                            
                            
                            

                        </PDFExport>

                    </Modal.Body>
                    <Modal.Footer>



                        <button className="btn btn-primary  btn-sm" onClick={handleClose}>
                            Close
                        </button>


                    </Modal.Footer>

                </Modal>  */}
          <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
            <Modal.Body>
              <div id="viewer-host">
                <Viewer ref={viewerRef} />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <button className="btn btn-primary  btn-sm" onClick={handleClose}>
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AccountProfileDetails;
