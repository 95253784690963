import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import SelectControl from "./selectcontrol";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Moment from "react-moment";
import Loading from "./loading";
import Header from "./header";
import AcctHoldingGrid from "./acctHoldingGrid";

import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "@telerik/kendo-intl";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";

import { Modal as AntdModal, Button as AntdBtn } from "antd";
import { AiFillSetting } from "react-icons/ai";

import { Button } from "react-bootstrap";
import { FaCog, FaFilter } from "react-icons/fa";
import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";

// import "@progress/kendo-theme-material/dist/all.css";
//import "@progress/kendo-theme-default/dist/all.css";
const AcctHoldingRpt = () => {
  var date = new Date(localStorage.getItem("processingDate"));
  // console.log("date", date);
  var maxFrmDt = date;
  const [asOfDt, setasOfDt] = React.useState(date);
  const [priceDt, setpriceDt] = React.useState(date);
  const [AcctHoldingRptData, populateAcctHoldingRptData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [loading, setLoading] = useState(false);
  const [isColumnSave, setIsColumnSave] = useState(false);

  const [effectivecDtInd, seteffectiveDtInd] = useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const navigate = useNavigate();
  const [reportData, setReportData] = useState([]);
  const [enableCombo, setEnableCombo] = useState(false);
  const [flag, setFlag] = React.useState(false);

  const [modalVisible, setModalVisible] = useState(true);
  const [selectedOption, setSelectedOption] = useState(1);
  const [bookCostSelectOpt, setBookCostSelectOpt] = useState("");

  const [bookInd, setBookInd] = useState(false);
  const [costInd, setCostInd] = useState(false);

  const [tipsInd, SetTipsInd] = useState(false);

  const [closeOption, setCloseOption] = useState(false);

  const [priceFlagInd, setPriceFlagInd] = useState(1);
  const [isPriceExistsFlagInd, setIsPriceExistsFlagInd] = useState(0);
  const dispatch = useDispatch();

  const [priceExistFlag, setPriceExistFlag] = useState("");

  let isPriceExistFlag = 0;

  const apicallval = useSelector(selectapicall);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        //setEmail(email);
        GetAcctHoldinData();
        getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    // fetchData();
  }, []);

  useEffect(() => {}, []);
  function setDate(val) {
    debugger;
    if (val == null) {
      setasOfDt(asOfDt);
    } else {
      let newdate = new Date(val);
      if (newdate <= new Date(localStorage.getItem("processingDate"))) {
        setasOfDt(val);
        setpriceDt(val);
      } else {
        setasOfDt(new Date(localStorage.getItem("processingDate")));
        setpriceDt(new Date(localStorage.getItem("processingDate")));
      }
    }
  }

  function setPrcDt(val) {
    let newdate = new Date(val);
    if (newdate <= new Date(localStorage.getItem("processingDate")))
      setpriceDt(val);
    else {
      setpriceDt(new Date(localStorage.getItem("processingDate")));
    }
    setpriceDt(val);
  }
  const handleEffectiveDtInd = (e) => {
    seteffectiveDtInd(e.target.checked ? true : false);
  };

  const handleBookIndicator = (e) => {
    setBookInd(e.target.checked ? true : false);
    console.log("e.target.checked", e.target.checked);
  };

  const handleCostIndicator = (e) => {
    setCostInd(e.target.checked ? true : false);
    console.log("e.target.checked", e.target.checked);
  };

  const GetAcctHoldinData = async () => {
    setLoading(true);
    setEnableCombo(true);
    let token = tempToken;

    // let token = JSON.parse(localStorage.getItem('token'));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let AcctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;
    let AsofDt = formatDate(asOfDt, "MM/dd/yyyy");

    console.log("AcctId", AcctId);

    // if (!Moment(AsofDt, 'MM-dd-yyyy').isValid()  || !Moment(priceDt, 'MM-dd-yyyy').isValid())
    // {
    //
    // }

    let RltnshpCd = "";

    let mConsolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;

    let EffectiveDtInd = effectivecDtInd;
    let PriceDt = formatDate(priceDt, "MM/dd/yyyy");
    let PriceFlag = priceFlagInd;
    let ShwCPIAdjShrs = tipsInd;

    //let pageId = 1;
    // const postData = {UserId, AcctId, AsofDt, EffectiveDtInd, PriceDt};
    const postData = {
      UserId,
      AcctId,
      AsofDt,
      EffectiveDtInd,
      PriceDt,
      mConsolidationId,
      RltnshpCd,
      PriceFlag,
      ShwCPIAdjShrs,
    };

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/AcctHolding", postData, config)
      .then((response) => {
        //  console.log(response);

        const rowData = response.data;
        populateAcctHoldingRptData(rowData?.ocAcctHolding || []);
        //localStorage.removeItem("HoldingDataState");
        localStorage.setItem(
          "filterData",
          JSON.stringify(rowData.ocAcctHolding)
        );
        setReportData(rowData);
        getColumnStateDb();
        setFlag(true);
        setLoading(false);
        setEnableCombo(false);
        setPriceExistFlag(response?.data?.priceValidation);
        // if(priceDt===""){
        //   setpriceDt("mm/dd/yyyy");
        // }
        // isPriceExistFlag = rowData.isPriceExists;
        // console.log("isPriceExistFlag", isPriceExistFlag);
        // setIsPriceExistsFlagInd(rowData.isPriceExists);


      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };

  if (apicallval === 1) {
    GetAcctHoldinData();
    dispatch(callremove());
  }

  const formSubmit = (event) => {
    event.preventDefault();
    setModalVisible(false);
    setCloseOption(false);
    GetAcctHoldinData();
  };

  const handleChange = (event) => {
    if (event.target.value === null || loading) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetAcctHoldinData();
    }
  };

  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 22;

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        console.log("save in db");
        console.log(response);
        const rowData = response.data;

        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);

        return error;
      });
  };

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetAcctHoldinData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  // Handle modal close actions
  const handleOk = () => {
    setModalVisible(false);
    GetAcctHoldinData();
  };

  const handleCancel = () => {
    setModalVisible(false);
    setCloseOption(false);
    !closeOption && navigate("/dashboard");
  };

  const handleRadioChange = (e) => {
    setSelectedOption(parseInt(e.target.value));
    setPriceFlagInd(parseInt(e.target.value));
    console.log("e.target.value", e.target.value);
    console.log("e", e);
    if (e.target.value == 1 || e.target.value == 2) {
      setpriceDt(date); // to be checked
    }
    // console.log("selectedOption", selectedOption);
    // console.log("Selected Price Date", priceDt);
  };

  const handleTipsInd = (e) => {
    SetTipsInd(e.target.checked ? true : false);
  };

  const handleSettings = () => {
    setModalVisible(true);
    setCloseOption(true);
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}

            <Loading />
          </div>
        )}
      </>
    );
  }
  const datepattern = "mm/dd/yyyy";
  return (
    <div>
      {/* <Header></Header> */}

      {/* Updated Header Section */}
      <div className="row d-flex justify-content-between align-items-center mx-2 py-1 border-bottom">
        {/* Report Heading */}
        <div className="col reportheading fs-6">Holdings Report</div>

        {/* Date Range or As Of Date */}
        <div className="col text-center">
          {`As Of ${new Date(asOfDt).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}`}
        </div>

        {/* Filter Button */}
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>
      </div>

      {/* Updated Modal Section */}
      <AntdModal
        open={modalVisible}
        title="Customize Holdings Report"
        closable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          {/* As Of Date */}
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpAsOfDt"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              As Of Date
            </label>
            <DatePicker
              id="dpAsOfDt"
              value={asOfDt}
              calendar={CustomCalendar}
              min={new Date("1/1/1000")}
              max={maxFrmDt}
              onChange={(e) => setasOfDt(e.value)}
              style={{ width: "100%" }}
              validationMessage={
                asOfDt == null
                  ? "Enter a valid date"
                  : asOfDt < new Date("1/1/1000")
                  ? "Enter a date in the correct format (mm/dd/yyyy)"
                  : asOfDt > maxFrmDt
                  ? "As Of Date cannot exceed the Processing Date"
                  : ""
              }
            />
          </div>

          <hr style={{ margin: "20px 0" }} />

          {/* Pricing Options */}
          <h6>Select Pricing Option</h6>
          <div style={{ marginBottom: "20px" }}>
            {[1, 2, 3].map((option) => (
              <label
                key={option}
                style={{ display: "block", marginBottom: "10px" }}
              >
                <input
                  type="radio"
                  value={option}
                  checked={selectedOption === option}
                  onChange={handleRadioChange}
                />
                {option === 1
                  ? " Use Current Price"
                  : option === 2
                  ? " Use End of Month Price"
                  : " Use Non EOM Price"}
              </label>
            ))}
          </div>

          {/* Conditional Price Date Field */}
          {selectedOption === 3 && (
            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="dpPriceDt"
                className="form-label"
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                Price Date
              </label>
              <DatePicker
                id="dpPriceDt"
                value={priceDt}
                calendar={CustomCalendar}
                min={new Date("1/1/1000")}
                max={maxFrmDt}
                onChange={(e) => setPrcDt(e.value)}
                style={{ width: "100%" }}
                validationMessage={
                  priceDt == null
                    ? "Enter a valid date"
                    : priceDt < new Date("1/1/1000")
                    ? "Enter a date in the correct format (mm/dd/yyyy)"
                    : priceDt > maxFrmDt
                    ? "Price Date cannot exceed the Processing Date"
                    : ""
                }
              />
            </div>
          )}

          <hr style={{ margin: "20px 0" }} />

          {/* Miscellaneous Section */}
          <h6>Miscellaneous Section</h6>
          <div style={{ marginBottom: "20px" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={effectivecDtInd}
                  onChange={handleEffectiveDtInd}
                  name="effectiveDtSwitch"
                />
              }
              label="Effective Date Indicator"
              style={{ marginBottom: "10px", display: "block" }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={tipsInd}
                  onChange={handleTipsInd}
                  name="tipsSwitch"
                />
              }
              label="Show Adjusted Shares for Tips"
              style={{ marginBottom: "10px", display: "block" }}
            />
          </div>

          {/* Actions */}

          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntdModal>
      {flag ? (
        <AcctHoldingGrid
          data={AcctHoldingRptData}
          flag={isColumnSave}
          reportData={reportData}
          priceExistFlag={priceExistFlag}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AcctHoldingRpt;
