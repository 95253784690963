import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Switch } from "@mui/material";
// import validator from 'validator';
import Select, { SelectChangeEvent } from "@mui/material/Select";
//import SelectControl from './selectcontrol';
import Loading from "./loading";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { lastDayOfMonth, isEqual } from "@progress/kendo-date-math";
import DailyPerformanceGrid from "./dailyPerformanceGrid";
import Header from "./header";
import { Button, Card } from "@mui/material";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
//import refreshFunction from './refreshFunc';
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
// import { abs } from 'mathjs'
import { CustomCalendar } from "./customCalendar";
import { Dropdown } from "react-bootstrap";
import Enumerable from "linq";

import { FaInfoCircle } from "react-icons/fa";
// import { Popover } from "@progress/kendo-react-tooltip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Modal as AntModal, Button as aButton } from "antd";
import { FaCog, FaFilter } from "react-icons/fa";
import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";

const DailyPerformanceRpt = () => {
  const [TimeWtdRtnRptRptData, populateTimeWtdRtnRptRptData] = useState([]);
  const [TimeWtdRtnRptRptDataChart, populateTimeWtdRtnRptRptDataChart] =
    useState([]);
  const [reportData, setReportData] = useState([]);
  const [IncepDt, setIncepDt] = useState();
  //const [AcctList, populateAccountListData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(false);
  const [isColumnSave, setIsColumnSave] = useState(false);
  var date = new Date(localStorage.getItem("processingDate"));
  date.setMonth(date.getMonth() - 1);
  const [frmDate, setfrmDate] = React.useState(date);
  //const [toDate, setToDate] = React.useState(new Date(localStorage.getItem("processingDate")));
  const [asOfDate, setAsOfDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(true);
  const [isChckedBM, setisChckedBM] = useState(false);
  const [openDuration, setOpenDuration] = React.useState(100);
  const [closeDuration, setCloseDuration] = React.useState(300);
  // const minFrmDt = new Date(2021, 8, 14);

  const maxFrmDt = new Date(localStorage.getItem("processingDate"));
  const minFrmDt = new Date(localStorage.getItem("processingDate"));
  minFrmDt.setMonth(minFrmDt.getMonth() - 60);
  const [isDisabled, setisDisabled] = React.useState(true);

  const [fromDate, setStartDate] = React.useState(date);
  const [toDate, setEndDate] = React.useState(date);
  const [onThisPage, setonThisPage] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [closeOption, setCloseOption] = useState(false);
  const [showCustomPeriod, setshowCustomPeriod] = useState(false);

  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);

  const [runInAggregate, setRunInAggregate] = useState(false);

  let acctId = 0;
  let AccountId = JSON.parse(localStorage.getItem("AcctSelected"));
  if (AccountId != null) {
    acctId = AccountId.acctId;
  }

  const [monthlyOrDaily, setMonthlyOrDaily] = React.useState(
    acctId.acctId == 0 && JSON.parse(localStorage.getItem("pConsId")) > 0
      ? "Monthly"
      : "Daily"
  );
  const [isDisabledDaily, setisDisabledDaily] = React.useState(
    acctId.acctId == 0 && JSON.parse(localStorage.getItem("pConsId")) > 0
      ? true
      : false
  );
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  //const [CardData, populateTimeWtdRtnRptRptCardData] = useState([]);
  const navigate = useNavigate();

  const [dateType, setDateType] = React.useState(1);

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  const handleAggregateMode = () => {
    if (runInAggregate == 0) {
      setRunInAggregate(true);
    }
    if (runInAggregate == 1) {
      setRunInAggregate(false);
    }
    console.log("Aggregate", runInAggregate);
  };

  const [initialState, setInitialState] = useState({
    runInAggregate: 0,
  });

  useEffect(() => {
    debugger;
    const fetchData = async () => {
      setLoading(true);
      try {
        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;
        GetTimeWtdRtnRpteData();
        //getColumnStateDb();
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  const handelChkBM = (e) => {
    setisChckedBM(e.target.checked);
  };

  const handleSettings = () => {
    setInitialState({
      runInAggregate,
    });
    setModalVisible(true);
    setCloseOption(true);
  };

  const closeModal = () => {
    setRunInAggregate(initialState.runInAggregate);

    setModalVisible(false);
    setonThisPage(false);
    !onThisPage && navigate("/dashboard");
  };

  const handleChangemonthlyOrDaily = (e) => {
    setMonthlyOrDaily(e.target.value);
  };

  const handelSubmit = (event) => {
    debugger;
    //  var date = new Date(asOfDate);
    //  var month =date.getMonth();
    //  var year =date.getFullYear();
    //  var day=date.getDate();
    //var lDay= lastDayOfMonth(new Date(year,month,day));

    //let date = dtfrm.selAcctData;
    setfrmDate(asOfDate);
    //  if(validator.isDate(asOfDate)&&(asOfDate>=minFrmDt)&&asOfDate<=maxFrmDt){
    //
    GetTimeWtdRtnRpteData();
    //  }
  };

  const formSubmit = (event) => {
    event.preventDefault();
    setModalVisible(false);
    setonThisPage(true);
    GetTimeWtdRtnRpteData();
  };

  const handleCustomPeriod = (e) => {
    setshowCustomPeriod(e.target.checked);
    setisDisabled(showCustomPeriod);
    //setOverdraftVal(e.target.checked ? overdraftVal : "");
  };

  const GetTimeWtdRtnRpteData = async () => {
    setLoading(true);

    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId")); //3689
    let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    let AcctId = lsSelectedAcct.acctId;
    let PageId = 1;
    let netFeeId = 1;

    var date = new Date(asOfDate);
    var month = date.getMonth();
    var year = date.getFullYear();
    var day = date.getDate();
    var lDay = lastDayOfMonth(new Date(year, month, day));
    var IsDaily = 0;
    if (monthlyOrDaily == "Daily") IsDaily = 0;

    //var lDay=lastDayOfMonth(asOfDate);
    if (!isEqual(asOfDate, lDay) && IsDaily == 0) {
      lDay = lastDayOfMonth(new Date(year, month - 1, day));

      setAsOfDate(lDay);
    } else {
      lDay = asOfDate;
      //setAsOfDate(lDay);
    }

    // if (lsSelectedAcct !== null) {
    //   AcctId = lsSelectedAcct.acctId;
    // }
    let AsofDate = lDay;
    let ConsolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;

    let acctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;

    let ShowBenchmark = 0;
    let RltnshpCd =
      localStorage.getItem("pRltncd") == null ||
      localStorage.getItem("pRltncd") == undefined
        ? ""
        : localStorage.getItem("pRltncd");
    if (isChckedBM) ShowBenchmark = 1;

    let customRetStartDt = fromDate;
    let customRetEndDt = toDate;

    if (showCustomPeriod == false) {
      customRetStartDt = "01/01/1900";
      customRetEndDt = "01/01/1900";
    }

    // UserId = 78167;
    // AsofDate = "01/31/2022";
    // AcctId = 119;
    // ConsolidationId = 0;
    // customRetStartDt = "01/31/2022";
    // customRetEndDt = "01/31/2022";
    // setRunInAggregate(false);

    const postData = {
      UserId,
      AcctId,
      AsofDate,
      ConsolidationId,
      customRetStartDt,
      customRetEndDt,
      runInAggregate,
    };
    debugger;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    //     {
    //   "userId": 0,
    //   "asOfDate": "string",
    //   "acctId": 0,
    //   "consolidationId": 0,
    //   "customRetStartDt": "string",
    //   "customRetEndDt": "string" fromDate
    // }

    debugger;
    await axios
      .post("/RTGetSelectedPeriodPerformance", postData, config)
      .then((response) => {
        debugger;
        const rowData = response.data;

        let consolidationName =
          JSON.parse(localStorage.getItem("RelationID"))?.cnsldtnNm || 0;

        if (rowData.lstSelectedPeriodT1 != null) {
          if (AcctId == 0 && runInAggregate == true) {
            for (let i = 0; i < rowData.lstSelectedPeriodT1?.length; i++)
              console.log("Inside Loop");
          }
        }

        let gridData = Enumerable.from(rowData.lstSelectedPeriodT1)
          .where((w) => w.market > 0)
          .toArray();

        let chartData = Enumerable.from(rowData.lstSelectedPeriodT2)
          .where((w) => w.market > 0)
          .toArray();

        populateTimeWtdRtnRptRptData(rowData?.lstSelectedPeriodT1 || []);
        populateTimeWtdRtnRptRptDataChart(rowData?.lstSelectedPeriodT2 || []);
        setLoading(false);
        setReportData(rowData);
        setFlag(true);
        let cdt = new Date(rowData.perfIncptnDt);
        let siDate =
          (cdt.getMonth() + 1).toString().padStart(2, "0") +
          "/" +
          cdt.getDate().toString().padStart(2, "0") +
          "/" +
          cdt.getFullYear();
        setIncepDt(siDate);
        console.log("Inception DATE:", siDate);
        if (lsSelectedAcct !== null) {
          SetselAcct(lsSelectedAcct);
        }
        // else {
        //   SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
        // }
        getColumnStateDb();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        // return error;
      });
  };

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetTimeWtdRtnRpteData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  if (apicallval === 1) {
    GetTimeWtdRtnRpteData();
    dispatch(callremove());
  }

  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 33;

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        console.log("save in db");
        //console.log(response);
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);
        return error;
      });
  };

  const handleChange = (event) => {
    //
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetTimeWtdRtnRpteData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            {!modalVisible && <Loading />}
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      {/* <Header></Header> */}
      <div className="my-2">
        <div className="rounded"></div>

        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>

        {/* <div className="d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
          <div className="col-md-4 col-lg-4 col-sm-11 text-start">
            <span className="px-2">Account(s)</span>
            <ComboBox
              style={{
                width: "330px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
            />
          </div>
        </div> */}
        <div className="mx-4">
          <p className="reportheading fs-6">
            Historical Market Performance Report
          </p>
        </div>
      </div>

      <div className="container-fluid bg-white">
        <form>
          <div className="row card-group mb-2">
            <div className="col card kpicard">
              <div className="card-header kpiheader bg-white">
                <div>
                  <i className="me-2">
                    <FaInfoCircle title="Performance Market Value may differ from the Total Market Value due to certain asset exclusions from the Performance Market Value (i.e. real estate assets) as well as with Performance Market Value using trade date value vs settlement date used for Total Market Value."></FaInfoCircle>
                  </i>
                  Performance Market Value
                </div>
              </div>
              <div className="card-body kpibody">
                {/* ${TimeWtdRtnRptRptDataChart.length == 0 ? '0' : formatNumber(abs(TimeWtdRtnRptRptDataChart[0].market), "##,#.00")} */}
                $
                {TimeWtdRtnRptRptDataChart.length == 0
                  ? "0"
                  : formatNumber(
                      TimeWtdRtnRptRptDataChart[0].market,
                      "##,#.00"
                    )}
              </div>
            </div>

            <div className="col card kpicard">
              <div className="card-header kpiheader bg-white">
                Account YTD %
              </div>
              <div className="card-body kpibody">
                {/* {TimeWtdRtnRptRptDataChart.length == 0 ? '0' : formatNumber(abs(TimeWtdRtnRptRptDataChart[0].retYTD), "##,#.00")}% */}
                {TimeWtdRtnRptRptDataChart.length == 0
                  ? "0"
                  : formatNumber(
                      TimeWtdRtnRptRptDataChart[0].retYTD,
                      "##,#.00"
                    )}
                %
              </div>
            </div>

            {/* <div className='col card kpicard'>
                      <div className='card-header kpiheader bg-white'>Benchmark YTD % </div>
                      <div className='card-body kpibody'>
                        {TimeWtdRtnRptRptDataChart.length < 2 ? '0' : formatNumber(abs(TimeWtdRtnRptRptDataChart[1].retYTD), "##,#.00")}%
                      
                      </div>

                    </div> */}
          </div>

          <div className="row mt-2 d-flex justify-content-end align-items-center">
            <div className="col">
              {/* <span className="py-1">As Of Date </span> */}
              {/* <label className='form-check-label px-2'>As Of Date</label> */}
              {/* <DatePicker
                id="dpFrm"
                value={asOfDate}
                className="form-control form-control-sm"
                size={"small"}
                width={150}
                format="MM/dd/yyyy"
                // calendar={CustomCalendar}
                min={minFrmDt}
                max={maxFrmDt}
                disabled={isDisabled}
                onChange={(e) => {
                  setAsOfDate(e.value);
                }}
              />

              <input
                type="button"
                className="btn btn-primary btn-sm mx-3"
                onClick={handelSubmit}
                value="submit"
              /> */}

              <AntModal
                open={modalVisible}
                title="Customize Selected Period Report"
                // onCancel={handleCancel}

                closable={false}
                // maskClosable={false}

                footer={[]}
              >
                <form onSubmit={formSubmit} style={{ padding: "20px" }}>
                  <div className="pt-3">
                    <span>
                      <h6>As Of Date</h6>
                    </span>

                    <DatePicker
                      id="dpAsOfDate"
                      value={asOfDate}
                      className="form-control form-control-sm"
                      size={"small"}
                      width={150}
                      format="MM/dd/yyyy"
                      // calendar={CustomCalendar}

                      // min={minFrmDt}

                      max={maxFrmDt}
                      disabled={false}
                      onChange={(e) => {
                        setAsOfDate(e.value);
                      }}
                    />
                  </div>

                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="chkCustomPeriod"
                        checked={showCustomPeriod}
                        onChange={handleCustomPeriod}
                        //checked={selectedOptions.stagedTrades}
                        // onChange={handleCheckboxChange}
                      />
                      &nbsp;&nbsp;Custom Period
                    </label>
                  </div>

                  <div className="pt-3">
                    <span>
                      <h6>Start Date</h6>
                    </span>

                    <DatePicker
                      id="dpFrm"
                      value={fromDate}
                      className="form-control form-control-sm"
                      size={"small"}
                      width={150}
                      format="MM/dd/yyyy"
                      // calendar={CustomCalendar}

                      // min={minFrmDt}

                      max={maxFrmDt}
                      disabled={isDisabled}
                      onChange={(e) => {
                        setStartDate(e.value);
                      }}
                      validationMessage={
                        frmDate == null
                          ? "Enter Valid Date"
                          : fromDate < new Date("1/1/1000")
                          ? "Enter date in correct format (mm/dd/yyyy)"
                          : fromDate > maxFrmDt
                          ? "From date cannot exceed one year from Processing date."
                          : fromDate > toDate
                          ? "From date cannot exceed to date."
                          : fromDate < minFrmDt
                          ? "From date cannot be less than Processing date."
                          : "Validation error"
                      }
                    />
                  </div>

                  <div className="pt-3">
                    <span>
                      <h6>End Date</h6>
                    </span>

                    <DatePicker
                      id="dpTo"
                      value={toDate}
                      className="form-control form-control-sm"
                      size={"small"}
                      width={150}
                      format="MM/dd/yyyy"
                      // calendar={CustomCalendar}

                      // min={minFrmDt}

                      max={maxFrmDt}
                      disabled={isDisabled}
                      onChange={(e) => {
                        setEndDate(e.value);
                      }}
                      valid={
                        frmDate <= toDate &&
                        toDate <= maxFrmDt &&
                        toDate >= minFrmDt
                      }
                      validationMessage={
                        toDate == null
                          ? "Enter Valid Date"
                          : toDate < new Date("1/1/1000")
                          ? "Enter date in correct format (mm/dd/yyyy)"
                          : toDate > maxFrmDt
                          ? "To date cannot exceed one year from Processing date."
                          : toDate < fromDate
                          ? "To date cannot be less than from date"
                          : toDate < minFrmDt
                          ? "To date cannot be less than Processing date."
                          : "Validation error"
                      }
                    />

                    {AcctId == 0 && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={runInAggregate}
                            onClick={handleAggregateMode}
                            name="chkCtr"
                          />
                        }
                        label="Run In Aggregate."
                        style={{ marginBottom: "10px", display: "block" }}
                      />
                    )}
                  </div>

                  <hr></hr>

                  <div
                    style={{
                      display: "flex",

                      justifyContent: "flex-end",

                      gap: "10px",
                    }}
                  >
                    <div
                      className="btn btn-outline-secondary mx-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </div>

                    <button className="btn btn-primary mx-2" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </AntModal>
            </div>
          </div>

          <div className="row">
            {flag ? (
              <DailyPerformanceGrid
                data={TimeWtdRtnRptRptData}
                flag={isColumnSave}
                alloc={TimeWtdRtnRptRptDataChart}
                InceptnDt={IncepDt}
              />
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default DailyPerformanceRpt;
