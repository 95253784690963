import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import SelectControl from "./selectcontrol";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Moment from "react-moment";
import Loading from "./loading";
import Header from "./header";

import AssetGrowthAndAllocationChart from "./assetGrowthAndAllocationChart";

import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "@telerik/kendo-intl";
import { Modal as AntdModal, Button as AntdBtn } from "antd";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { FaCog, FaFilter } from "react-icons/fa";

import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";

const AssetGrowthAndAllocationRpt = () => {
  var date = new Date(localStorage.getItem("processingDate"));
  var maxFrmDt = date;

  maxFrmDt.setMonth(maxFrmDt.getMonth() - 1);

  //const [assetAllocModelRptData, setAssetAllocModelRptData] = useState([]);
  const [assetGrowthAndAllacData, setassetGrowthAndAllacData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  //  const [selectedAcct, setSelectedAcct] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [isColumnSave, setIsColumnSave] = useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const [enableCombo, setEnableCombo] = useState(false);
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(true);
  const [closeOption, setCloseOption] = useState(false);

  const [asOfDt, setasOfDt] = React.useState(date);

  const [numberOfYears, setNumberOfYears] = useState(6);
  const [nextBom, setNextBom] = useState(false);
  const [showNetOfFees, setShowNetOfFees] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const [chkRptDispOptn, setChkRptDispOptn] = useState(true);
  const [runInAggregate, setRunInAggregate] = useState(false);

  const [flag, setFlag] = React.useState(false);

  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);

  const [initialState, setInitialState] = useState({
    chkRptDispOptn: true,
    runInAggregate: false,
  });

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;
        getAssetGrowthAndAllocationData();
        //getColumnStateDb();
      } catch (error) {
        console.error(error.message);
      }
    };
    // fetchData();
  }, []);

  const handlerunInAggregate = () => {
    setRunInAggregate(!runInAggregate);
  };

  const getAssetGrowthAndAllocationData = async () => {
    setLoading(true);
    setEnableCombo(true);
    //
    let token = tempToken;
    let asOfId = JSON.parse(localStorage.getItem("userId"));

    let acctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;
    let pageId = 1;
    let consolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;

    let asOfDate = formatDate(asOfDt, "MM/dd/yyyy");
    let histYearCount = numberOfYears;
    let netOfFeesInd = showNetOfFees;
    let ovrdIncptn = nextBom;

    const postData = {
      asOfId,
      acctId,
      consolidationId,
      asOfDate,
      histYearCount,
      netOfFeesInd,
      ovrdIncptn,
      runInAggregate,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetAstGrwthAlctn", postData, config)
      .then((response) => {
        const rowData = response.data;

        let consolidationName = JSON.parse(
          localStorage.getItem("RelationID")
        ).cnsldtnNm;

        if (AcctId == 0 && runInAggregate == true) {
          for (let i = 0; i < rowData.t1.length; i++) {
            if (AcctId == 0 && runInAggregate == true) {
              rowData.t1[i].account = consolidationName;
            }
          }

          for (let i = 0; i < rowData.t2.length; i++) {
            if (AcctId == 0 && runInAggregate == true) {
              rowData.t2[i].account = consolidationName;
            }
          }
        }

        setassetGrowthAndAllacData(rowData);
        // setAssetAllocModelRptData(rowData.t1);
        //getColumnStateDb();
        setLoading(false);
        setEnableCombo(false);
        //postData.modelId = response.data.acctMdl[0].modelId;
        console.log("Row Data", rowData);
        setFlag(true);
      })

      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
      });
  };

  if (apicallval === 1) {
    getAssetGrowthAndAllocationData();
    dispatch(callremove());
  }

  const handleChange = (event) => {
    if (event.target.value === null || loading) {
      console.log("Null one has been called");
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      console.log("I have been updated");
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      // getTopHoldingData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
      getAssetGrowthAndAllocationData();
    }
  };

  const refreshToken = async () => {
    let token = tempToken;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        // getTopHoldingData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  const handleCancel = () => {
    setModalVisible(false);
    setCloseOption(false);
    setChkRptDispOptn(initialState.chkRptDispOptn);
    setRunInAggregate(initialState.runInAggregate);
    !closeOption && navigate("/dashboard");
  };

  const formSubmit = (event) => {
    debugger;
    event.preventDefault();
    // getModelInfo();
    setModalVisible(false);
    setCloseOption(false);
    getAssetGrowthAndAllocationData();
  };

  const handleSettings = (e) => {
    setInitialState({
      chkRptDispOptn,
      runInAggregate,
    });
    setModalVisible(true);
    setCloseOption(true);
  };

  const validateNumberOfYears = () => {
    if (numberOfYears === "" || numberOfYears === null || numberOfYears <= 0) {
      setNumberOfYears(6); // Reset to default value if invalid
      setIsSubmitDisabled(true);
    }
  };

  const handleNumberOfYearsChange = (e) => {
    const value = e.target.value;
    const parsedValue = parseInt(value, 10);

    setNumberOfYears(parsedValue);

    if (isNaN(parsedValue) || parsedValue < 1 || parsedValue > 9) {
      setIsSubmitDisabled(true);
      setValidationMessage("Please enter a year between 1 to 9");
    } else {
      setIsSubmitDisabled(false);
      setValidationMessage("");
    }
  };

  const isEndOfMonth = (date) => {
    debugger;
    const nextDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 1
    );
    return nextDay.getDate() === 1;
  };

  const handleDateChange = (e) => {
    const selectedDate = e.value;
    setasOfDt(selectedDate);
    // setIsSubmitDisabled(!isEndOfMonth(selectedDate));
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            <Loading />
            {/* <AssetGrowthAndAllocationChart data={assetGrowthAndAllacData} /> */}
          </div>
        )}
      </>
    );
  }
  return (
    <div>
      <div className="row d-flex justify-content-start align-items-center my-1 px-2 mx-2 shadow-sm rounded">
        <div className="col reportheading fs-6">
          Asset Growth And Allocation Report
        </div>

        {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}

        {/* Filter Button */}
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>
      </div>

      <AntdModal
        open={modalVisible}
        title="Customize Asset Growth And Allocation Report"
        closable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "4px 20px 20px 20px" }}>
          {/* Miscellaneous Section */}
          <h6>Data Display Option</h6>
          <div style={{ marginBottom: "20px" }}>
            <div style={{ padding: "20px" }}>
              <div style={{ marginBottom: "20px" }}>
                <label>
                  <input
                    type="number"
                    value={numberOfYears}
                    onChange={handleNumberOfYearsChange}
                    onBlur={validateNumberOfYears}
                    min="1" // Enforces that the minimum value cannot be less than 1
                  />{" "}
                  Number of Years to Report
                </label>
              </div>
              {validationMessage && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  {validationMessage}
                </div>
              )}

              <div style={{ marginBottom: "20px" }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={nextBom}
                      onChange={() => setNextBom(!nextBom)}
                      name="nextBom"
                    />
                  }
                  label="Next BOM for Intra-Month Inception Dates"
                  style={{ marginBottom: "10px", display: "block" }}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showNetOfFees}
                      onChange={() => setShowNetOfFees(!showNetOfFees)}
                      name="showNetOfFees"
                    />
                  }
                  label="Show Net of Fees"
                  style={{ marginBottom: "10px", display: "block" }}
                />

                {AcctId == 0 && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={runInAggregate}
                        onClick={handlerunInAggregate}
                        name="chkCtr"
                      />
                    }
                    label="Run In Aggregate."
                    style={{ marginBottom: "10px", display: "block" }}
                  />
                )}
              </div>
            </div>
          </div>

          <hr style={{ margin: "20px 0" }} />

          {/* As Of Date */}
          <h6>Date Selection</h6>
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpAsOfDt"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              As Of Date
            </label>
            <DatePicker
              id="dpAsOfDt"
              value={asOfDt}
              // calendar={CustomCalendar}
              min={new Date("1/1/1000")}
              max={maxFrmDt}
              // onChange={(e) => setasOfDt(e.value)}
              onChange={(e) => setasOfDt(e.value)}
              style={{ width: "100%" }}
              // valid={date <= maxFrmDt && date >= new Date("1/1/1000")}
              validationMessage={
                asOfDt == null
                  ? "Enter a valid date"
                  : asOfDt < new Date("1/1/1000")
                  ? "Enter a date in the correct format (mm/dd/yyyy)"
                  : asOfDt > maxFrmDt
                  ? "As Of Date cannot exceed the Processing Date"
                  : ""
              }
            />
          </div>

          {/* Actions */}

          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button
              className="btn btn-primary mx-2"
              type="submit"
              disabled={isSubmitDisabled}
            >
              Submit
            </button>
          </div>
        </form>
      </AntdModal>

      {flag ? (
        <AssetGrowthAndAllocationChart
          assetGrowthAndAllacData={assetGrowthAndAllacData}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AssetGrowthAndAllocationRpt;
