import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SelectControl from "./selectcontrol";
import Loading from "./loading";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import Header from "./header";
import TimeWtdRtnRptGrid from "./TimeWtdRtnRptGrid";
import { CustomCalendar } from "./customCalendar";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import refreshFunction from "./refreshFunc";
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal as AntModal, Button as aButton } from "antd";
import { FaFilter } from "react-icons/fa";
import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
const TimeWtdRtnRpt = () => {
  const [TimeWtdRtnRptRptData, populateTimeWtdRtnRptRptData] = useState([]);
  const [AcctList, populateAccountListData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(0);
  const [isColumnSave, setIsColumnSave] = useState(false);
  const [toDate, setToDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const [frmDate, setFrmDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const minFrmDt = new Date(localStorage.getItem("processingDate"));
  minFrmDt.setFullYear(minFrmDt.getFullYear() - 4);
  const maxFrmDt = new Date(localStorage.getItem("processingDate"));

  const [isDisabled, setisDisabled] = React.useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const navigate = useNavigate();
  const [reportData, setReportData] = useState([]);
  const [enableCombo, setEnableCombo] = useState(false);
  const [closeOption, setCloseOption] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [chkRptDispOptn, setChkRptDispOptn] = useState(true);
  const [runInAggregate, setRunInAggregate] = useState(false);

  const [initialState, setInitialState] = useState({
    chkRptDispOptn: true,
    runInAggregate: false,
  });

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        //setEmail(email);
        GetTimeWtdRtnRpteData();
        getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    //fetchData();
  }, []);
  function setDate(val) {
    // if(val.language === '1'){
    //  const fDate = new Date("2021-04-22");

    //     setfrmDate(fDate);
    //     const setisDisabled = false;
    // }
    // if(val.language === '2'){
    if (val == null) {
      // const fDate = new Date("2021-01-22");
      setToDate(toDate);
    } else {
      setToDate(val);
    }
    //     const setisDisabled = true;
    // }
    //setToDate(val);
  }
  function setfrmDate(val) {
    // if(val.language === '1'){
    //  const fDate = new Date("2021-04-22");

    //     setfrmDate(fDate);
    //     const setisDisabled = false;
    // }
    // if(val.language === '2'){
    if (val == null) {
      // const fDate = new Date("2021-01-22");
      setFrmDate(frmDate);
    } else {
      setFrmDate(val);
    }
    //     const setisDisabled = true;
    // }
    //setToDate(val);
  }
  const searchClick = () => {
    GetTimeWtdRtnRpteData();
  };
  // const formSubmit = (event) => {
  //   GetTimeWtdRtnRpteData();
  // };

  const handleSettings = () => {
    setModalVisible(true);
    setCloseOption(true);
  };
  const formSubmit = (event) => {
    debugger;
    event.preventDefault();
    setModalVisible(false);
    setCloseOption(false);
    GetTimeWtdRtnRpteData();
  };
  const handlerunInAggregate = () => {
    setRunInAggregate(!runInAggregate);
  };
  const GetTimeWtdRtnRpteData = async () => {
    //
    setLoading(true);

    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let AsOfId = JSON.parse(localStorage.getItem("userId"));
    let AcctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0; //JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    let mConsolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;
    // let AcctId = 0;
    let PageId = 1;
    let netFeeId = 1;
    let StDate = frmDate;
    let ToDate = toDate;
    // if (lsSelectedAcct !== null) {
    //   AcctId = lsSelectedAcct.acctId;
    // }
    // let mConsolidationId = 0;
    let RltnshpCd = "";

    debugger;
    const postData = {
      AsOfId,
      AcctId,
      netFeeId,
      PageId,
      StDate,
      ToDate,
      mConsolidationId,
      RltnshpCd,
      runInAggregate,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    debugger;

    await axios
      .post("/RTTimeWtdRtnOfSlctdPrds", postData, config)
      .then((response) => {
        debugger;
        console.log("resonse 12", response);

        const rowData = response.data;

        let consolidationName = JSON.parse(
          localStorage.getItem("RelationID")
        )?.cnsldtnNm;

        if (rowData.lstRTTimeWtdReturnT1 != null) {
          for (let i = 0; i < rowData.lstRTTimeWtdReturnT1.length; i++) {
            if (AcctId == 0 && runInAggregate == true) {
              rowData.lstRTTimeWtdReturnT1[i].account = consolidationName;
            }
          }
        }

        populateTimeWtdRtnRptRptData(
          rowData.lstRTTimeWtdReturnT1 == null
            ? []
            : rowData.lstRTTimeWtdReturnT1
        );
        populateAccountListData(
          rowData.lstAcctTmWtdRtnList == null ? [] : rowData.lstAcctTmWtdRtnList
        );
        setSelAcctData(selAcctData);
        setReportData(rowData);
        if (JSON.parse(localStorage.getItem("AccountID")) !== null) {
          SetselAcct(JSON.parse(localStorage.getItem("AccountID")));
        } else {
          SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
        }
        getColumnStateDb();
        setLoading(false);
        setFlag(1);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        // return error;
      });
  };
  if (apicallval === 1) {
    GetTimeWtdRtnRpteData();
    dispatch(callremove());
  }
  debugger;

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetTimeWtdRtnRpteData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 33;

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        console.log("save in db");
        console.log(response);
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);
        return error;
      });
  };

  const handleCancel = () => {
    setModalVisible(false);
    setCloseOption(false);

    !closeOption && navigate("/dashboard");
  };

  const handleChange = (event) => {
    //
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetTimeWtdRtnRpteData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    // <form onSubmit={formSubmit}>
    <div>
      {/* <Header></Header> */}

      {/* <div className="mt-1">
          <div className="px-2 mx-2 bg-light shadow-sm rounded">
            <div className="table-responsive">
              <table className="table table-borderless mb-0">
                <tbody>
                  <tr>
                    <td
                      className="text-start"
                      style={{ verticalAlign: "bottom", minWidth: "240px" }}
                    >
                      <span className="px-2 d-block d-md-inline">
                        Account(s)
                      </span>
                      <ComboBox
                        style={{ width: "100%", maxWidth: "350px" }}
                        data={selAcctData}
                        textField="extrnlAcctId"
                        dataItemKey="acctId"
                        filterable={true}
                        value={selAcct}
                        onChange={handleChange}
                      />
                    </td>

                    <td style={{ verticalAlign: "bottom", minWidth: "180px" }}>
                      <span className="py-1 d-block d-md-inline">From</span>
                      <DatePicker
                        style={{ width: "100%", maxWidth: "200px" }}
                        id="dpFrm"
                        defaultValue={frmDate}
                        format="MM/dd/yyyy"
                        calendar={CustomCalendar}
                        min={minFrmDt}
                        formatPlaceholder={{
                          year: "yyyy",
                          month: "mm",
                          day: "dd",
                        }}
                        max={maxFrmDt}
                        disabled={isDisabled}
                        onChange={(e) => {
                          setfrmDate(e.value);
                        }}
                      />
                    </td>

                    <td style={{ verticalAlign: "bottom", minWidth: "180px" }}>
                      <span className="py-1 d-block d-md-inline">To</span>
                      <DatePicker
                        id="dpTo"
                        defaultValue={toDate}
                        format="MM/dd/yyyy"
                        calendar={CustomCalendar}
                        min={minFrmDt}
                        formatPlaceholder={{
                          year: "yyyy",
                          month: "mm",
                          day: "dd",
                        }}
                        max={maxFrmDt}
                        disabled={isDisabled}
                        onChange={(e) => {
                          setDate(e.value);
                        }}
                        style={{ width: "100%", maxWidth: "200px" }}
                      />
                    </td>

                    <td style={{ verticalAlign: "bottom" }}>
                      <input
                        type="submit"
                        className="btn btn-primary btn-sm"
                        value="Submit"
                        style={{ width: "100%", maxWidth: "100px" }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div> */}
      <AntModal
        open={modalVisible}
        title="Customize Time Weighted Return Report"
        // onCancel={handleCancel}
        closable={false}
        // maskClosable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpFrm"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              From
            </label>
            <DatePicker
              id="dpFrm"
              value={frmDate}
              // calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={isDisabled}
              style={{ width: "100%" }}
              valid={
                frmDate >= minFrmDt && frmDate <= maxFrmDt && frmDate != null
              }
              onChange={(e) => setfrmDate(e.value)}
              validationMessage={
                frmDate == null
                  ? "Enter Valid Date"
                  : frmDate < new Date("1/1/1000")
                  ? "Enter date in correct format (mm/dd/yyyy)"
                  : frmDate > maxFrmDt
                  ? "From date cannot exceed one year from Processing date."
                  : frmDate > toDate
                  ? "From date cannot exceed to date."
                  : frmDate < minFrmDt
                  ? "From date cannot be less than Processing date."
                  : "Validation error"
              }
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpTo"
              className="form-label"
              style={{
                display: "block",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              To
            </label>
            <DatePicker
              id="dpTo"
              value={toDate}
              // format="MM/dd/yyyy"
              // calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={false}
              onChange={(e) => {
                setToDate(e.value);
              }}
              style={{ width: "100%" }}
              valid={
                frmDate <= toDate && toDate <= maxFrmDt && toDate >= minFrmDt
              }
              validationMessage={
                toDate == null
                  ? "Enter Valid Date"
                  : toDate < new Date("1/1/1000")
                  ? "Enter date in correct format (mm/dd/yyyy)"
                  : toDate > maxFrmDt
                  ? "To date cannot exceed one year from Processing date."
                  : toDate < frmDate
                  ? "To date cannot be less than from date"
                  : toDate < minFrmDt
                  ? "To date cannot be less than Processing date."
                  : "Validation error"
              }
            />
          </div>
          <hr></hr>
          <div>
            {AcctId == 0 && (
              <FormControlLabel
                control={
                  <Switch
                    checked={runInAggregate}
                    onClick={handlerunInAggregate}
                    name="chkCtr"
                  />
                }
                label="Run In Aggregate."
                style={{ marginBottom: "10px", display: "block" }}
              />
            )}
          </div>

          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntModal>
      <form onSubmit={formSubmit}>
        <div className="row d-flex justify-content-between align-items-center mx-2 py-1 border-bottom">
          {/* Report Heading */}
          <div className="col reportheading fs-6">
            {/* Time Weighted Return Report */}
          </div>
          <div className="col text-center">
            {`From ${new Date(frmDate).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })} To ${new Date(toDate).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}`}
          </div>

          <div className="col text-end">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={handleSettings}
              title="Click to change filters"
            >
              <FaFilter></FaFilter>
              <span className="px-2">Change Filters</span>
            </button>
          </div>
        </div>
      </form>
      {flag === 1 ? (
        <TimeWtdRtnRptGrid
          data={TimeWtdRtnRptRptData}
          flag={isColumnSave}
          reportData={reportData}
        />
      ) : (
        <></>
      )}
    </div>
    // </form>
  );
};

export default TimeWtdRtnRpt;
