import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Loading from "./loading";
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./header";
import { filterBy } from "@progress/kendo-data-query";

import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { FaFilter } from "react-icons/fa";
import { Modal as AntModal } from "antd";
import { formatDate } from "@telerik/kendo-intl";
import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";
import { FormControlLabel, Switch } from "@mui/material";
import HistoricalMonthlyPerformanceValueDetails from "./historicalMonthlyPerformanceValueDetails";

const HistoricalMonthlyPerformanceRpt = () => {
  const [loading, setLoading] = useState(false);
  const [assetAllocModelRptData, setAssetAllocModelRptData] = useState([]);
  const [selectedAcct, setSelectedAcct] = useState(0);
  const [historicalMonthPerfData, setHistoricalMonthPerfData] = useState([]);
  const [modelId, setModelId] = useState(-1);
  const [session, setSession] = useState("");
  const [enableCombo, setEnableCombo] = useState(false);

  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const navigate = useNavigate();

  var date = new Date(localStorage.getItem("processingDate"));
  const [frmDate, setfrmDate] = React.useState(date);
  const [toDate, setToDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const [isDisabled, setisDisabled] = React.useState(false);
  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);
  const [closeOption, setCloseOption] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [netFeeInd, setNetFeeInd] = useState(1);
  const [selectedOption, setSelectedOption] = useState(1);
  const [displayBenchmark, setDisplayBenchmark] = useState(1);
  const [aggregateMode, setAggregateMode] = useState(0);

  const [initialState, setInitialState] = useState({
    selectedOption: 1,
    displayBenchmark: 1,
    netFeeInd: 1,
    aggregateMode: 0,
  });

  var tempToken = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        getHistoricalMonthlyPerformanceInfo();
      } catch (error) {
        console.error(error.message);
      }
    };
    // fetchData();
  }, []);
  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  const getHistoricalMonthlyPerformanceInfo = async () => {
    setLoading(true);
    setEnableCombo(true);
    console.log("test");
    let token = tempToken;
    console.log(token);
    // let token = JSON.parse(localStorage.getItem('token'));
    let AsOfId = JSON.parse(localStorage.getItem("userId"));

    // let AcctId = JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    let AcctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;
    let consolidationId = JSON.parse(localStorage.getItem("RelationID"))
      ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
      : 0;

    let AcctIdAndSmtname = JSON.parse(localStorage.getItem("AcctSelected"));

    let startDate = frmDate;
    let endDate = toDate;
    // let aggregateMode = AcctId != 0 ? 0 : 1;
    let useNetFees = netFeeInd;
    let dsplyBnchmrks = displayBenchmark;
    let blndBnchmrk = selectedOption;

    // let AsofDt =formatDate(asOfDt, "MM/dd/yyyy");

    // if (!Moment(AsofDt, 'MM-dd-yyyy').isValid()  || !Moment(priceDt, 'MM-dd-yyyy').isValid())

    // {

    //

    // }

    let RltnshpCd = "";

    let mConsolidationId = 0;

    let PageId = 1;

    // let BenchmarkList = "14,2,3,11,6,5,8,7,4,1,9";

    //let PriceDt =formatDate(priceDt, "MM/dd/yyyy");

    //let pageId = 1;

    // const postData = {UserId, AcctId, AsofDt, EffectiveDtInd, PriceDt};

    const postData = {
      AcctId,
      AsOfId,
      consolidationId,
      startDate,
      endDate,
      aggregateMode,
      useNetFees,
      dsplyBnchmrks,
      blndBnchmrk,
    };

    console.log("POSTDATA:", postData);

    //

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post("/RTGetHistoricalMonthlyPerf", postData, config)

      .then((response) => {
        const rowData = response.data;
        setAssetAllocModelRptData(rowData);

        console.log("Row Data", rowData);

        //getColumnStateDb();

        setLoading(false);
        setEnableCombo(false);

        // if(priceDt===""){

        //   setpriceDt("mm/dd/yyyy");

        // }
      })

      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }

        return error;
      });
  };

  if (apicallval === 1) {
    getHistoricalMonthlyPerformanceInfo();
    dispatch(callremove());
  }

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        getHistoricalMonthlyPerformanceInfo();
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const handleChange = (event) => {
    if (event.target.value === null) {
      console.log("Hii I am here");
      SetselAcct(selAcct);
      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem(
        "AcctSelected",
        JSON.stringify(JSON.parse(localStorage.getItem("acctData"))[0])
      );
    } else {
      console.log("Hii I am here section 2");
      SetselAcct(event.target.value);
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      getHistoricalMonthlyPerformanceInfo();
    }
  };

  const handleAggregateMode = () => {
    if (aggregateMode == 0) {
      setAggregateMode(1);
    }
    if (aggregateMode == 1) {
      setAggregateMode(0);
    }
  };

  const handleCheckboxChange = (e) => {
    if (netFeeInd == 0) {
      setNetFeeInd(1);
    }
    if (netFeeInd == 1) {
      setNetFeeInd(0);
    }
  };

  const formSubmit = (event) => {
    event.preventDefault();
    setModalVisible(false);
    setCloseOption(false);
    setLoading(true);
    getHistoricalMonthlyPerformanceInfo();
  };

  const handleSettings = () => {
    setInitialState({
      selectedOption,
      displayBenchmark,
      netFeeInd,
      aggregateMode,
    });
    setModalVisible(true);
    setCloseOption(true);
  };

  const handleCancel = () => {
    setSelectedOption(initialState.selectedOption);
    setDisplayBenchmark(initialState.displayBenchmark);
    setNetFeeInd(initialState.netFeeInd);
    setAggregateMode(initialState.aggregateMode);
    setModalVisible(false);
    setCloseOption(false);

    !closeOption && navigate("/dashboard");
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleBenchmarkDisplay = (e) => {
    if (displayBenchmark == 0) {
      setDisplayBenchmark(1);
    } else {
      setDisplayBenchmark(0);
    }
  };

  const filterData = (filter) => {
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };

  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header /> */}
            {/* <div className="my-1">
              <div className="row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
                <div className="col-md-4 col-lg-5 col-sm-11 text-start">
                  <span className="px-2">Account(s)</span>
                  {console.log("selctacct1", selAcct)}
                  <ComboBox
                    style={{
                      width: "350px",
                    }}
                    data={selAcctData}
                    textField="extrnlAcctId"
                    dataItemKey="acctId"
                    filterable={true}
                    value={selAcct}
                    onChange={handleChange}
                    onFilterChange={filterChange}
                    disabled={enableCombo}
                  />
                </div>
              </div>
            </div> */}
            <Loading />
          </div>
        )}
      </>
    );
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <AntModal
        open={modalVisible}
        title="Customize the Historical Monthly Performance Report"
        // onCancel={handleCancel}
        closable={false}
        // maskClosable={false}
        footer={[]}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpAsOfDt"
              className="form-label"
              style={{
                display: "block",
                fontWeight: 600,
                marginBottom: "5px",
              }}
            >
              From
            </label>
            <DatePicker
              id="dpFrm"
              value={frmDate}
              // format="MM/dd/yyyy"
              // calendar={CustomCalendar}
              // formatPlaceholder={{
              //   year: "yyyy",
              //   month: "mm",
              //   day: "dd",
              // }}
              //min={minFrmDt}
              valid={frmDate <= date}
              max={date}
              disabled={isDisabled}
              onChange={(e) => {
                setfrmDate(e.value);
              }}
              validationMessage={
                frmDate == null
                  ? "Enter Valid Date"
                  : frmDate < new Date("1/1/1000")
                  ? "Enter date in correct format (mm/dd/yyyy)"
                  : frmDate > date
                  ? "From date cannot exceed Processing date."
                  : frmDate > toDate
                  ? "From date cannot exceed to date."
                  : frmDate < new Date("1/2/1000")
                  ? "From date is not valid."
                  : "Validation error"
              }
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label
              htmlFor="dpAsOfDt"
              className="form-label"
              style={{
                display: "block",
                fontWeight: 600,
                marginBottom: "5px",
              }}
            >
              To
            </label>
            <DatePicker
              //disabled={false}
              value={toDate}
              max={date}
              // formatPlaceholder={{
              //   year: "yyyy",
              //   month: "mm",
              //   day: "dd",
              // }}
              // format="MM/dd/yyyy"
              valid={frmDate <= toDate && toDate <= date && toDate != null}
              onChange={(e) => {
                setToDate(e.value);
              }}
              // calendar={CustomCalendar}
              validationMessage={
                toDate == null
                  ? "Enter Valid Date"
                  : toDate < new Date("1/1/1000")
                  ? "Enter date in correct format (mm/dd/yyyy)"
                  : toDate > date
                  ? "To date cannot exceed Processing date."
                  : toDate < frmDate
                  ? "To date cannot be less than from date"
                  : toDate < new Date("1/2/1000")
                  ? "To date is not valid."
                  : "Validation error"
              }
            />
          </div>
          <hr></hr>
          <h6>Report Display Selection</h6>
          <FormControlLabel
            control={
              <Switch
                checked={netFeeInd}
                onClick={handleCheckboxChange}
                name="chkCtr"
              />
            }
            label="Net Fees in the ROR Calculation."
            style={{ marginBottom: "10px", display: "block" }}
          />

          {AcctId == 0 && (
            <FormControlLabel
              control={
                <Switch
                  checked={aggregateMode}
                  onClick={handleAggregateMode}
                  // disabled={true}
                  name="chkCtr"
                />
              }
              label="Run In Aggregate."
              style={{ marginBottom: "10px", display: "block" }}
            />
          )}

          {/* <label>
            <input
              type="checkbox"
              checked={netFeeInd}
              onChange={handleCheckboxChange}
            />
            &nbsp; Net Fees in the ROR Calculation.
          </label> */}
          <hr></hr>
          <h6>Benchmark Selection</h6>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={displayBenchmark}
                  onClick={handleBenchmarkDisplay}
                  name="chkCtr"
                />
              }
              label="Show Benchmarks"
              style={{ marginBottom: "10px", display: "block" }}
            />
            <label>
              <input
                type="radio"
                value={1}
                checked={selectedOption == 1}
                onChange={handleRadioChange}
              />
              &nbsp;Show Blended Benchmarks
            </label>
            <br />
            <label>
              <input
                type="radio"
                value={0}
                checked={selectedOption == 0}
                onChange={handleRadioChange}
              />
              &nbsp;Show Associated Benchmarks
            </label>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntModal>
      {/* <Header /> */}
      {/* <div className="my-1">
        <div className="row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded border-0">
          <div className="col-md-4 col-lg-5 col-sm-11 text-start">
            <span className="px-2">Account(s)</span>
            {console.log("selctacct", selAcct)}

            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
              disabled={enableCombo}
            />
          </div>
        </div>
      </div> */}

      {/* <AnnualPortfolioValueDetails
        data={assetAllocModelRptData}
        mergedData={combinedData}
      /> */}
      <div className="col text-end">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={handleSettings}
          title="Click to change filters"
        >
          <FaFilter />
          <span className="px-2">Change Filters</span>
        </button>
      </div>

      {!modalVisible && (
        <HistoricalMonthlyPerformanceValueDetails
          data={assetAllocModelRptData}
          aggregateMode={aggregateMode}
        />
      )}
    </div>
  );
};

export default HistoricalMonthlyPerformanceRpt;
