import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  ChartCategoryAxisTitle,
} from "@progress/kendo-react-charts";
import { orderBy } from "@progress/kendo-data-query";

import { formatNumber } from "@telerik/kendo-intl";

import Moment from "moment";
import Modal from "react-bootstrap/Modal";
import { Viewer } from "@grapecity/activereports-react";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import { process } from "@progress/kendo-data-query";

const HistoricalMonthlyPerformanceValueDetails = ({ data, aggregateMode }) => {
  const [show, setShow] = useState(false);
  const [NewReportData, setNewData] = useState("");
  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  const viewerRef = React.useRef();

  console.log("Data ", data);

  const t1Data = data.t1 || [];
  const t2Data = data.t2 || [];
  const t3Data = data.t3 || [];
  const t4Data = data.t4 || [];
  const t5Data = data.t5 || [];

  let AcctIdAndSmtname = JSON.parse(localStorage.getItem("AcctSelected"));

  // console.log("t1 Data", t1Data);
  // console.log("t2 Data", t2Data);
  // console.log("t3 Data", t3Data);
  // console.log("t4 Data", t4Data);
  console.log("t5 Data", t5Data);

  const excelExport = () => {
    setShow(true);
    openReport();
  };

  async function openReport() {
    //
    const report = await loadReport();

    //
    //   const parameters = [
    //     { Name: "pPriceDate", Value: [localStorage.getItem("processingDate")] },
    //     { Name: "pAsofDate", Value: [localStorage.getItem("processingDate")] },

    //     // {Name: 'pEndBalance', Value: [ChkBoxState]}
    //   ];

    for (let i = 0; i < newData.length; i++) {
      newData[i].incptnDt = Moment(newData[i].incptnDt).format("MM/DD/YYYY");
    }
    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(newData);
    //
    const parameters = [
      {
        Name: "pCnsldtnNm",
        Value: [
          JSON.parse(localStorage.getItem("RelationID")) != null
            ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm === ""
              ? 0
              : JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm
            : 0,
        ],
      },
      {
        Name: "pExtrnlAcctId",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 0
            : JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId,
        ],
      },
      {
        Name: "pShow",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 3
            : 2,
        ],
      },
    ];
    //if(loadingChild==false)
    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  async function loadReport() {
    // load report definition from the file
    //
    const reportResponse = await fetch(
      "HistoricalMonthlyPerformance.rdlx-json"
    );
    //
    const report = await reportResponse.json();
    return report;
  }

  const NumberCell = (props) => {
    return (
      <td style={{ textAlign: "right" }}>
        {formatNumber(props.dataItem[props.field], "##,#.00")}
      </td>
    );
  };

  const handleClose = () => setShow(false);

  const labelContent = (e) => `${e.value.toFixed(2)}%`;

  const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}%`;

  const categories = t3Data.map((item) => item.pctXVal);
  const ChartData = t3Data.map((item) => item.pctY);

  const t6Data = { t6: AcctIdAndSmtname };

  console.log("AcctIdAndSmtname All Data", t6Data);

  const newData = { ...data, t6: [AcctIdAndSmtname] };
  //setNewData(newData);

  console.log("with t6", newData);

  let gridDtata = "";

  const aggregates = [];

  const [collapsedState, setCollapsedState] = React.useState([]);

  const expandChange = (event, gridNo) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    if (gridNo == 2) {
      setResultGrid2({ ...resultGrid2 });
    }
    if (gridNo == 1) {
      setResultGrid1({ ...resultGrid1 });
    }
    if (gridNo == 3) {
      setResultGrid3({ ...resultGrid3 });
    }
  };

  const processWithGroups = (data, dataState) => {
    const groups = dataState.group;

    if (groups) {
      groups.map((group) => (group.aggregates = aggregates));
    }

    dataState.group = groups;
    const newDataState = process(data, dataState);
    setGroupIds({
      data: newDataState.data,
      group: dataState.group,
    });
    return newDataState;
  };

  gridDtata = t2Data;

  const createDataState = (dataState, gridNo) => {
    if (gridNo == 2) {
      return {
        result: process(t2Data.slice(0), dataState),
        dataState: dataState,
      };
    }
    if (gridNo == 1) {
      return {
        result: process(t1Data.slice(0), dataState),
        dataState: dataState,
      };
    }
    if (gridNo == 3) {
      return {
        result: process(t4Data.slice(0), dataState),
        dataState: dataState,
      };
    }
  };

  let initialState = createDataState(
    {
      take: 1000,
      skip: 0,
      group: [{ field: "extrnlAcctId" }],
    },
    2
  );
  let initialState1 = createDataState(
    {
      take: 1000,
      skip: 0,
      group: [{ field: "extrnlAcctId" }],
    },
    1
  );

  let initialState3 = createDataState(
    {
      take: 1000,
      skip: 0,
      group: [{ field: "extrnlAcctId" }],
    },
    3
  );
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [resultGrid2, setResultGrid2] = React.useState(
    processWithGroups(t2Data, initialState.dataState)
  );

  const [resultGrid1, setResultGrid1] = React.useState(
    processWithGroups(t1Data, initialState1.dataState)
  );

  const [resultGrid3, setResultGrid3] = React.useState(
    processWithGroups(t4Data, initialState3.dataState)
  );

  // console.log("resultssssss", result);

  const onDataStateChange = React.useCallback((e) => {
    setDataState(e.dataState);

    debugger;

    let updatedState = createDataState(e.dataState);
  }, []);

  return (
    <div className="bg-light">
      <div className="row my-1 mx-2 border-0">
        <div className="col-8 fs-6 reportheading mb-2">
          Historical Monthly Performance Report
        </div>
        <div className="col-4 text-end">
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={excelExport}
          >
            Preview
          </button>
        </div>
      </div>
      <div className="row bg-white g-0">
        <div className="col-12 h-100">
          <div className="card card-rounded w-100">
            <div className="card-body">
              <h6 className="card-title">Performance Return</h6>
              <Grid
                style={{ height: "400px" }}
                data={resultGrid1}
                {...dataState}
                onDataStateChange={onDataStateChange}
                onExpandChange={(event) => expandChange(event, 1)}
                expandField="expanded"
              >
                <Column field="indxAstNm" title=" " width={"350px"} />
                <Column
                  field="monthToDate"
                  title="Prior Month"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"200px"}
                />
                <Column
                  field="quarterToDate"
                  title="QTD"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"200px"}
                />
                <Column
                  field="yearToDate"
                  title="YTD"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"200px"}
                />
                <Column
                  field="oneYear"
                  title="1 - Yr"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"200px"}
                />
                <Column
                  field="threeYear"
                  title="3 - Yr"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"200px"}
                />
                <Column
                  field="fiveYear"
                  title="5 - Yr"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"200px"}
                />
                <Column
                  field="tenYear"
                  title="10 - Yr"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"200px"}
                />
                <Column
                  field="inception"
                  title="ITD"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"200px"}
                />
              </Grid>
            </div>
          </div>
        </div>
        <div className="col-12 h-100">
          <div className="card card-rounded w-100">
            <div className="card-body">
              <h6 className="card-title">Gross Net Caption</h6>
              <Grid
                style={{ height: "250px" }}
                // data={t2Data}
                data={resultGrid2}
                {...dataState}
                onDataStateChange={onDataStateChange}
                onExpandChange={(event) => expandChange(event, 2)}
                expandField="expanded"
              >
                <Column field="retYear" title=" " width={"200px"} />
                <Column
                  field="feeRetPct1"
                  title="Jan"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="feeRetPct2"
                  title="Feb"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="feeRetPct3"
                  title="Mar"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="feeRetPct4"
                  title="Apr"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="feeRetPct5"
                  title="May"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="feeRetPct6"
                  title="Jun"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="feeRetPct7"
                  title="Jul"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="feeRetPct8"
                  title="Aug"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="feeRetPct9"
                  title="Sep"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="feeRetPct10"
                  title="Oct"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="feeRetPct11"
                  title="Nov"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="feeRetPct12"
                  title="Dec"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="yearToDate"
                  title="YTD"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
              </Grid>
            </div>
          </div>
        </div>

        <div className="col-12 h-100">
          <div className="card card-rounded w-100">
            <div className="card-body">
              <h6 className="card-title">
                Monthly Return Distribution Since Inception - Net of Fees
              </h6>

              {AcctId != 0 || (AcctId == 0 && aggregateMode) ? (
                <Chart>
                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem categories={categories}>
                      <ChartCategoryAxisTitle text=" " />
                    </ChartCategoryAxisItem>
                  </ChartCategoryAxis>
                  <ChartTooltip format="N2" />
                  <ChartSeries>
                    <ChartSeriesItem
                      type="column"
                      gap={2}
                      spacing={0.25}
                      data={ChartData}
                    />
                  </ChartSeries>
                </Chart>
              ) : (
                <></>
              )}

              <Grid
                style={{ height: "350px" }}
                data={resultGrid3}
                {...dataState}
                onDataStateChange={onDataStateChange}
                onExpandChange={(event) => expandChange(event, 3)}
                expandField="expanded"
              >
                <Column field="indxAstNm" title=" " width={"250px"} />
                <Column
                  field="pctLessEqualMinus5"
                  title="<-5%"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="pctLessEqualMinus4"
                  title="-5% to -4%"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="pctLessEqualMinus3"
                  title="-4% to -3%"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="pctLessEqualMinus2"
                  title="-3% to -2%"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="pctLessEqualMinus1"
                  title="-2% to -1%"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="pctLessEqual0"
                  title="-1% to -0%"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="pctLessEqualPlus1"
                  title="0% to 1%"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="pctLessEqualPlus2"
                  title="1% to 2%"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="pctLessEqualPlus3"
                  title="2% to 3%"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="pctLessEqualPlus4"
                  title="3% to 4%"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="pctLessEqualPlus5"
                  title="4% to 5%"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
                <Column
                  field="pctMoreThan5"
                  title=">5%"
                  cell={NumberCell}
                  headerClassName="rightHeader"
                  width={"130px"}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HistoricalMonthlyPerformanceValueDetails;
