import React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import BankLogoPage from "./bankLogoPage";
import { process } from "@progress/kendo-data-query";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { FaFileExcel, FaFilePdf, FaEye } from "react-icons/fa";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";

import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";

import { GridPDFExport } from "@progress/kendo-react-pdf";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import Modal from "react-bootstrap/Modal";
import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";

const aggregates = [
  {
    field: "pCash",
    aggregate: "sum",
  },
  {
    field: "iCash",
    aggregate: "sum",
  },
  {
    field: "shares",
    aggregate: "sum",
  },
];

const AcctTransactionGrid = ({ data, flag, reportData }) => {
  let acctId = JSON.parse(localStorage.getItem("AccountID")).acctId;

  const initialGroup = [{ field: "accountName" }, { field: "tranTypNm" }];

  const processWithGroups = (data, dataState) => {
    const groups = dataState.group;

    if (groups) {
      groups.map((group) => (group.aggregates = aggregates));
    }

    dataState.group = groups;
    const newDataState = process(data, dataState);
    setGroupIds({
      data: newDataState.data,
      group: dataState.group,
    });
    return newDataState;
  };

  // call active report
  const [show, setShow] = React.useState(false);
  const viewerRef = React.useRef();
  async function loadReport() {
    // load report definition from the file

    const reportResponse = await fetch("AccountTransaction.rdlx-json");

    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    debugger;
    const report = await loadReport();

    const parameters = [
      // <p>Administrator : {localStorage.getItem("Administrator")}</p>
      // </div>
      // <div className="text-end fw-bold col-md-3">
      // <p>AccountType : {localStorage.getItem("acctType")}</p>

      { Name: "pAccountType", Value: [localStorage.getItem("acctType")] },
      {
        Name: "pAdministrator",
        Value: [localStorage.getItem("Administrator")],
      },
      {
        Name: "pStartingBalance",
        Value: [
          formatNumber(
            data.length === 0 ? "0.00" : data[0].startingShare,
            "##,#.00"
          ),
        ],
      },
      {
        Name: "pEndBalance",
        Value: [
          formatNumber(
            localStorage.getItem("EndingBal") === "0"
              ? "0.00"
              : localStorage.getItem("EndingBal"),
            "##,#.00"
          ),
        ],
      },
      {
        Name: "pCnsldtnNm",
        Value: [
          JSON.parse(localStorage.getItem("RelationID")) != null
            ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm === ""
              ? 0
              : JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm
            : 0,
        ],
      },
      {
        Name: "pExtrnlAcctId",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 0
            : JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId,
        ],
      },
      {
        Name: "pShow",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 3
            : 2,
        ],
      },
      //{Name: 'pE', Value: [ChkBoxState]}
    ];
    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(reportData);

    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  //

  var tempToken = JSON.parse(localStorage.getItem("token"));
  const menuWithExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const menuWithoutExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };
  const dataExport = process(data, {
    group: initialGroup,
  }).data;

  //const data = products;
  const _export = React.useRef(null);
  const excelExport = () => {
    setShow(true);
    openReport();
    // if (_export.current !== null) {
    //   _export.current.save();
    // }
  };
  const [locked, setLocked] = React.useState(false);

  const columnLocked = () => {
    setLocked(!locked);
  };
  const totalSum = (props) => {
    const field = props.field || "";
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };
  const DateCell = (props) => {
    if (props.field === "prcsDt") {
      let cdt = new Date(props.dataItem["prcsDt"]);
      return (
        <td style={{ textAlign: "left" }}>
          {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
          {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
        </td>
      );
    }
    if (props.field === "modDt") {
      let cdt = new Date(props.dataItem["modDt"]);
      return (
        <td style={{ textAlign: "left" }}>
          {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
          {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
        </td>
      );
    }
  };

  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });

    newColumnsState[2].footerCell = totalSum;
    newColumnsState[3].footerCell = totalSum;
    newColumnsState[4].footerCell = totalSum;

    // newColumnsState[1].columnMenu= menuWithExcelCheck;
    // newColumnsState[0].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[2].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[3].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[4].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[5].columnMenu= menuWithExcelCheck;
    // newColumnsState[6].columnMenu= menuWithExcelCheck;
    // newColumnsState[7].columnMenu= menuWithExcelCheck;
    // newColumnsState[8].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[9].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[10].columnMenu= menuWithExcelCheck;
    // newColumnsState[11].columnMenu= menuWithExcelCheck;
    // newColumnsState[12].columnMenu= menuWithExcelCheck;
    // newColumnsState[13].columnMenu= menuWithExcelCheck;
    // newColumnsState[14].columnMenu= menuWithExcelCheck;
    // newColumnsState[15].columnMenu= menuWithExcelCheck;
    // newColumnsState[17].columnMenu= menuWithExcelCheck;
    // newColumnsState[16].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[18].columnMenu= menuWithExcelCheck;
    // newColumnsState[19].columnMenu= menuWithoutExcelCheck;
    return newColumnsState;
  };
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  const defaultColumns = [
    // {
    //   title: 'Branch',
    //   field: 'branchName',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Account',
    //   field: 'accountType',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Account#',
    //   field: 'accountName',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Trans Type',
    //   field: 'tranTypNm',
    //   minWidth: "auto",
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: "Account Name",
    //   field: "accountName",
    //   minWidth: 150,
    //   show: acctId == 0 ? true : false,
    //   filter: "text",
    //   locked: false,
    // },
    {
      title: "Date",
      field: "prcsDt",
      minWidth: 150,
      show: true,
      filter: "date",
      locked: false,
    },
    {
      title: "Description",
      field: "totalLine",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: false,
    },
    // {
    //   title: 'Administrator',
    //   field: 'administrator',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
    // },
    // {
    //   title: 'Inv. Officer',
    //   field: 'investmentOfficer',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
    // },
    {
      title: "Principal($)",
      field: "pCash",
      minWidth: 150,
      show: true,
      filter: "numeric",
      footerCell: data.length != 0 && totalSum,
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Income($)",
      field: "iCash",
      minWidth: 150,
      show: true,
      filter: "numeric",
      footerCell: data.length != 0 && totalSum,
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Shares",
      field: "shares",
      minWidth: 150,
      show: true,
      filter: "numeric",
      footerCell: data.length != 0 && totalSum,
      format: "{0:d2}",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Created By",
      field: "createdByInit",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Location",
      field: "location",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Registration",
      field: "registration",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Effective Date",
      field: formatDate("effectiveDate", "mdy"),
      minWidth: 150,
      show: true,
      locked: false,
      filter: "date",
    },
    {
      title: "Entry Date",
      field: formatDate("entryDate", "mdy"),
      minWidth: 150,
      show: true,
      locked: false,
      filter: "date",
    },
    {
      title: "GL Description",
      field: "glDescription",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Long Name",
      field: "longName",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Payment Code",
      field: "paymentCode",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Process Code",
      field: "processCode",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Remittance Type",
      field: "remittanceType",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Sec Symbol",
      field: "secSymbol",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Sequence Number",
      field: "sequenceNumber",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Vendor",
      field: "vendorName",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Security",
      field: "security",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Trade Date",
      field: formatDate("tradeDate", "mdy"),
      minWidth: 100,
      show: true,
      filter: "date",
      locked: false,
    },
  ];
  const defaultColumnsCopy = [
    // {
    //   title: 'Branch',
    //   field: 'branchName',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Account',
    //   field: 'accountType',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },

    // {
    //   title: "Account Name",
    //   field: "accountName",
    //   minWidth: 150,
    //   show: acctId == 0 ? true : false,
    //   filter: "text",
    //   locked: true,
    // },
    // {
    //   title: 'Trans Type',
    //   field: 'tranTypNm',
    //   minWidth: "auto",
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Date",
      field: "prcsDt",
      minWidth: 150,
      show: true,
      filter: "date",
      locked: false,
    },
    {
      title: "Description",
      field: "totalLine",
      minWidth: 300,
      show: true,
      filter: "text",
      locked: false,
    },
    // {
    //   title: 'Administrator',
    //   field: 'administrator',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
    // },
    // {
    //   title: 'Inv. Officer',
    //   field: 'investmentOfficer',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: false,
    // },
    {
      title: "Principal($)",
      field: "pCash",
      minWidth: 150,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Income($)",
      field: "iCash",
      minWidth: 150,
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Shares",
      field: "shares",
      minWidth: 150,
      show: true,
      filter: "numeric",
      format: "{0:d2}",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Created By",
      field: "createdByInit",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Location",
      field: "location",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Registration",
      field: "registration",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Effective Date",
      field: formatDate("effectiveDate", "mdy"),
      minWidth: 150,
      show: true,
      locked: false,
      filter: "date",
    },
    {
      title: "Entry Date",
      field: formatDate("entryDate", "mdy"),
      minWidth: 150,
      show: true,
      locked: false,
      filter: "date",
    },
    {
      title: "GL Description",
      field: "glDescription",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Long Name",
      field: "longName",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Payment Code",
      field: "paymentCode",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Process Code",
      field: "processCode",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "RemittanceType",
      field: "remittanceType",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Sec Symbol",
      field: "secSymbol",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Sequence Number",
      field: "sequenceNumber",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Vendor",
      field: "vendorName",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Security",
      field: "security",
      minWidth: 150,
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Trade Date",
      field: formatDate("tradeDate", "mdy"),
      minWidth: 100,
      show: true,
      filter: "date",
      locked: false,
    },
  ];
  const CustomGroupHeader = (props) => {
    return `${props.value}`;
  };
  let loadedColumns = localStorage.getItem("gridColumns");
  const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumns;

  if (
    localStorage.getItem("TransactDataState") !== undefined &&
    localStorage.getItem("TransactDataState") !== null
  ) {
    var datastatetemp = JSON.parse(localStorage.getItem("TransactDataState"));
    if (datastatetemp.filter !== undefined) {
      if (datastatetemp.filter.filters.length > 0) {
        for (var i = 0; i < GridColumns.length; i++) {
          for (var j = 0; j < datastatetemp.filter.filters.length; j++) {
            if (
              GridColumns[i].field ==
              datastatetemp.filter.filters[j].filters[0].field
            ) {
              GridColumns[i].headerClassName = "active";
              break;
            } else {
              GridColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < GridColumns.length; i++) {
          GridColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < GridColumns.length; i++) {
        GridColumns[i].headerClassName = "";
      }
    }
  }
  for (var i = 0; i < GridColumns.length; i++) {
    for (var j = 0; j < defaultColumnsCopy.length; j++) {
      if (
        GridColumns[i].field === defaultColumnsCopy[j].field &&
        defaultColumnsCopy[j].headerClassName !== undefined
      )
        GridColumns[i].headerClassName =
          defaultColumnsCopy[j].headerClassName +
          " " +
          GridColumns[i].headerClassName;
    }
  }

  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  // let initialState = createDataState({
  //   take: 20,
  //   skip: 0,
  //   group: [
  //     {
  //       field: 'tranTypNm',
  //     },
  //   ],
  // });

  let initialState = createDataState({
    filter:
      localStorage.getItem("TransactDataState") === undefined ||
      localStorage.getItem("TransactDataState") === null
        ? undefined
        : JSON.parse(localStorage.getItem("TransactDataState")).filter,
    sort:
      localStorage.getItem("TransactDataState") === undefined ||
      localStorage.getItem("TransactDataState") === null
        ? [
            {
              field: "effectiveDate",
              dir: "desc",
            },
          ]
        : JSON.parse(localStorage.getItem("TransactDataState")).sort,
    take: 40000,
    skip: 0,
    group: [{ field: "accountName" }, { field: "tranTypNm" }],
  });
  let initialStateExport = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    group: [{ field: "accountName" }, { field: "tranTypNm" }],
  });

  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );

  const [resultExport, setResultExport] = React.useState(
    processWithGroups(data, initialStateExport.dataState)
  );
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [stateColumns, setStateColumns] = React.useState(GridColumns);
  const [currentColumns, setCurrentColumns] = React.useState(GridColumns);
  // GridColumns[2].footerCell = totalSum;
  // GridColumns[3].footerCell = totalSum;
  // GridColumns[4].footerCell = totalSum;
  // // GridColumns[1].columnMenu= menuWithExcelCheck;
  // // GridColumns[0].columnMenu= menuWithoutExcelCheck;
  // // GridColumns[2].columnMenu= menuWithoutExcelCheck;
  // // GridColumns[3].columnMenu= menuWithoutExcelCheck;
  // // GridColumns[4].columnMenu= menuWithoutExcelCheck;
  // // GridColumns[5].columnMenu= menuWithExcelCheck;
  // // GridColumns[6].columnMenu= menuWithExcelCheck;
  // // GridColumns[7].columnMenu= menuWithExcelCheck;
  // // GridColumns[8].columnMenu= menuWithoutExcelCheck;
  // // GridColumns[9].columnMenu= menuWithoutExcelCheck;
  // // GridColumns[10].columnMenu= menuWithExcelCheck;
  // // GridColumns[11].columnMenu= menuWithExcelCheck;
  // // GridColumns[12].columnMenu= menuWithExcelCheck;
  // // GridColumns[13].columnMenu= menuWithExcelCheck;
  // // GridColumns[14].columnMenu= menuWithExcelCheck;
  // // GridColumns[15].columnMenu= menuWithExcelCheck;
  // // GridColumns[17].columnMenu= menuWithExcelCheck;
  // // GridColumns[16].columnMenu= menuWithoutExcelCheck;
  // // GridColumns[18].columnMenu= menuWithExcelCheck;
  // // GridColumns[19].columnMenu= menuWithoutExcelCheck;
  let pageSize = Number.MAX_VALUE;
  const [page, setPage] = React.useState({
    skip: 0,
    take: pageSize,
  });

  const saveColumnStateDb = async () => {
    //Storing column settings in DB

    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 23;
    let ColumnSettings = localStorage.getItem("gridColumns");
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTSaveGridColumn/Index", postData, config)
      .then((response) => {
        console.log(response);
        const rowData = response.data;
        //populatePortfolioHoldingRptData(rowData.ocPortFolioHoldingsMainOutPut);
        //populatePortfolioHoldingRptDatatab2(rowData.ocPortFolioHoldingsTradeTypeOutPut);
        //setflagPortfolio(true);
        //setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    tempToken = token;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        saveColumnStateDb();
      })
      .catch((error) => {
        //

        console.log("my error is " + error);
      });
  };
  // const saveColumnsState = (columns) => {
  //     console.log('save called!!');
  //     //console.log(columns);
  //     let currentColumnsState = JSON.stringify(columns);
  //
  //     localStorage.setItem('gridColumns', currentColumnsState);

  //     saveColumnStateDb();

  // };

  useEffect(() => {
    saveColumnsState(stateColumns);
  }, [stateColumns]);
  const saveColumnsState = () => {
    console.log("save called!!");
    const columns = stateColumns;

    for (var i = 0; i < columns.length; i++) {
      if (columns[i].headerClassName !== undefined) {
        if (columns[i].headerClassName.includes("rightHeader")) {
          columns[i].headerClassName = "rightHeader";
        } else {
          columns[i].headerClassName = "";
        }
      }
    }
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);

    localStorage.setItem("gridColumns", currentColumnsState);

    saveColumnStateDb();
  };
  // const dataStateChange = (event) => {
  //   let updatedState = createDataState(event.dataState);
  //   let updatedStateExcel = createDataState({
  //     take: Number.MAX_VALUE,
  //     skip: 0,
  //     sort: event.dataState.sort,
  //     group: event.dataState.group,

  //   });
  //   setResult(processWithGroups(data, updatedState.dataState));
  //   setResultExport(processWithGroups(data, updatedStateExcel.dataState));
  //   setDataState(updatedState.dataState);
  // };
  const dataStateChange = (event) => {
    if (event.dataState.filter !== undefined) {
      if (event.dataState.filter.filters.length > 0) {
        for (var i = 0; i < stateColumns.length; i++) {
          for (var j = 0; j < event.dataState.filter.filters.length; j++) {
            if (
              stateColumns[i].field ==
              event.dataState.filter.filters[j].filters[0].field
            ) {
              stateColumns[i].headerClassName = "active";
              break;
            } else {
              stateColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < stateColumns.length; i++) {
          stateColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < stateColumns.length; i++) {
        stateColumns[i].headerClassName = "";
      }
    }

    for (var i = 0; i < stateColumns.length; i++) {
      for (var j = 0; j < defaultColumnsCopy.length; j++) {
        if (stateColumns[i].field === defaultColumnsCopy[j].field)
          stateColumns[i].headerClassName =
            defaultColumnsCopy[j].headerClassName +
            " " +
            stateColumns[i].headerClassName;
      }
    }
    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
    });
    localStorage.setItem(
      "TransactDataState",
      JSON.stringify(updatedState.dataState)
    );
    setResult(processWithGroups(data, updatedState.dataState));
    setResultExport(processWithGroups(data, updatedStateExcel.dataState));
    setDataState(updatedState.dataState);
  };

  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };

  // const handleColumnLockToggle = (columnField, state) => {
  //   let newColumns = currentColumns.map((column) => {
  //     if (column.field === columnField) {
  //       column.locked = state;
  //     }

  //     return column;
  //   });
  //   setCurrentColumns(newColumns);
  // }; // place all locked columns first

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };

  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };

  const NumberCell = (props) => {
    if (props.field === "branchName") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "accountType") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "accountName") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "tranTypNm") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "totalLine") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "administrator") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "investmentOfficer") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "pCash") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "iCash") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "shares") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
  };
  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      //IMPORTANT - You need to add collection with the columns and their field name
      //you can define the Grid columns outside of the Grid and reuse them here.
      const columns = [
        { field: "prcsDt" },
        { field: "totalLine" },
        { field: "pCash", aggregate: "sum" },
        { field: "iCash", aggregate: "sum" },
        { field: "shares", aggregate: "sum" },
        { field: "createdByInit" },
        { field: "location" },
        { field: "registration" },
        { field: formatDate("effectiveDate", "mdy") },
        { field: formatDate("entryDate", "mdy") },
        { field: "glDescription" },
        { field: "longName" },
        { field: "paymentCode" },
        { field: "processCode" },
        { field: "remittanceType" },
        { field: "secSymbol" },
        { field: "sequenceNumber" },
        { field: "vendorName" },
        { field: "security" },
        { field: formatDate("tradeDate", "mdy") },
      ];

      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - columns.length}
          ></td>
          {getCells(columns, cellProps)}
        </>
      );
    }
    // if (cellProps.rowType === "groupFooter") {
    //   if (cellProps.field === "shares") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(cellProps.dataItem.aggregates.shares.sum, "##,#.00")}
    //       </td>
    //     );
    //   }

    //   if (cellProps.field === "iCash") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(cellProps.dataItem.aggregates.iCash.sum, "##,#.00")}
    //       </td>
    //     );
    //   }

    //   if (cellProps.field === "pCash") {
    //     return (
    //       <td
    //         style={{ textAlign: "right" }}
    //         aria-colindex={cellProps.columnIndex}
    //         role={"gridcell"}
    //       >
    //         {formatNumber(cellProps.dataItem.aggregates.pCash.sum, "##,#.00")}
    //       </td>
    //     );
    //   }
    // }
    if (cellProps.rowType === "data") {
      if (cellProps.field === "prcsDt") {
        let cdt = new Date(cellProps.dataItem["prcsDt"]);
        return (
          <td style={{ textAlign: "left" }}>
            {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
            {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
          </td>
        );
      }
      if (cellProps.field === "effectiveDate") {
        let cdt = new Date(cellProps.dataItem["effectiveDate"]);
        return (
          <td style={{ textAlign: "left" }}>
            {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
            {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
          </td>
        );
      }
      if (cellProps.field === "entryDate") {
        let cdt = new Date(cellProps.dataItem["entryDate"]);
        return (
          <td style={{ textAlign: "left" }}>
            {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
            {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
          </td>
        );
      }
      if (cellProps.field === "tradeDate") {
        let cdt = new Date(cellProps.dataItem["tradeDate"]);
        return (
          <td style={{ textAlign: "left" }}>
            {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
            {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
          </td>
        );
      }
      if (cellProps.field === "holdingDate") {
        let cdt = new Date(cellProps.dataItem["holdingDate"]);
        return (
          <td style={{ textAlign: "left" }}>
            {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
            {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
          </td>
        );
      }
      if (cellProps.field === "pCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "iCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "shares") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "processCode") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "sequenceNumber") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "###")}
          </td>
        );
      }
    }

    return tdElement;
  };

  let _pdfExport;
  const exportPDF = () => {
    //let oldDataState = { ...dataState };
    // if (pdfExportComponent.current) {
    //setDataState({ ...dataState, skip: 0, take: Number.MAX_VALUE });

    _pdfExport.save();

    // setTimeout(() => {
    //   setDataState(oldDataState);
    // });
  };
  //const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const PageTemplate = (props) => {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            top: "1px",

            width: "98%",
            borderBottom: "1px solid #bce8f1",
          }}
        >
          <div className="row d-flex mx-3">
            <div className="col text-start">
              <a className="px-2">
                <BankLogoPage />
              </a>
            </div>
            <div className="col text-end px-5 py-2">
              <h2 className="fw-bold text-fitek">
                Account Transaction Date Range Report
              </h2>
            </div>
          </div>

          <div className="row d-flex justify-content-between align-items-center py-2 mt-1 bg-light shadow-sm rounded">
            <div className="fw-bold text-end col-md-2">
              <p>
                Account :{" "}
                {JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId}
              </p>
            </div>
            <div className="fw-bold text-end col-md-2">
              <p>Processing Date : {localStorage.getItem("processingDate")}</p>
            </div>

            <div className="fw-bold text-end col-md-3">
              <p>Administrator : {localStorage.getItem("Administrator")}</p>
            </div>
            <div className="fw-bold text-end col-md-3">
              <p>AccountType : {localStorage.getItem("acctType")}</p>
            </div>
          </div>

          {/* <div className='fw-bold  text-center'>
                    Account Profile</div> */}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            //  borderTop: "1px solid #bce8f1"
          }}
        >
          Page {props.pageNum} of {props.totalPages}
        </div>
      </div>
    );
  };

  console.log(result);
  return (
    <div>
      {show ? (
        <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
          <Modal.Body>
            <div id="viewer-host">
              <Viewer ref={viewerRef} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary  btn-sm" onClick={handleClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}

      <div className="card-body mt-0 pt-0">
        <div className="row d-flex justify-content-start align-items-center bg-light shadow-sm rounded">
          {/* <div className='text-end col-md-2'><p>Processing Date : {localStorage.getItem("processingDate")}</p></div> */}
          {acctId != 0 && (
            <div className="text-end fw-bold col-md-3">
              <p>Administrator : {localStorage.getItem("Administrator")}</p>
            </div>
          )}
          {acctId != 0 && (
            <div className="text-end fw-bold col-md-3">
              <p>Account Type : {localStorage.getItem("acctType")}</p>
            </div>
          )}
          {/* <div className="col text-end">
                                <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
                                    <DropdownItem onClick={excelExport}><span className='px-1'><FaFileExcel /></span>Excel</DropdownItem> */}
          {/* <DropdownItem onClick={exportPDF}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem> */}

          {/* </DropdownButton>

                            </div> */}

          <div className="col text-end">
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={excelExport}
            >
              <FaEye /> Preview
            </button>
          </div>
        </div>
        <div className="mx-1 px-1">
          <div className="row d-flex justify-content-start align-items-center my-1 px-2 mx-2 bg-light shadow-sm rounded">
            <div className="subheader text-end col-md-12">
              {/*<p>Starting Balances : {formatNumber(data.length===0?"0.00":data[0].startingShare, '##,#.00')}</p>*/}
            </div>
          </div>
          <ExcelExport
            data={dataExport}
            group={initialGroup}
            ref={_export}
            fileName={
              JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
              "_AcctTransactDateaRange.xlsx"
            }
          >
            <ExcelExportColumnGroup
              title={
                "Account Number: " +
                JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
                "  Processing Date: " +
                localStorage.getItem("processingDate") +
                "  Administrator: " +
                localStorage.getItem("Administrator") +
                "  AccountType: " +
                localStorage.getItem("acctType")
              }
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              <ExcelExportColumn
                field="tranTypNm"
                hidden={true}
                groupHeader={CustomGroupHeader}
              />
              <ExcelExportColumn field="prcsDt" title="Date" />
              <ExcelExportColumn
                field="totalLine"
                title="Description"
                width={500}
              />
              <ExcelExportColumn
                field="pCash"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Principal($)"
              />
              <ExcelExportColumn
                field="iCash"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Income($)"
              />
              <ExcelExportColumn
                field="shares"
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Shares"
              />
              <ExcelExportColumn
                field="CreatedByInit"
                title="Created By"
                width={150}
              />
              <ExcelExportColumn
                field="location"
                title="Location"
                width={150}
              />
              <ExcelExportColumn
                field="registration"
                title="Registration"
                width={150}
              />
              <ExcelExportColumn
                field="effectiveDate"
                title="EffectiveDate"
                width={150}
              />
              <ExcelExportColumn
                field="entryDate"
                title="EntryDate"
                width={150}
              />
              <ExcelExportColumn
                field="gLDescription"
                title="GL Description"
                width={150}
              />
              <ExcelExportColumn
                field="longName"
                title="Long Name"
                width={150}
              />
              <ExcelExportColumn
                field="paymentCode"
                title="Payment Code"
                width={150}
              />
              <ExcelExportColumn
                field="processCode"
                title="Process Code"
                headerCellOptions={{ textAlign: "right" }}
                width={150}
              />
              <ExcelExportColumn
                field="remittanceType"
                title="Remittance Type"
                width={150}
              />
              <ExcelExportColumn
                field="secSymbol"
                title="Sec Symbol"
                width={150}
              />
              <ExcelExportColumn
                field="sequenceNumber"
                headerCellOptions={{ textAlign: "right" }}
                title="Sequence Number"
                width={150}
              />
              <ExcelExportColumn
                field="vendorName"
                title="Vendor"
                width={150}
              />
              <ExcelExportColumn
                field="security"
                title="Security"
                width={150}
              />
              <ExcelExportColumn
                field="tradeDate"
                title="TradeDate"
                width={150}
              />
            </ExcelExportColumnGroup>
          </ExcelExport>

          <Grid
            style={{ height: "600px" }}
            data={result}
            {...dataState}
            onDataStateChange={dataStateChange}
            //onColumnReorder={onColumnReorder}
            //onColumnResize={onColumnResize}
            expandField="expanded"
            onExpandChange={expandChange}
            cellRender={cellRender}
            sortable={true}
            //resizable={true}
            pageable={{
              buttonCount: 0,
              info: true,
              previousNext: false,
            }}
            //reorderable={true}

            pageSize={Number.MAX_VALUE}
            groupable={{
              // footer: "visible",
              enabled: false,
            }}
          >
            {stateColumns.map(
              (column, idx) =>
                column.show && (
                  <Column
                    width={setWidth(column.minWidth)}
                    //  locked={column.locked}
                    key={idx}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    footerCell={column.footerCell}
                    headerClassName={column.headerClassName}
                    {...column}
                    //columnMenu={column.columnMenu}

                    //cell={NumberCell}
                    // columnMenu={(props) => (
                    //   <CustomColumnMenuNoGrp
                    //     {...props}
                    //     columns={stateColumns}
                    //     onColumnsSubmit={onColumnsSubmit}
                    //   />
                    // )}
                  />
                )
            )}
          </Grid>

          <div className="row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
            <div className="subheader text-end col-md-12">
              {/*<p>Ending Balances : {formatNumber(localStorage.getItem('EndingBal')==="0"?"0.00":localStorage.getItem('EndingBal'), '##,#.00')}</p>*/}
            </div>
          </div>
          {/* <GridPDFExport
            ref={(element) => {
              _pdfExport = element;
            }}
            margin={{ top: 100, left: 30, right: 30, bottom: 40 }}
            pageTemplate={PageTemplate}
            forcePageBreak=".page-break"
            paperSize="A4"
            scale={0.5}
            allPages={true}
            //margin="1cm"
            landscape={true}
            repeatHeaders={true}
          >
            
             <Grid
              style={{ height: "600px" }}
              data={result}
              {...dataState}
              onDataStateChange={dataStateChange}
              //onColumnReorder={onColumnReorder}
              //onColumnResize={onColumnResize}
              expandField="expanded"
              onExpandChange={expandChange}
              cellRender={cellRender}
              sortable={true}
              resizable={true}
              pageable={true}
              reorderable={true}
             
               pageSize={10}

              groupable={{
                footer: 'visible',
                enabled: false
              }}
            >
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      locked={column.locked}
                      key={idx}
                      field={column.field}
                      footerCell={column.footerCell}
                      //cell={NumberCell}
                      title={column.title}
                      headerClassName={column.headerClassName}

                      filter={column.filter}
                      columnMenu={(props) => (
                        <CustomColumnMenuNoGrp
                          {...props}
                          columns={stateColumns}
                          onColumnsSubmit={onColumnsSubmit}
                        />
                      )}
                    />
                  )
              )}
            </Grid>
          </GridPDFExport> */}
        </div>
      </div>
      <br />
    </div>
  );
};

export default AcctTransactionGrid;
