import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import BankLogoPage from "./bankLogoPage";
import Modal from "react-bootstrap/Modal";
//import data from './data.json';
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { FaFileExcel, FaFilePdf, FaEye } from "react-icons/fa";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";
import {
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";

import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "1px",

          width: "98%",
          borderBottom: "1px solid #bce8f1",
        }}
      >
        <div className="row d-flex mx-3">
          <div className="col text-start">
            <a className="px-2">
              <BankLogoPage />
            </a>
          </div>
          <div className="col text-end px-5 py-2">
            <h2 className="fw-bold text-fitek">
              Assets By Market Capital Report
            </h2>
          </div>
        </div>

        {/* <div className='fw-bold  text-center'>
                  Account Profile</div> */}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          //  borderTop: "1px solid #bce8f1"
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
};

const AstByMrktCapitalGrid = ({ data, flag, accntSelected, reportData }) => {
  // call active report
  //const [show, setShow] = React.useState(false);
  const viewerRef = React.useRef();
  async function loadReport() {
    // load report definition from the file

    const reportResponse = await fetch("AssetByMarketCapital.rdlx-json");

    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    const report = await loadReport();
    console.log("in open report");

    const parameters = [
      // <p>Administrator : {localStorage.getItem("Administrator")}</p>
      // </div>
      // <div className="text-end fw-bold col-md-3">
      // <p>AccountType : {localStorage.getItem("acctType")}</p>
      {
        Name: "pCnsldtnNm",
        Value: [
          JSON.parse(localStorage.getItem("RelationID")) != null
            ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm === ""
              ? 0
              : JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm
            : 0,
        ],
      },
      {
        Name: "pExtrnlAcctId",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 0
            : JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId,
        ],
      },
      {
        Name: "pShow",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 3
            : 2,
        ],
      },
      //{Name: 'pE', Value: [ChkBoxState]}
    ];

    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(reportData);

    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  //
  var tempToken = JSON.parse(localStorage.getItem("token"));

  const menuWithExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const menuWithoutExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const _export = React.useRef(null);
  const excelExport = () => {
    setShow(true);
    openReport();
    // if (_export.current !== null) {
    //   _export.current.save(data);
    // }
  };
  const [locked, setLocked] = React.useState(false);
  let allPDFExport;
  const columnLocked = () => {
    setLocked(!locked);
  };
  const totalSum = (props) => {
    const field = props.field || "";
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };
  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    newColumnsState[5].footerCell = totalSum;

    // newColumnsState[0].columnMenu= menuWithExcelCheck;
    // newColumnsState[1].columnMenu= menuWithExcelCheck;
    // newColumnsState[2].columnMenu= menuWithExcelCheck;
    // newColumnsState[3].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[4].columnMenu= menuWithoutExcelCheck;
    // GridColumns[5].columnMenu= menuWithoutExcelCheck;
    // GridColumns[6].columnMenu= menuWithoutExcelCheck;
    return newColumnsState;
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const defaultColumns = [
    {
      title: "Ticker",
      field: "tckrSymbl",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: true,
    },
    {
      title: "Cusip",
      field: "cusip",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: true,
    },
    {
      title: "Asset Short name",
      field: "astShrtNm",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: true,
    },

    {
      title: "Volume",
      field: "nbrOutstndngShrs",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Current Price($)",
      field: "currPrc",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Market Capital($)",
      field: "marketCap",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    // {
    //   title: "Capital Type",
    //   field: "mktCapNm",
    //   minWidth: "auto",
    //   show: true,
    //   filter: "text",
    //   locked: false,
    // },
  ];

  let loadedColumns = localStorage.getItem("gridColumns");
  const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumns;
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      resultExport: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 1000,
    skip: 0,
    group: [{ field: "extrnlAcctId" }, { field: "mktCapNm" }],
  });
  let initialStateExport = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    group: [{ field: "extrnlAcctId" }, { field: "mktCapNm" }],
  });
  const [result, setResult] = useState(initialState.result);
  const [dataState, setDataState] = useState(initialState.dataState);
  const [stateColumns, setStateColumns] = useState(GridColumns);

  const [resultExport, setResultExport] = useState(initialStateExport.result);

  // GridColumns[0].columnMenu= menuWithExcelCheck;
  // GridColumns[1].columnMenu= menuWithExcelCheck;
  // GridColumns[2].columnMenu= menuWithExcelCheck;
  // GridColumns[3].columnMenu= menuWithoutExcelCheck;
  // GridColumns[4].columnMenu= menuWithoutExcelCheck;
  // GridColumns[5].columnMenu= menuWithoutExcelCheck;
  // GridColumns[6].columnMenu= menuWithoutExcelCheck;

  const saveColumnStateDb = async () => {
    //Storing column settings in DB

    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 27;
    let ColumnSettings = localStorage.getItem("gridColumns");
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTSaveGridColumn/Index", postData, config)
      .then((response) => {
        console.log(response);
        const rowData = response.data;
        //populatePortfolioHoldingRptData(rowData.ocPortFolioHoldingsMainOutPut);
        //populatePortfolioHoldingRptDatatab2(rowData.ocPortFolioHoldingsTradeTypeOutPut);
        //setflagPortfolio(true);
        //setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    tempToken = token;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        saveColumnStateDb();
      })
      .catch((error) => {
        //

        console.log("my error is " + error);
      });
  };
  let _pdfExport;
  const saveColumnsState = (columns) => {
    console.log("save called!!");
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);

    localStorage.setItem("gridColumns", currentColumnsState);

    saveColumnStateDb();
  };

  useEffect(() => {
    saveColumnsState(stateColumns);
  }, [stateColumns]);

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
    });

    setResult(updatedState.result);
    setResultExport(updatedStateExcel.result);
    setDataState(updatedState.dataState);
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
    setResultExport({ ...result });
  };
  const showpreview = async () => {
    //setDataState({ ...dataState,  skip: 0,take: 1000 });
    //setResult(data);
    setShow(true);
  };

  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };

  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };

  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      //const columns = columns;
      const columns = [
        { field: "tckrSymbl" },
        { field: "cusip" },
        { field: "astShrtNm" },
        { field: "nbrOutstndngShrs" },
        { field: "currPrc" },
        { field: "marketCap" },

        // { field: "mktCapNm" },
      ];
      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - columns.length}
          ></td>
          {getCells(columns, cellProps)}
        </>
      );
    }
    // if (cellProps.rowType === 'groupFooter') {

    //   if (cellProps.field === 'totMarket') {
    //     return (
    //       <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
    //         {cellProps.dataItem.aggregates.totMarket.sum}
    //       </td>
    //     );
    //   }

    // }
    if (cellProps.rowType === "data") {
      if (cellProps.field === "nbrOutstndngShrs") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "currPrc") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "marketCap") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }
    return tdElement;
  };

  const FormatLongNumber = ({ value }) => {
    if (value == 0) {
      return 0;
    } else {
      // hundreds
      if (value <= 999) {
        return value;
      }
      // thousands
      else if (value >= 1000 && value <= 999999) {
        return (value / 1000).toFixed(2) + "K";
      }
      // millions
      else if (value >= 1000000 && value <= 999999999) {
        return (value / 1000000).toFixed(2) + "M";
      }
      // billions
      else if (value >= 1000000000 && value <= 999999999999) {
        return (value / 1000000000).toFixed(2) + "B";
      } else return value.toFixed(2);
    }
  };
  const exportPDF = () => {
    _pdfExport.save();
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  return (
    <div>
      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {
        <div className="row d-flex justify-content-between align-items-center card-header bg-light my-1">
          <div className="col text-end mx-2">
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={excelExport}
            >
              <FaEye /> Preview
            </button>
          </div>
        </div>
      }
      <div className="card-body">
        <div className="mx-1 my-1">
          <ExcelExport
            data={data}
            ref={_export}
            fileName={
              accntSelected.extrnlAcctId + "_AssetByMarketCapitalReport.xlsx"
            }
          >
            <ExcelExportColumnGroup
              title={
                "Account Number: " +
                JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
                "  Processing Date: " +
                localStorage.getItem("processingDate")
              }
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              <ExcelExportColumn field="tckrSymbl" title="Ticker" width={80} />
              <ExcelExportColumn field="cusip" title="Cusip" width={90} />
              <ExcelExportColumn
                field="astShrtNm"
                title="Asset Short Name"
                width={270}
              />
              <ExcelExportColumn
                field="nbrOutstndngShrs"
                width={110}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Volume"
              />
              <ExcelExportColumn
                field="currPrc"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Current Price($)"
              />
              <ExcelExportColumn
                field="marketCap"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Market Capital ($)"
              />
              {/* <ExcelExportColumn
                field="mktCapNm"
                width={150}
                title="Capital Type"
              /> */}
            </ExcelExportColumnGroup>
          </ExcelExport>
          <Grid
            style={{ height: "620px" }}
            data={result}
            {...dataState}
            onDataStateChange={dataStateChange}
            //onColumnReorder={onColumnReorder}
            ///onColumnResize={onColumnResize}
            expandField="expanded"
            onExpandChange={expandChange}
            cellRender={cellRender}
            sortable={true}
            //resizable={true}
            pageable={{
              buttonCount: 0,
              info: true,
              previousNext: false,
            }}
            //reorderable={true}

            pageSize={Number.MAX_VALUE}
            // groupable={{
            //   footer: "visible",
            //   enabled: false,
            // }}
          >
            {stateColumns.map(
              (column, idx) =>
                column.show && (
                  <Column
                    width={setWidth(column.minWidth)}
                    //  locked={column.locked}
                    key={idx}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    footerCell={column.footerCell}
                    headerClassName={column.headerClassName}
                    {...column}
                    //columnMenu={column.columnMenu}
                    //cell={NumberCell}
                    // columnMenu={(props) => (
                    //   <CustomColumnMenuNoGrp
                    //     {...props}
                    //     columns={stateColumns}
                    //     onColumnsSubmit={onColumnsSubmit}
                    //   />
                    // )}
                  />
                )
            )}
          </Grid>
        </div>
      </div>
      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
        fileName={
          accntSelected.extrnlAcctId + "_AssetByMarketCapitalReport.pdf"
        }
        margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
        pageTemplate={PageTemplate}
        forcePageBreak=".page-break"
        paperSize="A4"
        scale={0.3}
        allPages={true}
        //margin="1cm"
        landscape={true}
        repeatHeaders={true}
      >
        <Grid
          style={{ height: "600px" }}
          data={resultExport}
          {...dataState}
          onDataStateChange={dataStateChange}
          expandField="expanded"
          onExpandChange={expandChange}
          cellRender={cellRender}
          sortable={true}
          // resizable={true}
          pageable={{
            buttonCount: 0,
            info: true,
            previousNext: false,
          }}
          // total={result.length}
          pageSize={numberSymbols.MAX_VALUE}
          // onColumnReorder={onColumnReorder}
          //onColumnResize={onColumnResize}

          // groupable={{
          //   footer: "visible",
          //   enabled: false,
          // }}
        >
          {stateColumns.map(
            (column, idx) =>
              column.show && (
                <Column
                  width={setWidth(column.minWidth)}
                  locked={column.locked}
                  key={idx}
                  field={column.field}
                  title={column.title}
                  filter={column.filter}
                  footerCell={column.footerCell}
                  headerClassName={column.headerClassName}
                  //cell={NumberCell}
                  columnMenu={(props) => (
                    <CustomColumnMenuNoGrp
                      {...props}
                      columns={stateColumns}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  )}
                />
              )
          )}
        </Grid>
      </GridPDFExport>
    </div>
  );
};
export default AstByMrktCapitalGrid;
