import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Enumerable from "linq";
import { filterBy } from "@progress/kendo-data-query";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { useLocation, useNavigate } from "react-router-dom";
import { FaBookOpen, FaEye, FaPlus } from "react-icons/fa";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { ColumnMenu } from "./columnMenu";
import TextField from "@mui/material/TextField";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { process } from "@progress/kendo-data-query";
import Loading from "./loading";
import Header from "./header";
import CustomDashboardGrd from "./cstmDashboardGrd";

import { FcExpired } from "react-icons/fc";

import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";

const CustomDashboardRpt = () => {
  const [loading, setLoading] = React.useState(true);
  const [tmpltDetails, SetTmpltDetails] = React.useState([]);
  const [qualRpts, setRptList] = React.useState([]);
  const [flag, setFlag] = React.useState(0);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData")).slice()
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [session, setSession] = useState("");

  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [triggerRefreshCopyTempl, setTriggerRefreshCopyTempl] = useState(false);

  const navigate = useNavigate();
    const dispatch = useDispatch();
    const apicallval = useSelector(selectapicall);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        GetTmpltList();
      } catch (errMsg) {
        console.log(errMsg);
      }
    };
    fetchData();
  }, []);

      // Function to handle refresh triggered by child
      const handleChildAction = () => {
        console.log('Child action triggered refresh');
        setTriggerRefresh((prev) => !prev); // Toggle the state to force a re-render
        GetTmpltList(); // Optionally refresh the template list
    };

    const handleChildActionForCopyTempl = () => {
      console.log('Child action triggered refresh');
      setTriggerRefreshCopyTempl((prev) => !prev); // Toggle the state to force a re-render
      GetTmpltList(); // Optionally refresh the template list
  };
  const GetTmpltList = async () => {
    let token = tempToken;
    setFlag(0)
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const postData = {};

    await axios
      .post("TemplateProfile/GetAllTemplates", postData, config)
      .then((response) => {
        const rowData = response.data;
        console.log("rowData", rowData);
        const templateData = rowData.ocTmpltDetailsT;
        const lstRpts = rowData.ocQualRptLstT;
        SetTmpltDetails(rowData.ocTmpltDetailsT);
        setRptList(Enumerable.from(rowData.ocQualRptLstT).toArray());
        // setRptList(rowData.oCQualRptLstT);
        setLoading(false);
        setFlag(1);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        console.log("my error is " + error);
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetTmpltList();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

    if (apicallval === 1) {
      GetTmpltList();
      dispatch(callremove());
    }
  const filterData = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };
  const handleChange = (event) => {
    if (event.target.value === null) {
      SetselAcct(selAcct);
      //GetUpdatedAccountProfile(0);
      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem(
        "AcctSelected",
        JSON.stringify(JSON.parse(localStorage.getItem("acctData"))[0])
      );
    } else {
      SetselAcct(event.target.value);

      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));

      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };
  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <Loading />
        )}
      </>
    );
  }

  return (
    <div style={{ overflow: "hidden" }}>
      {/* <Header></Header> */}
      <div className="my-2">
        <div className="rounded"></div>

        {/* <div className="d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
          <div className="col-md-4 col-lg-4 col-sm-11 text-start">
            <span className="px-2">Account(s)</span>
            <ComboBox
              style={{
                width: "330px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
            />
          </div>
        </div> */}
        {/* <form>
                <div className='row d-flex justify-content-between align-items-center mx-2 px-1 py-2'>
        <div className='col-md-3 col-lg-3 col-sm-10'>
          <span className='py-1'>From</span>
        <DatePicker id="dpFrm"
              value={frmDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={isDisabled}
              onChange={(e)=>{
                setDate(e.value);
                
              }}
            
            />

        </div>

        <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>To</span>
        <DatePicker
              //defaultValue={toDate}
              value={toDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={false}
              onChange={(e)=>{
                setTDate(e.value);
                
              }}
            />

        </div>
        <div className='col-md-2 col-lg-2 col-sm-10'>
        <input type="submit"  className='btn btn-primary btn-sm' value="Submit"/> 
        </div>

      </div>
  </form>    */}
      </div>

      {flag === 1 ? (
        <CustomDashboardGrd tmplRecords={tmpltDetails} rptListQual={qualRpts} onDeleteTmplt={handleChildAction} onCopyTempl={handleChildActionForCopyTempl}/>
      ) : (
        <></>
      )}
    </div>
  );
};
export default CustomDashboardRpt;
