import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import axios from "axios";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";
import { useState, useEffect, useRef } from "react";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { orderBy } from "@progress/kendo-data-query";
import { process } from "@progress/kendo-data-query";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import BankLogoPage from "./bankLogoPage";
import {
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";
import Modal from "react-bootstrap/Modal";

import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  // if (groups) {
  //   groups.map((group) => (group.aggregates = aggregates));
  // }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const TopHoldingsGrid = ({ data, acct, flag, priceExistFlag }) => {
  debugger;

  let acctId = JSON.parse(localStorage.getItem("AccountID")).acctId;
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };

  let initialState = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    group: [{ field: "extrnlAcctId" }],
  });

  // call active report
  const [show, setShow] = React.useState(false);
  const viewerRef = React.useRef();
  async function loadReport() {
    // load report definition from the file

    const reportResponse = await fetch("TopHolding.rdlx-json");

    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    const report = await loadReport();

    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(data);

    const parameters = [
      {
        Name: "pCnsldtnNm",
        Value: [
          JSON.parse(localStorage.getItem("RelationID")) != null
            ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm === ""
              ? 0
              : JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm
            : 0,
        ],
      },
      {
        Name: "pExtrnlAcctId",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 0
            : JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId,
        ],
      },
      {
        Name: "pShow",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 3
            : 2,
        ],
      },
      //{Name: 'pE', Value: [ChkBoxState]}
    ];

    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  //   const [resultState, setResultState] = React.useState(
  //     process(data, initialDataState)
  // );

  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );

  const [dataState, setDataState] = useState(initialState.dataState);

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
    });

    setResult(updatedState.result);
    // setResultExport(updatedStateExcel.result);
    setDataState(updatedState.dataState);
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };

  //
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const menuWithExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const menuWithoutExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };
  const [sort, setSort] = React.useState([]);
  const initialDataState = {};
  let _pdfExport;

  const gridRef = useRef();
  const _export = React.useRef(null);
  const excelExport = () => {
    setShow(true);
    openReport();
    // if (_export.current !== null) {
    //     _export.current.save(data);
    // }
  };
  const exportPDF = () => {
    _pdfExport.save();
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };

  const PageTemplate = (props) => {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            top: "1px",

            width: "98%",
            borderBottom: "1px solid #bce8f1",
          }}
        >
          <div className="row d-flex mx-3">
            <div className="col text-start">
              <a className="px-2">
                <BankLogoPage />
              </a>
            </div>
            <div className="col text-end px-5 py-2">
              <h2 className="fw-bold text-fitek">Top Holding Report</h2>
            </div>
          </div>

          {/* <div className='fw-bold  text-center'>
                        Account Profile</div> */}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            //  borderTop: "1px solid #bce8f1"
          }}
        >
          Page {props.pageNum} of {props.totalPages}
        </div>
      </div>
    );
  };

  const NumberCell = (props) => {
    return (
      <td style={{ textAlign: "right" }}>
        {formatNumber(props.dataItem[props.field], "##,#.00")}
      </td>
    );
  };
  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    // newColumnsState[1].columnMenu= menuWithExcelCheck;
    // newColumnsState[0].columnMenu= menuWithExcelCheck;
    // newColumnsState[2].columnMenu= menuWithExcelCheck;
    // newColumnsState[3].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[4].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[5].columnMenu= menuWithoutExcelCheck;
    // newColumnsState[6].columnMenu= menuWithoutExcelCheck;
    return newColumnsState;
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  let defaultColumns = [
    {
      title: "Ticker",
      field: "ticker",
      minWidth: "auto",
      show: true,
      filter: "text",
    },
    {
      title: "Cusip",
      field: "cusip",
      minWidth: "auto",
      show: true,
      filter: "text",
    },
    {
      title: "Description",
      field: "astshrtNm",
      minWidth: "auto",
      show: true,
      filter: "text",
    },
    {
      title: "Shares",
      field: "shares",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      textAlign: "rightHeader",
      headerClassName: "rightHeader",
    },
    {
      title: "Total Cost($)",
      field: "txcstAmt",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
    },
    {
      title: "Market Value($)",
      field: "market",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
    },
    {
      title: "Percent",
      field: "marketPercent",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
    },
  ];

  let loadedColumns = localStorage.getItem("gridColumns");
  const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumns; //? JSON.parse(loadedColumns) : defaultColumns;
  // GridColumns[1].columnMenu= menuWithExcelCheck;
  // GridColumns[0].columnMenu= menuWithExcelCheck;
  // GridColumns[2].columnMenu= menuWithExcelCheck;
  // GridColumns[3].columnMenu= menuWithoutExcelCheck;
  // GridColumns[4].columnMenu= menuWithoutExcelCheck;
  // GridColumns[5].columnMenu= menuWithoutExcelCheck;
  // GridColumns[6].columnMenu= menuWithoutExcelCheck;

  const [stateColumns, setStateColumns] = useState(GridColumns);

  const saveColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 25; //let 1 for Portfoliholdings Grid
    let ColumnSettings = localStorage.getItem("gridColumns");
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTSaveGridColumn/Index", postData, config)
      .then((response) => {
        console.log(response);
        const rowData = response.data;
        //populatePortfolioHoldingRptData(rowData.ocPortFolioHoldingsMainOutPut);
        //populatePortfolioHoldingRptDatatab2(rowData.ocPortFolioHoldingsTradeTypeOutPut);
        //setflagPortfolio(true);
        //setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    tempToken = token;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        saveColumnStateDb();
      })
      .catch((error) => {
        //

        console.log("my error is " + error);
      });
  };
  const saveColumnsState = (columns) => {
    console.log("save called!!");
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);
    localStorage.setItem("gridColumns", currentColumnsState);

    saveColumnStateDb();
  };

  useEffect(() => {
    saveColumnsState(stateColumns);
  }, [stateColumns]);

  const onDataStateChange = React.useCallback((e) => {
    setDataState(e.dataState);
    // let gridData = JSON.parse(localStorage.getItem('gridData'));
    // const groups = e.dataState.group;

    // if (groups) {
    //   groups.map((group) => (group.aggregates = aggregates));
    // }
    // e.dataState.group = groups;
    setResult(process(data, e.dataState));
  }, []);

  const cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "data") {
      if (cellProps.field === "shares") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "txcstAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "market") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "marketPercent") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }
    return tdElement;
  };

  const grid = (
    <Grid
      style={{ height: "600px" }}
      data={result}
      // resizable={true}
      // reorderable={true}
      sortable={true}
      ref={gridRef.current}
      // onColumnReorder={onColumnReorder}
      // onColumnResize={onColumnResize}
      // pageSize={100}
      pageable={{
        buttonCount: 0,
        info: true,
        previousNext: false,
      }}
      pageSize={Number.MAX_VALUE}
      groupable={{
        footer: "visible",
        enabled: false,
      }}
      onDataStateChange={dataStateChange}
      expandField="expanded"
      onExpandChange={expandChange}
      {...dataState}
      cellRender={cellRender}
    >
      {priceExistFlag != "" ? (
        <GridNoRecords>
          <div style={{ color: "red", textAlign: "left" }}>
            {priceExistFlag}
            {console.log("No Price Exist for the date")}
          </div>
        </GridNoRecords>
      ) : (
        <>{console.log("Price Exist For the date")}</>
      )}
      {stateColumns.map(
        (column, idx) =>
          column.show && (
            <Column
              width={setWidth(column.minWidth)}
              key={idx}
              field={column.field}
              title={column.title}
              headerClassName={column.headerClassName}
              {...column}
              //columnMenu={column.columnMenu}
              // columnMenu={(props) => (
              //     <CustomColumnMenuNoGrp
              //         {...props}
              //         columns={stateColumns}
              //         onColumnsSubmit={onColumnsSubmit}
              //     />
              // )}
            />
          )
      )}
    </Grid>
  );
  const handleClose = () => setShow(false);
  return (
    <div>
      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div className="row mx-1 my-2">
        <div className="col col-md-12 col-sm-12 py-2">
          <div className="card-header row d-flex justify-content-between align-items-center my-2">
            {/* <div className="col">
                            <p className="tableheader h6">Top Holdings Report</p>
                        </div> */}
            {/* <div className='subheader col-lg text-end'>Due to rounding, percentage may not equal 100.</div>
                        <div className="col text-end">
                                <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
                                    <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem> */}
            {/* <DropdownItem onClick={exportPDF}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem> */}

            {/* </DropdownButton>


                            </div> */}
            <div className="col text-end">
              {/* <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
              <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem>
              

            </DropdownButton> */}

              <button
                className="btn btn-outline-primary btn-sm"
                onClick={excelExport}
              >
                Preview
              </button>
            </div>

            {/* <div className="col">
                            <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={excelExport}
                            >
                                Export to Excel
                            </button>
                            &nbsp;
                            <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={exportPDF}
                            >
                                Export to PDF
                            </button>

                        </div> */}
          </div>

          <ExcelExport
            data={data}
            ref={_export}
            fileName={
              JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
              "_TopHoldings.xlsx"
            }
          >
            <ExcelExportColumnGroup
              title={
                "Account Number: " +
                JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
                "  Processing Date: " +
                localStorage.getItem("processingDate")
              }
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              <ExcelExportColumn field="ticker" title="Ticker" width={70} />
              <ExcelExportColumn field="cusip" title="Cusip" width={80} />
              <ExcelExportColumn
                field="astshrtNm"
                title="Description"
                width={270}
              />

              <ExcelExportColumn
                field="shares"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Shares"
              />
              <ExcelExportColumn
                field="txcstAmt"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Tax Cost($)"
              />
              <ExcelExportColumn
                field="market"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Market Value($)"
              />
              <ExcelExportColumn
                field="marketPercent"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
                title="Percent"
              />
            </ExcelExportColumnGroup>
          </ExcelExport>
          {grid}

          <GridPDFExport
            ref={(element) => {
              _pdfExport = element;
            }}
            margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
            pageTemplate={PageTemplate}
            forcePageBreak=".page-break"
            paperSize="A4"
            scale={0.5}
            allPages={true}
            //margin="1cm"
            landscape={true}
            repeatHeaders={true}
          >
            {grid}
          </GridPDFExport>
        </div>
      </div>
    </div>
  );
};

export default TopHoldingsGrid;
