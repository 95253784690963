import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SelectControl from "./selectcontrol";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import { filterBy } from "@progress/kendo-data-query";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Loading from "./loading";
import Header from "./header";
import PortPerfSecurityGrid from "./portPerfSecurityGrid";
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCog, FaFilter } from "react-icons/fa";
import { Modal as AntdModal, Button as AntdBtn } from "antd";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";

import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useSelector, useDispatch } from "react-redux";

const PortPerfSecurityRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [portPerfSecurityData, setPortPerfSecurityData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData")).slice()
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [flagPortPerfSec, setPortPerfSec] = React.useState(false);
  const [isColumnSave, setIsColumnSave] = useState(false);

  const [isDisabled, setisDisabled] = React.useState(false);
  var date = new Date(localStorage.getItem("processingDate"));
  date.setMonth(date.getMonth() - 12);
  const [frmDate, setfrmDate] = React.useState(date);
  const [toDate, setToDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const [flag, setFlag] = React.useState(false);
  var minDate = new Date(localStorage.getItem("processingDate"));
  minDate.setMonth(minDate.getMonth() - 24);
  var maxDate = new Date(localStorage.getItem("processingDate"));
  //maxDate.setMonth(maxDate.getMonth());
  const [minFrmDt, setminFrmDt] = React.useState(minDate);
  const [maxFrmDt, setmaxFrmDt] = React.useState(maxDate);

  var minToDate = new Date(localStorage.getItem("processingDate"));
  minToDate.setMonth(minToDate.getMonth() - 12);
  var maxToDate = new Date(localStorage.getItem("processingDate"));
  const [session, setSession] = useState("");
  const [enableCombo, setEnableCombo] = useState(false);
  const navigate = useNavigate();

  const [closeOption, setCloseOption] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [showExcludeAsset, setShowExcludeAsset] = useState(true);
  const [fromPriceDt, setFromPriceDt] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const [fromPriceDtSelOpt, setFromPriceDtSelOpt] = useState(1);
  const [fromPriceFlagInd, setFromPriceFlagInd] = useState(1);

  const [toPriceDt, setToPriceDt] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const [toPriceDtSelOpt, setToPriceDtSelOpt] = useState(1);
  const [toPriceFlagInd, setToPriceFlagInd] = useState(1);

  const [priceExistFlag, setPriceExistFlag] = useState("");
  const [runInAggregate, setRunInAggregate] = useState(false);

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);

  const [initialState, setInitialState] = useState({
    showExcludeAsset: 1,
    runInAggregate: 0,
  });

  const handleAggregateMode = () => {
    if (runInAggregate == 0) {
      setRunInAggregate(true);
    }
    if (runInAggregate == 1) {
      setRunInAggregate(false);
    }
    console.log("Aggregate", runInAggregate);
  };

  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 28; //let 1 for Portfoliholdings Grid

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        console.log("save in db");
        console.log(response);
        const rowData = response.data;

        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);

        return error;
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        GetPortPerfSecurityData();
        getColumnStateDb();
      } catch (error) {
        console.error(error.message);
      }
    };
    // fetchData();
  }, []);

  function setDate(val) {
    //setfrmDate(val);
    if (val == null) {
      setfrmDate(frmDate);
    } else {
      setfrmDate(val);
    }
  }
  function setTDate(val) {
    if (val == null) {
      setToDate(toDate);
    } else {
      setToDate(val);
    }
  }
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetPortPerfSecurityData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const handleCancel = () => {
    setShowExcludeAsset(initialState.showExcludeAsset);
    setRunInAggregate(initialState.runInAggregate);

    setModalVisible(false);
    setCloseOption(false);

    !closeOption && navigate("/dashboard");
  };

  const handleSettings = () => {
    setInitialState({
      showExcludeAsset,
      runInAggregate,
    });

    setModalVisible(true);
    setCloseOption(true);
  };
  const formSubmit = (event) => {
    event.preventDefault();
    setModalVisible(false);
    setCloseOption(false);
    GetPortPerfSecurityData();
  };

  const handleExcludeAsset = (e) => {
    setShowExcludeAsset(e.target.checked ? true : false);
  };

  function setPrcDt(val) {
    let newdate = new Date(val);
    if (newdate <= new Date(localStorage.getItem("processingDate")))
      setFromPriceDt(val);
    else {
      setFromPriceDt(new Date(localStorage.getItem("processingDate")));
    }
    setFromPriceDt(val);
  }

  const fromPriceDtHandleRadioChange = (e) => {
    setFromPriceDtSelOpt(parseInt(e.target.value));
    setFromPriceFlagInd(parseInt(e.target.value));
    console.log("e.target.value", e.target.value);
    console.log("e", e);
    if (e.target.value == 1 || e.target.value == 2) {
      setFromPriceDt(date); // to be checked
    }
    // console.log("fromPriceDtSelOpt", fromPriceDtSelOpt);
    // console.log("Selected Price Date", fromPriceDt);
  };

  const toPriceDtHandleRadioChange = (e) => {
    setToPriceDtSelOpt(parseInt(e.target.value));
    setToPriceFlagInd(parseInt(e.target.value));
    console.log("e.target.value", e.target.value);
    console.log("e", e);
    if (e.target.value == 1 || e.target.value == 2) {
      setToPriceDt(date); // to be checked
    }
    // console.log("fromPriceDtSelOpt", fromPriceDtSelOpt);
    // console.log("Selected Price Date", fromPriceDt);
  };

  const GetPortPerfSecurityData = async () => {
    setLoading(true);
    setEnableCombo(true);
    let token = tempToken;
    //let token = JSON.parse(localStorage.getItem('token'));
    let AsOfId = JSON.parse(localStorage.getItem("userId"));
    let AcctId =
      JSON.parse(localStorage.getItem("AccountID")) != null
        ? JSON.parse(localStorage.getItem("AccountID")).acctId
        : 0;
    // let PageId = 1;
    let consolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;
    // let StartDt = formatDate(frmDate, "MM/dd/yyyy");
    // let EndDt = formatDate(toDate, "MM/dd/yyyy");

    let mPriceDt = formatDate(fromPriceDt, "MM/dd/yyyy"); //start price date
    let ePriceDt = formatDate(toPriceDt, "MM/dd/yyyy"); //end price date
    let mPriceFlag = fromPriceFlagInd; // start price date flag
    let ePriceFlag = toPriceFlagInd; // end price date flag
    let showExcldAst = showExcludeAsset;
    let asOfStartDt = formatDate(frmDate, "MM/dd/yyyy");
    let asOfEndDt = formatDate(toDate, "MM/dd/yyyy");

    const postData = {
      AsOfId,
      AcctId,
      consolidationId,
      mPriceDt,
      ePriceDt,
      mPriceFlag,
      ePriceFlag,
      showExcldAst,
      asOfStartDt,
      asOfEndDt,
      runInAggregate,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTPortPerfBySecurity", postData, config)
      .then((response) => {
        //    const rowData = Enumerable.from(response.data.ocPortFolioHoldingsMainOutPut).where(w => w.totMarket !== 0)
        //     .toArray();

        const rowData = response.data;

        let consolidationName =
          JSON.parse(localStorage.getItem("RelationID"))?.cnsldtnNm || 0;

        if (rowData.t1 != null) {
          if (AcctId == 0 && runInAggregate == true) {
            for (let i = 0; i < rowData.t1?.length; i++)
              rowData.t1[i].extrnlAcctId = consolidationName;
          }
        }

        setPortPerfSecurityData(rowData?.t1 || []);
        localStorage.setItem("filterData", JSON.stringify(rowData.t1));
        setPriceExistFlag(response?.data?.priceValidation);
        getColumnStateDb();
        setPortPerfSec(true);
        setLoading(false);
        setEnableCombo(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
      });
  };

  if (apicallval === 1) {
    GetPortPerfSecurityData();
    dispatch(callremove());
  }

  const filterData = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };
  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };
  const handleChange = (event) => {
    if (event.target.value === null) {
      SetselAcct(JSON.parse(localStorage.getItem("acctData"))[0]);

      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem(
        "AcctSelected",
        JSON.stringify(JSON.parse(localStorage.getItem("acctData"))[0])
      );
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      console.log(event.target.value);
      GetPortPerfSecurityData();
    }
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header></Header> */}
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    <div>
      {/* <Header></Header> */}
      {/* <form onSubmit={formSubmit}  > */}
      <div className="row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
        <div className="col reportheading fs-6">
          Portfolio Performance By Security Report
        </div>

        {/* Filter Button */}
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>

        {/* <div className='col-md-3 col-lg-3 col-sm-10'>
          <span className='py-1'>From</span>
        <DatePicker id="dpFrm"
              defaultValue={frmDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              min={minFrmDt}
              formatPlaceholder={{
                year: "yyyy",
                month: "mm",
                day: "dd",
              }}
              max={maxFrmDt}
              disabled={isDisabled}
              onChange={(e)=>{
                setDate(e.value);
                
              }}
            
            />

        </div>

        <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>To</span>
        <DatePicker
              //disabled={true}
              defaultValue={toDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              formatPlaceholder={{
                year: "yyyy",
                month: "mm",
                day: "dd",
              }}
              min={minToDate}
              max={maxToDate}
              onChange={(e)=>{
                setTDate(e.value);
                
              }}
            />

        </div> */}

        {/* <div className='col-md-2 col-lg-2 col-sm-10'> */}

        {/* <input type="submit" className='btn btn-primary btn-sm'value="Submit"/>  */}

        {/* </div> */}
        {/*Search end*/}
      </div>

      <AntdModal
        open={modalVisible}
        title="Customize Portfolio Performance Security Report"
        closable={false}
        footer={[]}
        width={900}
      >
        <form onSubmit={formSubmit} style={{ padding: "20px" }}>
          {/* AsOf Date */}
          <h6>As Of Date</h6>
          {/* <div className="col-md-3 col-lg-3 col-sm-10"> */}
          <span className="py-1">From </span>&nbsp;&nbsp;
          <DatePicker
            id="dpFrm"
            defaultValue={frmDate}
            format="MM/dd/yyyy"
            calendar={CustomCalendar}
            min={minFrmDt}
            formatPlaceholder={{
              year: "yyyy",
              month: "mm",
              day: "dd",
            }}
            width={300}
            max={maxFrmDt}
            disabled={isDisabled}
            onChange={(e) => {
              setDate(e.value);
            }}
            validationMessage={
              frmDate == null
                ? "Enter Valid Date"
                : frmDate < new Date("1/1/1000")
                ? "Enter date in correct format (mm/dd/yyyy)"
                : frmDate > maxFrmDt
                ? "From date cannot exceed one year from Processing date."
                : frmDate > toDate
                ? "From date cannot exceed to date."
                : frmDate < minFrmDt
                ? "From date cannot be less than Processing date."
                : "Validation error"
            }
          />
          {/* </div> */}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {/* <div className="col-md-3 col-lg-3 col-sm-10"> */}
          <span className="py-1">To</span>&nbsp;&nbsp;
          <DatePicker
            //disabled={true}
            defaultValue={toDate}
            format="MM/dd/yyyy"
            calendar={CustomCalendar}
            formatPlaceholder={{
              year: "yyyy",
              month: "mm",
              day: "dd",
            }}
            width={300}
            min={minToDate}
            max={maxToDate}
            onChange={(e) => {
              setTDate(e.value);
            }}
            validationMessage={
              toDate == null
                ? "Enter Valid Date"
                : toDate < new Date("1/1/1000")
                ? "Enter date in correct format (mm/dd/yyyy)"
                : toDate > maxToDate
                ? "To date cannot exceed one year from Processing date."
                : toDate < frmDate
                ? "To date cannot be less than from date"
                : toDate < minToDate
                ? "To date cannot be less than Processing date."
                : "Validation error"
            }
          />
          {/* </div> */}
          <hr></hr>
          {/* Pricing Options */}
          <h6>Select Pricing Option</h6>
          <span>From Date</span>
          <div style={{ marginBottom: "20px" }}>
            {[1, 2, 3].map((option) => (
              <label
                key={option}
                style={{ display: "block", marginBottom: "10px" }}
              >
                <input
                  type="radio"
                  value={option}
                  checked={fromPriceDtSelOpt === option}
                  onChange={fromPriceDtHandleRadioChange}
                />
                {option === 1
                  ? " Use Current Price"
                  : option === 2
                  ? " Use End of Month Price"
                  : " Use Non EOM Price"}
              </label>
            ))}
          </div>
          {/* Conditional Price Date Field */}
          {fromPriceDtSelOpt === 3 && (
            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="dpPriceDt"
                className="form-label"
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                From Price Date
              </label>
              <DatePicker
                id="dpPriceDt"
                value={fromPriceDt}
                calendar={CustomCalendar}
                min={new Date("1/1/1000")}
                max={maxFrmDt}
                width={300}
                onChange={(e) => setFromPriceDt(e.value)}
                style={{ width: "100%" }}
                validationMessage={
                  fromPriceDt == null
                    ? "Enter Valid Date"
                    : fromPriceDt < new Date("1/1/1000")
                    ? "Enter date in correct format (mm/dd/yyyy)"
                    : fromPriceDt > maxFrmDt
                    ? "From date cannot exceed one year from Processing date."
                    : fromPriceDt > toDate
                    ? "From date cannot exceed to date."
                    : fromPriceDt < new Date("1/1/1000")
                    ? "From date cannot be less than Processing date."
                    : "Validation error"
                }
              />
            </div>
          )}
          <hr></hr>
          <span>To Date</span>
          <div style={{ marginBottom: "20px" }}>
            {[1, 2, 3].map((option) => (
              <label
                key={option}
                style={{ display: "block", marginBottom: "10px" }}
              >
                <input
                  type="radio"
                  value={option}
                  checked={toPriceDtSelOpt === option}
                  onChange={toPriceDtHandleRadioChange}
                />
                {option === 1
                  ? " Use Current Price"
                  : option === 2
                  ? " Use End of Month Price"
                  : " Use Non EOM Price"}
              </label>
            ))}
          </div>
          {/* Conditional Price Date Field */}
          {toPriceDtSelOpt === 3 && (
            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="dpPriceDt"
                className="form-label"
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                To Price Date
              </label>
              <DatePicker
                id="dpPriceDt"
                value={toPriceDt}
                width={300}
                calendar={CustomCalendar}
                min={new Date("1/1/1000")}
                max={maxFrmDt}
                onChange={(e) => setToPriceDt(e.value)}
                style={{ width: "100%" }}
                validationMessage={
                  toDate == null
                    ? "Enter Valid Date"
                    : toDate < new Date("1/1/1000")
                    ? "Enter date in correct format (mm/dd/yyyy)"
                    : toDate > fromPriceDt
                    ? "To date cannot exceed one year from Processing date."
                    : toDate < fromPriceDt
                    ? "To date cannot be less than from date"
                    : toDate < new Date("1/1/1000")
                    ? "To date cannot be less than Processing date."
                    : "Validation error"
                }
              />
            </div>
          )}
          <hr></hr>
          {/* Miscellaneous Section */}
          <h6>Miscellaneous Section</h6>
          <div style={{ marginBottom: "20px" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showExcludeAsset}
                  onChange={handleExcludeAsset}
                  name="excludeAsset"
                />
              }
              label=" Show Excluded Assets"
              style={{ marginBottom: "10px", display: "block" }}
            />
          </div>
          {/* Actions */}
          {/* <hr style={{ margin: "20px 0" }} /> */}
          {AcctId == 0 && (
            <FormControlLabel
              control={
                <Switch
                  checked={runInAggregate}
                  onClick={handleAggregateMode}
                  name="chkCtr"
                />
              }
              label="Run In Aggregate."
              style={{ marginBottom: "10px", display: "block" }}
            />
          )}
          <hr style={{ margin: "20px 0" }} />
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <div
              className="btn btn-outline-secondary mx-2"
              onClick={handleCancel}
            >
              Cancel
            </div>
            <button className="btn btn-primary mx-2" type="submit">
              Submit
            </button>
          </div>
        </form>
      </AntdModal>

      {/* <div className='row d-flex justify-content-between align-items-center mx-2 px-1 py-2'>
          <div className='reportheading fs-6 col-md-3 col-lg-3 col-sm-10'>Portfolio Performance By Security Report</div>
        <div className='col-md-3 col-lg-3 col-sm-10'>
          <span className='py-1'>From</span>
        <DatePicker id="dpFrm"
              defaultValue={frmDate}
              format="MM/dd/yyyy"
              min={minFrmDt}
              max={maxFrmDt}
              disabled={isDisabled}
              onChange={(e)=>{
                setDate(e.value);
                
              }}
            
            />

        </div>

        <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>To</span>
        <DatePicker
              //disabled={true}
              defaultValue={toDate}
              format="MM/dd/yyyy"
              min={minToDate}
              max={maxToDate}
              onChange={(e)=>{
                setTDate(e.value);
                
              }}
            />

        </div>

        <div className='col-md-2 col-lg-2 col-sm-10'>

        <button className='btn btn-primary btn-sm' onClick={GetPortPerfSecurityData}>Submit</button>

        </div>

      </div> */}
      {/* </form> */}
      <div>
        {flagPortPerfSec ? (
          <PortPerfSecurityGrid
            data={portPerfSecurityData}
            flag={isColumnSave}
            accntSelected={selAcct}
            priceExistFlag={priceExistFlag}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default PortPerfSecurityRpt;
