import React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect, useRef } from "react";

import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import { process } from "@progress/kendo-data-query";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { Button as ButtonKendo } from "@progress/kendo-react-buttons";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import axios from "axios";
import { ResponsiveContainer } from "recharts";
import { ExcelExportColumnGroup } from "@progress/kendo-react-excel-export";
import { Viewer } from "@grapecity/activereports-react";
import Modal from "react-bootstrap/Modal";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";

import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
//import Moment from 'react-moment';
import Enumerable from "linq";
import "hammerjs";
import { Button } from "react-bootstrap";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";
import {
  FaCartPlus,
  FaFileExcel,
  FaFilePdf,
  FaMinus,
  FaMinusCircle,
  FaPlus,
  FaPlusCircle,
} from "react-icons/fa";
//import BankLogoPage from './bankLogoPage';
const CustomGroupHeader = (props) => {
  return `${props.value}`;
};
const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "1px",

          width: "98%",
          borderBottom: "1px solid #bce8f1",
        }}
      >
        <div className="row d-flex mx-3">
          {/* <div className='col text-start'>
            <a className='px-2'  ><BankLogoPage /></a>

          </div> */}
          <div className="col text-end px-5 py-2">
            <h2 className="fw-bold text-fitek">Time Weighted Return Report </h2>
          </div>
        </div>

        {/* <div className='fw-bold  text-center'>
                  Account Profile</div> */}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          //  borderTop: "1px solid #bce8f1"
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
};

const chartBootstrapV4Colors = [
  "#006fba",
  "#0C483E",
  "#00AB8D",
  "#65697D",
  "#D8DAE5",
  "#9E8AA3",
  "#D38BB2",
  "#7EF58B",
  "#E78B8B",
];

const initialGroup = [
  {
    field: "extrnlAcctId",
  },
];

const aggregates = [];

const processWithGroups = (data, dataState) => {
  //
  const groups = dataState.group;
  const filesDocArray = [];

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const BenchmarkSelPeriodPerGrid = ({ data }) => {
  const [colmenu, setColmenu] = useState(true);

  var lable1 = "";
  var lable2 = "";
  const categories = [
    "MTD",
    "QTD",
    "YTD",
    "1 Year",
    "3 Year",
    "5 Year",
    "10 Year",
    "Inception",
  ];

  var arr1 = [];
  var arr2 = [];

  const _export = React.useRef(null);

  const dataExport = process(data, {
    // group: initialGroup,
  }).data;
  const [locked, setLocked] = React.useState(false);
  const [expandedState, setExpandedState] = React.useState(true);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const columnLocked = () => {
    setLocked(!locked);
  };

  const RightNameHeader = (props) => {
    return (
      <a
        className="k-link"
        style={{
          float: "right",
        }}
        onClick={props.onClick}
      >
        {/* <span className="k-icon k-i-cart" /> */}
        <span
          style={
            {
              // color: "#53d2fa",
            }
          }
        >
          {props.title}
        </span>
        {props.children}
      </a>
    );
  };

  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    for (var i = 0; i < newColumnsState.length; i++) {
      for (var j = 0; j < columns.length; j++) {
        if (newColumnsState[i].field === columns[j].field)
          newColumnsState[i].headerClassName = columns[j].headerClassName;
      }
    }
    // newColumnsState[0].show = localStorage.getItem("SelAcctId")>0?false:true  ;
    // newColumnsState[0].columnMenu = menuWithExcelCheck;
    // newColumnsState[1].columnMenu = menuWithExcelCheck;
    // newColumnsState[2].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[3].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[4].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[5].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[6].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[7].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[8].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[9].columnMenu = menuWithoutExcelCheck;

    return newColumnsState;
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };

  const cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "data") {
      if (cellProps.field === "monthToDate") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? "0.00"
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "quarterToDate") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? "0.00"
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "halfYearToDate") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? "0.00"
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "yearToDate") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? "0.00"
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "oneYear") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? "0.00"
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "threeYear") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? "0.00"
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "fiveYear") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? "0.00"
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "tenYear") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? "0.00"
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }

    return tdElement;
  };

  const defaultColumns = [
    {
      title: "Market Indicators",
      field: "astShrtNm",
      minWidth: "220px",
      show: true,
      filter: "text",
      locked: false,
      //headerClassName: 'rightHeader'
    },

    // {
    //   title: "Market Value",
    //   field: "market",
    //   minWidth: "130px",
    //   show: true,
    //   filter: "numeric",
    //   locked: false,
    //   locked: true,
    //   headerClassName: "rightHeader",
    // },
    {
      title: "1 Month",
      field: "monthToDate",

      show: true,
      filter: "numeric",
      minWidth: "auto",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "3 Months",
      field: "quarterToDate",

      show: true,
      filter: "numeric",
      minWidth: "auto",
      locked: false,

      headerClassName: "rightHeader",
    },
    {
      title: "6 Months",
      field: "halfYearToDate",

      show: true,
      filter: "numeric",
      minWidth: "auto",
      locked: false,

      headerClassName: "rightHeader",
    },
    {
      title: "Year to Date",
      field: "yearToDate",

      show: true,
      filter: "numeric",
      minWidth: "auto",
      locked: false,

      headerClassName: "rightHeader",
    },
    // {
    //   title: '1 Mo',
    //   field: 'retOneMonth',
    //   minWidth: 'auto',
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,

    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: '3 Mo',
    //   field: 'retThreeMonth',
    //   minWidth: 'auto',
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,

    //   headerClassName: 'rightHeader'
    // },
    {
      title: "1 Yr",
      field: "oneYear",

      show: true,
      filter: "numeric",
      locked: false,
      minWidth: "auto",

      headerClassName: "rightHeader",
    },
    {
      title: "3 Yrs",
      field: "threeYear",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,

      headerClassName: "rightHeader",
    },
    {
      title: "5 Yrs",
      field: "fiveYear",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,

      headerClassName: "rightHeader",
    },
    {
      title: "10 Yrs",
      field: "tenYear",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,

      headerClassName: "rightHeader",
    },
    // {
    //   title: "",
    //   field: "retInception",

    //   show: true,
    //   filter: "numeric",
    //   locked: false,
    //   minWidth: "130px",
    //   headerClassName: "rightHeader",
    // },
  ];

  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    // sort: [{ field: 'account', dir: 'asc' }],
    group: [{ field: "extrnlAcctId" }],
  });

  let initialStateExcel = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    // sort: [{ field: 'account', dir: 'asc' }],
    // group: [{ field: 'groupHeader' }],
  });

  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );

  const [resultExcel, setResultExcel] = React.useState(
    processWithGroups(data, initialStateExcel.dataState)
  );

  const [dataState, setDataState] = React.useState(initialState.dataState);
  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
    });

    setResult(updatedState.result);
    // setResultExport(updatedStateExcel.result);
    setDataState(updatedState.dataState);
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };

  let loadedColumns = localStorage.getItem("gridColumns");

  //const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumns;
  const GridColumns = defaultColumns;
  // GridColumns[0].show = localStorage.getItem("SelAcctId")>0?false:true  ;
  // GridColumns[0].columnMenu = menuWithExcelCheck;
  // GridColumns[1].columnMenu = menuWithExcelCheck;
  // GridColumns[2].columnMenu = menuWithoutExcelCheck;
  // GridColumns[3].columnMenu = menuWithoutExcelCheck;
  // GridColumns[4].columnMenu = menuWithoutExcelCheck;
  // GridColumns[5].columnMenu = menuWithoutExcelCheck;
  // GridColumns[6].columnMenu = menuWithoutExcelCheck;
  // GridColumns[7].columnMenu = menuWithoutExcelCheck;
  // GridColumns[8].columnMenu = menuWithoutExcelCheck;
  // GridColumns[9].columnMenu = menuWithoutExcelCheck;

  //   GridColumns[9].title = "Inception\n(" + InceptnDt + ")";
  const [stateColumns, setStateColumns] = React.useState(GridColumns);

  let pageSize = 20;
  const [page, setPage] = React.useState({
    skip: 0,
    take: pageSize,
  });

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    tempToken = token;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        // saveColumnStateDb();
      })
      .catch((error) => {
        //

        console.log("my error is " + error);
      });
  };

  //   const [allocData, setAllocData] = React.useState(alloc);

  // const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
  // const labelContent = (e) => `${e.value.toFixed(2)}`;
  let allPDFExport;
  let gridPDFExport = [];
  let gridPDFExportcopy = [];

  const viewerRef = React.useRef();
  async function loadReport() {
    // load report definition from the file

    const reportResponse = await fetch(
      "BenchmarkSelectedPeriodPerformence.rdlx-json"
    );

    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    const report = await loadReport();

    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(data);

    const parameters = [
      {
        Name: "pCnsldtnNm",
        Value: [
          JSON.parse(localStorage.getItem("RelationID")) != null
            ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm === ""
              ? 0
              : JSON.parse(localStorage.getItem("RelationID")).cnsldtnNm
            : 0,
        ],
      },
      {
        Name: "pExtrnlAcctId",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 0
            : JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId,
        ],
      },
      {
        Name: "pShow",
        Value: [
          JSON.parse(localStorage.getItem("AccountID")).extrnlAcctId === ""
            ? 3
            : 2,
        ],
      },
    ];

    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  const showpreview = async (event) => {
    event.preventDefault();
    setShow(true);
    openReport();
  };

  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };

  const exportPDFWithMethod = () => {
    let gridElement = document.querySelector(".k-grid");
    drawDOM(gridElement, {
      paperSize: "A4",
    })
      .then((group) => {
        return exportPDF(group);
      })
      .then((dataUri) => {
        // console.log(dataUri.split(";base64,")[1]);
        gridPDFExport[0] = dataUri;
      });
  };
  var excelTitle =
    localStorage.getItem("SelAcctId") == 0
      ? "Consolidation:" + localStorage.getItem("pConsName")
      : "Account Number:" + localStorage.getItem("SelAcctNm");
  const grid = (
    <Grid
      style={{ height: "650px" }}
      // style={{ height: 'auto' , width: "100%",}}
      //style={{  width: "auto", position: 'relative',  border: 'none' }}
      data={result}
      {...dataState}
      onDataStateChange={dataStateChange}
      onColumnReorder={onColumnReorder}
      onColumnResize={onColumnResize}
      expandField="expanded"
      onExpandChange={expandChange}
      sortable={true}
      // resizable={true}
      // reorderable={true}
      pageable={{
        buttonCount: 0,

        info: true,

        previousNext: false,
      }}
      groupable={{
        footer: "none",
        enabled: false,
      }}
      //group={[{field:'groupHeader'}]}
      // skip={page.skip}
      // pageable={{
      //   pageSizes: true,
      // }}
      // pageSize={page.take}
      // total={data.length}
      // groupable={{
      //   footer: 'always',
      //   enabled: false
      // }}
      cellRender={cellRender}

      //onRowClick={ChangeLineChart}
    >
      {/* <GridToolbar> */}
      {/* <div className="col text-start">
          {
            expandedState?
            <> <Button sx={{ textTransform: 'none' }} variant="outlined" size='small' onClick={handleColapse}  ><FaMinus className='mx-2'></FaMinus></Button></>
            :
            <> <Button sx={{ textTransform: 'none' }} variant="outlined" size='small' onClick={handleExpand} ><FaPlus className='mx-2'></FaPlus></Button></>
          }
          </div> */}
      {/* <ButtonKendo className="buttons-container-button" icon="excel" onClick={excelExport}>
          Export
        </ButtonKendo> */}

      {/* </GridToolbar> */}
      {stateColumns.map(
        (column, idx) =>
          column.show && (
            <Column
              width={setWidth(column.minWidth)}
              // locked={column.locked}
              key={idx}
              field={column.field}
              title={column.title}
              filter={column.filter}
              // footerCell={column.footerCell}
              // cell={NumberCell}
              // headerCell={column.headerCell}
              headerClassName={column.headerClassName}
              {...column}

              // columnMenu={column.filter==="text"?(props) => (

              //   <>

              //     {
              //       column.filter === "text" ?

              //         <CustomColumnMenuNoGrpChkBox
              //           {...props}
              //           columns={stateColumns}
              //           data={data}
              //           onColumnsSubmit={onColumnsSubmit}
              //         />

              //         :
              //         <CustomColumnMenuNoGrp
              //           {...props}
              //           columns={stateColumns}
              //           onColumnsSubmit={onColumnsSubmit}
              //         />
              //     }
              //   </>
              // ):false}

              // columnMenu={(props) => (
              //   <CustomColumnMenuNoGrp
              //     {...props}
              //     columns={stateColumns}
              //     onColumnsSubmit={onColumnsSubmit}

              //   />
              // )}
            />
          )
      )}
    </Grid>
  );

  const chart = (
    <ResponsiveContainer>
      <Chart seriesColors={chartBootstrapV4Colors} style={{ height: "370px" }}>
        <ChartLegend position="bottom" />
        <ChartSeriesDefaults
          type="column"
          // labels={{
          //   format: "c",
          // }}
        />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories} />
        </ChartCategoryAxis>
        <ChartTooltip />
        <ChartSeries>
          <ChartSeriesItem data={arr1} name={lable1} />
          <ChartSeriesItem data={arr2} name={lable2} />
        </ChartSeries>
      </Chart>
    </ResponsiveContainer>
  );

  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const [btnStatus, setBtnStatus] = React.useState(true);
  return (
    <div>
      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <div className="card-body">
        {data.length > 0 ? (
          <div className="col text-end">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={showpreview}
            >
              Preview
            </button>
          </div>
        ) : (
          <div className="col text-end">
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={showpreview}
              disabled={true}
            >
              Preview
            </button>
          </div>
        )}
        <div className="mx-1 px-1 my-1 py-1">
          <ExcelExport
            data={dataExport}
            group={initialGroup}
            ref={_export}
            fileName={excelTitle + "_Performance.xlsx"}
          >
            <ExcelExportColumnGroup
              title={excelTitle}
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              <ExcelExportColumn
                field="groupHeader"
                hidden={true}
                groupHeader={CustomGroupHeader}
              />
              {/* <ExcelExportColumn field="detailedLine" title="Details" width={140} />
            <ExcelExportColumn field="monthToDate" title="Month to date (%)" width={150} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="quarterToDate" title="Quarter to date (%)" width={200} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }}/>
            <ExcelExportColumn field="yearToDate" title="Year to date (%)" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="oneYear" title="Last 1 year (%)" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="threeYear" title="Last 3 year (%)" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="fiveYear" title="Last 5 year (%)" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="tenYear" title="Last 10 year (%)" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="inception" title="Since inception (%)" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} /> */}
            </ExcelExportColumnGroup>
          </ExcelExport>
          {grid}
        </div>
        {/* {grid} */}
      </div>

      {/* <br />
      <div className='row py-2'>
        <div className="col">
          <p className="tableheader h6">Total Account Vs Benchmark</p>
        </div>
        <div className='card'>
          <div className='card-body'>

            {chart}

          </div>
        </div>

      </div> */}
    </div>
  );
};

export default BenchmarkSelPeriodPerGrid;
