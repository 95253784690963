import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { formatNumber, formatDate, parseNumber } from "@telerik/kendo-intl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { filterBy } from "@progress/kendo-data-query";
import { lastDayOfMonth, isEqual } from "@progress/kendo-date-math";

import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Loading from "./loading";
import Header from "./header";
import AccountProfileDetails from "./accountProfileDetails";
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import AnnualPortfolioValueDetails from "./annualPortfolioValueDetails";
import { Card, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";

import { Modal as AntModal, Button as aButton } from "antd";
import { FaFilter } from "react-icons/fa";

import { callremove, selectapicall } from "../features/apicall/apicallSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addDays,
  addWeeks,
  addMonths,
  addYears,
  addDecades,
  addCenturies,
} from "@progress/kendo-date-math";

const AnnualPortfolioValueRpt = () => {
  var date = new Date(localStorage.getItem("processingDate"));

  const [assetAllocModelRptData, setAssetAllocModelRptData] = useState([]);
  const [chartColor, setChartColor] = useState([]);

  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [selectedAcct, setSelectedAcct] = useState(0);
  const [loading, setLoading] = useState(false);
  const [annualPortfolioData, setAnnualPortfolioData] = useState([]);
  const [modelId, setModelId] = useState(-1);
  const [flag, setFlag] = useState(false);
  const [session, setSession] = useState("");
  const [enableCombo, setEnableCombo] = useState(false);

  const [asOfDt, setasOfDt] = React.useState(date);

  const [combinedData, setCombinedData] = useState([]);
  const [chkNetofFees, setChkShowNetofFees] = useState(true);

  const [chkBOMInceptionDates, setChkBOMInceptionDates] = useState(true);
  const [modalVisible, setModalVisible] = useState(true);
  const [closeOption, setCloseOption] = useState(false);

  const [chkRptDispOptn, setChkRptDispOptn] = useState(true);
  const [runInAggregate, setRunInAggregate] = useState(false);

  const navigate = useNavigate();

  var maxFrmDt = date;

  var tempToken = JSON.parse(localStorage.getItem("token"));

  const dispatch = useDispatch();
  const apicallval = useSelector(selectapicall);

  const [initialState, setInitialState] = useState({
    chkNetofFees: true,
    chkBOMInceptionDates: true,
    runInAggregate: false,
  });

  let AcctId =
    JSON.parse(localStorage.getItem("AccountID")) != null
      ? JSON.parse(localStorage.getItem("AccountID")).acctId
      : 0;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        getAnnualPortfolioInfo();
      } catch (error) {
        console.error(error.message);
      }
    };
    // fetchData();
  }, []);

  const handlerunInAggregate = () => {
    setRunInAggregate(!runInAggregate);
  };

  const getAnnualPortfolioInfo = async () => {
    setLoading(true);
    setEnableCombo(true);

    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    // let AcctId = JSON.parse(localStorage.getItem('AcctSelected')).acctId;
    //let AcctId = 0;
    setSelectedAcct(JSON.parse(localStorage.getItem("AcctSelected")).acctId);
    // let ConsolidationId = 8;
    let AsOfId = JSON.parse(localStorage.getItem("userId"));
    let lsSelectedAcct = JSON.parse(localStorage.getItem("AccountID"));
    let AcctId = lsSelectedAcct != null ? lsSelectedAcct.acctId : 0;
    var lastDay = lastDayOfMonth(asOfDt);
    var processingDate = new Date(localStorage.getItem("processingDate"));
    if (lastDay > processingDate) {
      lastDay = addMonths(lastDay, -1);
    }
    var equaldates = isEqual(asOfDt, lastDay);
    if (!equaldates) {
      var AsOfDt = formatDate(lastDay, "MM/dd/yyyy");
      setasOfDt(lastDay);
    } else {
      var AsOfDt = formatDate(asOfDt, "MM/dd/yyyy");
    }

    let ConsolidationId =
      JSON.parse(localStorage.getItem("RelationID")) != null
        ? JSON.parse(localStorage.getItem("RelationID")).cnsldtnId
        : 0;

    let netOfFeesInd = chkNetofFees;
    let ovrdIncptn = chkBOMInceptionDates;
    /////////////////////////////////////////////////////
    // let token = tempToken;
    // let UserId = JSON.parse(localStorage.getItem("userId"));

    // let lsSelectedAcct = JSON.parse(localStorage.getItem("AccountID")); // JSON.parse(localStorage.getItem("AcctSelected"));
    //  let AcctId = lsSelectedAcct != null ? lsSelectedAcct.acctId : 0;
    let mAccountId = AcctId;
    let NumOfRows = 10;
    let PageId = 1;
    let modelId = JSON.parse(localStorage.getItem("modelId"));
    let invMix = 0;
    // let AsOfDt = formatDate(asOfDt, "MM/dd/yyyy");

    // let PriceFlag = priceFlagInd;
    // let PriceDt = formatDate(priceDt, "MM/dd/yyyy");

    // if (modelId == -1) invMix = 1;
    // if (lsSelectedAcct !== null) {
    //     AcctId = lsSelectedAcct.acctId;
    // }
    //const postData = { AsOfId, AcctId, ModelId, PageId,invMix };
    //  let AsOfDt = formatDate(asOfDt, "MM/dd/yyyy");
    //  let showExcldAst = chkRptDispOptn;
    /////////////////////////////////////////////////////

    const postData1 = {
      AsOfId,
      AcctId,
      AsOfDt,
      ConsolidationId,
      netOfFeesInd,
      ovrdIncptn,
      runInAggregate,
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    // {
    //     "asOfId": 78034,
    //     "acctId": 0,
    //     "asOfDt": "12/31/2023",
    //     "consolidationId": 8,
    //     "netOfFeesInd": false,
    //     "ovrdIncptn": false
    //   }

    await axios
      .post("/RTAnnualPortfolio", postData1, config)
      .then((response) => {
        // console.log("Response", response)
        // console.log("got response")
        console.log("config", config);

        const rowData = response.data;
        rowData.t1 = rowData.t1 || [];
        rowData.t2 = rowData.t2 || [];
        let consolidationName = JSON.parse(
          localStorage.getItem("RelationID")
        )?.cnsldtnNm;
        for (let i = 0; i < rowData.t2.length; i++) {
          if (AcctId == 0 && runInAggregate == true) {
            rowData.t2[i].account = consolidationName;
          }
        }
        setAssetAllocModelRptData(rowData);
        debugger;
        console.log("Row Data", rowData);

        // for(var i=0;i<rowData.t2.length;i++)
        //     {

        //     }

        // const combinedArray = rowData.t2.concat(rowData.t3);

        // console.log("combinedArray", combinedArray);
        // setCombinedData(combinedArray)

        // console.log("got response 1")

        // localStorage.setItem("modelId", JSON.stringify(response.data.acctMdl[0].modelId));
        // console.log("got response 2")
        ////////////////////  setAnnualPortfolioData(response.data.model);
        // console.log("got response 3")
        ///////////////////// setModelId(JSON.parse(localStorage.getItem("modelId")));
        // console.log("got response 4")
        setFlag(true);
        setLoading(false);
        setEnableCombo(false);
        // console.log("Afterv Response Loading",loading);
      })

      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
      });
  };

  if (apicallval === 1) {
    getAnnualPortfolioInfo();
    dispatch(callremove());
  }

  const handleChangeShowNetofFees = (e) => {
    debugger;
    setChkShowNetofFees(e.currentTarget.checked);
  };

  const handleChangeBOMInceptionDates = (e) => {
    debugger;
    setChkBOMInceptionDates(e.currentTarget.checked);
  };

  const formSubmit = (event) => {
    debugger;
    event.preventDefault();
    // getModelInfo();

    setModalVisible(false);
    setCloseOption(false);
    getAnnualPortfolioInfo();
  };
  const handleSettings = (e) => {
    setInitialState({
      chkNetofFees,
      chkBOMInceptionDates,
      runInAggregate,
    });
    e.preventDefault();
    setModalVisible(true);
    setCloseOption(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setCloseOption(false);
    setChkBOMInceptionDates(initialState.chkBOMInceptionDates);
    setChkShowNetofFees(initialState.chkNetofFees);
    setRunInAggregate(initialState.runInAggregate);

    !closeOption && navigate("/dashboard");
  };

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        getAnnualPortfolioInfo();
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        // console.log("my error is " + error);
      });
  };

  // const GetAllocModelData = async () => {

  //     setLoading(true);

  //     let token = tempToken;
  //     let UserId = JSON.parse(localStorage.getItem('userId'));
  //     //
  //     let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
  //     let AcctId = lsSelectedAcct.acctId;
  //     let mAccountId = AcctId;
  //     let NumOfRows = 10;
  //     let PageId = 1;
  //     let modelId = JSON.parse(localStorage.getItem("modelId"));
  //     let invMix = 0;
  //     if(modelId==-1)
  //     invMix=1;
  //     // if (lsSelectedAcct !== null) {
  //     //     AcctId = lsSelectedAcct.acctId;
  //     // }
  //     let AsOfId = JSON.parse(localStorage.getItem('userId'));

  //     const postData = { AsOfId, AcctId, PageId,invMix };
  //     // const postData = { UserId, mAccountId};
  //     const config = {
  //         headers: {
  //             'authorization': `Bearer ${token.token}`,
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json'
  //         }

  //     };
  //     await axios.post('/RTGetAnnualPortfolio',
  //         postData,
  //         config
  //     )
  //         .then(response => {
  //           //
  //               console.log("MainResponse", response);
  //           //
  //             const rowData = response.data;
  //             for(let i=0;i<rowData.ocrtAccountProfile.length;i++)
  //                 {
  //                     rowData.ocrtAccountProfile[i].txCstAmt=parseNumber(formatNumber(rowData.ocrtAccountProfile[i].txCstAmt, "##,#.00"));
  //                     rowData.ocrtAccountProfile[i].market=parseNumber(formatNumber(rowData.ocrtAccountProfile[i].market,"##,#.00"));
  //                     rowData.ocrtAccountProfile[i].income=parseNumber(formatNumber(rowData.ocrtAccountProfile[i].income,"##,#.00"));
  //                     rowData.ocrtAccountProfile[i].yield=parseNumber(formatNumber(rowData.ocrtAccountProfile[i].yield,"##,#.00"));
  //                     rowData.ocrtAccountProfile[i].marketPercent=parseNumber(formatNumber(rowData.ocrtAccountProfile[i].marketPercent,"##,#.00"));
  //                 }
  //             setAssetAllocModelRptData(rowData.ocrtAccountProfile);
  //             setChartColor(rowData.ocAssetColorModel);

  //             if (lsSelectedAcct !== null) {
  //                 SetselAcct(lsSelectedAcct);
  //             }
  //             else {
  //                 //SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
  //             }
  //             setLoading(false);
  //             setFlag(1);
  //         })
  //         .catch((error) => {

  //             if (error.response.status === 401 )

  //             {
  //               refreshToken();

  //             }
  //             return error;
  //         });

  // }

  const filterData = (filter) => {
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };

  const handleChange = (event) => {
    if (event.target.value === null) {
      // console.log("Hii I am here");
      SetselAcct(selAcct);
      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem(
        "AcctSelected",
        JSON.stringify(JSON.parse(localStorage.getItem("acctData"))[0])
      );
    } else {
      // console.log("Hii I am here section 2");
      SetselAcct(event.target.value);
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      getAnnualPortfolioInfo();
    }
  };

  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        // console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            {/* <Header /> */}
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    <div>
      {/* Updated Header Section */}
      <div className="row d-flex justify-content-between align-items-center mx-2 py-1 border-bottom">
        {/* Report Heading */}
        <div className="col reportheading fs-6">Annual Portfolio Report</div>

        {/* Placeholder for Additional Information (if needed) */}
        <div className="col text-center">
          {/* Add dynamic text here if required */}
        </div>

        {/* Filter Button */}
        <div className="col text-end">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSettings}
            title="Click to change filters"
          >
            <FaFilter />
            <span className="px-2">Change Filters</span>
          </button>
        </div>
      </div>

      <div>
        <AntModal
          open={modalVisible}
          title="Customize the Annual Portfolio Report"
          closable={false}
          footer={[]}
        >
          <form onSubmit={formSubmit} style={{ padding: "20px" }}>
            {/* As Of Date */}
            <div style={{ marginBottom: "20px" }}>
              <label
                htmlFor="dpAsOfDt"
                className="form-label"
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                As Of Date
              </label>
              <DatePicker
                id="dpAsOfDt"
                value={asOfDt}
                calendar={CustomCalendar}
                min={new Date("1/1/1000")}
                max={maxFrmDt}
                onChange={(e) => setasOfDt(e.value)}
                style={{ width: "100%" }}
                validationMessage={
                  asOfDt == null
                    ? "Enter a valid date"
                    : asOfDt < new Date("1/1/1000")
                    ? "Enter a date in the correct format (mm/dd/yyyy)"
                    : asOfDt > maxFrmDt
                    ? "As Of Date cannot exceed the Processing Date"
                    : ""
                }
              />
            </div>

            {/* Report Display Option */}
            <hr style={{ margin: "20px 0" }} />
            <h6>Report Display Option</h6>
            <div style={{ marginBottom: "20px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={chkNetofFees}
                    onChange={handleChangeShowNetofFees}
                    name="NetofFees"
                  />
                }
                label="Show Net of Fees"
                style={{ marginBottom: "10px", display: "block" }}
              />
            </div>
            <div style={{ marginBottom: "20px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={chkBOMInceptionDates}
                    onChange={handleChangeBOMInceptionDates}
                    name="reportBOMInceptionDates"
                  />
                }
                label="Use Next BOM for Intra-Month Inception Dates"
                style={{ marginBottom: "10px", display: "block" }}
              />
            </div>
            <div>
              {AcctId == 0 && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={runInAggregate}
                      onClick={handlerunInAggregate}
                      name="chkCtr"
                    />
                  }
                  label="Run In Aggregate."
                  style={{ marginBottom: "10px", display: "block" }}
                />
              )}
            </div>

            {/* Actions */}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div
                className="btn btn-outline-secondary mx-2"
                onClick={handleCancel}
              >
                Cancel
              </div>
              <button className="btn btn-primary mx-2" type="submit">
                Submit
              </button>
            </div>
          </form>
        </AntModal>
      </div>
      {/* <Header /> */}
      {/* <div className='my-1'>
                <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                        <span className='px-2'>Account(s)</span>
                        {console.log("selctacct",selAcct)}

                        <ComboBox
                            style={{
                                width: "350px",
                            }}
                            data={selAcctData}
                            textField="extrnlAcctId"
                            dataItemKey="acctId"
                            filterable={true}
                            value={selAcct}
                            onChange={handleChange}
                            onFilterChange={filterChange}
                            disabled={enableCombo}
                        />
                    </div>
                </div>
            </div> */}

      {/* <AccountProfileDetails chartColor={chartColor} data={assetAllocModelRptData} acct={selAcct} allannualPortfolioData={annualPortfolioData} selModelId={modelId} /> */}
      {flag ? (
        <AnnualPortfolioValueDetails data={assetAllocModelRptData} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AnnualPortfolioValueRpt;
